import React, { useState, useEffect, useRef } from 'react';
import {
  CustomFile,
  defaultLinkEditorProps,
  LinkModel,
  SizeInfo,
  VideoLinkModel,
} from 'types/CommonTypes';
import { isValid } from 'libs/helper/utils';
import WhiteButton from 'components/commons/WhiteButton';
import Input from 'components/commons/Input';
import useForm from 'libs/hooks/useForm';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import VideoEditor from 'features/adminFeatures/commons/components/VideoEditor';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { updateLink, updateModel } from './commonLogic';
import {
  ToggleListButton,
  ToggleListTitle,
  LinkItemType,
  ToggleListButtonContainerStyle,
} from 'components/commons/ToogleListButton';
import { ToggleButtonType } from 'components/commons/ToggleButton';

const YouTubeEditor = ({
  model,
  origin,
  stores,
  onRegistComplete,
}: defaultLinkEditorProps<VideoLinkModel>) => {
  const titleRef = useRef<any>(null);
  const urlRef = useRef<any>(null);
  const isFormValid = useForm(stores.formValidationStore);
  const { t } = useTranslation();

  const disPlayTypeList: Array<LinkItemType> = [
    {
      id: 'external',
      title: 'Youtube',
      image: '/image/sns/youtube.svg',
    },
    {
      id: 'upload',
      title: 'Upload',
      image: '/image/grid.png',
    },
  ];

  /** @description iFrame용 prefix */
  const youtubePrefix = 'https://www.youtube.com/embed/';

  /** @description 유튜브 주소용 prefix */
  const youtubeOrigin = 'https://www.youtube.com/watch?v=';

  const [linkModel, setLinkModel] = useState<VideoLinkModel>();
  const [youtubeUrl, setYoutubeUrl] = useState<string>();
  const [sizeInfo, setSizeInfo] = useState<SizeInfo>();
  const [videoType, setVideoType] = useState<'upload' | 'external' | undefined>();
  const [videoSource, setVideoSource] = useState<CustomFile | undefined>();
  const [formValid, setFormValid] = useState<boolean>();

  const handleOnUrlPaste = (value: string) => {
    const url = getYoutubeLink(value);
    setYoutubeUrl(youtubePrefix + url);
  };

  const handleSaveLink = async () => {
    stores.uiStore.progress.show();
    const result = await updateLink(linkModel!, isValid(model), { ...stores });
    onRegistComplete(result!);
    if (result!) {
      toast(t('linkRegistration'));
    }
    stores.uiStore.progress.close();
  };

  const getYoutubeLink = (value?: string) => {
    if (typeof value === 'undefined') {
      return '';
    }
    if (value.length < 10) {
      return '';
    } else {
      return value.substring(value.length - 11);
    }
  };

  useEffect(() => {
    if (typeof model !== 'undefined') {
      const _model = { ...model };
      if (typeof _model.custom !== 'undefined') {
        _model.customObject = JSON.parse(_model.custom);
        setSizeInfo(_model.customObject?.videoSize);
        setVideoType(_model?.customObject?.videoType);
      }
      setLinkModel(_model);
      titleRef.current.value = _model.title;
      if (typeof _model.linkUrl !== 'undefined') {
        urlRef.current.value = youtubeOrigin + _model.linkUrl;
      } else {
        urlRef.current.value = '';
      }
      if (_model.customObject?.videoType === 'external') {
        handleOnUrlPaste(_model?.linkUrl as string);
        setYoutubeUrl(youtubePrefix + _model?.linkUrl);
      } else {
        if (typeof _model.thumbnail !== 'undefined') {
          setVideoSource(_model.thumbnail);
        }
      }
    } else {
      setYoutubeUrl(undefined);
      setVideoType('external');
      setLinkModel({ linkType: 'Youtube', customObject: { videoType: 'external' } });
      setVideoSource(undefined);
      if (titleRef.current !== null) {
        titleRef.current.value = '';
      }
      if (urlRef.current !== null) {
        urlRef.current.value = '';
      }
    }
  }, [model]);

  useEffect(() => {
    if (typeof videoType !== 'undefined') {
      updateModel(setLinkModel, 'customObject', {
        ...linkModel?.customObject,
        videoType: videoType,
      });
    }
  }, [videoType]);

  useEffect(() => {
    if (videoType === 'upload') {
      if (typeof linkModel?.thumbnail === 'undefined') {
        setFormValid(false);
      } else {
        setFormValid(isFormValid.result);
      }
    } else {
      setFormValid(isFormValid.result);
    }
  }, [isFormValid]);
  return (
    <>
      <Row>
        <ToggleListButtonContainerStyle style={{ width: '100%' }}>
          <ToggleListTitle>{t('displayForm')}</ToggleListTitle>
          <ToggleListButton
            defaultChecked={videoType}
            toggleItems={disPlayTypeList}
            onClick={(item: LinkItemType) => {
              if (item.id !== 'upload') {
                updateModel(setLinkModel, 'thumbnail', undefined);
                setVideoType(item.id as any);
              } else {
                if (typeof youtubeUrl !== 'undefined') {
                  stores.uiStore.confirm.show({
                    message: t('removeYoutubeUrl'),
                    okText: t('confirm'),
                    closeText: t('cancellation'),
                    onConfirmed: () => {
                      setYoutubeUrl(undefined);
                      urlRef.current.value = '';
                    },
                    onCancel: () => {
                      setVideoType('external');
                    },
                  });
                }
                setVideoType(item.id as any);
              }
            }}
          />
        </ToggleListButtonContainerStyle>
      </Row>

      {videoType === 'upload' ? (
        <VideoEditorStyle>
          <VideoEditor
            mode="edit"
            video={videoSource}
            isCover={sizeInfo?.isCover}
            onSettingComplete={(videoSize: SizeInfo) => {
              updateModel(setLinkModel, 'customObject', {
                ...linkModel?.customObject,
                videoSize,
              });
            }}
            maxHeight="500px"
            onVideoChange={(video: any) => {
              updateModel(setLinkModel, 'thumbnail', video);
            }}
          />
        </VideoEditorStyle>
      ) : (
        <YoutubeContainerStyle>
          <YoutubeVideoStyle>
            <iframe
              className="youtube-frame"
              height="275"
              // src={youtubePrefix + youtubeUrl}
              src={youtubeUrl}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media;fullscreen; gyroscope; picture-in-picture"
            />
          </YoutubeVideoStyle>
          {typeof youtubeUrl === 'undefined' && (
            <NoSourceStyle className="absolute-center">{t('youtubeUrl')}</NoSourceStyle>
          )}
        </YoutubeContainerStyle>
      )}
      <YoutubeTitleStyle>
        <Input
          id="title"
          ref={titleRef}
          placeholder="Title"
          containerStyle={{ height: 57 }}
          required
          onChangeValue={(value: string) => {
            updateModel(setLinkModel, 'title', value);
          }}
          defaultValue={linkModel?.title}
          type="text"
        />
      </YoutubeTitleStyle>
      <YoutubeUrlStyle>
        <Input
          ref={urlRef}
          id="linkUrl"
          containerStyle={{ height: 57 }}
          placeholder="Youtube Url"
          required
          disabled={videoType === 'external' ? false : true}
          onChangeValue={(value: string) => {
            updateModel(setLinkModel, 'linkUrl', getYoutubeLink(value));
            handleOnUrlPaste(value);
          }}
          onPaste={(e) => {
            handleOnUrlPaste(e.clipboardData.getData('Text'));
          }}
          format="url"
          defaultValue={
            typeof linkModel?.linkUrl !== 'undefined' && linkModel?.linkUrl !== ''
              ? youtubeOrigin + linkModel?.linkUrl
              : ''
          }
          type="text"
        />
      </YoutubeUrlStyle>
      <WhiteButton
        type="SAVE"
        onClick={handleSaveLink}
        disabled={!formValid}
        style={{ marginTop: 20 }}
      />
    </>
  );
};

const YoutubeContainerStyle = styled.div`
  background: #000;
  border-radius: 6px;
  position: relative;
  margin-bottom: 60px;
`;

const VideoEditorStyle = styled.div`
  /* width: 100%;
  height: 100px; */
  margin: 0 0 10px 0;
  /* border: 1px solid #eeeeee; */
  /* width: 460px;
  height: 390px; */
`;

const YoutubeVideoStyle = styled.div`
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  min-height: 50px;
  justify-content: space-between;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
  overflow: hidden;
`;

const YoutubeTitleStyle = styled.div`
  margin: 10px 0 10px 0;
`;

const YoutubeUrlStyle = styled.div``;

const NoSourceStyle = styled.div`
  position: absolute;
  color: #fff;
  width: 300px;
  margin: auto;
  text-align: center;
`;

export default YouTubeEditor;
