import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { JoinDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';

import TopWrapper from 'features/mainFeatures/auth/components/TopWrapper';
import TopWrapperLeft from 'features/mainFeatures/auth/components/TopWrapperLeft';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Form from 'controls/Form';
import BlueBtn from 'features/mainFeatures/auth/components/BlueBtn';
// import { Input } from 'components/commons';
import AuthInput from 'features/mainFeatures/auth/components/AuthInput';
import { toast } from 'react-toastify';

interface InfoDto {
  email?: string;
  userName?: string;
}

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authStore } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isActiveButton, setIsActiveButton] = useState<boolean>(false);

  const idRef = useRef<any>(null);

  const handleClick = () => {
    navigate('/login');
  };

  const handleChangeUserInfo = (info: string) => {
    idRef.current = info;
    idRef.current.length > 4 ? setIsActiveButton(true) : setIsActiveButton(false);
  };

  const handleClickResetPassword = () => {
    const token = searchParams.get('token');
    if (token !== null) {
      authStore.resetPassword(token, {
        newPassword: idRef.current,
        confirmNewPassword: idRef.current,
      });
    } else {
      //TODO : translate
      toast('잘못된 값이 있습니다.');
    }
  };

  return (
    <FindIdContainer>
      <LoginTitle>{process.env.REACT_APP_SERVEICE_NAME}</LoginTitle>
      <div style={{ width: '300px' }}>
        <AuthInput
          // defaultValue={!_.isUndefined(meStore.me) ? meStore.me?.userName : ''}
          label={t('newPassword')}
          // subLabel="(영문, 숫자만 가능. 15자 이내)"
          placeholder={t('newPassword')}
          maxLength={20}
          type="password"
          onChange={handleChangeUserInfo}
        />
      </div>
      {/* <Form
        isValid={(result: boolean) => {
          setIsValidForm(result);
        }}
      >
        <Input
          required
          // label="ID / UserName"
          expression={/^[A-Za-z0-9+]{4,15}$/}
          id="IdInput"
          placeholder="아이디"
          type="text"
          containerStyle={{
            background: 'white',
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
            borderRadius: '30px',
            margin: '1em 1em 0 1em',
            paddingLeft: '7em',
          }}
          labelStyle={{ position: 'relative', top: '57px', left: '-6px', width: '100px' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClickLogin();
            }
          }}
          onChangeValue={(e) => {
            if (e !== '') {
              setIsValidateIdInput(true);
            }
            setLoginData((prevState: JoinDto) => {
              return {
                ...prevState,
                userName: e,
              };
            });
          }}
        />
      </Form> */}
      {/* <LoginBtn validState={isValidForm} onClick={handleClickFindPassword}>
        비밀번호 찾기
      </LoginBtn> */}
      <FindPasswordButtonStyled onClick={handleClickResetPassword} isActiveButton={isActiveButton}>
        {t('resetPassword')}
      </FindPasswordButtonStyled>
    </FindIdContainer>
  );
};

const flexCenter = css`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const FindIdContainer = styled.div`
  margin: auto;
  height: 100vh;
  ${flexCenter};
  flex-direction: column;
`;

const LoginTitle = styled.div`
  text-align: center;
  font-family: 'Barlow';
  font-size: 100px;
  color: #3e64ea;
  font-weight: 500;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 4em;
  }
`;

const LoginLinkContainer = styled.div`
  ${flexCenter}
  margin-top: 2em;
  width: 18em;
  > a {
    font-size: 16px;
    margin-bottom: 1em;
  }
  > a:first-child {
    margin-right: 20px;
  }
  @media ${(props) => props.theme.media.mobile} {
    width: inherit;
    flex-wrap: wrap;
  }
`;

const LoginBtn = styled(BlueBtn)<{ validState?: boolean }>`
  margin-top: 56px;
  cursor: ${({ validState }) => (validState ? 'pointer' : 'none')};
  /* pointer-events: ${({ validState }) => (validState ? 'auto' : 'none')}; */
  background-color: ${({ validState }) => (validState ? '#3e64ea' : '#999999')};
`;

const FindPasswordButtonStyled = styled(BlueBtn)<{ isActiveButton?: boolean }>`
  margin-top: 56px;
  width: 160px;
  cursor: ${({ isActiveButton }) => (isActiveButton ? 'pointer' : 'none')};
  pointer-events: ${({ isActiveButton }) => (isActiveButton ? 'auto' : 'none')};
  background-color: ${({ isActiveButton }) => (isActiveButton ? '#3e64ea' : '#999999')};
`;

export default ResetPassword;
