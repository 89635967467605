import React, { useEffect, useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';

const QtyController = ({
  maxCount,
  onChangeValue,
}: {
  maxCount?: number;
  onChangeValue: (value: number) => void;
}) => {
  const [count, setCount] = useState<number>(1);
  const handleReduce = () => {
    if (count > 1) {
      setCount((prev) => prev - 1);
    }
  };
  const handleAdd = () => {
    if (typeof maxCount !== 'undefined') {
      if (count < maxCount) {
        setCount((prev) => prev + 1);
      }
    } else {
      setCount((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (typeof onChangeValue !== 'undefined') {
      onChangeValue(count);
    }
  }, [count]);

  return (
    <>
      <ContainerStyle>
        <div className="row">
          <IconStyle>
            <RemoveIcon style={{ fontSize: 20, color: '#A6A6A6' }} onClick={handleReduce} />
          </IconStyle>
          <div style={{ fontSize: 20, padding: '0 0px' }}>{count}</div>
          <IconStyle>
            <AddIcon style={{ fontSize: 20, color: '#A6A6A6' }} onClick={handleAdd} />
          </IconStyle>
        </div>
      </ContainerStyle>
    </>
  );
};

const ContainerStyle = styled.div`
  width: 120px;
`;

const IconStyle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 40px;
  width: 30px;
  height: 30px;
  justify-content: center;
`;
export default QtyController;
