import React, { memo } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import TemplateMaster from 'features/adminFeatures/template/containers/TemplateMaster';

const TemplateWrapper = observer(({ isSimulator }: { isSimulator?: boolean }) => {
  const { userStore } = useStore();

  const bindSimulator = () => {
    if (typeof userStore.userInfo !== 'undefined') {
      return <TemplateMaster userInfo={userStore.userInfo} />;
    }
  };

  return <div>{bindSimulator()}</div>;
});

export default memo(TemplateWrapper);
