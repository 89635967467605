/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState, UIEvent } from 'react';
import {
  Title,
  SelfIntroduction,
  SocialComponent,
} from 'features/adminFeatures/template/components';
import FollowListContainer from 'features/mainFeatures/follow/containers/FollowListContainer';
import { TextAreaStyle } from 'features/adminFeatures/template/components/TemplateStyle';
import { CustomType, CustomUserInfoDto, CustomUserProfile } from 'types/CommonTypes';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import BackgroundImageComponent from 'features/adminFeatures/template/components/BackgroundImageComponent';
import LinksComponent from 'features/adminFeatures/template/components/LinksComponent';
import Fnb from 'features/commonFeatures/navigationBar/containers/Fnb';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from 'components/commons/Avatar';
import styled from 'styled-components';
import 'features/adminFeatures/template/components/TemplateMaster.css';
import ItemsComponent from '../components/ItemsComponent';
import useScreenType from 'libs/hooks/useScreenType';

type Props = {
  userInfo: CustomUserInfoDto | undefined;
  isSimulator?: boolean;
};

const TemplateMaster = observer(({ userInfo, isSimulator }: Props) => {
  const { templateStore, simulatorStore } = useStore();
  const { userName } = useParams();
  const [style, setStyle] = useState<CustomType>({});
  const [containerHover, setContainerHover] = useState<boolean>(false);
  const [isUserPage, setIsUserPage] = useState<boolean>(false);
  const [didScroll, setDidScroll] = useState<boolean>(false);
  const [useBackground, setUseBackground] = useState<boolean>();
  const [userProfile, setUserProfile] = useState<CustomUserProfile>();
  const getUrl = window.location.pathname;
  const screenType = useScreenType();

  const handleMouseOver = () => {
    if (isUserPage) {
      setContainerHover(false);
    } else {
      setContainerHover(true);
    }
  };

  const handleMouseOut = () => {
    setContainerHover(false);
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const currentScroll = e.currentTarget.scrollTop;
    if (isUserPage && currentScroll > 50) {
      setDidScroll(true);
      setContainerHover(false);
    } else if (currentScroll < 50) {
      setDidScroll(false);
    }
    /** 240만큼 스크롤 내리면 헤더에 프로필을 생성해준다 */
    if (e.currentTarget.scrollTop >= 240) {
      templateStore.setIsScrollHeaderProfile(true);
    } else {
      templateStore.setIsScrollHeaderProfile(false);
    }
  };

  const applyUserStyle = () => {
    if (typeof userInfo?.layoutStyle?.style !== 'undefined') {
      const styleValue = JSON.parse(userInfo?.layoutStyle?.style);
      if (
        typeof styleValue?.useBackgroundImage === 'undefined' ||
        styleValue.useBackgroundImage === 'true'
      ) {
        setUseBackground(true);
      } else {
        setUseBackground(false);
      }
      setStyle(styleValue);
    } else {
      setUseBackground(true);
    }
  };

  const init = () => {
    if (getUrl === `/${userName}`) {
      setIsUserPage(true);
      setContainerHover(false);
    } else {
      setIsUserPage(false);
    }
  };

  // useEffect(() => {
  //   if (typeof templateStore.currentTemplate?.style !== 'undefined') {
  //     const styleValue = JSON.parse(templateStore.currentTemplate?.style);
  //     if (
  //       typeof styleValue?.useBackgroundImage === 'undefined' ||
  //       styleValue.useBackgroundImage === 'true'
  //     ) {
  //       setUseBackground(true);
  //     } else {
  //       setUseBackground(false);
  //     }
  //     setStyle(styleValue);
  //   } else {
  //     setUseBackground(true);
  //   }
  // }, [templateStore.currentTemplate]);

  /**
   * @description userInfo가 있는 경우는, userHomePage에서의 호출임.
   */
  useEffect(() => {
    if (typeof userInfo !== 'undefined') {
      setUserProfile(userInfo.profile);
      applyUserStyle();
    }
  }, [userInfo]);

  /**
   * @description simulatorStore 있는 경우는, admin에서의 호출임.
   */
  useEffect(() => {
    if (typeof simulatorStore.profile !== 'undefined') {
      setUserProfile(simulatorStore.profile);
    }
  }, [simulatorStore.profile]);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {useBackground === true && (
        <BackgroundImageComponent profile={userProfile} useEmpty={false} />
      )}
      {typeof style !== 'undefined' && (
        <ContainerStyle
          id="container"
          style={{ height: templateStore.isSimulator ? '730px' : '100vh' }}
          containerHover={containerHover}
          customStyle={style}
          didScroll={didScroll}
        >
          <div
            id="content"
            onScroll={handleScroll}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={{
              height: '100%',
              overflowY: templateStore.isSimulator === true ? 'scroll' : 'auto',
            }}
          >
            <ProfileWrapStyle>
              {/* 공용 컴포넌트 */}
              <Avatar
                profile={userInfo?.profile}
                customStyle={style}
                size={screenType === 'mobile' ? 'large' : 'medium'}
                containerHover={containerHover}
              />
              <TitleAreaStyle customStyle={style}>
                <Title profile={userInfo?.profile} />
                <SelfIntroduction profile={userInfo?.profile} />
                <SocialComponent socials={userInfo?.socialLinks} />
              </TitleAreaStyle>
            </ProfileWrapStyle>
            <ShapeContainerStyle
              customStyle={style}
              containerHover={containerHover}
              didScroll={didScroll}
              isUserPage={isUserPage}
            >
              <ShapeStyle
                customStyle={style}
                isUserPage={isUserPage}
                containerHover={containerHover}
              />
            </ShapeContainerStyle>
            {templateStore.currentMode === 'follow' && (
              <>
                <CloseContainerStyle
                  onClick={() => {
                    templateStore.setCurrentMode('link');
                  }}
                >
                  <CloseIconStyle>
                    <CloseIcon style={{ marginLeft: 10 }} />
                    <CloseTitleStyle>Close</CloseTitleStyle>
                  </CloseIconStyle>
                </CloseContainerStyle>
                <FollowListStyle customStyle={style}>
                  <FollowListContainer />
                </FollowListStyle>
              </>
            )}
            {templateStore.currentMode === 'link' && (
              <LinkStyle customStyle={style}>
                <LinksComponent links={userInfo?.links} />
              </LinkStyle>
            )}
            {templateStore.currentMode === 'item' && (
              <LinkStyle customStyle={style}>
                <ItemsComponent links={userInfo?.links} />
              </LinkStyle>
            )}
            <FnbStyle customStyle={style}>
              <Fnb userInfo={userInfo?.profile} />
            </FnbStyle>
          </div>
        </ContainerStyle>
      )}
    </>
  );
});

const ContainerStyle = styled.div<{
  customStyle: CustomType;
  containerHover: boolean;
  didScroll: boolean;
}>`
  z-index: 1;
  ${(props) => props.customStyle.container}
  ${(props) => (props.containerHover ? '' : props.customStyle.containerHoverStyle)}
  transition: ${(props) => (props.containerHover ? 'all 0.5s ease-in' : 'all 0.5s ease-in')};
  height: 100vh;
  background-color: ${(props) =>
    props.containerHover || props.didScroll
      ? props.customStyle.shape?.background || props.customStyle.shape?.color
      : props.customStyle.container?.background};
`;

const ProfileWrapStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
`;

const ShapeContainerStyle = styled.div<{
  customStyle: CustomType;
  containerHover: boolean;
  didScroll: boolean;
  isUserPage: boolean;
}>`
  height: 0;
  display: flex;
  z-index: -1;
  ${(props) => props.customStyle.shapeContainer};
  ${(props) =>
    props.didScroll || props.containerHover
      ? props.customStyle.downScroll || props.customStyle.shapeContainerHover
      : props.customStyle.upScroll}
  ${(props) =>
    props.containerHover === true && props.isUserPage === false
      ? props.customStyle.shapeContainerHover
      : ''}
  ${(props) => (props.isUserPage ? props.customStyle.myPageShapeContainer : '')}
`;

const ShapeStyle = styled.div<{
  customStyle: CustomType;
  isUserPage?: boolean;
  containerHover?: boolean;
}>`
  ${(props) => props.customStyle.shape}
  &::before,
  &::after {
    ${(props) => props.customStyle.shapeBeforeAfter}
    width: ${(props) =>
      props.isUserPage ? props.customStyle.bigShape?.width : props.customStyle.shape?.width};
    height: ${(props) =>
      props.isUserPage ? props.customStyle.bigShape?.height : props.customStyle.shape?.height};
  }
  &::before {
    ${(props) => props.customStyle.shapeBefore}
  }
  &::after {
    ${(props) => props.customStyle.shapeAfter}
  }
  ${(props) => (props.containerHover ? props.customStyle.shapeHover : '')}
  width: ${(props) =>
    props.isUserPage ? props.customStyle.bigShape?.width : props.customStyle.shape?.width};
  height: ${(props) =>
    props.isUserPage ? props.customStyle.bigShape?.height : props.customStyle.shape?.height};
  ${(props) => (props.isUserPage ? props.customStyle.myPageShape : '')}

  @media screen and (max-width: 650px) {
    ${(props) => (props.isUserPage ? props.customStyle.middleShape : '')}
    &:before,
    &:after {
      ${(props) => (props.isUserPage ? props.customStyle.middleShape : '')}
    }
  }
  @media screen and (max-width: 400px) {
    ${(props) => (props.isUserPage ? props.customStyle.smallShape : '')}
    &:before,
    &:after {
      ${(props) => (props.isUserPage ? props.customStyle.smallShape : '')}
    }
  }
`;

const TitleAreaStyle = styled(TextAreaStyle)<{ customStyle?: CustomType }>`
  display: flex;
  flex-direction: column;
  width: 90%;
  text-align: center;
  position: relative;
  z-index: 10;
  max-width: 530px;
  > div {
    ${(props) => props.customStyle?.titleStyle}
  }
`;

const LinkStyle = styled.div<{ customStyle?: CustomType }>`
  > div > div > div {
    ${(props) => props.customStyle?.linkBoxStyle}
    &:before {
      ${(props) => props.customStyle?.linkBoxBefore}
    }
    &:hover {
      ${(props) => props.customStyle?.linkBoxHoverStyle}
      background: 'initial';
    }
  }
  > div > div:nth-child(4n + 1) > div {
    ${(props) => props.customStyle?.linkBoxStyle1}
  }
  > div > div:nth-child(4n + 2) > div {
    ${(props) => props.customStyle?.linkBoxStyle2}
  }
  > div > div:nth-child(4n + 3) > div {
    ${(props) => props.customStyle?.linkBoxStyle3}
  }
  > div > div:nth-child(4n + 4) > div {
    ${(props) => props.customStyle?.linkBoxStyle4}
  }
  > div > div > div > a,
  > div > div > div > a > div {
    ${(props) => props.customStyle?.linkTextStyle}
  }
  > div > div > div > a > div > div {
    ${(props) => props.customStyle?.boxShadow}
  }
`;

const FollowListStyle = styled.div<{ customStyle?: CustomType }>`
  margin: 0 auto;
  > div {
    margin: 0 auto !important;
    ${(props) => props.customStyle?.linkBoxStyle}
  }
`;

const FnbStyle = styled.div<{
  customStyle?: CustomType;
}>`
  ${(props) => props.customStyle?.fnbStyle}
  top: 100vh;
  position: sticky;
  margin-top: 30px;
  text-align: center;
  bottom: 20px;
`;

const CloseContainerStyle = styled.div`
  max-width: 550px;
  padding: 0 20px 0 20px;
  margin: auto;
`;

const CloseIconStyle = styled.div`
  width: 100px;
  height: 40px;
  border-radius: 60px;
  background: #fff;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
`;

const CloseTitleStyle = styled.div`
  margin: 0 10px 0 0;
`;

export default TemplateMaster;
