import React, { useState, useEffect } from 'react';

import { CropType } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import { FileDto } from 'services/data-contracts';
import Cropper from 'react-easy-crop';
/**
 * @description 특정 이미지를 크롭후 리사이즈 한다.
 * @param param0
 * @returns
 */
const ImageEditor = ({
  image,
  cropType,
  onCropComplete,
}: {
  image: any;
  cropType?: CropType;

  currentImage?: FileDto;
  multipleUpload?: string;
  cropSize?: { width: number; height: number };
  onCropComplete?: (croppedArea: any, croppedAreaPixels: any) => void;
}) => {
  const { t } = useTranslation();

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspectRatio, setAspectRatio] = useState<any>();

  // const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
  //   setCroppedAreaPixels(croppedAreaPixels);
  // }, []);

  useEffect(() => {
    if (typeof cropType !== 'undefined') {
      switch (cropType) {
        case 'horizontal':
          setAspectRatio(2);
          break;
        case 'square':
          setAspectRatio(1);
          break;
        case 'vertical':
          setAspectRatio(0.5);
          break;
        default:
          if (typeof cropType === 'number') {
            setAspectRatio(cropType);
          }
          break;
      }
    }
  }, [cropType]);

  return (
    <Cropper
      image={image.publicUrl}
      crop={crop}
      zoom={zoom}
      aspect={aspectRatio}
      onCropChange={setCrop}
      objectFit={'contain'}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
    />
  );
};

export default ImageEditor;
