const socialServiceList = [
  // { key: '밴드캠프', value: 'bandcamp.png' },
  // { key: '카메오', value: 'cameo.png' },
  // { key: '클럽하우스', value: 'clubhouse.png' },
  // { key: '링크드인', value: 'linkedin.png' },
  // { key: '패트리온', value: 'patereon.png' },
  // { key: '핀터레스트', value: 'pinterest.png' },
  // { key: '포쉬마크', value: 'poshmark.png' },
  // { key: '시그널', value: 'signal.png' },
  // { key: '스냅챗', value: 'snapchat.png' },
  // { key: '사운드클라우드', value: 'soundcloud.png' },
  // { key: '스포티파이', value: 'spotify.png' },
  // { key: '서브스택', value: 'substack.png' },
  { key: '유튜브', value: 'youtube.svg', type: 'Youtube', translation: 'youtube' },
  // { key: '텔레그램', value: 'telegram.png' },
  { key: '인스타그램', value: 'instagram.svg', type: 'Instagram', translation: 'instaGram' },
  // { key: '트위치', value: 'twitch.png' },
  { key: '페이스북', value: 'facebook.svg', type: 'Facebook', translation: 'faceBook' },
  // { key: '왓츠앱', value: 'whatsapp.png' },
  { key: '트위터', value: 'twitter.svg', type: 'Twitter', translation: 'twitter' },
  { key: '틱톡', value: 'tiktok.svg', type: 'Tiktok', translation: 'tikTok' },
];

export default socialServiceList;
