import React, { useState } from 'react';
import styled from 'styled-components';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import Typography from '@mui/material/Typography';
import _, { debounce } from 'lodash';

interface SelectProps {
  label: string;
  value: string;
}

interface SelectStyleProps {
  height: string;
  width: string;
}

const Select = ({
  selectList,
  defaultItem,
  selectstyle,
  placeholder,
  onClick,
}: {
  selectList: SelectProps[];
  defaultItem?: SelectProps;
  selectstyle?: SelectStyleProps;
  placeholder?: string;
  onClick: (itme?: any | undefined) => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectProps>();

  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', height: '50px' }}>
      <TextSelectLabel
        onClick={() => {
          setOpen(!open);
        }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        selectstyle={!_.isUndefined(selectstyle) ? selectstyle : undefined}
      >
        {!_.isUndefined(selectedItem) ? (
          <>{selectedItem.label}</>
        ) : (
          <>
            <span style={{ color: '#333333' }}>{!_.isUndefined(placeholder) && placeholder}</span>
            {!_.isUndefined(defaultItem) && defaultItem.label}
          </>
        )}
        {open === true ? (
          <KeyboardArrowDownIcon style={{ transition: 'all 0.45s ease-out' }} />
        ) : (
          <KeyboardArrowUpIcon style={{ transition: 'all 0.45s ease-out' }} />
        )}
      </TextSelectLabel>
      {open === true && (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <TextSelect selectstyle={!_.isUndefined(selectstyle) ? selectstyle : undefined}>
            {selectList.map((item: SelectProps, i: number) => (
              <TextOption
                key={i}
                value={item.value}
                selectedItem={selectedItem}
                onClick={() => {
                  onClick(item.value);
                  setSelectedItem(item);
                  setOpen(false);
                }}
              >
                {item.label}
                {/* {item.value === selectedItem?.value && (
                  <CheckRoundedIcon
                    sx={{
                      fontSize: '24px',
                      paddingRight: '8px',
                      alignItems: 'center',
                      height: '40px',
                    }}
                  />
                )} */}
              </TextOption>
            ))}
          </TextSelect>
        </ClickAwayListener>
      )}
    </div>
  );
};

const TextSelectLabel = styled(Typography)<{ selectstyle: SelectStyleProps | undefined }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  width: ${(props) => (!_.isUndefined(props.selectstyle) ? props.selectstyle?.width : '100%')};
  height: ${(props) => (!_.isUndefined(props.selectstyle) ? props.selectstyle?.height : '60px')};

  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #dddddd;
  &:hover {
    background: #efefef;
  }
`;

const TextSelect = styled.ul<{ selectstyle: SelectStyleProps | undefined }>`
  padding-left: 0px;
  width: ${(props) => (!_.isUndefined(props.selectstyle) ? props.selectstyle?.width : '100%')};
  height: 120px;

  overflow-y: scroll;
  /* text-align: end; */
  margin-top: 214px;
  position: absolute;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.22);
  border-radius: 8px;
  right: -4px;
  z-index: 100;
`;

const TextOption = styled.li<{ value: string; selectedItem: SelectProps | undefined }>`
  background-color: ${(props) =>
    !_.isUndefined(props.selectedItem) && props.selectedItem?.value === props.value
      ? '#f2f2f2'
      : 'white'};
  height: 60px;
  line-height: 60px;
  /* text-align: end; */
  padding-left: 16px;
  padding-right: 16px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

export default Select;
