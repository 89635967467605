import _ from 'lodash';
import { InputFormat, ValidationType } from 'types/CommonTypes';

function validityCheck(value: string, format: InputFormat) {
  const expressions = {
    text: /[^(가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9)]/,
    // email: /[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/,
    email: /^[a-zA-Z0-9]+(?:[_.-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[_.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,4}$/,
    tel: /\d{2,3}-\d{3,4}-\d{4}/,
    number: /^[0-9\b]+$/,
    // url: /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi,
    url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
  };

  /**
   * null일경우 유효성 통과이므로 true를 반환한다.
   */
  return value.match((expressions as any)[format]) === null ? false : true;
}

function validityCustomCheck(value: string, validation: ValidationType) {
  /**
   * null일경우 유효성 통과이므로 true를 반환한다.
   */
  const result = value.match(validation.expression) === null ? false : true;
  return result;
}
export { validityCheck, validityCustomCheck };
