import React, { useRef, useState, useEffect } from 'react';
import { TemplateDto, UserStyleDto } from 'services/data-contracts';
import { CommonButtonStyle } from 'resources/styles/common/styles';
import { updateModel } from 'components/items/commonLogic';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import TemplateFormModal from 'features/adminFeatures/template/components//TemplateFormModal';
import MobileCommonFooter from 'features/adminFeatures/commons/components/MobileCommonFooter';
import PaginationComponent from 'components/commons/PaginationComponent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useScreenType from 'libs/hooks/useScreenType';
import styled from 'styled-components';

type DefaultProps = {
  onShowSimulator: () => void;
  onCloseSimulator: () => void;
};

const TemplateManagerContainer = observer(({ onShowSimulator }: DefaultProps) => {
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const { templateStore, meStore, parameterStore } = useStore();
  const [model, setModel] = useState<UserStyleDto>();
  const editType = useRef<'create' | 'edit'>();
  const screenType = useScreenType();
  const navigation = useNavigate();
  const { t } = useTranslation();

  const closeModal = () => {
    setOpenTemplateModal(false);
  };

  // 삭제하기 함수
  const handleDeleteTemplate = async (id: number) => {
    const response: any = await templateStore.deleteTemplate(id);
    if (typeof response?.data?.errorCode !== 'undefined') {
      toast(t('fail'));
    } else {
      templateStore.getTemplates();
      toast(t('templateDelete'));
    }
  };

  // 저장하기 함수
  const handleClickSaveTemplate = () => {
    if (typeof model !== 'undefined') {
      meStore.saveMyLayout(model);
    }
    toast(t('templateApply'));
  };

  // 페이지네이션 관련 함수
  const handleClickPageNumber = async (e: any, pageNumber: number) => {
    await parameterStore.addParam('page', pageNumber - 1);
    replacementPage();
  };
  const replacementPage = () => {
    navigation(`/admin/spacepage/templatemanager`);
  };
  const init = () => {
    templateStore.getTemplates(parameterStore.parameters);
  };

  useEffect(() => {
    init();
  }, [parameterStore.parameters]);

  return (
    <>
      <ContainerStyle>
        {/* 템플릿 추가하기 */}
        <CreateButtonStyle
          onClick={() => {
            editType.current = 'create';
            setOpenTemplateModal(true);
          }}
        >
          {t('templateAdd')}
        </CreateButtonStyle>

        {/* 리스트 목록 */}
        <ListContainerStyle>
          {templateStore.templates?.map((template: TemplateDto) => (
            <ListWrapStyle
              key={template?.id}
              style={{ backgroundImage: `url(${template?.thumbnail?.publicUrl})` }}
              onClick={() => {
                templateStore.setTemplate(template);
                updateModel(setModel, 'template', { id: template.id });
              }}
            >
              <TemplateButtonStyle>
                <TemplateStyle>
                  {template?.name === templateStore.currentTemplate?.name && (
                    <SelectedIconStyle>
                      <CheckCircleIcon style={{ color: 'green', fontSize: 30 }} />
                    </SelectedIconStyle>
                  )}
                  <TemplateTitleStyle>{template?.name}</TemplateTitleStyle>
                </TemplateStyle>
                <ContainerButtonStyle>
                  <DeleteButtonStyle
                    type="button"
                    onClick={() => {
                      handleDeleteTemplate(template.id as number);
                    }}
                  >
                    {t('delete')}
                  </DeleteButtonStyle>
                  <EditButtonStyle
                    type="button"
                    onClick={() => {
                      editType.current = 'edit';
                      setOpenTemplateModal(true);
                    }}
                  >
                    {t('edit')}
                  </EditButtonStyle>
                </ContainerButtonStyle>
              </TemplateButtonStyle>
            </ListWrapStyle>
          ))}

          {/* 페이지네이션 */}
          {templateStore.pagination?.totalPages > 0 && (
            <PaginationComponent
              pagination={templateStore.pagination}
              handleClickPageNumber={handleClickPageNumber}
            />
          )}

          {/* 템플릿 저장하기 */}
          {screenType === 'mobile' ? (
            <MobileCommonFooter
              isValidForm
              onSaveClick={() => {}}
              onShowSimulator={onShowSimulator}
            />
          ) : (
            <>
              <SaveButtonStyle
                onClick={() => {
                  handleClickSaveTemplate();
                }}
              >
                {t('saveTemplate')}
              </SaveButtonStyle>
            </>
          )}
        </ListContainerStyle>
        <TemplateFormModal
          type={editType.current!}
          isOpen={openTemplateModal}
          onClose={() => closeModal()}
        />
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  margin: 10px 10px 10px 10px;
  @media ${(props) => props.theme.media.mobile} {
    padding: 10px 0 0 0;
  }
`;

const CreateButtonStyle = styled(CommonButtonStyle)`
  @media ${(props) => props.theme.media.mobile} {
    font-size: 15px;
    height: 45px;
  }
`;

const ListContainerStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 10% 0;
`;

const ListWrapStyle = styled.div`
  flex: 1 1 auto;
  cursor: pointer;
  z-index: 1;
  position: relative;
  margin: 6px 6px 6px 6px;
  text-align: center;
  padding: 12px 12px 12px 12px;
  border-radius: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 2px 5px rgba(0, 0, 0, 0.24);
  transition: all 0.2s linear;
  :hover {
    transform: scale(1.03);
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    backdrop-filter: blur(0.5px);
  }
  background-size: cover;
`;

const TemplateStyle = styled.div`
  width: 140px;
  height: 40px;
`;

const SelectedIconStyle = styled.div`
  position: absolute;
  top: -22px;
  left: -20px;
`;

const TemplateTitleStyle = styled.div`
  color: white;
  font-size: 18px;
  font-weight: 600;
`;

const ContainerButtonStyle = styled.div`
  margin: 70px 0 0 0;
`;

const DeleteButtonStyle = styled.button`
  cursor: pointer;
  width: 60px;
  height: 25px;
  margin: 2px 2px 2px 2px;
  color: rgba(0, 0, 0, 0.712);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: white;
  font-size: 14px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 2px 5px rgba(0, 0, 0, 0.24);
`;

const EditButtonStyle = styled(CommonButtonStyle)`
  cursor: pointer;
  width: 60px;
  height: 25px;
  margin: 2px;
  color: #3e64ea;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: white;
  font-size: 14px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.24), 0 2px 5px rgba(0, 0, 0, 0.24);
`;

const TemplateButtonStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  height: 170px;
`;

const SaveButtonStyle = styled(CommonButtonStyle)`
  position: fixed;
  width: 580px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 15px;
    height: 45px;
  }
  bottom: 0px;
  z-index: 100;
`;

export default TemplateManagerContainer;
