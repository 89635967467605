import React, { CSSProperties } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const IconButton = ({
  image,
  width,
  useBadge,
  selected = true,
  children,
  containerStyle,
  lastItem,
  onClick,
  onBadgeClick,
}: {
  image?: string;
  width?: number;
  selected?: boolean;
  useBadge?: boolean;
  lastItem?: boolean;
  containerStyle?: CSSProperties;
  children?: React.ReactElement;
  onClick?: (e: any) => void;
  onBadgeClick?: () => void;
}) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: typeof lastItem !== 'undefined' ? 0 : 10,
        ...containerStyle,
      }}
    >
      <IconButtonStyle
        onClick={(e) => {
          typeof onClick == 'function' && onClick(e);
        }}
      >
        <div style={{ textAlign: 'center' }}>
          {typeof image !== 'undefined' ? (
            <img
              src={image}
              style={{
                width: width,
                opacity: selected === true ? 1 : 0.5,
                filter: selected !== true ? 'grayscale(100%)' : 'none',
              }}
              alt=""
            />
          ) : (
            <>
              {typeof children !== 'undefined' && (
                <div style={{ width: width, height: '100%' }}>{children}</div>
              )}
            </>
          )}
        </div>
      </IconButtonStyle>

      {useBadge && (
        <Badge onClick={onBadgeClick}>
          <CloseIcon style={{ color: '#fff', fontSize: 16 }} />
        </Badge>
      )}
    </div>
  );
};

const IconButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 0 0 0;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  margin: 0 0 0 0;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  }
`;

const Badge = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  top: -5px;
  right: -5px;
  border-radius: 20px;
  background-color: #efefef;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
`;

export default IconButton;
