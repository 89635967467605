import React, { useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import MainContainer from 'features/mainFeatures/main/containers/MainContainer';

const LayoutWrapper = () => {
  const { meStore } = useStore();

  useEffect(() => {
    if (typeof meStore.myProfile !== 'undefined' && meStore.myProfile !== null) {
      meStore.getMyProfile();
    }
  }, [meStore.myProfile]);

  return <MainContainer />;
};

export default LayoutWrapper;
