import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const VerifyPage = observer(() => {
  const { t } = useTranslation();
  const { authStore, meStore } = useStore();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const handleClickConfirm = async () => {
    const token = searchParams.get('token');
    if (token) {
      const response: any = await authStore.confirmJoin(token);
      if (response?.data?.errorCode) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        const { errorCode, errorMessage } = response.data;
        if (errorCode === 'USER.ALREADY_VERIFIED') {
          toast('이미 인증된 메일입니다\n잠시 후 로그인 페이지로 이동합니다', {
            type: 'error',
            position: 'top-center',
            autoClose: 2000,
            onClose: () => {
              navigate('/login');
            },
            closeOnClick: false,
            pauseOnHover: false,
            closeButton: false,
          });
        } else if (errorCode === 'CONFIRMATION_TOKEN.TOKEN_EXPIRED') {
          // TODO: 정확한 에러 코드를 알아내어 분기해줘야 함 (ex: 인증 메일 시간 만료)
          toast(errorMessage, {
            type: 'error',
            position: 'top-center',
            autoClose: 2000,
            onClose: () => {
              navigate('/unverified');
            },
            closeOnClick: false,
            pauseOnHover: false,
            closeButton: false,
          });
        } else {
          toast(errorMessage, {
            type: 'error',
            position: 'top-center',
            autoClose: 2000,
            closeOnClick: false,
            pauseOnHover: false,
            closeButton: false,
          });
        }
        return;
      }
    } else {
      toast(t('informationDoesNotExist'));
    }
  };

  return (
    <EmailContainer>
      <EmailBox>
        <img src="image/livelink.png" alt="" width={150} />
        <Title fontSize={'20px'} fontWeight={500} marginTop={'2em'}>
          {t('helloOhMoss')}
        </Title>
        <Text>{t('clickVerifyOhMossService')}</Text>
        <Btn onClick={handleClickConfirm}>{t('certification')}</Btn>
      </EmailBox>
    </EmailContainer>
  );
});

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmailContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: #ffffff;
  ${flexCenter}
`;

const EmailBox = styled.div`
  width: 380px;
  height: 390px;
  padding: 3em 0;
  background: #f8f8f8;
  ${flexCenter}
  flex-direction: column;
  text-align: center;
  /* width: 90%; */
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 390px) {
    width: 90%;
    height: 350px;
    padding: 1em 0;
  }
`;

const Text = styled.p<{ fontSize?: string; fontWeight?: number; marginTop?: string }>`
  font-size: ${(props) => props.fontSize || '16px'};
  padding: 0 20px;
  line-break: strict;
  font-weight: ${(props) => props.fontWeight || 300};
  margin-top: ${(props) => props.marginTop || '0'};
  @media screen and (max-width: 360px) {
    font-size: 14px;
    padding: 0 10px;
  }
`;

const Title = styled(Text)`
  @media screen and (max-width: 360px) {
    font-size: 16px;
  }
`;

const Btn = styled.button.attrs((props) => ({
  type: 'submit',
}))`
  margin-top: 1em;
  background: #3e64ea;
  border: none;
  border-radius: 20px;
  width: 150px;
  height: 5em;
  color: white;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  @media screen and (max-width: 360px) {
    width: 4em;
    height: 4em;
    font-size: 16px;
  }
`;

export default VerifyPage;
