import React, { useState, useRef, CSSProperties, useEffect } from 'react';
import { MenuBoxContainerStyle, MenuListContainer } from 'resources/styles/common/button';
import { ImageUploader, TextArea, TextToolBox } from 'components/commons';
import { CommonButtonStyle } from 'resources/styles/common/styles';
import { SectionBox } from 'resources/controls';
import { CustomUserProfile } from 'types/CommonTypes';
import { ColorPicker } from 'components/items';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { isValid } from 'libs/helper/utils';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import Input from 'components/commons/Input';
import styled from 'styled-components';

type MenuBoxType = {
  imgSrc: string;
  title: string;
  isActive: boolean;
  onClick?: () => void;
};

const ProfileContainer = observer(() => {
  const { t } = useTranslation();

  /** @description 모델 */
  const { meStore, fileStore, simulatorStore } = useStore();
  const titleRef = useRef<HTMLInputElement>(null);
  const templateRef = useRef<HTMLInputElement>(null);
  const [profileModel, setProfileModel] = useState<CustomUserProfile>();

  const updateModel = (key: any, value: any) => {
    const newProfile: any = { ...simulatorStore?.profile };
    (newProfile?.profile as any)[key] = value;
    simulatorStore.updateProfile(newProfile);

    setProfileModel((prevState: CustomUserProfile) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleClickSaveProfile = async () => {
    /** 저장된 number포맷 or bullet포맷 적용 */
    const newProfileModel = { ...profileModel };

    if (newProfileModel?.profileImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadImage(profileModel?.profileImage?.file);
      newProfileModel.profileImage.id = image.id;
    }
    if (newProfileModel?.backgroundImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadImage(profileModel?.backgroundImage?.file);
      newProfileModel.backgroundImage.id = image.id;
    }
    if (newProfileModel?.footerImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadImage(profileModel?.footerImage?.file);
      newProfileModel.footerImage.id = image.id;
    }

    newProfileModel.custom = JSON.stringify({
      titleProps: newProfileModel.titleProps,
      selfIntroductionProps: newProfileModel.selfIntroductionProps,
      template: newProfileModel.template,
      footerProps: newProfileModel.footerProps,
      titleYPosition: newProfileModel.titleYPosition,
    });

    /** 저장후, 변경된 userInfo의 정보를 가져온다 */
    meStore.saveMyProfile(newProfileModel);
    toast(t('yourProfileSave'), { position: 'top-center' });
    await meStore.getMyProfile();
  };

  /** 프로필 이미지, 배경 이미지, 배경 색상, 테마 */
  const MenuBox = ({ imgSrc, title, isActive, onClick }: MenuBoxType) => {
    return (
      <MenuButtonStyle
        isActive={isActive}
        onClick={() => {
          if (typeof onClick !== 'undefined') {
            onClick();
          }

          if (title === '템플릿') {
            templateRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }}
      >
        <MenuImgStyle src={imgSrc} alt="" style={{}} />
        <MenuTitleStyle>{title}</MenuTitleStyle>
      </MenuButtonStyle>
    );
  };

  useEffect(() => {
    if (isValid(simulatorStore.profile)) {
      setProfileModel(simulatorStore?.profile);
    }
  }, [simulatorStore?.profile]);

  return (
    <ContainerStyle>
      <ProfileInfoStyle>
        <SectionBox>
          <MenuBoxContainerStyle>
            <MenuListContainer>
              {isValid(profileModel?.profileImage) && (
                <DeleteButtonStyle onClick={() => updateModel('profileImage', undefined)}>
                  <DeleteImgStyle src="/image/adminProfile/close-round.png" />
                </DeleteButtonStyle>
              )}
              <ImageUploader
                attachType="image"
                cropSize={{ width: 800, height: 800 }}
                currentImage={profileModel?.profileImage}
                style={{ flex: 1 }}
                onSelected={(thumbnail: any) => {
                  updateModel('profileImage', thumbnail[0]);
                }}
              >
                <MenuBox
                  isActive={isValid(profileModel?.profileImage)}
                  imgSrc="/image/adminProfile/user-circle-add-icon.png"
                  title={t('profileImage')}
                />
              </ImageUploader>
            </MenuListContainer>
            <MenuListContainer>
              {typeof profileModel?.backgroundImage !== 'undefined' && (
                <DeleteButtonStyle
                  onClick={() => {
                    updateModel('backgroundImage', undefined);
                    updateModel('backgroundColor', '#efefef');
                  }}
                >
                  <DeleteImgStyle src="/image/adminProfile/close-round.png" />
                </DeleteButtonStyle>
              )}
              <ImageUploader
                attachType="image"
                cropSize={{ width: 2000, height: 2000 }}
                currentImage={profileModel?.backgroundImage}
                cropType="vertical"
                useColor
                style={{ flex: 1 }}
                onSelected={(thumbnail: any) => {
                  updateModel('backgroundImage', thumbnail[0]);
                }}
              >
                <MenuBox
                  isActive={typeof profileModel?.backgroundImage !== 'undefined'}
                  imgSrc="/image/adminProfile/background-image-icon.png"
                  title={t('backgroundImage')}
                />
              </ImageUploader>
            </MenuListContainer>
            <MenuListContainer isLastItem>
              {isValid(profileModel?.backgroundColor) && (
                <DeleteButtonStyle onClick={() => updateModel('backgroundColor', undefined)}>
                  <DeleteImgStyle src="/image/adminProfile/close-round.png" />
                </DeleteButtonStyle>
              )}
              <ColorPicker
                pickerStyle={{
                  position: 'absolute',
                  top: 80,
                  left: -10,
                }}
                onColorChanged={(color: string) => {
                  updateModel('backgroundColor', color);
                }}
                color={simulatorStore.profile?.backgroundColor}
              >
                <MenuBox
                  isActive={isValid(profileModel?.backgroundColor)}
                  imgSrc="/image/adminProfile/brush-icon.png"
                  title={t('backgroundColor')}
                />
              </ColorPicker>
            </MenuListContainer>
          </MenuBoxContainerStyle>
        </SectionBox>
        <SectionBox>
          <InputTitleStyle>{t('profileTitle')}</InputTitleStyle>
          <TextToolBox
            show
            style={profileModel?.titleProps?.style}
            containerStyle={toolBoxStyle}
            onChangeStyle={(style: CSSProperties) => {
              updateModel('titleProps', {
                style: { ...profileModel?.titleProps?.style, ...style },
              });
            }}
          />
          <Input
            ref={titleRef}
            placeholder={t('profileTitle')}
            type="text"
            containerStyle={{ border: 0 }}
            style={{
              color: '#111',
              fontSize: 16,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              border: '1px solid #bbb',
              padding: '0 24px',
            }}
            defaultValue={profileModel?.title}
            onChangeValue={(value: string) => {
              updateModel('title', value);
            }}
          />
          <InputTitleStyle style={{ marginTop: 15 }}>{t('profileIntroduction')}</InputTitleStyle>
          <TextToolBox
            show
            style={profileModel?.selfIntroductionProps?.style}
            containerStyle={toolBoxStyle}
            onChangeStyle={(style: CSSProperties) => {
              updateModel('selfIntroductionProps', {
                style: { ...profileModel?.selfIntroductionProps?.style, ...style },
              });
            }}
          />
          <TextArea
            placeholder={t('profileIntroduction')}
            type="textarea"
            containerStyle={{ border: 0 }}
            style={{
              width: 'calc(100% - 12px)',
              height: 144,
              fontSize: 16,
              color: '#111',
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              border: '1px solid #bbb',
              padding: 10,
            }}
            value={profileModel?.selfIntroduction}
            onChangeValue={(value: string) => updateModel('selfIntroduction', value)}
          />
          <InputTitleStyle style={{ marginTop: 15 }}>{t('bottomDocument')}</InputTitleStyle>
          <TextToolBox
            show
            showImageIcon
            style={profileModel?.footerProps?.style}
            containerStyle={toolBoxStyle}
            imageUrl={profileModel?.footerImage?.publicUrl}
            onChangeImage={(action: 'add' | 'delete', thumbnail?: any) => {
              if (action === 'delete') {
                updateModel('footerImage', undefined);
              } else if (action === 'add') {
                updateModel('footerImage', thumbnail);
              }
            }}
            onChangeStyle={(style: CSSProperties) => {
              updateModel('footerProps', {
                ...profileModel?.footerProps,
                style: { ...profileModel?.footerProps?.style, ...style },
              });
            }}
          />
          <Input
            placeholder={t('bottomDocument')}
            type="text"
            containerStyle={{ border: 0 }}
            style={{
              color: '#111',
              fontSize: 16,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              border: '1px solid #bbb',
              padding: '0 24px',
            }}
            defaultValue={profileModel?.footerTitle}
            onChangeValue={(value: string) => {
              updateModel('footerTitle', value);
            }}
          />
        </SectionBox>
      </ProfileInfoStyle>
      <SaveButtonStyle onClick={handleClickSaveProfile}>{t('saveProfile')}</SaveButtonStyle>
    </ContainerStyle>
  );
});

const ContainerStyle = styled.div`
  margin-bottom: 50px;
`;

const toolBoxStyle = {
  borderRadius: 12,
  padding: '5px 0 5px 0',
  margin: '-5px 30px 0 30px',
};

const ProfileInfoStyle = styled.div`
  border-radius: 24px;
  padding: 30px 30px 30px 30px;
  margin: 20px 0 0 0;
  position: relative;
`;

const DeleteButtonStyle = styled.div`
  width: 22px;
  height: 22px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
`;

const DeleteImgStyle = styled.img`
  width: 100%;
  height: 100%;
`;

const InputTitleStyle = styled.div`
  color: #666;
  font-size: 16px;
  height: 40px;
  display: flex;
  align-items: center;
`;

const MenuButtonStyle = styled.div<{ isActive: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${(props) => (props.isActive ? '#fbfbfb' : 'rgba(255, 255, 255, 0.8)')};
  border: 1px solid ${(props) => (props.isActive ? '#bbb' : '#eee')};
  padding: 10px 0 10px 0;
  cursor: pointer;
`;

const MenuImgStyle = styled.img`
  width: 30px;
  height: 30px;
`;

const MenuTitleStyle = styled.div`
  color: #666;
  font-weight: 400;
  font-size: 14px;
  margin: 8px 0 0 0;
`;

const SaveButtonStyle = styled(CommonButtonStyle)`
  width: calc(100% - 50px);
  margin: 0 0 0 25px;
  position: sticky;
  bottom: 10px;
`;

export default ProfileContainer;
