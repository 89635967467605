import AuthStore from 'stores/AuthStore';
import FileStore from 'stores/FileStore';
import LinkStore from 'stores/LinkStore';
import MeStore from 'stores/MeStore';
import ParameterStore from 'stores/ParameterStore';
import TemplateStore from 'stores/TemplateStore';
import UserStore from 'stores/UserStore';
import FormValidationStore from './FormValidationStore';
import FollowStore from './FollowStore';
import UIStore from './UIStore';
import SimulatorStore from './SimulatorStore';
import PaymentStore from './PaymentStore';

const RootStore = {
  authStore: new AuthStore(),
  fileStore: new FileStore(),
  linkStore: new LinkStore(),
  meStore: new MeStore(),
  parameterStore: new ParameterStore(),
  templateStore: new TemplateStore(),
  userStore: new UserStore(),
  formValidationStore: new FormValidationStore(),
  followStore: new FollowStore(),
  uiStore: new UIStore(),
  simulatorStore: new SimulatorStore(),
  paymentStore: new PaymentStore(),
};

export default RootStore;
