import styled, { css } from 'styled-components';

const displayAlignCenter = css`
  display: flex;
  align-items: center;
`;

export const LinkEditorStyle = styled.div`
  ${displayAlignCenter}
  justify-content: space-between;
  width: 100%;
  height: 80px;
  margin: 30px auto 0 auto;
  border-radius: 46px;
  border: 1px solid #dddddd;
`;

export const RegisterButtonStyle = styled.div<{ active?: boolean }>`
  ${displayAlignCenter}
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 0 40px 40px 0;
  font-weight: 500;
  pointer-events: ${({ active }) => (active ? 'auto' : 'none')};
  cursor: ${({ active }) => (active ? 'pointer' : '')};
  color: ${({ active }) => (active ? 'black' : 'gray')};
`;

export const ImageUploaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-left: 10px;
  background: #636363;
  border-radius: 50%;
  cursor: pointer;
`;

export const EditorWrapperStyle = styled.div`
  ${displayAlignCenter}
  justify-content: center;
`;

export const InputComponentStyle = styled.div`
  width: 200px;
  margin-left: 20px;
  > div {
    height: 40px !important;
    ${displayAlignCenter}
  }
  > div > div {
    height: 20px !important;
    padding-top: 0 !important;
  }
`;
