import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ImageSelectorCommonProps, CustomFile } from 'types/CommonTypes';
import { cropImage, convertBase64Files } from './commonLogin';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { ModalHeader } from 'components/commons';
import { ColorPicker } from 'components/items';
import { useTranslation } from 'react-i18next';
import { isValid } from 'libs/helper/utils';
import IconButton from 'resources/controls/IconButton';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import 'react-spring-bottom-sheet/dist/style.css';

/**
 * @description 특정 이미지를 크롭후 리사이즈 한다.
 * @param param0
 * @returns
 */

const ImageSelectorBottomSheet = ({
  isShow,
  cropSize,
  useCrop,
  multipleUpload,
  currentImage,
  origin,
  files,
  cropType = 'square',
  currentColor,
  attachType = 'image',
  onColorChanged,
  onChangeThumbnails,
  onClose,
}: ImageSelectorCommonProps) => {
  const { t } = useTranslation();
  /** @description 파일업로드용 ref */
  const onChangeFile = useRef<any>();

  const [modalShow, setModalShow] = useState<boolean>(false);

  /** @description 크롭윈도우 표시여부 */
  const [cropShow, setCropShow] = useState<boolean>(false);

  /** @description 크롭할 대상 사진(base64) */
  const [targetImage, setTargetImage] = useState<any>();

  /** @description 크롭관련 프로퍼티들 */
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [multiple, setMultiple] = useState({});
  const [aspectRatio, setAspectRatio] = useState<any>();
  const [colorPickerShow, setColorPickerShow] = useState<boolean>(false);
  const [accept, setAccept] = useState<string>('');
  // const [showReset, setShowReset] = useState<boolean>(false);

  /**
   * @description 이미지 크롭핑 후 리사이즈(기본 200 * 200)
   */
  const handleClickApplyCrop = useCallback(async () => {
    if (typeof cropSize === 'undefined') return;
    try {
      const croppedImage = (await cropImage(
        cropSize,
        targetImage,
        croppedAreaPixels,
        rotation,
      )) as CustomFile;

      onChangeThumbnails([croppedImage]);
      setModalShow(false);
      onClose();
      setCropShow(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  /**
   * @description 이미지 첨부버튼 클릭
   * @param e
   */
  const handleChangeImage = async (e: any) => {
    const images = (await convertBase64Files(e)) as Array<CustomFile>;
    if (multipleUpload === true) {
      onChangeThumbnails(images);
      setModalShow(false);
      onClose();

      return;
    }
    if (images.length === 1) {
      if (images[0].file?.type.indexOf('image/') === 0) {
        setTargetImage(images[0]);
        setCropShow(true);
      } else {
        onChangeThumbnails(images);
        setModalShow(false);
        onClose();
      }
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    const reset = isValid(currentImage) || isValid(currentColor);
    setModalShow(isShow);
  }, [isShow]);

  useEffect(() => {
    if (typeof cropType !== 'undefined') {
      switch (cropType) {
        case 'horizontal':
          setAspectRatio(2);
          break;
        case 'square':
          setAspectRatio(1);
          break;
        case 'vertical':
          setAspectRatio(0.5);
          break;
        default:
          break;
      }
    }
  }, [cropType]);

  useEffect(() => {
    switch (attachType) {
      case 'image':
        setAccept('.jpg, .jpeg, .png, .gif');
        break;
      case 'video':
        setAccept('.mp4');
        break;
      case 'media':
        setAccept('.jpg, .jpeg, .png, .gif, .mp4');
        break;

      default:
        setAccept('');
        break;
    }
  }, [attachType]);

  useEffect(() => {
    typeof multipleUpload !== 'undefined' && setMultiple({ multiple: 'multiple' });
  }, [multipleUpload]);

  useEffect(() => {
    typeof files !== 'undefined' && handleChangeImage(files);
  }, [files]);

  return (
    <BottomSheet open={modalShow}>
      {/* <CloseButtonStyle>hi</CloseButtonStyle> */}
      {cropShow ? (
        <>
          <ModalHeader
            onClose={() => {
              setCropShow(false);
            }}
          >
            <div>{t('imageInsideRectangleDisplay')}</div>
          </ModalHeader>
          <CropContainerStyle>
            <Cropper
              image={targetImage.publicUrl}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={setCrop}
              objectFit={'contain'}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </CropContainerStyle>

          <ButtonContainerStyle>
            <ApplyButtonStyle
              className="button default"
              onClick={() => {
                handleClickApplyCrop();
              }}
            >
              {t('apply')}
            </ApplyButtonStyle>
            <CancellationButtonStyle
              className="button default"
              onClick={() => {
                setCropShow(false);
              }}
            >
              {t('cancellation')}
            </CancellationButtonStyle>
          </ButtonContainerStyle>
        </>
      ) : (
        <>
          <ModalHeader
            onClose={() => {
              setModalShow(false);
              onClose();
            }}
          >
            <div>{t('pleaseSelectDesireImageFormat')}</div>
          </ModalHeader>
          <ContentsStyle>
            <IconButton
              onClick={(e: any) => {
                onChangeFile.current.click(e);
              }}
              containerStyle={containerStyle}
              image="/image/adminProfile/user-circle-add-icon.png"
            >
              <div> {t('imageUpload')}</div>
            </IconButton>

            <InputStyle
              type="file"
              accept={accept}
              {...multiple}
              id="file"
              ref={(ref) => (onChangeFile.current = ref)}
              onChange={(e) => {
                handleChangeImage(e);
              }}
            />
          </ContentsStyle>
          {colorPickerShow === true && (
            <ColorPickerContainer>
              <ColorPicker
                onClose={() => {
                  setColorPickerShow(false);
                }}
                show
                onColorChanged={(color: string) => {
                  if (typeof onColorChanged !== 'undefined') {
                    onColorChanged(color);
                  }
                }}
              >
                <></>
              </ColorPicker>
            </ColorPickerContainer>
          )}
        </>
      )}
    </BottomSheet>
  );
};

const containerStyle = {
  width: 100,
  height: 120,
  border: '1px solid #efefef',
  borderRadius: 10,
  marginBottom: 30,
};

const CropContainerStyle = styled.div`
  position: relative;
  height: 300px;
`;

const ButtonContainerStyle = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  flex-direction: row;
  justify-content: space-around;
  background: black;
`;

const ApplyButtonStyle = styled.div`
  width: 180px;
  margin: 0 10px 0 0;
`;

const CancellationButtonStyle = styled.div`
  width: 100px;
`;

const InputStyle = styled.input`
  display: none;
`;

const ContentsStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ColorPickerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// const CloseButtonStyle = styled.div`
//   position: absolute;
//   top: -20px;
//   background: #ff1080;
//   left: 0;
//   right: 0;
//   text-align: center;
// `;

export default ImageSelectorBottomSheet;
