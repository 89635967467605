import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import LinkComponent from 'components/items/admin/LinkComponent';
import styled from 'styled-components';
import { LinkModel } from 'types/CommonTypes';
import { LinkDto } from 'services/data-contracts';
import { toast } from 'react-toastify';

const ListOfLinksContainer = observer(
  ({
    draggable,
    onLinkSelect,
    onLinkDelete,
    onLinkEdit,
  }: {
    draggable?: boolean;
    onLinkSelect?: (link: LinkModel) => void;
    onLinkDelete?: (linkId: number) => void;
    onLinkEdit?: (link: LinkModel) => void;
  }) => {
    const { t } = useTranslation();
    const { linkStore, meStore, userStore } = useStore();
    const [links, setLinks] = useState<LinkModel[]>([]);
    const [selectedLink, setSelectedLink] = useState<LinkModel>();
    const grid = 1;

    const reorder = (startIndex: number, endIndex: number): Array<LinkModel> => {
      const result = Array.from(links);
      const [dragItem] = result.splice(startIndex, 1);

      /** 서버 반영전, UI 변경 목적 */
      result.splice(endIndex, 0, dragItem);

      /** 옮긴 링크의 seq를 옮긴 index + 1 값으로 수정해준다 */
      dragItem.seq = endIndex + 1;

      /** 링크 순번 수정 */
      if (dragItem.id) {
        linkStore.reOrderLinkSeq(dragItem?.id, dragItem);
      }

      setTimeout(() => {
        linkStore.getLinks();
      }, 300);

      return result;
    };

    const onDragEnd = (result: DropResult): void => {
      if (!result.destination) {
        return;
      }
      const dragLinks: Array<LinkModel> = reorder(result.source.index, result.destination.index);
      setLinks(dragLinks);
    };

    /** (라이브러리) Droppable 스타일링 */
    const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
      padding: grid,
      width: '100%',
    });

    /** (라이브러리) Draggable 스타일링 */
    const getItemStyle = (
      isDragging: boolean,
      draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    ): React.CSSProperties => ({
      userSelect: 'none',
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
      ...draggableStyle,
    });

    const handleLinkShowChanged = async (link: LinkDto, isShow: boolean) => {
      const result = await meStore.updateLinkActive(link?.id!, isShow);
      if (result) {
        userStore.getUserInfo(meStore?.me?.userName!);
        // linkStore.getLinks();
      }
    };

    const LinkComponetWrapper = (link: LinkModel) => {
      return (
        <LinkComponent
          item={link}
          on={false}
          mode={'edit'}
          onShowChange={(isShow: boolean) => {
            handleLinkShowChanged(link, isShow);
          }}
          onSelect={() => {
            if (typeof onLinkSelect === 'function') onLinkSelect(link);
          }}
          onDelete={() => {
            if (typeof onLinkDelete === 'function') onLinkDelete(link?.id as number);
          }}
          onEdit={(link: LinkModel) => {
            setSelectedLink(link);
            if (typeof onLinkEdit === 'function') onLinkEdit(link);
          }}
        />
      );
    };

    useEffect(() => {
      linkStore.getLinks();
    }, []);

    useLayoutEffect(() => {
      setLinks(linkStore.links);
    }, [linkStore.links]);

    return (
      <>
        {draggable === true ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot): JSX.Element => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {links?.map((link: LinkModel, index: number) => (
                      <Draggable key={link?.id} draggableId={String(link?.id)} index={index}>
                        {(provided, snapshot): JSX.Element => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <LinkComponetWrapper {...link} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        ) : (
          <>
            {links?.map((link: LinkModel, index: number) => (
              <div key={index}>
                <LinkComponetWrapper {...link} />
              </div>
            ))}
          </>
        )}
        {linkStore.links?.length === 0 && <EmptyContainer>{t('registerNotLink')}</EmptyContainer>}
      </>
    );
  },
);

const ContainerTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin: 40px 0 20px 0;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 16px;
    margin: 20px 0 0 0;
  }
`;

const EmptyContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

export default ListOfLinksContainer;
