import ParameterStore from './ParameterStore';
import UserStore from './UserStore';
import AuthStore from './AuthStore';
import FileStore from './FileStore';
import LinkStore from './LinkStore';
import MeStore from './MeStore';
import TemplateStore from './TemplateStore';
import FormValidationStore from './FormValidationStore';
import FollowStore from './FollowStore';
import UIStore from './UIStore';
import SimulatorStore from './SimulatorStore';
class RootStore {
  parameterStore = new ParameterStore();
  userStore = new UserStore();
  authStore = new AuthStore();
  fileStore = new FileStore();
  linkStore = new LinkStore();
  meStore = new MeStore();
  templateStore = new TemplateStore();
  formValidationStore = new FormValidationStore();
  followStore = new FollowStore();
  uiStore = new UIStore();
  simulatorStore = new SimulatorStore();
}
export default RootStore;
