import React, { useEffect, useRef } from 'react';

const Form = ({
  children,
  isValidForm,
}: {
  children: React.ReactNode;
  isValidForm: (result: boolean) => void;
}) => {
  /**
   * @description Form child에서 컨트롤 목록 추가
   */
  const controls = useRef<Array<{ id: string; isValidForm?: boolean }>>([]);

  /**
   * @description 이벤트에 따라 현제 전체 Validation Result를 반환한다.
   * @param id 컨트롤 id
   * @param value Validation Result
   */
  const handleCheckValid = (id: string, value: boolean) => {
    const filter = controls.current.filter(
      (control: { id: string; isValidForm?: boolean }) => control.id === id,
    );
    if (filter.length > 0) {
      filter[0].isValidForm = value;
    }
    const filter2 = controls.current.filter(
      (control: { id: string; isValidForm?: boolean }) => control.isValidForm === false,
    );

    /** 부모에게 전체 컨트롤 세트의 검사 결과를 반환한다. */
    isValidForm(filter2.length === 0 ? true : false);
  };

  /**
   * @description 새로운 컨트롤을 배열에 추가한다.
   * @param child
   */
  const appendControl = (child: React.ReactElement) => {
    const filter = controls.current.filter(
      (control: { id: string; isValidForm?: boolean }) => control.id === child.props.id,
    );

    if (filter.length === 0) {
      //required 선언된것들만 대상으로 함.
      if (child.props.required) {
        controls.current.push({ id: child.props.id, isValidForm: false });
      }
    }
  };

  /**
   * @description props를 추가하여 새롭게 child를 생성한다.
   */
  const childrenWithProps = React.Children.map(children, (child) => {
    const validate = (id: string, result: boolean) => {
      /**
       * @description Child에서 유효성 검사결과를 실행함.
       */
      handleCheckValid(id, result);
    };
    if (React.isValidElement(child) && child.type !== 'div') {
      appendControl(child);
      return React.cloneElement(child, { validate });
    }
    return child;
  });
  useEffect(() => {
    const filter2 = controls.current.filter(
      (control: { id: string; isValidForm?: boolean }) => control.isValidForm === false,
    );

    /** 부모에게 전체 컨트롤 세트의 검사 결과를 반환한다. */
    isValidForm(filter2.length === 0 ? true : false);
  }, []);
  return <div>{childrenWithProps}</div>;
};

export default Form;
