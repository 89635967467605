import React, { useEffect, useState } from 'react';
import { QRContainer } from 'containers/adminContainers';
import { CommonRowStyle } from 'resources/styles/common/styles';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import ProfileContainer from 'features/adminFeatures/profile/containers/ProfileContainer';
import useScreenType from 'libs/hooks/useScreenType';
import styled from 'styled-components';

type Props = {
  onShowSimulator: () => void;
  onCloseSimulator: () => void;
};

type TabNameType = 'profile' | 'QRCode';

const ProfilePage = observer(({ onShowSimulator, onCloseSimulator }: Props) => {
  const { t } = useTranslation();
  // const { templateStore, meStore } = useStore();
  const [tabName, setTabName] = useState<TabNameType>('profile');
  const screenType = useScreenType();

  const switchTab = (tabName: TabNameType) => {
    setTabName(tabName);
  };

  useEffect(() => {
    if (screenType === 'window') {
      onCloseSimulator();
    }
  }, [screenType]);

  return (
    <>
      {screenType === 'window' && (
        <TabBarContainer>
          <TabBar
            isActive={tabName === 'profile'}
            marginRight={10}
            onClick={() => {
              switchTab('profile');
            }}
          >
            {t('myProfile')}
          </TabBar>
          <TabBar
            isActive={tabName === 'QRCode'}
            onClick={() => {
              switchTab('QRCode');
            }}
          >
            {t('qrCode')}
          </TabBar>
        </TabBarContainer>
      )}
      <CommonRowStyle>
        <CommonRowProfileStyle>
          {tabName === 'profile' ? (
            <ProfileContainer onShowSimulator={onShowSimulator} />
          ) : (
            <QRContainer />
          )}
        </CommonRowProfileStyle>
      </CommonRowStyle>
    </>
  );
});

const TabBarContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 0 0;
`;

const TabBar = styled.div<{ isActive: boolean; marginRight?: number }>`
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: ${(props) => (props.isActive ? '#fff' : '#999')};
  background-color: ${(props) => (props.isActive ? props.theme.colors.pointColor : '#fff')};
  border: 1px solid #dddddd;
  border-radius: 25px;
  margin: 0 ${(props) => (props?.marginRight ? props.marginRight : 0)}px 0 0;
  cursor: pointer;
`;

const CommonRowProfileStyle = styled.div`
  flex: 1;
`;

export default ProfilePage;
