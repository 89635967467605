import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { MarketingTerms } from 'features/mainFeatures/terms/containers';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const MarketingTermsContainer = observer(({ useButton }: { useButton?: boolean }) => {
  const { t } = useTranslation();
  const { authStore } = useStore();
  const navigation = useNavigate();

  const handleClickAcceptMarketing = () => {
    //
  };

  return (
    <TermWrapper>
      {/* <MobileHeader title="마케팅 활용 및 수신동의" /> */}
      <TermsTitle>{t('marketUsageConsent')}</TermsTitle>
      {useButton && <AcceptButton onClick={handleClickAcceptMarketing}>{t('agree')}</AcceptButton>}
      <TermsContent>
        <MarketingTerms />
      </TermsContent>
    </TermWrapper>
  );
});

const TermWrapper = styled.div`
  @media ${(props) => props.theme.media.desk} {
    width: 600px;
  }
  @media ${(props) => props.theme.media.mobile} {
    /* width: ; */
  }
`;

const AcceptButton = styled.div`
  color: white;
  height: 46px;
  line-height: 46px;
  border-radius: 20px;
  text-align: center;
  background-color: #d8d8d8;
  margin: 0 1em;
  margin-top: 1em;

  /* background-color: ${(props) => props.theme.colors.pointColor}; */
  &:hover {
    opacity: 0.4;
    cursor: pointer;
  }
`;

const TermsTitle = styled.div`
  font-size: 30px;
  text-align: center;
  padding-bottom: 32px;
  padding-top: 32px;
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const TermsContent = styled.div`
  /* margin-top: 32px; */
  height: 100%;
  /* overflow-y: scroll; */
`;

export default MarketingTermsContainer;
