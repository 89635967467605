import { makeAutoObservable, toJS } from 'mobx';
import { File as FileService } from 'services/File';

// import _ from "lodash";
import { REST_API_URL } from 'stores/AppStore';

import _ from 'lodash';
import { FileDto } from 'services/data-contracts';

class FileStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: FileService<unknown> = new FileService({
    baseURL: REST_API_URL,
  });

  /** ============================================================================================== */

  /** 링크 이미지 업로드 */
  *uploadImage(file: any) {
    if (typeof file.publicUrl !== 'undefined') {
      delete file.publicUrl;
    }
    const { data, status, error } = yield this.api.uploadFile({ file });
    if (status === 200) {
      return data;
    } else {
      return error;
    }
  }

  /** 배경 이미지 업로드 */
  *uploadBackgroundImage(file: any) {
    if (typeof file.publicUrl !== 'undefined') {
      delete file.publicUrl;
    }
    const { data, status, error } = yield this.api.uploadBackgroundImage({ file });
    if (status === 200) {
      return data;
    } else {
      return error;
    }
  }

  /** 프로필 이미지 업로드 */
  *uploadProfileImage(file: any) {
    if (typeof file.publicUrl !== 'undefined') {
      delete file.publicUrl;
    }
    const { data, status, error } = yield this.api.uploadProfileImage({ file });
    if (status === 200) {
      return data;
    } else {
      return error;
    }
  }

  // _base64ToArrayBuffer(base64: any) {
  //   var binary_string = window.atob(base64);
  //   var len = binary_string.length;
  //   var bytes = new Uint8Array(len);
  //   for (var i = 0; i < len; i++) {
  //     bytes[i] = binary_string.charCodeAt(i);
  //   }
  //   return bytes.buffer;
  // }
}

export default FileStore;
