import React, { useLayoutEffect, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useStore } from 'stores/StoreHelper';

const Follow = observer(
  ({ follow, onClick }: { follow?: boolean; onClick: (isFollow: boolean) => void }) => {
    const [isFollow, setIsFollow] = useState<boolean>(false);
    const { uiStore } = useStore();
    const handleFollow = () => {
      if (isFollow === true) {
        uiStore.confirm.show({
          message: 'Follow를 취소하시겠습니까?',
          onConfirmed: () => {
            setIsFollow((prev) => !prev);
            onClick(!isFollow);
          },
        });
      } else {
        setIsFollow((prev) => !prev);
        onClick(!isFollow);
      }
    };

    useLayoutEffect(() => {
      if (typeof follow !== 'undefined') {
        setIsFollow(follow);
      }
    }, [follow]);

    return (
      <div style={{ cursor: 'pointer' }} onClick={handleFollow}>
        {isFollow ? (
          <FavoriteIcon style={{ color: 'red' }} />
        ) : (
          <FavoriteBorderIcon style={{ color: 'red' }} />
        )}
      </div>
    );
  },
);
export default Follow;
