import React, { CSSProperties, useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

type ToggleListProps = {
  containerStyle?: CSSProperties;
  itemStyle?: CSSProperties;
  onClick?: (item: LinkItemType) => void;
  toggleItems: Array<LinkItemType>;
  defaultChecked?: string;
};

type LinkItemType = {
  title: string;
  image?: string;
  checked?: boolean;
  id?: string;
};

const ToggleListButton = ({
  onClick,
  containerStyle,
  itemStyle,
  toggleItems,
  defaultChecked,
}: ToggleListProps) => {
  const _containerStyle: CSSProperties = { ...containerStyle };
  const _itemStyle: CSSProperties = { ...itemStyle };
  const [checked, setChecked] = useState<string>();

  useEffect(() => {
    if (typeof defaultChecked !== 'undefined') {
      const temp = toggleItems.filter((item: LinkItemType) => item.id === defaultChecked);
      if (temp.length > 0) {
        setChecked(temp[0].id);
      }
    }
  }, [defaultChecked]);
  return (
    <>
      <TabContainerStyle style={_containerStyle}>
        <TabContainerWrapStyle>
          {toggleItems.map((toggleItem: LinkItemType, i: number) => (
            <div
              onClick={() => {
                setChecked(toggleItem.id);
                if (typeof onClick === 'function') {
                  onClick(toggleItem);
                }
              }}
            >
              <TabButtonLabel
                on={checked === toggleItem.id ? true : false}
                htmlFor={toggleItem.title}
              >
                <IconStyle src={toggleItem.image} />
                <TitleStyle>{toggleItem.title}</TitleStyle>
              </TabButtonLabel>
            </div>
          ))}
        </TabContainerWrapStyle>
      </TabContainerStyle>
    </>
  );
};

const TabContainerStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 6px;
`;

const TabContainerWrapStyle = styled.div`
  display: flex;
  margin: 10px;
`;

const OriginConatainer = css`
  background: #ffffff;

  > div {
    color: #999;
  }
  > img {
    opacity: 0.3;
  }
`;

const SelectedContainer = css`
  background-color: #7cb251;
  transition: 0.4s;
  color: #fff;

  transition-timing-function: ease-in-out;
  > div {
    color: #fff;
  }
  > img {
    opacity: 1;
    filter: opacity(0.4) drop-shadow(0 0 0 white);
  }
`;

// label, 스타일 수정을 위한 스타일 컴포넌트
const TabButtonLabel = styled.label<{ on?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 100px;
  border-radius: 50px;
  padding: 0 15px;
  align-items: center;
  cursor: pointer;
  height: 40px;
  ${(props) => (props.on === true ? SelectedContainer : OriginConatainer)};
`;

const IconStyle = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100px;
  margin: 0 8px 0 0;
  transition: 0.2s;
  transition-timing-function: ease;
`;

const TitleStyle = styled.div`
  font-size: 16px;
  transition: 0.2s;
  transition-timing-function: ease;
`;

const ToggleListTitle = styled.div`
  width: 100%;
  margin-left: 20px;
  display: flex;
`;

const ToggleListButtonContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin: 10px 0 6px 0;
`;

export { ToggleListButton, LinkItemType, ToggleListTitle, ToggleListButtonContainerStyle };
