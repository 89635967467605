import { CustomFile, SizeInfo } from 'types/CommonTypes';
import getCroppedImg from 'libs/helper/cropImage';
import imageCompression from 'browser-image-compression';
import loadBase64Image from 'libs/helper/loadBase64Image';

const cropImage = async (
  cropSize: SizeInfo,
  targetImage: CustomFile | undefined,
  croppedAreaPixels: any,
  rotation: any,
) => {
  try {
    //Blob file
    const croppedImage = (await getCroppedImg(targetImage?.publicUrl, croppedAreaPixels, rotation, {
      width: cropSize.width,
      height: cropSize.height,
    })) as any;

    //base64
    const _base64Image = await imageCompression.getDataUrlFromFile(croppedImage);

    const _thumbnail: CustomFile = { file: croppedImage };
    _thumbnail.publicUrl = _base64Image;
    _thumbnail.contentType = _thumbnail.file?.type;

    // onChangeThumbnails([_thumbnail]);
    // setModalShow(false);
    // onClose();
    // setCropShow(false);
    return _thumbnail as CustomFile;
  } catch (e) {
    console.error(e);
  }
};

const convertBase64Files = async (e: any) => {
  const files = Array.from(e);
  return Promise.all(
    await files?.map(async (file: any) => {
      const base64Image = await loadBase64Image(file);
      const fileObject = await fetch(base64Image as string).then((res) => res.blob());
      // if (fileObject.type.indexOf('image/') === 0) {
      return {
        publicUrl: base64Image,
        file: fileObject,
        contentType: fileObject.type,
      } as CustomFile;
      // } else {
      //   return {
      //     publicUrl: base64Image,
      //     file: fileObject,

      //     //sizeInfo: { isCover: true },
      //   } as CustomFile;
      // }
    }),
  );
};

export { cropImage, convertBase64Files };
