import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ImageUploader } from 'components/commons';
import { FileDto } from 'services/data-contracts';
import { CustomFile } from 'types/CommonTypes';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import styled from 'styled-components';

type DefaultProps = {
  index: number;
  width: number;
  height: number;
  onApplyProfile: (image?: FileDto) => void;
  onSlideChange: (swipe: any) => void;
  onChangeControlType: () => void;
  onProfileBinding?: (thumbnail: CustomFile) => void;
};

const ProfileEditorSwipe = observer(
  ({
    index,
    width,
    height,
    onApplyProfile,
    onSlideChange,
    onChangeControlType,
    onProfileBinding,
  }: DefaultProps) => {
    const { meStore } = useStore();
    /**
     * 기존 width에 slidesPerView 개수만큼 곱하고
     * 추가로 spaceBetween 수치 * slidesPerView 개수 값을 전체에 더해야 사이즈가 정사이즈
     */
    const slidesPerView = 8;
    const [swiper, setSwiper] = useState<any>(null);
    const swipeItemWidth = width * slidesPerView + 9 * slidesPerView;

    const init = async () => {
      try {
        await meStore.getProfileImages();
      } catch (e) {
        toast('배경사진 불러오기에 실패했습니다', { position: 'top-center' });
      }
    };

    /** 해당 방식은 swiper/react 원격 조정방식 */
    useEffect(() => {
      if (swiper) {
        swiper.slideTo(index);
      }
    }, [index]);

    useEffect(() => {
      init();
    }, []);

    return (
      <SwiperContainerStyle containerWidth={swipeItemWidth}>
        <GuideTextStyle>Click profile to apply.</GuideTextStyle>
        <Swiper
          centeredSlides
          spaceBetween={19}
          slidesPerView={slidesPerView}
          grabCursor
          onSlideChange={(swiper) => {
            onSlideChange(swiper.activeIndex);
            onChangeControlType();
          }}
          /** 해당 방식은 swiper/react 원격 조정방식 */
          onSwiper={setSwiper}
        >
          <SwiperSlide>
            <ImageUploader
              attachType="image"
              cropSize={{ width: 1600, height: 1600 }}
              onSelected={(thumbnails: any) => {
                if (typeof onProfileBinding !== 'undefined') {
                  onProfileBinding(thumbnails[0]);
                }
              }}
            >
              <ProfileImageContainerStyle>
                <AddIconStyle size={25} src="/image/plus.png" />
              </ProfileImageContainerStyle>
            </ImageUploader>
          </SwiperSlide>
          {meStore.profileImages.map((item) => (
            <SwiperSlide
              key={item?.id}
              style={{ width, height }}
              onClick={() => onApplyProfile(item?.image)}
            >
              <SlideImgStyle src={item?.image?.publicUrl} />
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainerStyle>
    );
  },
);

const SwiperContainerStyle = styled.div<{ containerWidth: number; isVisible?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.5s ease-in-out;
  transform: translate(-50%, -250%);
  z-index: 10;
  width: 900px;
  @media ${(props) => props.theme.media.mobile} {
    top: 0;
    transform: translate(-50%, 110%);
  }
`;

const SlideImgStyle = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100px;
  border: 8px solid transparent;
`;

const AddIconStyle = styled.img<{ size: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const ProfileImageContainerStyle = styled.div`
  background: #3c3d3e;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GuideTextStyle = styled.div`
  position: absolute;
  top: 120px;
  z-index: 1000;
  background-color: yellow;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 7px 0px #000;
`;

export default ProfileEditorSwipe;
