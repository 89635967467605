/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ChangePasswordDto,
  ForgotPasswordDto,
  JoinDto,
  TokenDto,
  ValidationDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Auth<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 탈퇴
   *
   * @tags Auth
   * @name Resign
   * @summary 탈퇴
   * @request POST:/v1/auth/resign
   * @secure
   */
  resign = (params: RequestParams = {}) =>
    this.request<TokenDto, any>({
      path: `/v1/auth/resign`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 비밀번호 리셋
   *
   * @tags Auth
   * @name ResetPassword
   * @summary 비밀번호 리셋
   * @request POST:/v1/auth/reset-password
   * @secure
   */
  resetPassword = (query: { token: string }, data: ChangePasswordDto, params: RequestParams = {}) =>
    this.request<TokenDto, any>({
      path: `/v1/auth/reset-password`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 가입인증 링크 메일 요청
   *
   * @tags Auth
   * @name RequestJoinToken
   * @summary 가입인증 링크 메일 요청
   * @request POST:/v1/auth/request-join-token
   * @secure
   */
  requestJoinToken = (data: JoinDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/auth/request-join-token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 로그인 토큰 재발급
   *
   * @tags Auth
   * @name RefreshToken
   * @summary 로그인 토큰 재발급
   * @request POST:/v1/auth/refresh-token
   * @secure
   */
  refreshToken = (data: TokenDto, params: RequestParams = {}) =>
    this.request<TokenDto, any>({
      path: `/v1/auth/refresh-token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 로그인
   *
   * @tags Auth
   * @name Login
   * @summary 로그인
   * @request POST:/v1/auth/login
   * @secure
   */
  login = (data: JoinDto, params: RequestParams = {}) =>
    this.request<TokenDto, any>({
      path: `/v1/auth/login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 회원가입 (가입인증 링크 메일발송)
   *
   * @tags Auth
   * @name Join
   * @summary 회원가입 (가입인증 링크 메일발송)
   * @request POST:/v1/auth/join
   * @secure
   */
  join = (data: JoinDto, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/auth/join`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 비밀번호 찾기 (비밀번호리셋 링크 메일발송)
   *
   * @tags Auth
   * @name ForgotPassword
   * @summary 비밀번호 찾기 (비밀번호리셋 링크 메일발송)
   * @request POST:/v1/auth/forgot-password
   * @secure
   */
  forgotPassword = (data: ForgotPasswordDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/auth/forgot-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 비밀번호 변경
   *
   * @tags Auth
   * @name ChangePassword
   * @summary 비밀번호 변경
   * @request POST:/v1/auth/change-password
   * @secure
   */
  changePassword = (data: ChangePasswordDto, params: RequestParams = {}) =>
    this.request<TokenDto, any>({
      path: `/v1/auth/change-password`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 로그인 UserName 중복 체크
   *
   * @tags Auth
   * @name DuplicateUserName
   * @summary 로그인 UserName 중복 체크
   * @request GET:/v1/auth/duplicate-check/user-name
   * @secure
   */
  duplicateUserName = (query: { userName: string }, params: RequestParams = {}) =>
    this.request<ValidationDto, any>({
      path: `/v1/auth/duplicate-check/user-name`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 회원가입 인증
   *
   * @tags Auth
   * @name ConfirmJoin
   * @summary 회원가입 인증
   * @request GET:/v1/auth/confirm-join
   * @secure
   */
  confirmJoin = (query: { token: string }, params: RequestParams = {}) =>
    this.request<TokenDto, any>({
      path: `/v1/auth/confirm-join`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
