import { makeAutoObservable } from 'mobx';
import { LinkDto, SocialLinkDto } from 'services/data-contracts';
import { CustomUserInfoDto, CustomUserProfile } from 'types/CommonTypes';

class SimulatorStore {
  simulatorMode: boolean;

  links?: LinkDto[];

  socialLinks?: SocialLinkDto[];

  profile: CustomUserProfile;

  layoutStyle: any;

  constructor() {
    makeAutoObservable(this);
    this.simulatorMode = false;
  }

  updateLinks(links: LinkDto[]) {
    this.links = links;
  }

  updateProfile(profile: CustomUserProfile) {
    this.profile = profile;
  }

  updateSocialLinks(socialLinks: SocialLinkDto[]) {
    this.socialLinks = socialLinks;
  }

  updateLayoutStyle(layoutStyle: any) {
    this.layoutStyle = layoutStyle;
  }

  updateUserInfo(userInfo: CustomUserInfoDto) {
    this.profile = userInfo.profile;
    this.links = userInfo.links;
    this.socialLinks = userInfo.socialLinks;
    this.layoutStyle = userInfo.layoutStyle;
  }

  setSimulatorMode(value: boolean) {
    this.simulatorMode = value;
  }
}

export default SimulatorStore;
