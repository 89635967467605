import React, { useState, useEffect, SyntheticEvent, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CustomFile, SizeInfo } from 'types/CommonTypes';
import { ImageUploaderStyle } from 'resources/styles/link/styles';
import { ImageUploader } from 'components/commons';
import { useStore } from 'stores/StoreHelper';
import ToggleButton from 'controls/toggleButton/ToggleButton';

/**
 * @description 특정 이미지를 크롭후 리사이즈 한다.
 * @param param0
 * @returns
 */
const VideoEditor = ({
  video,
  isCover,
  maxHeight,
  onVideoChange,
  onSettingComplete,
  mode,
}: {
  video?: CustomFile;
  isCover?: boolean;
  maxHeight: string;
  mode?: 'view' | 'edit';
  onVideoChange?: (video: any) => void;
  onSettingComplete?: (settings?: SizeInfo) => void;
}) => {
  const CINEMA = 1.777; //16:9
  /** @description 세로사이즈가 더 큰 경우, 최대 높이를 설정함. */
  const HEIGHT_RATE = 1.2;
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const originWidth = useRef<number>(0);
  const originHeight = useRef<number>(0);

  /** @description 리사이즈한 최초 사이즈(cover인 경우 사이즈가 계속 변하기 때문에 최초사이즈 저장 */

  /** @description 최초로 바인딩할때 비디오 사이즈를 얻어와서 저장한다. */
  const [isFirstBinding, setIsFirstBinding] = useState<boolean>(true);

  const { uiStore } = useStore();
  const [videoSize, setVideoSize] = useState<SizeInfo>();
  const [_isCover, setIsCover] = useState<boolean>();
  const [videoSource, setVideoSource] = useState<any>();
  const [containerHeight, setContainerHeight] = useState<number | string>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const [editorMode, setEditorMode] = useState<'view' | 'edit'>();

  const setNewSize = () => {
    if (typeof videoSource === 'undefined') {
      setContainerHeight(containerRef.current?.offsetWidth! / CINEMA);
      return;
    }
    let _width: number | string, _height: number | string;

    if (_isCover || isCover === true) {
      if (originWidth.current > originHeight.current) {
        setContainerHeight('100%');
      } else {
        const height = containerRef.current?.offsetWidth! * HEIGHT_RATE;
        setContainerHeight(height);
      }
      _width = '100%';
      _height = '100%';
    } else {
      if (originWidth.current > originHeight.current) {
        _width = '100%';
        _height = 'auto';
        setContainerHeight('100%');
      } else {
        _width = 'auto';
        _height = '100%';
        const height = containerRef.current?.offsetWidth! * HEIGHT_RATE;
        setContainerHeight(height);
      }
    }
    setVideoSize({
      width: _width,
      height: _height,
      isCover: _isCover,
    });
  };

  useEffect(() => {
    if (typeof _isCover !== 'undefined') {
      setNewSize();
    }
  }, [_isCover]);

  useEffect(() => {
    if (typeof isCover !== 'undefined') {
      if (originHeight.current > 0) setIsCover(isCover);
    }
  }, [isCover]);

  useEffect(() => {
    const newVideoSize: SizeInfo = {
      ...videoSize,
      isCover: isCover,
    };
    if (typeof onSettingComplete === 'function') {
      onSettingComplete(newVideoSize);
    }
  }, [videoSize]);

  useEffect(() => {
    //  setVideoSize(undefined);
    setIsFirstBinding(true);
  }, [videoSource]);

  useEffect(() => {
    setVideoSource(undefined);
    setContainerHeight('100%');
    if (typeof isCover === 'undefined') {
      setIsCover(false);
    }
    setTimeout(() => {
      setVideoSource(video);
    }, 500);

    return () => {
      video = undefined;
      setVideoSource(undefined);
      setIsFirstBinding(true);
    };
  }, [video]);

  useEffect(() => {
    if (typeof mode !== 'undefined') {
      setEditorMode(mode);
    }
  }, [mode]);

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: '100%',

        marginBottom: mode === 'edit' ? 60 : 0,
      }}
    >
      <div
        ref={containerRef}
        style={{
          width: '100%',
          height: containerHeight,
          alignContent: 'center',
          justifyContent: 'center',
          borderRadius: 5,
          overflow: 'hidden',
        }}
      >
        <VideoContainerStyle
          style={{
            minHeight: typeof videoSource?.publicUrl === 'undefined' ? 230 : 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            background: '#000',
          }}
        >
          {typeof videoSource !== 'undefined' && (
            <>
              <VideoContentStyle
                isCover={_isCover || isCover}
                width={videoSize?.width}
                height={videoSize?.height}
                playsInline
                autoPlay
                muted
                onLoadedData={(response: SyntheticEvent) => {
                  const { clientWidth, clientHeight } = response.target as any;
                  if (isFirstBinding === true) {
                    setLoaded(true);
                    setIsFirstBinding(false);
                  }
                  originWidth.current = clientWidth;
                  originHeight.current = clientHeight;
                  setNewSize();
                }}
              >
                <source src={videoSource?.publicUrl} />
                Your browser does not support HTML5 video.
              </VideoContentStyle>
            </>
          )}
          {editorMode === 'edit' && (
            <ImageUploader
              multipleUpload={false}
              attachType="video"
              style={{ textAlign: 'left', position: 'absolute' }}
              onSelected={(thumbnails: any) => {
                uiStore.progress.show();
                setVideoSource(undefined);
                setTimeout(() => {
                  setVideoSource(thumbnails[0]);
                  uiStore.progress.close();
                }, 1000);
                if (typeof onVideoChange === 'function') {
                  onVideoChange(thumbnails[0]);
                }
              }}
            >
              <ImageUploaderStyle style={{ margin: 'auto' }}>
                <img src="/image/camera.svg" alt="" width={25} height={25} />
              </ImageUploaderStyle>
            </ImageUploader>
          )}
        </VideoContainerStyle>
      </div>
      {editorMode === 'edit' && (
        <div
          style={{
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            bottom: -50,
          }}
        >
          <ToggleButton
            checked={_isCover}
            label="Fit"
            onToggleClick={(isChecked: boolean) => {
              setIsCover(isChecked);
            }}
          />
        </div>
      )}
    </div>
  );
};

const VideoContainerStyle = styled.div`
  margin: auto;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
`;

const VideoContentStyle = styled.video<{ isCover?: boolean }>`
  object-fit: ${(props) => (props.isCover ? 'cover' : 'unset')};
  margin: auto;
`;

export default React.memo(VideoEditor);
