import React, { useEffect, useState, useRef } from 'react';
import { FollowButtonsContainer } from 'features/mainFeatures/follow/containers/FollowButtonsContainer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { isValid } from 'libs/helper/utils';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import MobileShareDrawer from 'components/commons/MobileShareDrawer';
import QRCodePrintModal from 'components/commons/QRCodePrintModal';
import MobileDrawer from 'components/commons/MobileDrawer';
import MenuPopup from 'components/commons/MenuPopup';
import useScreenType from 'libs/hooks/useScreenType';
import DehazeIcon from '@mui/icons-material/Dehaze';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import _ from 'lodash';

const GnbUserHome = observer(({ userName }: { userName: string }) => {
  const { authStore, userStore, templateStore } = useStore();
  const { t } = useTranslation();

  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [me, setMe] = useState<any>();
  const [menu, setMenu] = useState<any>();

  const open = Boolean(anchorEl);
  const qrRef = useRef<any>();
  const screenType = useScreenType();
  const navigate = useNavigate();

  const menuListAdmin = [
    {
      title: `@${me?.profile.userName}`,
      onClick: () => {
        handleGoMyProfile();
      },
    },

    {
      title: t('manager'),
      onClick: () => {
        navigate('/admin');
      },
    },

    {
      title: t('share'),
      onClick: () => {
        setShowShareModal(true);
      },
    },
    // {
    //   title: t('deClare'),
    //   onClick: () => {
    //     handleClickReport();
    //   },
    // },
    {
      title: t('account'),
      onClick: () => {
        navigate('/admin/account');
      },
    },
    {
      title: t('logOut'),
      onClick: () => {
        handleClickLogout();
      },
    },
  ];

  // const menuListAdminWithoutReport = [
  //   {
  //     title: t('logOut'),
  //     onClick: () => {
  //       handleClickLogout();
  //     },
  //   },
  //   {
  //     title: t('manager'),
  //     onClick: () => {
  //       navigate('/admin');
  //     },
  //   },
  //   {
  //     title: t('setting'),
  //     onClick: () => {
  //       navigate('/admin/account');
  //     },
  //   },
  //   {
  //     title: t('myPage'),
  //     onClick: () => {
  //       handleGoMyProfile();
  //     },
  //   },
  //   {
  //     title: t('share'),
  //     onClick: () => {
  //       setShowShareModal(true);
  //     },
  //   },
  // ];

  const menuList = [
    {
      title: t('logIn'),
      onClick: () => {
        handleClickLogout();
      },
    },
    {
      title: t('signUp'),
      onClick: () => {
        navigate('/sign-up');
      },
    },
    {
      title: t('deClare'),
      onClick: () => {
        handleClickReport();
      },
    },
  ];

  const init = async () => {
    const me = await localStorage.getItem('me');
    const meParse = !_.isNull(me) ? JSON.parse(me) : null;
    setMe(meParse);
    //document.querySelector('#gnb_container')?.setAttribute('style', 'display:none');
  };

  const handleClickOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickLogout = () => {
    authStore.logout();
    handleClickCloseMenu();
  };

  const handleClickCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickReport = () => {
    if (!_.isNull(me) && !_.isUndefined(me)) {
      navigate('/report');
      localStorage.setItem('reportUserName', userName);
    } else {
      toast(t('useLoginIn'));
    }
  };

  const handleGoMyProfile = () => {
    navigate(`/${me?.profile.userName}`);
  };

  useEffect(() => {
    init();
    return () => {
      setMe(null);
      templateStore.setUserInfo(undefined);
    };
  }, []);

  return (
    <>
      <UserGNBStyle className="row" isScroll={templateStore.isScrollHeaderProfile}>
        <FollowButtonStyle isMobile={screenType === 'mobile'}>
          <FollowButtonsContainer />
        </FollowButtonStyle>
        {/* <ShareButtonStyle
          onClick={() => {
            setShowShareModal(true);
          }}
        >
          <img src="/image/export.png" alt="" style={{ width: '24px', height: '24px' }} />
        </ShareButtonStyle> */}
        {/* {templateStore.isScrollHeaderProfile && (
          <UserGNBTitleStyle>{templateStore.userInfo?.profile?.title}</UserGNBTitleStyle>
        )} */}
        <ShareButtonStyle>
          <Button
            onClick={handleClickOpenMenu}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            sx={{ color: 'black', verticalAlign: 'baseline', minWidth: '0px' }}
          >
            <AvatarDivStyle isShow={templateStore.isScrollHeaderProfile}>
              {isValid(templateStore.userInfo?.profile?.profileImage?.publicUrl) && (
                <AvatarStyle
                  src={`${templateStore.userInfo?.profile?.profileImage?.publicUrl}`}
                  alt=""
                />
              )}
            </AvatarDivStyle>
            <DehazeIcon />
          </Button>
        </ShareButtonStyle>
      </UserGNBStyle>

      {screenType === 'window' ? (
        <QRCodePrintModal
          qrRef={qrRef}
          onClose={() => {
            setShowShareModal(false);
          }}
          userName={userName}
          show={showShareModal}
        >
          <QrDuplicationButtonStyle
            onClick={() => {
              navigator.clipboard.writeText(`https://ohmoss.com/${userName}`);
              toast.success(t('copyClipBoard'));
              setShowShareModal(false);
            }}
          >{`https://ohmoss.com/${userName}`}</QrDuplicationButtonStyle>
        </QRCodePrintModal>
      ) : (
        <MobileShareDrawer
          open={showShareModal}
          profile={me}
          qrRef={qrRef}
          handleClose={() => setShowShareModal(false)}
        />
      )}
      {screenType === 'window' ? (
        <MenuPopup
          open={open}
          anchorEl={anchorEl}
          menuList={!_.isNull(me) ? menuListAdmin : menuList}
          handleClose={handleClickCloseMenu}
        />
      ) : (
        <MobileDrawer
          open={open}
          // menuList={!_.isNull(me) ? menuListAdmin : menuList}
          menuList={!_.isNull(me) ? menuListAdmin : menuList}
          handleClose={handleClickCloseMenu}
        />
      )}
    </>
  );
});

const UserGNBStyle = styled.div<{ isScroll: boolean }>`
  position: fixed;
  top: 0;
  height: 80px;
  width: 100%;
  z-index: 100;
  justify-content: space-between;
  background: ${(props) => (props.isScroll ? 'rgba(255, 255, 255, 0)' : 'transparent')};
  /* box-shadow: ${(props) =>
    props.isScroll ? '0px 1px 10px rgba(146, 146, 146, 0.1)' : 'transparent'}; */
  /* ${(props) => props.isScroll && `backdrop-filter: blur(20px)`} */
  transition-duration: 0.2s;
  transition-delay: 0.2s;
  transition-timing-function: ease;
`;

const ShareButtonStyle = styled.div`
  z-index: 100;
  right: 30px;
  top: 30px;
  width: 45px;
  margin: 20px 20px 20px 20px;
  height: 45px;
  line-height: 58px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  opacity: 1;
  transition: width 0.2s, opacity 0.3s;
  align-items: flex-start;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const FollowButtonStyle = styled.div<{ isMobile: boolean }>`
  z-index: 100;
  right: 30px;
  top: 30px;
  width: ${(props) => (props.isMobile ? '90px' : '45px')};
  margin: 20px 20px 20px 20px;
  height: 45px;
  line-height: 58px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  opacity: 0.6;
  transition: width 0.2s, opacity 0.5s, visibility 0.5s;
  align-items: flex-start;
  cursor: pointer;
  > div {
    justify-content: space-between;
    margin-left: 10px;
  }
  > div > div:first-child {
    z-index: 10;
    /* background: #fff;
    width: 20px;
    height: 35px; */
    margin: 3px 0 0 0;
  }
  > div > div:first-child > svg {
    position: relative;
    top: -3px;
  }
  > div > div:last-child {
    right: ${(props) => (props.isMobile ? '0px' : '20px')};
    visibility: ${(props) => (props.isMobile ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.isMobile ? 1 : 0)};
    transition: width 0.2s, opacity 0.1s, visibility 1s;
  }
  &:hover {
    opacity: 1;
    width: 100px;
    transition: width 0.2s, opacity 0.5s, visibility 0.5s;
    > div > div:last-child {
      visibility: visible;
      opacity: 1;
      transition: width 0.2s, opacity 0.5s, visibility 0.5s;
    }
  }
`;

const QrDuplicationButtonStyle = styled.div`
  text-align: center;
  border: 2px dashed #e9d4ff;
  border-radius: 10px;
  margin: 10px 10px 10px 10px;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
`;

const UserGNBTitleStyle = styled.div`
  display: flex;
  align-items: center;
  color: #111;
  font-size: 18px;
`;

const AvatarDivStyle = styled.div<{ isShow: boolean }>`
  width: 50px;
  height: 50px;
  margin: 2px 0 0 2.5px;
  opacity: ${(props) => (props.isShow ? 1 : 0)};
  transition-duration: 0.2s;
  transition-delay: 0.2s;
  transition-timing-function: ease;
  position: absolute;
`;

const AvatarStyle = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100px;
`;

export default GnbUserHome;
