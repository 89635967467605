import React, { useEffect } from 'react';
import { ItemContainerStyle } from 'features/adminFeatures/template/components/ItemContainerStyle';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { LinkDto } from 'services/data-contracts';
import styled from 'styled-components';
import _ from 'lodash';
import { useStore } from 'stores/StoreHelper';

type defaultProps = {
  item: LinkDto;
  mode?: 'create' | 'edit';
  onDelete?: (item: LinkDto) => void;
};

const PageLink = ({ item, mode, onDelete }: defaultProps) => {
  const IMAGE_WIDTH = 50;
  const navigation = useNavigate();
  const { pathname } = useLocation();
  const { userStore } = useStore();
  const me = localStorage.getItem('me');
  const { userName } = useParams();

  const handleClickPage = () => {
    if (typeof item !== 'undefined') {
      let _userName;
      // if (!_.isUndefined(userName)) {
      //   _userName = userName;
      // } else {
      //   // if (location.pathname === '/admin') {
      //   // } else {
      //   //   _userName = userStore.userInfo?.profile?.userName;
      //   // }
      // }
      if (pathname.startsWith('/admin')) {
        if (_.isString(me)) {
          _userName = JSON.parse(me).profile.userName;
          window.open(`${window.location.origin}/${_userName}/pages/${item.id}`, '_blank');
        }
      } else {
        if (!_.isUndefined(userName)) {
          _userName = userName;
        } else {
          _userName = userStore.userInfo?.profile?.userName;
        }
        navigation(`/${_userName}/pages/${item.id}`);
      }
    }
  };

  return (
    <ItemContainerStyle onClick={handleClickPage}>
      <ItemStyle rel="noreferrer" href={item?.linkUrl} target="_blank">
        <LinkContainerTextStyle>
          <LinkTextStyle>
            {typeof item.thumbnail !== 'undefined' && (
              <div
                style={{
                  height: IMAGE_WIDTH,
                  width: IMAGE_WIDTH,
                  float: 'left',
                  position: 'relative',
                }}
              >
                <img
                  alt=""
                  src={item.thumbnail?.publicUrl}
                  width={IMAGE_WIDTH}
                  height={IMAGE_WIDTH}
                  style={{ borderRadius: 8 }}
                />
              </div>
            )}
            <div
              style={{
                display: 'table',
                position: 'absolute',
                left: typeof item.thumbnail !== 'undefined' ? IMAGE_WIDTH : 10,
                right: typeof item.thumbnail !== 'undefined' ? IMAGE_WIDTH : 10,
                width: typeof item.thumbnail !== 'undefined' ? 'calc(100% - 100px)' : '95%',
                height: 50,
              }}
            >
              <LinkTitle>{item?.title}</LinkTitle>
            </div>
          </LinkTextStyle>
        </LinkContainerTextStyle>
      </ItemStyle>
      {mode === 'edit' && (
        <RowContainerStyle>
          <div>E</div>
          <div
            style={{
              cursor: 'pointer',
              width: 20,
            }}
            onClick={() => {
              if (typeof onDelete !== 'undefined') {
                onDelete(item);
              }
            }}
          >
            <img src="/image/trash.png"></img>
          </div>
        </RowContainerStyle>
      )}
    </ItemContainerStyle>
  );
};

const ItemStyle = styled.a`
  width: 100%;
  text-decoration: none;
  color: black;
`;

const LinkContainerTextStyle = styled.div`
  display: table;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const LinkTextStyle = styled.div`
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  min-height: 50px;
  padding: 5px;
  justify-content: space-between;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
`;

const LinkTitle = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 16px;
  letter-spacing: 2px;
`;

const RowContainerStyle = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
`;

export default PageLink;
