import { makeAutoObservable } from 'mobx';
import { LinkDto, SocialLinkDto } from 'services/data-contracts';
import { User as UserService } from 'services/User';
import { REST_API_URL } from 'stores/AppStore';
import { isValid } from 'libs/helper/utils';

import _ from 'lodash';
import { CustomUserInfoDto } from 'types/CommonTypes';

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: UserService<unknown> = new UserService({
    baseURL: REST_API_URL,
  });

  userInfo: CustomUserInfoDto;

  links: LinkDto[] = [];

  link: LinkDto;

  socialLinks: SocialLinkDto[] = [];

  /**
   * @description 공개된 사용자 정보를 취득한다.
   * @param userName
   */
  *getUserInfo(userName: string) {
    const { status, error, data } = yield this.api.getUserInfo(userName, { page: 0, size: 1000 });
    if (status === 200) {
      if (typeof data.profile?.custom !== 'undefined') {
        const parsedCustom = JSON.parse(data.profile?.custom);
        const mergedProfile = Object.assign(data.profile, parsedCustom) as CustomUserInfoDto;
        mergedProfile.followInfo = {
          followers: data.followerCount,
          followings: data.followingCount,
          followedByViewer: data.followedByViewer,
        };

        if (data.profile.userName === 'karbot') {
          data.profile.userType = 'pro';
        }
        /** @description Background Image가 가장 우선한다. */
        if (typeof data.profile.backgroundImage === 'undefined') {
          if (typeof data.profile.addInImage !== 'undefined') {
            data.profile.backgroundImage = data.profile.addInImage;
            data.profile.addInImage = undefined;
          }
        }

        data.profile = mergedProfile;

        this.userInfo = data;
      } else {
        this.userInfo = data;
      }
      this.links = data.links;
      this.socialLinks = data.socialLinks;
      return data;
    } else {
      return error;
    }
  }

  *getLink(userName: string, linkId: number) {
    const { data } = yield this.api.getLink(userName, linkId);
    if (typeof data.custom !== 'undefined') {
      data.customObject = JSON.parse(data.custom);
    }

    this.link = data;
    return data;
  }
}

export default UserStore;
