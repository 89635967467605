/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LinkDto, UserInfoDto, UserProfileDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class User<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 팔로우
   *
   * @tags User
   * @name Follow
   * @summary 팔로우
   * @request POST:/v1/users/{targetUserName}/follow
   * @secure
   */
  follow = (targetUserName: string, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/users/${targetUserName}/follow`,
      method: 'POST',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크정보 조회
   *
   * @tags User
   * @name GetLink
   * @summary 링크정보 조회
   * @request GET:/v1/users/{userName}/links/{linkId}
   * @secure
   */
  getLink = (userName: string, linkId: number, params: RequestParams = {}) =>
    this.request<LinkDto, any>({
      path: `/v1/users/${userName}/links/${linkId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 사용자 프로필 조회
   *
   * @tags User
   * @name GetUserInfo
   * @summary 사용자 프로필 조회
   * @request GET:/v1/users/{userName}/info
   * @secure
   */
  getUserInfo = (
    userName: string,
    query?: { isActive?: boolean; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<UserInfoDto, any>({
      path: `/v1/users/${userName}/info`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 팔로잉 목록
   *
   * @tags User
   * @name GetFollowings
   * @summary 팔로잉 목록
   * @request GET:/v1/users/{userName}/followings
   * @secure
   */
  getFollowings = (
    userName: string,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<UserProfileDto[], any>({
      path: `/v1/users/${userName}/followings`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 팔로워 목록
   *
   * @tags User
   * @name GetFollowers
   * @summary 팔로워 목록
   * @request GET:/v1/users/{userName}/followers
   * @secure
   */
  getFollowers = (
    userName: string,
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<UserProfileDto[], any>({
      path: `/v1/users/${userName}/followers`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 언팔로우
   *
   * @tags User
   * @name Unfollow
   * @summary 언팔로우
   * @request DELETE:/v1/users/{targetUserName}/unfollow
   * @secure
   */
  unfollow = (targetUserName: string, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/users/${targetUserName}/unfollow`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 팔로우요청 취소
   *
   * @tags User
   * @name CancelRequestedFollow
   * @summary 팔로우요청 취소
   * @request DELETE:/v1/users/{targetUserName}/cancel-requested-follow
   * @secure
   */
  cancelRequestedFollow = (targetUserName: string, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/users/${targetUserName}/cancel-requested-follow`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
}
