import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import useScreenType from 'libs/hooks/useScreenType';
import { BottomSheet } from 'react-spring-bottom-sheet';

import 'react-spring-bottom-sheet/dist/style.css';

const ImageUploader = ({ show, children }: { show: boolean; children: React.ReactNode }) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const screenType = useScreenType();

  useEffect(() => {
    setModalShow(show);
  }, [show]);

  return (
    <>
      {screenType === 'window' ? (
        <Modal
          ariaHideApp={false}
          isOpen={modalShow}
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              border: 'none',
              zIndex: 3010000,
            },
            content: {
              height: 500,
              padding: 0,
              background: '#fff',
              top: '50%',
              left: '50%',
              width: '100%',
              maxWidth: 600,
              border: 'none',
              transform: 'translate(-50%, -50%',
            },
          }}
        >
          {children}
        </Modal>
      ) : (
        <BottomSheet open={modalShow}>{children}</BottomSheet>
      )}
    </>
  );
};

export default ImageUploader;
