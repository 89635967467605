import React, { useState, useEffect } from 'react';
import { TemplateDto, UserStyleDto } from 'services/data-contracts';
import { updateModel } from 'components/items/commonLogic';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { Confirm } from 'components/commons';
// import MobileCommonFooter from 'features/adminFeatures/commons/components/MobileCommonFooter';
import PaginationComponent from 'components/commons/PaginationComponent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WhiteButton from 'components/commons/WhiteButton';
import useForm from 'libs/hooks/useForm';
import styled from 'styled-components';

type DefaultProps = {
  onShowSimulator?: () => void;
  onCloseSimulator?: () => void;
};

const TemplateContainer = observer(({ onShowSimulator }: DefaultProps) => {
  const stores = useStore();
  const isFormValid = useForm(stores.formValidationStore);
  const { simulatorStore, meStore, parameterStore, uiStore, fileStore, templateStore } = useStore();
  const [model, setModel] = useState<UserStyleDto>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const navigation = useNavigate();
  const { t } = useTranslation();

  // 저장하기 함수
  const handleClickSaveTemplate = () => {
    setShowConfirm(true);
  };

  const handleClickConfirm = async () => {
    const newStyleModel: UserStyleDto = { ...model };

    if (typeof newStyleModel !== 'undefined' && typeof simulatorStore?.profile !== 'undefined') {
      meStore.saveMyLayout(newStyleModel);
      meStore.saveMyProfile(simulatorStore?.profile);
    } else if (typeof simulatorStore?.profile !== 'undefined') {
      meStore.saveMyLayout({ template: { id: 561 } });
      meStore.saveMyProfile(simulatorStore?.profile);
    }
    if (simulatorStore?.profile?.backgroundImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadBackgroundImage(
        simulatorStore?.profile?.backgroundImage?.file,
      );
      //TODO
      // simulatorStore?.profile?.backgroundImage = { id: image.id };
      // await meStore.saveMyProfile(simulatorStore?.profile);
    }
    toast(t('templateApply'));
    uiStore.drawer.confirm();
    await meStore.getMyProfile();
  };

  // 페이지네이션 관련 함수
  const handleClickPageNumber = async (e: any, pageNumber: number) => {
    await parameterStore.addParam('page', pageNumber - 1);
    replacementPage();
  };

  const replacementPage = () => {
    navigation(`/admin`);
  };

  const init = () => {
    templateStore.getTemplates(parameterStore.parameters);
  };

  const handleUpdateTemplate = (value: any) => {
    templateStore.setUserInfo({
      ...templateStore.userInfo,
      profile: {
        ...templateStore?.userInfo?.profile,
        backgroundColor: undefined,
        backgroundImage: undefined,
        template: value,
      },
    });
  };

  useEffect(() => {
    init();
  }, [parameterStore.parameters]);

  return (
    <>
      {/* 리스트 목록 */}
      <ListContainerStyle>
        {templateStore.templates?.map((template: TemplateDto) => (
          <TemplateContainerStyle>
            <TemplateStyle
              key={template?.id}
              style={{
                backgroundImage: `url(${template?.thumbnail?.publicUrl})`,
                backgroundSize: 'cover',
                margin: 5,
              }}
              onClick={() => {
                handleUpdateTemplate(template);
                templateStore.setTemplate(template);
                simulatorStore.updateLayoutStyle(template);
                updateModel(setModel, 'template', { id: template.id });
              }}
            >
              <TemplateButtonStyle>
                {template?.name === templateStore.currentTemplate?.name && (
                  <SelectedIconStyle>
                    <CheckCircleIcon style={{ color: 'green', fontSize: 30 }} />
                  </SelectedIconStyle>
                )}
              </TemplateButtonStyle>
            </TemplateStyle>
          </TemplateContainerStyle>
        ))}
      </ListContainerStyle>

      {/* 페이지네이션 */}
      <PaginationContainerStyle>
        {templateStore.pagination?.totalPages > 0 && (
          <PaginationComponent
            pagination={templateStore.pagination}
            handleClickPageNumber={handleClickPageNumber}
          />
        )}
      </PaginationContainerStyle>

      {/* 템플릿 저장하기 */}
      {/* <MobileCommonFooter isValidForm onSaveClick={() => {}} onShowSimulator={onShowSimulator} /> */}
      <WhiteButton
        type="SAVE"
        disabled={isFormValid.result}
        style={{ marginTop: '40px' }}
        onClick={() => {
          handleClickSaveTemplate();
        }}
      />
      <Confirm
        title={t('backgroundDelete')}
        closeText="취소"
        visible={showConfirm}
        setVisible={setShowConfirm}
        onConfirm={handleClickConfirm}
      />
    </>
  );
});

const ListContainerStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 30px 0 0 0;
`;

const TemplateContainerStyle = styled.div`
  margin: 0 0 10px 0;
`;

const TemplateStyle = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  @media ${(props) => props.theme.media.mobile} {
    width: 100px;
    height: 100px;
  }
`;

const TemplateButtonStyle = styled.div`
  position: relative;
  cursor: pointer;
`;

const SelectedIconStyle = styled.div`
  position: absolute;
  top: -10px;
  left: 40px;
  @media ${(props) => props.theme.media.mobile} {
    left: 30px;
  }
`;

const PaginationContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px auto 10px auto;
  @media ${(props) => props.theme.media.mobile} {
    margin: 10px 0 10px 0;
  }
`;

export default TemplateContainer;
