import React, { useEffect, useState } from 'react';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { GnbUserHome } from 'features/commonFeatures/navigationBar/containers';

const PaymentFailPage = observer(() => {
  const clientKey = 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq';

  const { userStore, templateStore } = useStore();
  const params = useParams();
  const { t } = useTranslation();

  const init = async () => {};

  useEffect(() => {
    init();
    return () => {};
  }, [params]);

  return <>결제에 실패하였습니다.</>;
});

export default PaymentFailPage;
