import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfirmModal from 'features/adminFeatures/account/components/SecessionConfirmModal';
import BasicButton from 'components/commons/BasicButton';

const AccountPage = observer(() => {
  const { t } = useTranslation();
  const { authStore, meStore } = useStore();
  const navigation = useNavigate();
  const [userInfo, setUserInfo] = useState<any>();
  const [isOpenSecessionAccountConfirmModal, setIsOpenSecessionAccountConfirmModal] =
    useState<boolean>(false);

  const handleClickAccountInfo = () => {
    //
  };

  const handleClickLogout = () => {
    authStore.logout();
  };

  // const handleClickSecessionAccount = () => {
  //   authStore.resign();
  // };

  useEffect(() => {
    const me = localStorage.getItem('me');
    if (me === null) {
      return;
    }
    const myInfo = JSON.parse(me);
    setUserInfo(myInfo);
    return () => {};
  }, [meStore]);

  return (
    <>
      <ConfirmModal
        isShow={isOpenSecessionAccountConfirmModal}
        title={t('withDrawal')}
        content={
          <ol>
            <li>{t('withDrawalDescriptionOne')}</li>
            <li>{t('withDrawalDescriptionTwo')}</li>
            <li>{t('withDrawalDescriptionThree')}</li>
          </ol>
        }
        cancel={() => {
          setIsOpenSecessionAccountConfirmModal(false);
        }}
        cancelTitle={t('no')}
        approvalTitle={t('toOptOut')}
        approval={() => {
          authStore.resign();
        }}
      />
      <AccountContainer>
        <MainTitle />

        {/* {t('setting')}</MainTitle> */}
        <ButtonGroup marginTop="100px">
          <Title>{t('changeUserInformation')}</Title>
          <h5>User name</h5>
          <div>{userInfo?.profile?.userName}</div>
          <h5>Email</h5>
          <div>{userInfo?.email}</div>
          {/* <BasicButton
            title={t('accountInformation')}
            onClick={() => {
              navigation('/admin/account/info');
            }}
          /> */}
        </ButtonGroup>
        <ButtonGroup>
          <Title>{t('leaveLogOut')}</Title>
          <BasicButton
            title={t('logOut')}
            onClick={() => {
              handleClickLogout();
            }}
          />
          <BasicButton
            title={t('toOptOut')}
            onClick={() => {
              setIsOpenSecessionAccountConfirmModal(true);
            }}
          />
        </ButtonGroup>
        <ButtonGroup>
          {/* <Title>{t('serviceInformation')}</Title>
          <BasicButton
            title={t('termsOfService')}
            onClick={() => {
              navigation('/use-terms');
            }}
          />
          <BasicButton
            title={t('marketUsageConsent')}
            onClick={() => {
              navigation('/marketing-terms');
            }}
          /> */}
          <Footer>
            <div style={{ paddingBottom: '16px' }}>{t('nbhdTitle')}</div>
            <Title>
              {t('nbhdDescriptionOne')}
              <br />
              {t('nbhdDescriptionTwo')}
              <br />
              {t('nbhdDescriptionThree')}
            </Title>
            <Title>
              {t('email')}: moss@ohmoss.com
              <br />
              {t('tel')} : +82-2-6949-8264 <br />
              {t('fax')} : +82-70-8282-9191
            </Title>
          </Footer>
        </ButtonGroup>
      </AccountContainer>
    </>
  );
});

const ButtonGroup = styled.div<{ marginTop?: string }>`
  margin-bottom: 32px;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: ${(props) => props.marginTop};
  }
`;

const MainTitle = styled.div`
  text-align: center;
  font-size: 50px;
  padding-bottom: 16px;
  margin-top: 100px;
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const Title = styled.div`
  color: #666666;
  padding-bottom: 16px;
`;

const Footer = styled.div`
  font-size: 12px;
  position: absolute;
  margin-top: 80px;
  margin-right: 16px;
  max-width: 680px;
  @media ${(props) => props.theme.media.mobile} {
    max-width: 500px;
  }
`;

const AccountContainer = styled.div`
  margin: 0 auto;
  max-width: 680px;
  @media ${(props) => props.theme.media.mobile} {
    padding-top: 60px;
    max-width: 500px;
    min-width: 300px;
    padding-left: 1em;
    padding-right: 1em;
  }
`;

export default AccountPage;
