import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonType } from 'components/commons/ToggleButton';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStore } from 'stores/StoreHelper';
import { LinkModel } from 'types/CommonTypes';
import { observer } from 'mobx-react';
import { Pagination } from 'swiper';
import { t } from 'i18next';
import { Badge } from '@mui/material';
import * as Editor from 'components/items';
import ListOfLinksContainer from 'features/adminFeatures/link/containers/ListOfLinksContainer';
import styled from 'styled-components';
import 'swiper/css/pagination';
import _ from 'lodash';
import 'swiper/css';

const linkTypeList: Array<ToggleButtonType> = [
  {
    iconSrc: '/image/link.png',
    description: 'Link',
    id: 'SimpleLink',
    checked: 'checked',
  },
  {
    iconSrc: '/image/linkType_text.png',
    description: 'Text',
    id: 'TextLink',
  },
  {
    iconSrc: '/image/card.png',
    description: 'Card',
    id: 'CardLink',
  },
  {
    iconSrc: '/image/map.png',
    description: 'Map',
    id: 'MapLink',
  },
  {
    iconSrc: '/image/youtube.png',
    description: 'Video',
    id: 'Youtube',
  },
  {
    iconSrc: '/image/phone.png',
    description: 'Phone',
    id: 'TelLink',
  },
  {
    iconSrc: '/image/pageIcon.png',
    description: 'Page',
    id: 'Page',
    // exclusive: true,
  },
  {
    iconSrc: '/image/pageIcon.png',
    description: 'ItemLink',
    id: 'ItemLink',
    exclusive: true,
  },
  // {
  //   iconSrc: '/image/pageIcon.png',
  //   description: 'Notice',
  //   id: 'Notice',
  //   exclusive: true,
  // },
];

const LinkCreateContainer = observer(
  ({
    link,
    onClose,
  }: {
    link?: LinkModel;
    onClose: () => void;
    onSuccess: (mode: 'create' | 'edit') => void;
  }) => {
    const [linkType, setLinkType] = useState<string>();
    const [selectedLink, setSelectedLink] = useState<any>();

    const stores = useStore();

    const handleClickType = (item: string) => {
      setLinkType(item);
      //
    };

    const handleOnItemDelete = async (linkId: number) => {
      stores.uiStore.confirm.show({
        message: 'Are you really want to delete this link?',
        okText: 'Delete',
        closeText: 'Cancel',
        onConfirmed: async () => {
          await stores.linkStore.deleteLink(linkId);
          stores.userStore.getUserInfo(stores.meStore.me.userName!);

          reload('Item deleted!');
        },
      });
    };

    const onLinkCreated = (result: string) => {
      setLinkType('SimpleLink');
      setSelectedLink(undefined);
      reload(result);
    };

    const reload = (result: string) => {
      setTimeout(async () => {
        await stores.linkStore.getLinks();
        stores.userStore.getUserInfo(stores.meStore.me.userName!);
      }, 300);
    };

    const getLinkContainer = () => {
      // stores.formValidationStore.destroy();
      switch (linkType) {
        case 'SimpleLink':
          return (
            <Editor.SimpleLinkEditor
              stores={stores}
              onRegistComplete={onLinkCreated}
              model={selectedLink}
              onCancelModity={() => {
                setSelectedLink(undefined);
                setLinkType('SimpleLink');
              }}
            />
          );
        case 'TextLink':
          return (
            <Editor.TextLinkEditor
              stores={stores}
              onRegistComplete={onLinkCreated}
              model={selectedLink}
              onCancelModity={() => {
                setSelectedLink(undefined);
                setLinkType('SimpleLink');
              }}
            />
          );
        case 'CardLink':
          return (
            <Editor.CardLinkEditor
              stores={stores}
              onRegistComplete={onLinkCreated}
              model={selectedLink}
              onCancelModity={() => {
                setSelectedLink(undefined);
                setLinkType('SimpleLink');
              }}
            />
          );
        case 'MapLink':
          return (
            <Editor.MapLinkEditor
              stores={stores}
              model={selectedLink}
              onRegistComplete={onLinkCreated}
              onCancelModity={() => {
                setSelectedLink(undefined);
                setLinkType('SimpleLink');
              }}
            />
          );
        case 'TelLink':
          return (
            <Editor.TelLinkEditor
              stores={stores}
              model={selectedLink}
              onRegistComplete={onLinkCreated}
              onCancelModity={() => {
                setSelectedLink(undefined);
                setLinkType('SimpleLink');
              }}
            />
          );
        case 'Youtube':
          return (
            <Editor.YouTubeEditor
              stores={stores}
              onRegistComplete={onLinkCreated}
              model={selectedLink}
              onCancelModity={() => {
                setSelectedLink(undefined);
                setLinkType('SimpleLink');
              }}
            />
          );
        case 'Page':
          return (
            <Editor.PageEditor
              stores={stores}
              onRegistComplete={onLinkCreated}
              model={selectedLink}
              onCancelModity={() => {
                setSelectedLink(undefined);
                setLinkType('SimpleLink');
              }}
            />
          );
        case 'ItemLink':
          return (
            <Editor.ItemLinkEditor
              stores={stores}
              onRegistComplete={onLinkCreated}
              model={selectedLink}
              onCancelModity={() => {
                setSelectedLink(undefined);
                setLinkType('ItemLink');
              }}
            />
          );
        default:
          break;
      }
    };

    useEffect(() => {
      stores.linkStore.getLinks();
    }, [stores.linkStore]);

    useEffect(() => {
      if (typeof selectedLink?.linkType !== 'undefined') {
        setLinkType(selectedLink?.linkType);
        handleClickType(selectedLink?.linkType);
      }
    }, [selectedLink]);

    useEffect(() => {
      if (typeof link === 'undefined') {
        setLinkType('SimpleLink');
        handleClickType('SimpleLink');
      } else {
        setLinkType(link.linkType);
        handleClickType(link.linkType!);
        setSelectedLink(link);
      }
    }, [link]);

    return (
      <>
        <LinkDescriptionStyle>{t('linkInfo1')}</LinkDescriptionStyle>
        <SwiperSlideContainerStyle>
          <OnEditingStyle
            show={typeof selectedLink !== 'undefined'}
            onClick={() => {
              setSelectedLink(undefined);
            }}
          >
            {t('endEditMode')}
          </OnEditingStyle>
          {typeof linkType !== 'undefined' && (
            <SwiperStyle
              show={typeof selectedLink === 'undefined'}
              pagination
              modules={[Pagination]}
              spaceBetween={15}
              slidesPerView={3}
              style={{ height: 60, display: 'flex', flexWrap: 'wrap' }}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              {linkTypeList.map((itemLinkType) => (
                <SwiperSlide key={itemLinkType.id}>
                  <div
                    key={itemLinkType.id}
                    onClick={() => {
                      handleClickType(itemLinkType.id);
                      setSelectedLink(undefined);
                    }}
                  >
                    {itemLinkType.exclusive === true ? (
                      <BadgeStyle color="secondary" badgeContent={'Pro'} />
                    ) : (
                      <BadgeStyle style={{ opacity: 0 }} />
                    )}
                    <ToggleButton
                      item={itemLinkType}
                      defaultChecked={linkType === itemLinkType.id}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </SwiperStyle>
          )}
        </SwiperSlideContainerStyle>
        <div>{getLinkContainer()}</div>
        {stores.linkStore.links.length > 0 && (
          <div
            style={{
              background: '#eee',
              padding: 10,
              borderRadius: 15,
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            {/* <SeperatorStyle /> */}
            <div style={{ fontSize: 20, fontWeight: 500 }}>{t('linkInfo2')}</div>
            <div style={{ margin: '10px 0' }}>
              {t('linkInfo3')}
              <br /> {t('linkInfo4')}
            </div>
          </div>
        )}
        <ListOfLinksContainer
          draggable={true}
          onLinkDelete={(linkId: number) => {
            handleOnItemDelete(linkId);
          }}
          onLinkSelect={(link: LinkModel) => {
            setSelectedLink(link);
            setLinkType(link.linkType);
          }}
        />
      </>
    );
  },
);

const BadgeStyle = styled(Badge)`
  position: absolute;
  top: 10px;
  right: -60px;
`;

const LinkDescriptionStyle = styled.div`
  font-size: 14px;
  color: #111111;
  margin: 10px 0 30px 0;
  font-weight: 400;
`;

const SwiperSlideContainerStyle = styled.div`
  flex-wrap: wrap;
  background-color: #fff;
  align-items: center;
  border-radius: 10px;
`;

const SeperatorStyle = styled.div`
  height: 20px;
  border-bottom: 1px solid #666;
`;

const SwiperStyle = styled(Swiper)<{ show: boolean }>`
  transition: all 0.3s ease-in-out;
  height: ${(props) => (props.show ? 'auto' : '0px !important')};
  opacity: ${(props) => (props.show ? 1 : 0)};
  padding-bottom: ${(props) => (props.show ? '40px' : '0px !important')};
`;

const OnEditingStyle = styled.div<{ show: boolean }>`
  position: absoulte;
  background: #670056;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 15px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
  transition-delay: ${(props) => (props.show ? 0 : 4)};
  height: ${(props) => (props.show ? '40px' : '0px !important')};
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

export default LinkCreateContainer;
