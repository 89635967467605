import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { CommonBetweenRowStyle } from 'resources/styles/common/styles';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { SocialLinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { Input } from 'components/commons';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import SocialServiceList from 'features/adminFeatures/link/components/SocialServiceList';
import socialServiceList from 'types/socialServiceList';
import useScreenType from 'libs/hooks/useScreenType';
import Theme from 'resources/styles/common/theme';
import 'react-spring-bottom-sheet/dist/style.css';
import Confirm from 'components/commons/Confirm';
import useForm from 'libs/hooks/useForm';
import styled from 'styled-components';
import Modal from 'react-modal';
import _ from 'lodash';
import { CustomUserInfoDto } from 'types/CommonTypes';

const SocialLinksContainer = observer(() => {
  const { linkStore, meStore, formValidationStore, templateStore } = useStore();
  const { t } = useTranslation();
  const isValid = useForm(formValidationStore);
  /** @description 모델 */
  const [model, setModel] = useState<SocialLinkDto>();
  const formRef = useRef<HTMLFormElement>(null);
  const [showSocialsMobile, setShowSocialsMobile] = useState<boolean>(false);
  const [showSocialsDesktop, setShowSocialsDesktop] = useState<boolean>(false);
  const [isOpenedEditModal, setIsOpenedEditModal] = useState<boolean>(false);
  const [selectedSocial, setSelectedSocial] = useState<any>();
  const [editingLink, setEditingLink] = useState<SocialLinkDto>();
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState<boolean>(false);
  const [deletingLinkId, setDeletingLinkId] = useState<number>();
  const screenType = useScreenType();
  const urlInputRef = useRef<HTMLInputElement>(null);
  const editInputRef = useRef<HTMLInputElement>(null);

  const handleConfirmRegister = async (
    e: SyntheticEvent<HTMLFormElement> | SyntheticEvent<HTMLDivElement>,
  ) => {
    e.preventDefault();
    if (typeof selectedSocial === 'undefined') {
      toast(t('pleaseSnsSelect'), { type: 'warning', position: 'top-center' });
      return;
    }
    if (typeof model?.linkUrl === 'undefined' || model?.linkUrl === '') {
      toast(t('pleaseEnterYourAddress'), { type: 'warning', position: 'top-center' });
      return;
    }

    if (!isValid.result) {
      toast(t('enterCorrectAddress'), { type: 'warning', position: 'top-center' });
      return;
    }

    const response: any = await linkStore.createSocialLink(model);
    if (typeof response.data?.errorCode !== 'undefined') return;
    toast.success(t('youCreatedLink'), { position: 'top-center' });
    setSelectedSocial(undefined);
    setModel(undefined);
    formRef.current?.reset();
    formValidationStore.destroy();
    meStore.getMySNS();
  };

  useEffect(() => {
    const _profile: any = { ...templateStore.userInfo, socialLinks: meStore.socials };
    templateStore.setUserInfo(_profile!);
  }, [meStore.socials]);

  const handleSelectService = (social: { key: string; value: string; type?: string }) => {
    if (!social.type) {
      toast(t('snsNotYetSupport'), { type: 'warning' });
    } else {
      setModel((prev) => {
        return {
          ...prev,
          socialType: social.type as any,
        };
      });
      setSelectedSocial(social);
      setShowSocialsMobile(false);
      setShowSocialsDesktop(false);
    }
  };

  const handleAskDeleteLink = (linkId: number) => {
    setDeletingLinkId(linkId);
    setDeleteConfirmVisible(true);
  };

  const handleUnregisterLink = async () => {
    if (typeof deletingLinkId !== 'undefined') {
      const response: any = await linkStore.deleteSocialLink(deletingLinkId);
      if (typeof response?.data?.errorCode !== 'undefined') return;
      toast(t('linkRemove'), { type: 'success' });
      setTimeout(() => {
        meStore.getMySNS();
      }, 300);
    }
  };

  const handleEditLink = (link: SocialLinkDto) => {
    setEditingLink(link);
    setIsOpenedEditModal(true);
  };

  const handleConfirmEdit = async () => {
    if (editingLink?.linkUrl === '') {
      toast(t('pleaseEnterYourAddress'), { type: 'warning' });
      editInputRef.current?.focus();
      return;
    }
    if (!isValid.result) {
      toast(t('enterCorrectAddress'), { type: 'warning', position: 'top-center' });
      return;
    }
    if (typeof editingLink?.id !== 'undefined') {
      const response: any = await linkStore.updateSocialLink(editingLink?.id, editingLink);
      if (typeof response?.data?.errorCode !== 'undefined') return;

      toast(t('linkFixed'), { type: 'success' });
      setIsOpenedEditModal(false);
      formValidationStore.destroy();
      meStore.getMySNS();
    }
  };

  const handleOpenLink = (link: SocialLinkDto) => {
    if (typeof link?.linkUrl !== 'undefined') {
      window.open(link.linkUrl, '_blank');
    }
  };

  useEffect(() => {
    meStore.getMySNS();
  }, []);
  return (
    <>
      <Container ref={formRef} onSubmit={handleConfirmRegister}>
        <Title>{t('snsSlect')}</Title>
        <SocialServiceSelector
          onClick={() => {
            if (screenType === 'window') {
              setShowSocialsDesktop(true);
            } else {
              setShowSocialsMobile(true);
            }
          }}
        >
          <CommonBetweenRowStyle>
            {typeof selectedSocial === 'undefined' ? (
              <Placeholder>{t('pleaseSelect')}</Placeholder>
            ) : (
              <CommonBetweenRowStyle>
                <ServiceIcon src={`/image/sns/${selectedSocial.value}`} alt="" />
                <p style={{ marginLeft: 10 }}>
                  {t(
                    _.find(socialServiceList, { key: selectedSocial.key })?.translation ||
                      'youtube',
                  )}
                </p>
              </CommonBetweenRowStyle>
            )}
            <ArrowIconButton>
              <ArrowIcon src={'/image/arrow_down.png'} alt={''} />
            </ArrowIconButton>
          </CommonBetweenRowStyle>
        </SocialServiceSelector>
        <Title>URL</Title>
        <InputContainer
          id={'validUrl'}
          ref={urlInputRef}
          required
          placeholder={'https://'}
          onChangeValue={(e) => {
            setModel((prevState: SocialLinkDto) => {
              return {
                ...prevState,
                linkUrl: e,
              };
            });
          }}
          type={'text'}
          format={'url'}
        />
        <ConfirmButton onClick={handleConfirmRegister}>{t('snsRegistration')}</ConfirmButton>
        {meStore.socials?.map((link: SocialLinkDto) => (
          <RegisteredServiceRow key={link?.id}>
            <RegisteredServiceInfoContainer onClick={() => handleOpenLink(link)}>
              <RegisteredSocialName>
                {t(_.find(socialServiceList, { type: link.socialType })?.translation || '')}
              </RegisteredSocialName>
              <ServiceIconLarger src={`/image/sns/${link.socialType}.png`} alt="" />
            </RegisteredServiceInfoContainer>
            <IconButton
              onClick={() => {
                handleEditLink(link);
              }}
            >
              <img src={'/image/pencil.png'} alt={''} style={{ width: 25 }} />
            </IconButton>
            <IconButton
              onClick={() => {
                handleAskDeleteLink(link.id!);
              }}
            >
              <img src={'/image/trash.png'} alt={''} />
            </IconButton>
          </RegisteredServiceRow>
        ))}
        {meStore.socials?.length === 0 && <EmptyContainer>{t('notSocialLink')}</EmptyContainer>}
      </Container>
      <BottomSheet
        open={showSocialsMobile}
        style={{
          zIndex: 2,
          maxWidth: 300,
        }}
        maxHeight={window.innerHeight * 0.7}
        onDismiss={() => {
          setShowSocialsMobile(false);
        }}
      >
        <ServiceListContainer>
          <SocialServiceList
            registeredSocials={meStore.socials}
            onPressService={handleSelectService}
            onRequestClose={() => setShowSocialsMobile(false)}
          />
        </ServiceListContainer>
      </BottomSheet>
      <Modal
        ariaHideApp={false}
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)', zIndex: 100 },
          content: {
            margin: 'auto',
            height: 'fit-content',
            border: 0,
            maxWidth: 500,
          },
        }}
        isOpen={showSocialsDesktop}
      >
        <SocialServiceList
          registeredSocials={meStore.socials}
          onPressService={handleSelectService}
          onRequestClose={() => setShowSocialsDesktop(false)}
        />
      </Modal>
      <Modal
        ariaHideApp={false}
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)', zIndex: 100 },
          content: {
            margin: 'auto',
            height: 'fit-content',
            border: 0,
            maxWidth: 500,
          },
        }}
        isOpen={isOpenedEditModal}
      >
        <EditLinkContainer>
          <EditContentContainer>
            <EditingLinkInfoContainer>
              <ServiceIconLarger
                src={`/image/sns/${editingLink?.socialType?.toLowerCase()}.png`}
                alt=""
              />
              <span style={{ marginLeft: 10 }}>
                {t(
                  _.find(socialServiceList, { type: editingLink?.socialType })?.translation ||
                    'youtube',
                )}
              </span>
            </EditingLinkInfoContainer>
            <InputContainer
              required
              ref={editInputRef}
              id={'editUrlValid'}
              placeholder={'https://'}
              value={editingLink?.linkUrl}
              onChangeValue={(e) => {
                setEditingLink({ ...editingLink, linkUrl: e });
              }}
              format={'url'}
              type={'text'}
            />
          </EditContentContainer>
          <ButtonArea>
            <Button
              width={'40%'}
              backgroundColor={Theme.colors.pointColor}
              fontColor={'white'}
              onClick={handleConfirmEdit}
            >
              {t('save')}
            </Button>
            <Button
              width={'40%'}
              backgroundColor={Theme.colors.borderColor}
              onClick={() => setIsOpenedEditModal(false)}
            >
              {t('close')}
            </Button>
          </ButtonArea>
        </EditLinkContainer>
      </Modal>
      <Confirm
        title={t('linkDelete')}
        onConfirm={handleUnregisterLink}
        visible={deleteConfirmVisible}
        setVisible={setDeleteConfirmVisible}
      />
    </>
  );
});

const Container = styled.form`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 20px 0;
`;

const SocialServiceSelector = styled.div`
  cursor: pointer;
  background-color: white;
  flex-grow: 1;
  height: 50px;
  border: 0;
  border-radius: 8px;
  padding: 0 16px 0 16px;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 20px 0;
`;

const InputContainer = styled(Input)`
  height: 50px;
  flex-grow: 1;
  border: 0;
  border-radius: 8px;
  padding: 0 16px 0 16px;
  font-size: 16px;
  font-weight: 400;
  ::placeholder {
    color: #ccc;
  }
`;

const ConfirmButton = styled.div`
  cursor: pointer;
  flex-grow: 1;
  height: 50px;
  border-radius: 8px;
  color: ${(props) => props.theme.colors.pointColor};
  font-weight: 500;
  font-size: 16px;
  background-color: #d0dbf1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 20px 0;
`;

const ServiceIcon = styled.img`
  height: 20px;
`;
const ServiceIconLarger = styled.img`
  height: 30px;
`;

const Placeholder = styled.p`
  font-size: 16px;
  color: #ccc;
`;

const ArrowIconButton = styled.div`
  background-color: ${(props) => props.theme.colors.borderColor};
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ArrowIcon = styled.img`
  width: 15px;
  height: 10px;
  margin: 0 1px 0 0;
`;

const ServiceListContainer = styled.div`
  padding: 20px 30px 20px 30px;
`;

const CloseButton = styled.div`
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  cursor: pointer;
  display: flex;
  background-color: #eee;
  border-radius: 100px;
  align-self: flex-end;
  margin: 10px 10px 20px 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
`;

const CloseIcon = styled.img`
  width: 15px;
`;

const ServiceRow = styled(CommonBetweenRowStyle)<{ isRegistered?: boolean }>`
  cursor: ${(props) => (props.isRegistered ? 'auto' : 'pointer')};
  padding: 16px 16px 16px 16px;
  border: 1px solid #eee;
  border-radius: 8px;
  margin: 16px 0 16px 0;
  background-color: ${(props) => (props.isRegistered ? '#eee' : 'white')};
  opacity: ${(props) => (props.isRegistered ? 0.5 : 1)};
`;

const RegisteredServiceRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 10px 0;
`;

const RegisteredServiceInfoContainer = styled.div`
  cursor: pointer;
  height: 50px;
  display: flex;
  flex: 1;
  flex-direction: row;
  border-radius: 8px;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 16px;
`;

const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  border-radius: 8px;
  margin: 0 0 0 10px;
`;

const RegisteredSocialName = styled.span`
  font-size: 16px;
`;

const EditLinkContainer = styled.div`
  padding: 16px;
  background-color: white;
  border-radius: 8px;
`;

const EditContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0 0 0;
`;

const Button = styled.div<{ backgroundColor?: string; fontColor?: string; width: string }>`
  display: flex;
  cursor: pointer;
  width: ${(props) => props.width};
  padding: 10px 15px 10px 15px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor || 'white'};
  color: ${(props) => props.fontColor || 'black'};
`;

const EditingLinkInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 20px 0;
`;

const EmptyContainer = styled.div`
  text-align: center;
`;

export default SocialLinksContainer;
