import { makeAutoObservable } from 'mobx';
import { UserProfileDto } from 'services/data-contracts';
import { Me as MeService } from 'services/Me';
import { User as UserService } from 'services/User';
import { REST_API_URL } from 'stores/AppStore';

class FollowStore {
  constructor() {
    makeAutoObservable(this);
  }

  meApi: MeService<unknown> = new MeService({
    baseURL: REST_API_URL,
  });

  userApi: UserService<unknown> = new UserService({ baseURL: REST_API_URL });

  userFollowers: Array<UserProfileDto> | undefined;
  userFollowing: Array<UserProfileDto> | undefined;
  userSummary: Array<UserProfileDto> | undefined;

  *getFollowers(userName: string) {
    const { data } = yield this.userApi.getFollowers(userName);
    this.userFollowers = data;
    this.userSummary = data.slice(0, 2);
  }

  *getFollowings(userName: string) {
    const { data } = yield this.userApi.getFollowings(userName);
    this.userFollowing = data;
  }

  *follow(userName: string) {
    const { data } = yield this.userApi.follow(userName);
    return data;
  }

  *unFollow(userName: string) {
    const { data } = yield this.userApi.unfollow(userName);
    return data;
  }

  unmount() {
    this.userFollowers = undefined;
    this.userFollowing = undefined;
  }
}

export default FollowStore;
