import React, {
  CSSProperties,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  useState,
  HTMLInputTypeAttribute,
} from 'react';
import { Row } from 'components/commons/layouts';
import { InputFormat } from 'types/CommonTypes';
import { Input } from 'components/commons';
import styled from 'styled-components';

type DefaultProps = {
  defaultValue?: string;
  inputType?: HTMLInputTypeAttribute;
  required?: boolean;
  id?: string;
  placeholder?: string;
  type: 'Link Title' | 'Title' | 'Url' | 'Number';
  pattern?: string;
  divStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  format?: InputFormat;
  maxLength?: number;
  onChangeValue: (value: string) => void;
};

const InputComponent = forwardRef(
  (
    {
      defaultValue,
      inputType = 'text',
      id,
      required,
      placeholder,
      type,
      pattern,
      divStyle,
      inputStyle,
      format,
      maxLength,
      onChangeValue,
    }: DefaultProps,
    ref: any,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isViewMode, setIsViewMode] = useState<boolean>(true);
    const [defaultTitle, setDefaultTitle] = useState<string>('');
    const [title, setTitle] = useState<string>(type);

    useImperativeHandle(ref, () => ({
      reset,
      focus,
    }));

    const reset = () => {
      setTitle(type);
      setDefaultTitle('');
      if (inputRef.current !== null) {
        inputRef.current.value = '';
      }
    };

    const focus = () => {
      inputRef.current?.focus();
    };

    const handleChangeTitle = (value: string) => {
      if (value === '') {
        setTitle(type);
      } else {
        setTitle(value);
      }
    };

    useEffect(() => {
      if (isViewMode === false) {
        inputRef.current?.focus();
        if (typeof defaultValue !== 'undefined') {
          setDefaultTitle(defaultValue);
        }
      }
    }, [isViewMode]);

    useEffect(() => {
      if (typeof defaultValue !== 'undefined' && defaultValue.length > 0) {
        setTitle(defaultValue);
      } else {
        setTitle(placeholder || '');
      }
    }, [defaultValue]);

    useEffect(() => {
      return () => {
        setDefaultTitle('');
      };
    }, []);
    return (
      <InputComponentStyle>
        {isViewMode === true ? (
          <Row
            style={{ justifyContent: 'flex-start', height: 50, paddingTop: 5, cursor: 'pointer' }}
          >
            <div
              style={divStyle}
              onClick={() => {
                setIsViewMode((prev: boolean) => !prev);
              }}
            >
              {title}
            </div>
            <ImageSizeContainerStyle
              onClick={() => {
                setIsViewMode((prev: boolean) => !prev);
              }}
            >
              <ImageSizeStyle type={type} />
            </ImageSizeContainerStyle>
          </Row>
        ) : (
          <Input
            ref={inputRef}
            required
            placeholder={placeholder}
            style={inputStyle}
            containerStyle={{ borderRadius: 10 }}
            id={id}
            format={format}
            defaultValue={defaultTitle}
            pattern={pattern}
            maxLength={maxLength}
            onChangeValue={(value: string) => {
              /** 해당 maxLength 로직은 input 특성상 type="number"일 때, maxLength가 동작하지 않는 이슈로 작성 */
              if (typeof maxLength !== 'undefined') {
                if (value.length <= maxLength) {
                  onChangeValue(value);
                  handleChangeTitle(value);
                  setDefaultTitle(value);
                }
                return;
              }
              onChangeValue(value);
              handleChangeTitle(value);
              setDefaultTitle(value);
            }}
            onBlur={() => {
              setIsViewMode((prev: boolean) => !prev);
            }}
            value={defaultTitle}
            /** type이 number라면 maxlength가 동작하지 않습니다 */
            type={inputType}
          />
        )}
      </InputComponentStyle>
    );
  },
);

const InputComponentStyle = styled.div`
  width: 100%;
  height: 70px;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
`;

const ImageSizeContainerStyle = styled.div`
  cursor: pointer;
`;

const ImageSizeStyle = styled.img.attrs({
  alt: '',
  src: '/images/edit.svg',
})<{ type: string }>`
  width: ${(props) => (props.type !== 'Link Title' ? '10px' : '18px')};
`;

export default InputComponent;
