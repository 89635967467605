import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { REST_API_URL } from 'stores/AppStore';

interface TokenDto {
  email: string;
  exp: number;
  iat: number;
  isActive: number;
  isAdmin: number;
  isVerify: number;
  userId: number;
  userName: number;
}

const RefreshToken = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  // const { authStore } = useStore();
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  const d = new Date();

  const expireAt = jwtDecode(String(accessToken)) as TokenDto;

  /** 남은 엑세스 토큰 만료 시간 */
  const remainTime = !_.isEmpty(expireAt) && Number(expireAt.exp) - Math.floor(d.getTime() / 1000);

  // 토큰이 만료되었고, refreshToken 이 저장되어 있을 때
  if (remainTime < 600) {
    // 토큰 갱신 서버통신
    const result = await axios({
      method: 'post',
      url: `${REST_API_URL}v1/auth/refresh-token`,
      data: {
        accessToken: String(accessToken),
        refreshToken: String(refreshToken),
      },
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        // alert('refresh');
        // window.location.href = '/admin';
      })
      .catch((response) => {
        toast(response, { type: 'error', position: 'top-center' });
      });
  }

  return config;
};

const refreshErrorHandle = (err: any) => {
  localStorage.removeItem('refreshToken');
};

export { RefreshToken, refreshErrorHandle };
