import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

interface DefaultProps {
  style?: CSSProperties;
  image?: string;
  onImageDeleted?: () => void;
}

const BackgroundButton = ({ style, image, onImageDeleted }: DefaultProps) => {
  const handleClicked = (e: any) => {
    // 페이지 유형
  };
  return (
    <ContainerStyle style={{ ...style }}>
      <div style={{ textAlign: 'center' }}>
        {typeof image !== 'undefined' ? (
          <>
            <img src={image} style={{ width: '100%', marginTop: 25 }} />
            <div
              style={{ position: 'absolute', right: 0, top: 0, zIndex: 1000 }}
              onClick={(e: any) => {
                if (typeof onImageDeleted === 'function') {
                  e.stopPropagation();
                  onImageDeleted();
                }
              }}
            >
              <img style={{ width: 30 }} src="/image/adminProfile/close-round.png" />
            </div>
          </>
        ) : (
          <img src="/image/adminProfile/background-image-icon.png" style={{ width: '100%' }} />
        )}

        <div>Footer</div>
      </div>
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  aspect-ratio: 1/1;
  width: 100%;
  background: #efefef;
  justify-content: center;
  display: flex;
  align-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: auto;
`;

export default BackgroundButton;
