import React, { useRef, useState } from 'react';
import { ImageUploader, Input, TextArea } from 'components/commons';
import { updateModel } from 'components/items/commonLogic';
import { PrimaryButtonStyle } from 'resources/controls';
import { TemplateDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'libs/helper/utils';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import _ from 'lodash';

type TemplateProps = {
  type: 'create' | 'edit';
  onClose: () => void;
};

const TemplateFormContainer = observer(({ type, onClose }: TemplateProps) => {
  const { templateStore, fileStore } = useStore();
  const [modelCurrent, setModelCurrent] = useState<TemplateDto>(templateStore.currentTemplate);
  const [model, setModel] = useState<TemplateDto>({
    templateType: 'Layout',
  });
  const [isCheck, setISCheck] = useState<boolean>(false);
  const { t } = useTranslation();
  const backgroundImage = useRef<HTMLDivElement | null>(null);
  const thumbnailImage = useRef<HTMLDivElement | null>(null);
  const shapeImage = useRef<HTMLDivElement | null>(null);

  const checkHandler = () => {
    setISCheck(!isCheck);
  };

  // 템플릿 추가하기
  const handleSaveTemplate = async () => {
    const tempModel = cloneDeep(model);
    if (typeof model.style === 'undefined') {
      return;
    }
    const temp = JSON.parse(model.style!);
    if (typeof model.style !== 'undefined') {
      if (typeof backgroundImage.current !== 'undefined' && backgroundImage.current !== null) {
        const image: any = await fileStore?.uploadImage(backgroundImage.current);
        temp.container.backgroundImage = `url(${image?.publicUrl})`;
      }
      if (typeof shapeImage.current !== 'undefined' && shapeImage.current !== null) {
        const image: any = await fileStore?.uploadImage(shapeImage.current);
        temp.shapeContainer.backgroundImage = `url(${image?.publicUrl})`;
      }
    }
    if (typeof thumbnailImage.current !== 'undefined' && thumbnailImage.current != null) {
      const image: any = await fileStore?.uploadImage(thumbnailImage.current);
      if (typeof image?.data?.errorCode !== 'undefined') {
        return toast(t('fail'), { type: 'error' });
      } else {
        tempModel.thumbnail = { id: image.id };
      }
    }
    tempModel.style = JSON.stringify(temp);
    if (isCheck === true) {
      tempModel.useBackgroundImage = true;
    } else {
      tempModel.useBackgroundImage = false;
    }
    const response: any = await templateStore.uploadTemplate('Layout', tempModel);
    if (typeof response?.data?.errorCode !== 'undefined') {
      toast(t('fail'));
    } else {
      templateStore.getTemplates();
      toast(t('templateAddText'));
      onClose();
    }
  };

  // 저장하기 함수
  const handleClickSaveTemplate = async () => {
    if (
      typeof modelCurrent?.templateType !== 'undefined' &&
      typeof modelCurrent?.id !== 'undefined'
    ) {
      if (typeof thumbnailImage.current !== 'undefined' && thumbnailImage.current !== null) {
        const image: any = await fileStore?.uploadImage(thumbnailImage.current);
        modelCurrent.thumbnail = { id: image.id };
      }
      const response: any = await templateStore.updateTemplate(
        modelCurrent?.templateType,
        modelCurrent?.id,
        modelCurrent,
      );
      if (typeof response?.data?.errorCode !== 'undefined') {
        toast(t('fail'));
      } else {
        /** 전체 리스트에서 model에 대한 index를 loadsh를 이용해서 찾는다 */
        const modelIndex = _.findIndex(
          templateStore.templates,
          (item) => item.id === modelCurrent.id,
        );
        /** 찾은 index를 활용해서 전체 데이터(templateStore.templates)에서 model에 해당하는 객체를 update 시켜준다 (loadsh를 이용해서) */
        _.update(templateStore.templates, modelIndex, () => modelCurrent);
        toast(t('templateSave'));
        onClose();
      }
    }
  };

  return (
    <>
      {/* 템플릿 작성 */}
      {type === 'create' && (
        <>
          <TemplateAddContainerStyle>
            <Input
              label="Template Title"
              onChangeValue={(value: string) => {
                updateModel(setModel, 'name', value);
              }}
            />
            <Input
              label="Template Display name"
              onChangeValue={(value: string) => {
                updateModel(setModel, 'displayName', value);
              }}
            />
            <TextArea
              style={{ height: '500px' }}
              onChangeValue={(value: string) => {
                updateModel(setModel, 'style', value);
              }}
            />
          </TemplateAddContainerStyle>
          <TemplateInputContainerStyle>
            <CheckBoxInputStyle
              type="checkbox"
              onChange={() => {
                checkHandler();
              }}
            />
            {/* 테마 배경이미지 업로드 */}
            <ImageUploader
              attachType="image"
              cropType="horizontal"
              cropSize={{ width: 1600, height: 1600 }}
              style={uploaderStyle}
              onSelected={(thumbnail: any) => {
                backgroundImage.current = thumbnail[0].file;
              }}
            >
              <div style={uploaderDivStyle}>
                <img src="/image/background-icon.png" alt="" />
                <p style={uploaderPStyle}>Container 배경이미지</p>
              </div>
            </ImageUploader>
            <ImageUploader
              attachType="image"
              cropType="horizontal"
              cropSize={{ width: 1600, height: 1600 }}
              style={uploaderStyle}
              onSelected={(thumbnail: any) => {
                shapeImage.current = thumbnail[0].file;
              }}
            >
              <div style={uploaderDivStyle}>
                <img src="/image/background-icon.png" alt="" />
                <p style={uploaderPStyle}>Shape 이미지</p>
              </div>
            </ImageUploader>
            <ImageUploader
              attachType="image"
              cropType="vertical"
              cropSize={{ width: 1600, height: 1600 }}
              style={uploaderStyle}
              onSelected={(thumbnail: any) => {
                updateModel(setModel, 'thumbnail', thumbnail[0]);
                thumbnailImage.current = thumbnail[0].file;
              }}
            >
              <div style={uploaderDivStyle}>
                <img src="/image/background-icon.png" alt="" />
                <p style={uploaderPStyle}>썸네일 이미지</p>
              </div>
            </ImageUploader>
          </TemplateInputContainerStyle>
          <PrimaryButtonStyle
            onClick={handleSaveTemplate}
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 720,
              margin: '6px auto',
            }}
          >
            {t('templateAdd')}
          </PrimaryButtonStyle>
        </>
      )}

      {/* 템플릿 수정 */}
      {type === 'edit' && (
        <>
          <ContainerStyle>
            <Input
              defaultValue={templateStore.currentTemplate.name}
              label="Template Title"
              onChangeValue={(value: string) => {
                updateModel(setModelCurrent, 'name', value);
              }}
            />
            <Input
              defaultValue={templateStore.currentTemplate.displayName}
              label="Template Display name"
              onChangeValue={(value: string) => {
                updateModel(setModelCurrent, 'displayName', value);
              }}
            />
            <TextArea
              style={{ height: '500px' }}
              label="Template Style"
              defaultValue={templateStore.currentTemplate.style}
              onChangeValue={(value: string) => {
                updateModel(setModelCurrent, 'style', value);
              }}
            />
          </ContainerStyle>
          <TemplateInputContainerStyle>
            <CheckBoxInputStyle
              type="checkbox"
              onChange={() => {
                checkHandler();
              }}
            />
            <ImageUploader
              attachType="image"
              cropType="vertical"
              cropSize={{ width: 1600, height: 1600 }}
              style={uploaderStyle}
              onSelected={(thumbnail: any) => {
                updateModel(setModel, 'thumbnail', thumbnail[0]);
                thumbnailImage.current = thumbnail[0].file;
              }}
            >
              <div style={uploaderDivStyle}>
                <img src="/image/background-icon.png" alt="" />
                <p style={uploaderPStyle}>썸네일 이미지</p>
              </div>
            </ImageUploader>
          </TemplateInputContainerStyle>
          <PrimaryButtonStyle
            onClick={handleClickSaveTemplate}
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: 720,
              margin: '6px auto',
            }}
          >
            {t('saveTemplate')}
          </PrimaryButtonStyle>
        </>
      )}
    </>
  );
});

const TemplateAddContainerStyle = styled.div`
  width: 90%;
  margin: auto;
  padding-top: 10px;
`;

const TemplateInputContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 90%;
  align-items: center;
`;

const uploaderStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
const uploaderDivStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  margin: '10px 10px 10px 10px',
};

const uploaderPStyle: React.CSSProperties = {
  marginRight: '10px',
  marginLeft: '10px',
};

const ContainerStyle = styled.div`
  width: 90%;
  padding: 10px 0 0 0;
  margin: auto;
`;

const CheckBoxInputStyle = styled.input`
  width: 30px;
  height: 30px;
`;

export default TemplateFormContainer;
