import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { CustomProvider } from 'stores/StoreHelper';
import RootStore from 'stores';
import { QueryClientProvider, QueryClient } from 'react-query';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

const store = new RootStore();
const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <CustomProvider>
      {/* <Provider {...store}> */}
      <React.StrictMode>
        <App />
      </React.StrictMode>
      {/* </Provider> */}
    </CustomProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
