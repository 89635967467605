import React, { CSSProperties, ReactElement, useEffect } from 'react';
import styled from 'styled-components';

type Props = {
  type?: 'Preview' | 'SAVE' | 'Update' | 'Cancel';
  onClick?: () => void;
  disabled?: boolean;
  children?: any;
  style?: CSSProperties;
};

const WhiteButton = ({ type, onClick, disabled, style, children }: Props) => {
  useEffect(() => {
    // console.log('hji');
  }, [disabled]);

  return (
    <WhiteButtonStyle
      disabled={disabled}
      onClick={() => {
        if (typeof onClick !== 'undefined') {
          onClick();
        }
      }}
      style={style}
    >
      {typeof children !== 'undefined' ? <>{children}</> : <>{type}</>}
    </WhiteButtonStyle>
  );
};

const WhiteButtonStyle = styled.button<{ style?: CSSProperties }>`
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-size: 16px;
  border: 1px solid #eee;
  background: ${(props) => (!props.disabled ? '#185DB6' : '#fff')};
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (!props.disabled ? '#fff' : '#185DB6')};
  font-family: 'Raleway';
  font-weight: 600;

  &:hover {
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
`;

export default WhiteButton;
