import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { CommonBetweenRowStyle } from 'resources/styles/common/styles';
import { SocialLinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { Input } from 'components/commons';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { SwiperSlide } from 'swiper/react';
import { SectionBox } from 'resources/controls';
import { ColorPicker } from 'components/items';
import socialServiceList from 'types/socialServiceList';
import Confirm from 'components/commons/Confirm';
import useForm from 'libs/hooks/useForm';
import styled from 'styled-components';
import _ from 'lodash';

const ProfileSocialComponent = observer(() => {
  const { linkStore, meStore, formValidationStore, simulatorStore, uiStore } = useStore();
  const { t } = useTranslation();
  const isValid = useForm(formValidationStore);
  /** @description 모델 */
  const [model, setModel] = useState<SocialLinkDto>();
  const formRef = useRef<HTMLFormElement>(null);
  const [editingLink, setEditingLink] = useState<SocialLinkDto>();
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState<boolean>(false);

  /** @description 선택된 기존 SNS */
  const [selectedSns, setSelectedSns] = useState<any>();

  const editInputRef = useRef<HTMLInputElement>(null);

  const handleConfirmRegister = async () => {
    if (typeof model?.socialType === 'undefined') {
      toast(t('pleaseSnsSelect'), { type: 'warning', position: 'top-center' });
      return;
    }
    if (typeof model?.linkUrl === 'undefined' || model?.linkUrl === '') {
      toast(t('pleaseEnterYourAddress'), { type: 'warning', position: 'top-center' });
      return;
    }

    const response: any = await linkStore.createSocialLink(model);
    if (typeof response.data?.errorCode !== 'undefined') return;
    toast.success(t('youCreatedLink'), { position: 'top-center' });
    setModel(undefined);
    formRef.current?.reset();
    meStore.getMySNS();
  };

  const handleUnregisterLink = async (id: number) => {
    if (typeof id !== 'undefined') {
      const response: any = await linkStore.deleteSocialLink(id);
      if (typeof response?.data?.errorCode !== 'undefined') return;
      toast(t('linkRemove'), { type: 'success' });
      setSelectedSns('');

      setTimeout(() => {
        meStore.getMySNS();
      }, 300);
    }
  };

  const handleConfirmEdit = async () => {
    if (editingLink?.linkUrl === '') {
      toast(t('pleaseEnterYourAddress'), { type: 'warning' });
      editInputRef.current?.focus();
      return;
    }

    if (typeof editingLink !== 'undefined') {
      const response: any = await linkStore.updateSocialLink(selectedSns?.id, editingLink);
      if (typeof response?.data?.errorCode !== 'undefined') return;
      toast(t('linkFixed'), { type: 'success' });
      setModel(undefined);
      setSelectedSns('');
      formRef.current?.reset();
      meStore.getMySNS();
    }
  };

  useEffect(() => {
    simulatorStore.updateSocialLinks(meStore.socials);
  }, [meStore.socials]);

  const handleSelectService = (social: { key: string; value: string; type?: string }) => {
    if (!social.type) {
      toast(t('snsNotYetSupport'), { type: 'warning' });
    } else {
      setModel((prev) => {
        return {
          ...prev,
          socialType: social.type as any,
        };
      });
    }
  };

  const handleAskDeleteLink = (linkId?: number) => {
    if (typeof linkId === 'undefined') {
      return;
    }
    uiStore.confirm.show({
      message: '연결된 SNS를 삭제하시겠습니까?',
      onConfirmed: () => {
        handleUnregisterLink(linkId);
      },
    });
    // setDeletingLinkId(linkId);
    // setDeleteConfirmVisible(true);
  };

  useEffect(() => {
    meStore.getMySNS();
  }, []);
  return (
    <>
      <SectionBox>
        <Container ref={formRef}>
          <SwiperSlideStyle>
            <SwiperSlide>
              {socialServiceList.map((social) => {
                const registeredSocial = _.find(meStore.socials, { socialType: social.type });
                return (
                  <>
                    <ServiceBtnStyle name="sns" id={social.type} />
                    <ServiceBtnLabelStyle
                      htmlFor={social.type}
                      onClick={() => {
                        if (typeof registeredSocial !== 'undefined') {
                          setSelectedSns(registeredSocial);
                        } else {
                          handleSelectService(social);
                        }
                      }}
                    >
                      <ServiceRow
                        isRegistered={typeof registeredSocial !== 'undefined'}
                        key={`list_${social.key}`}
                      >
                        <div>
                          <ServiceIconStyle>
                            <ServiceIconLarger src={`/image/sns/${social.value}`} alt="" />
                          </ServiceIconStyle>
                          <DeleteButtonStyle
                            onClick={() => {
                              handleAskDeleteLink(registeredSocial?.id);
                            }}
                            isRegistered={typeof registeredSocial !== 'undefined'}
                          >
                            <img src="/image/close.svg" alt="" width={10} height={10} />
                          </DeleteButtonStyle>
                        </div>
                      </ServiceRow>
                    </ServiceBtnLabelStyle>
                  </>
                );
              })}
            </SwiperSlide>
          </SwiperSlideStyle>
          <InputContainerStyle>
            <InputContentStyle
              required
              ref={editInputRef}
              id="editUrlValid"
              placeholder={'https://'}
              defaultValue={selectedSns?.linkUrl}
              onChangeValue={(e) => {
                if (typeof selectedSns !== 'undefined') {
                  setEditingLink({ ...editingLink, linkUrl: e });
                } else {
                  setModel((prevState: SocialLinkDto) => {
                    return {
                      ...prevState,
                      linkUrl: e,
                    };
                  });
                }
              }}
              format={'url'}
              type={'text'}
            />
            <ColorPicker
              pickerStyle={{
                position: 'absolute',
                top: 570,
                left: -10,
              }}
              onColorChanged={(color: string) => {
                console.log(color);
              }}
              // defaultValue={templateStore.userInfo?.profile?.backgroundColor}
            >
              <ColorPickerImgStyle>
                <img src="/image/palate_inactive.png" alt="" width={25} height={25} />
              </ColorPickerImgStyle>
            </ColorPicker>
            <RegisterButtonStyle
              disabled={!isValid.result}
              onClick={(e) => {
                if (typeof selectedSns !== 'undefined') {
                  handleConfirmEdit();
                } else {
                  handleConfirmRegister();
                }
              }}
            >
              {t(typeof selectedSns !== 'undefined' ? 'edit' : 'save')}
            </RegisterButtonStyle>
          </InputContainerStyle>
          {meStore.socials?.length === 0 && <EmptyContainer>{t('notSocialLink')}</EmptyContainer>}
        </Container>
        <Confirm
          title={t('linkDelete1111')}
          // onConfirm={handleUnregisterLink}
          visible={deleteConfirmVisible}
          setVisible={setDeleteConfirmVisible}
        />
      </SectionBox>
    </>
  );
});

const ColorPickerImgStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
`;

const InputContainerStyle = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #eeeeee;
  > div {
    margin-top: 5px;
  }
  > div > div > input {
    background: white;
  }
`;
const ServiceIconStyle = styled.div`
  > img {
    margin-top: 10px;
  }
`;

const SwiperSlideStyle = styled.div`
  /* margin: 5px auto 0; */
  > div {
    width: 100%;
  }
  > div > label {
    height: 50px;
    width: 50px;
    margin: 0 10px;
  }
`;

const DeleteButtonStyle = styled.div<{ isRegistered?: boolean }>`
  visibility: ${({ isRegistered }) => (isRegistered ? 'visible' : 'hidden')};
  cursor: ${({ isRegistered }) => (isRegistered ? 'pointer' : 'none')};
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 10px;
  position: relative;
  top: -35px;
  right: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RegisterButtonStyle = styled.div<{ disabled: boolean }>`
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  color: ${(props) => (props.disabled ? '#aaaaaa' : '#fff')};
  background: ${(props) => (props.disabled ? '#f7f7f7' : '#f06800')};
  border-radius: 20px;
`;

const Container = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 20px 10px;
`;

const InputContentStyle = styled(Input)`
  height: 50px;
  flex-grow: 1;
  border: 0;
  border-radius: 8px;
  padding: 0 16px 0 16px;
  font-size: 16px;
  font-weight: 400;
  ::placeholder {
    color: #ccc;
  }
`;

const ServiceIconLarger = styled.img`
  height: 30px;
`;

const ServiceRow = styled(CommonBetweenRowStyle)<{ isRegistered?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  opacity: ${(props) => (props.isRegistered ? 1 : 0.5)};
`;

const ServiceBtnStyle = styled.input.attrs({ type: 'radio' })`
  display: none;
  &:checked + label::after {
    display: block;
    content: '';
    width: 50px;
    height: 2px;
    background: #adadad;
    position: relative;
    bottom: 12px;
    > div {
      margin-bottom: 2px;
    }
  }
`;

const ServiceBtnLabelStyle = styled.label``;

const EmptyContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export default ProfileSocialComponent;
