import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const UserNotFound = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoSignUp = () => {
    navigate('/sign-up');
  };

  const handleGoFindAccount = () => {
    navigate('/');
  };

  return (
    <Container>
      <Content>
        <MossTitle>{process.env.REACT_APP_SERVEICE_NAME}</MossTitle>
        <Magnifier src={'image/magnifier.png'} />
        <Title>{t('notPage')}</Title>
        {/* <OptionContainer>
          <Option onClick={handleGoSignUp}>{t('signUp')}</Option>, &nbsp;
          <Option onClick={handleGoFindAccount}>{t('findSubscriberInformation')}</Option>
        </OptionContainer> */}
      </Content>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 50px 30px 50px 30px;
  background-color: #f8f8f8;
  border-radius: 50px;
  text-align: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 50%;
  margin: 0 0 50px 0;
`;

const Magnifier = styled.img`
  width: 30%;
  margin: 0 0 30px 0;
`;

const Title = styled.p`
  color: #222;
  font-weight: 500;
  font-size: 20px;
`;

const OptionContainer = styled.div``;

const Option = styled.a`
  color: #3e64ea;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

const MossTitle = styled.div`
  text-align: center;
  font-family: 'sacramento';
  font-size: 70px;
  margin-bottom: 20px;
  color: #3e64ea;
  font-weight: 500;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 4em;
  }
`;

export default UserNotFound;
