import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { TemplateWrapper } from 'features/adminFeatures/template/components';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
// import GnbUserProfile from 'features/commonFeatures/navigationBar/containers/GnbUserProfile';
import UserNotFound from 'pages/UserNotFound';
import styled from 'styled-components';
import { GnbUserHome } from 'features/commonFeatures/navigationBar/containers';

const UserHomePage = observer(() => {
  const { userStore, templateStore, uiStore } = useStore();
  const params = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [userName, setUserName] = useState<string>();
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const init = async () => {
    setLoading(true);

    // 유저페이지 초기 진입시 templateStore.isSimulator undefined로 출력되어 추가
    templateStore.setSimulatorMode(false);
    templateStore.setCurrentMode('link');
    const { userName } = params;
    document.title = userName || 'oh!moss';

    if (typeof userName !== 'undefined') {
      const userInfo = (await userStore.getUserInfo(userName)) as any;
      if (userInfo?.status === 404) {
        setIsNotFound(true);
      }
      setUserName(userName);
      setTimeout(() => {
        document.querySelector('#gnb_container')?.setAttribute('style', 'display:none');
      }, 10);
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
    return () => {
      templateStore.setUserInfo(undefined);
    };
  }, [params]);

  useEffect(() => {}, []);

  return (
    <>
      {loading ? (
        <Backdrop open style={{ zIndex: 20 }}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <>
          {/* <GnbUserProfile userName={userName!} /> */}
          <GnbUserHome userName={userName!} />
          {typeof userStore.userInfo !== 'undefined' && (
            <ContainerStyle>
              <TemplateWrapper />
            </ContainerStyle>
          )}
          {isNotFound == true && <UserNotFound />}
        </>
      )}
    </>
  );
});

const ContainerStyle = styled.div`
  padding: 80px 0 0 0;
  margin: 0 0 46px 0;
`;

export default UserHomePage;
