import React, { RefObject } from 'react';
import {
  exportComponentAsPDF,
  exportComponentAsPNG,
  exportComponentAsJPEG,
} from 'react-component-export-image';
import { UserInfoDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import QRCode from 'react-qr-code';

interface DrawerDto {
  open: boolean;
  profile?: UserInfoDto;
  qrRef: RefObject<HTMLDivElement>;
  handleClose: (itme?: any | undefined) => void | undefined;
}

const MobileShareDrawer = ({ open, profile, qrRef, handleClose }: DrawerDto) => {
  const { t } = useTranslation();
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    handleClose();
    // setAnchorEl(anchor);
  };

  const buttonItems = [
    // {
    //   id: 1,
    //   title: 'PDF',
    //   image: '/image/export.png',
    //   onClick: () => {
    //     exportComponentAsPDF(qrRef, {
    //       fileName: `${profile.profile?.userName}_QR`,
    //       html2CanvasOptions: {},
    //       pdfOptions: { w: 210, h: 210 },
    //     });
    //   },
    // },
    {
      id: 2,
      title: 'PNG',
      image: '/image/export.png',
      onClick: () => {
        exportComponentAsPNG(qrRef, {
          fileName: `${profile?.userName}_QR`,
        });
      },
    },
    {
      id: 3,
      title: 'JPG',
      image: '/image/export.png',
      onClick: () => {
        exportComponentAsJPEG(qrRef, {
          fileName: `${profile?.userName}_QR`,
        });
      },
    },
    {
      id: 4,
      title: t('share'),
      image: '/image/export.png',
      onClick: () => {
        handleClickShare();
      },
    },
  ];

  const handleClickShare = () => {
    // navigator.share({
    //   title: document.title,
    //   text: 'Hello World',
    //   url: 'https://developer.mozilla.org',
    // });
    navigator.share({
      title: `${profile?.userName}의 오모스`,
      text: `${profile?.userName}의 오모스`,
      url: `https://ohmoss.com/${profile?.userName}`,
    });
    // window.navigator
    //   .share({
    //     title: `${profile.profile?.userName}의 오모스`,
    //     url: `https://ohmoss.com/${profile.profile?.userName}`,
    //   })
    //   .then(() => {
    //     console.log('Thanks for sharing!');
    //   })
    //   .catch(console.error);
  };

  const handleClickCopy = () => {
    toast.success(t('copyClipBoard'));
    navigator.clipboard.writeText(`https://ohmoss.com/${profile?.userName}`);
  };

  return (
    <SwipeableDrawer
      anchor={'top'}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Box
        sx={{ width: 'auto', textAlign: 'center', paddingTop: '56px' }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <WrapperStyle>
          <QRCode value={`https://ohmoss.com/${profile?.userName}`} size={184} />
          <ShareUrlContainerStyle>
            <div>{`https://ohmoss.com/${profile?.userName}`}</div>
            <CopyButtonStyle onClick={handleClickCopy}>COPY</CopyButtonStyle>
          </ShareUrlContainerStyle>
          <ButtonContainerStyle>
            {buttonItems.map((item: any, i: number) => (
              <ButtonStyle key={i} onClick={item.onClick}>
                <img src={item.image} alt="" style={{ width: 24 }} />
                <div style={{ fontSize: 12 }}>{item.title}</div>
              </ButtonStyle>
            ))}
          </ButtonContainerStyle>
        </WrapperStyle>
      </Box>
    </SwipeableDrawer>
  );
};

const WrapperStyle = styled.div``;

const ShareUrlContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3em 3em 3em 3em;
  border: 1px solid #c2c2c2;
  padding: 0 16px 0 16px;
  height: 36px;
  line-height: 36px;
  border-radius: 24px;
  font-size: 14px;
`;

const CopyButtonStyle = styled.div`
  background-color: gray;
  color: white;
  margin: 8px 0 0 0;
  padding: 0 4px 0 4px;
  height: 20px;
  line-height: 20px;
  border-radius: 16px;
  font-size: 8px;
  font-weight: 300;
  &:hover {
    cursor: pointer;
    opacity: 0.4;
  }
`;

const ButtonContainerStyle = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 1em 2em 1em;
`;

const ButtonStyle = styled.div`
  width: 5.4em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  background-color: white;
  padding: 8px 0 0 0;
  height: 54px;
  border-radius: 8px;
  font-size: 14px;
`;

export default MobileShareDrawer;
