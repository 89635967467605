const BackgroundTypeList = [
  {
    id: 1732,
    fileName: 'background_1.jpg',
    fileType: 'Background',
    fileSize: 1759845,
    contentType: 'image/jpeg',
    width: 4160,
    height: 6240,
    publicUrl:
      'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/0d7552f6-5142-4546-9b00-45dbe1a61271',
    creator: {
      id: 365,
    },
    createDate: '2022-07-11T14:19:52',
    thumbnail: '/images/background/thumbnail/background_thumbnail_1.jpg',
  },
  {
    id: 1733,
    fileName: 'background_2.jpg',
    fileType: 'Background',
    fileSize: 1011685,
    contentType: 'image/jpeg',
    width: 4096,
    height: 3112,
    publicUrl:
      'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/7128c9ef-9019-46c7-a946-7d0de35ed204',
    creator: {
      id: 365,
    },
    createDate: '2022-07-11T14:20:39',
    thumbnail: '/images/background/thumbnail/background_thumbnail_2.jpg',
  },
  {
    id: 1734,
    fileName: 'background_3.jpg',
    fileType: 'Background',
    fileSize: 2528488,
    contentType: 'image/jpeg',
    width: 5304,
    height: 7952,
    publicUrl:
      'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/80e4a535-dca5-4886-ac10-1320a668ce45',
    creator: {
      id: 365,
    },
    createDate: '2022-07-11T14:21:04',
    thumbnail: '/images/background/thumbnail/background_thumbnail_3.jpg',
  },
  {
    id: 1735,
    fileName: 'background_4.jpg',
    fileType: 'Background',
    fileSize: 4032431,
    contentType: 'image/jpeg',
    width: 4480,
    height: 6720,
    publicUrl:
      'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/af972840-0c94-4335-a3f5-14a417d85baf',
    creator: {
      id: 365,
    },
    createDate: '2022-07-11T14:21:22',
    thumbnail: '/images/background/thumbnail/background_thumbnail_4.jpg',
  },
  {
    id: 1736,
    fileName: 'background_5.jpg',
    fileType: 'Background',
    fileSize: 1265650,
    contentType: 'image/jpeg',
    width: 2075,
    height: 3070,
    publicUrl:
      'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/8e099623-a91e-41b5-86e2-7c6216582a4e',
    creator: {
      id: 365,
    },
    createDate: '2022-07-11T14:21:37',
    thumbnail: '/images/background/thumbnail/background_thumbnail_5.jpg',
  },
  {
    id: 1737,
    fileName: 'background_6.jpg',
    fileType: 'Background',
    fileSize: 3311663,
    contentType: 'image/jpeg',
    width: 4000,
    height: 6000,
    publicUrl:
      'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/06c04812-d4ea-4aff-81e0-d78d443c5986',
    creator: {
      id: 365,
    },
    createDate: '2022-07-11T14:21:57',
    thumbnail: '/images/background/thumbnail/background_thumbnail_6.jpg',
  },
  {
    id: 1738,
    fileName: 'background_7.jpg',
    fileType: 'Background',
    fileSize: 5840625,
    contentType: 'image/jpeg',
    width: 3966,
    height: 5949,
    publicUrl:
      'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/684b4af9-0d2a-4dcd-b8f0-f6c89f343b93',
    creator: {
      id: 365,
    },
    createDate: '2022-07-11T14:22:18',
    thumbnail: '/images/background/thumbnail/background_thumbnail_7.jpg',
  },
  {
    id: 1739,
    fileName: 'background_8.jpg',
    fileType: 'Background',
    fileSize: 2640383,
    contentType: 'image/jpeg',
    width: 4000,
    height: 6000,
    publicUrl:
      'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/89d12836-1f97-4672-9619-ae199c24b82a',
    creator: {
      id: 365,
    },
    createDate: '2022-07-11T14:22:38',
    thumbnail: '/images/background/thumbnail/background_thumbnail_8.jpg',
  },
  {
    id: 1741,
    fileName: 'background_9.jpg',
    fileType: 'Background',
    fileSize: 3315129,
    contentType: 'image/jpeg',
    width: 4000,
    height: 6000,
    publicUrl:
      'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/ed012461-a6f6-4367-8c68-ec35763b91fa',
    creator: {
      id: 365,
    },
    createDate: '2022-07-11T14:27:04',
    thumbnail: '/images/background/thumbnail/background_thumbnail_9.jpg',
  },
];

const defaultServiceImage = {
  id: 2162,
  contentType: 'image/png',
  publicUrl:
    'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/d8c314ac-322a-44a2-9281-8f99bda41a57',
};

export { BackgroundTypeList, defaultServiceImage };
