import { makeAutoObservable } from 'mobx';
import { Template as TemplateService } from 'services/Template';
import { CustomUserProfile, TemplatePagination, CustomUserInfoDto } from 'types/CommonTypes';
import { REST_API_URL } from 'stores/AppStore';
import { TemplateDto, UserInfoDto } from 'services/data-contracts';

class TemplateStore {
  currentTemplate: TemplateDto;

  model: CustomUserProfile;

  templates: Array<TemplateDto>;

  userInfo?: CustomUserInfoDto;

  isSimulator: boolean;

  isScrollHeaderProfile: boolean;

  pagination: TemplatePagination;

  currentMode: 'link' | 'follow' | 'item';

  constructor() {
    makeAutoObservable(this);
  }

  api: TemplateService<unknown> = new TemplateService({
    baseURL: REST_API_URL,
  });

  /**
   * @descriptino Template목록을 취득한다.
   * @param query
   */
  *getTemplates(query?: { templateType?: number; page?: number; sort?: string[] }) {
    const { data, headers } = yield this.api.getTemplates('Layout', { ...query, size: 9 });
    this.pagination = JSON.parse(headers['x-pagination']);
    this.templates = data;
  }

  /**
   * @description Template을 업로드한다.
   * @param templateType
   * @param template
   */
  *uploadTemplate(templateType: 'Layout' | 'Box', template: TemplateDto) {
    const { data } = yield this.api.createTemplate(templateType, template);
  }

  /**
   * @description Template을 업데이트한다.
   * @param templateType
   * @param templateId
   * @param template
   * @returns
   */
  *updateTemplate(templateType: 'Layout' | 'Box', templateId: number, template: TemplateDto) {
    const { data, status, error } = yield this.api.updateTemplate(
      templateType,
      templateId,
      template,
    );
    if (status === 200) {
      return data;
    } else {
      return error;
    }
  }

  /**
   * @description Template을 삭제한다.
   * @param templateId
   * @returns
   */
  *deleteTemplate(templateId: number) {
    const { data, status, error } = yield this.api.deleteTemplate('Layout', templateId);
    if (status === 200) {
      return data;
    } else {
      return error;
    }
  }

  setTemplate(template: TemplateDto) {
    this.currentTemplate = template;
  }

  setModel(model: CustomUserProfile) {
    this.model = model;
  }

  setUserInfo(userInfo: CustomUserInfoDto | undefined) {
    this.userInfo = userInfo;
    if (typeof userInfo?.layoutStyle !== 'undefined') {
      this.currentTemplate = userInfo.layoutStyle;
    }
  }

  setSimulatorMode(isSimulator: boolean) {
    this.isSimulator = isSimulator;
  }

  setIsScrollHeaderProfile = (isScrollHeaderProfile: boolean) => {
    this.isScrollHeaderProfile = isScrollHeaderProfile;
  };

  setCurrentMode(mode: 'link' | 'follow' | 'item') {
    this.currentMode = mode;
  }
}

export default TemplateStore;
