import React, { useState } from 'react';
import { Row } from 'components/commons/layouts';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from 'components/items';
import { useStore } from 'stores/StoreHelper';
import { Confirm } from 'components/commons';
import { isValid } from 'libs/helper/utils';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import WhiteButton from 'components/commons/WhiteButton';
import styled from 'styled-components';

const BackgroundColorComponent = observer(() => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const { meStore, uiStore, simulatorStore } = useStore();
  const { t } = useTranslation();
  const colors = [
    '#efefef',
    '#555555',
    '#ff6738',
    '#ff9898',
    '#ffd24b',
    '#99e4ed',
    '#81c784',
    '#7585ff',
  ];

  const handleClickConfirm = async () => {
    if (typeof simulatorStore?.profile !== 'undefined') {
      meStore.saveMyProfile(simulatorStore?.profile);
      await meStore.saveMyLayout({ template: { id: 561 } });
    }
    if (typeof simulatorStore?.profile?.template?.id !== 'undefined') {
      meStore.saveMyLayout({ template: { id: simulatorStore?.profile.template.id } });
    } else {
      await meStore.saveMyLayout({ template: { id: 561 } });
    }
    toast(t('yourProfileSave'), { position: 'top-center' });
    uiStore.drawer.confirm();
    await meStore.getMyProfile();
  };

  const handleUpdateBackgroundColor = (value: string | undefined) => {
    // templateStore.setUserInfo({
    //   ...templateStore.userInfo,
    //   profile: {
    //     ...templateStore?.userInfo?.profile,
    //     backgroundColor: value,
    //     backgroundImage: undefined,
    //     template: undefined,
    //   },
    // });

    simulatorStore.updateProfile({
      ...simulatorStore?.profile,
      backgroundColor: value,
      backgroundImage: undefined,
      template: undefined,
    });
    // templateStore.setTemplate({});
  };

  // const handleSetDefaultBg = () => {
  //   templateStore.setUserInfo({
  //     ...templateStore?.userInfo,
  //     profile: {
  //       backgroundImage: {
  //         id: 2162,
  //         contentType: 'image/png',
  //         publicUrl:
  //           'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/d8c314ac-322a-44a2-9281-8f99bda41a57',
  //       },
  //     },
  //   });
  //   templateStore.setTemplate({ id: 561 });
  // };

  const handleClickSave = () => {
    setShowConfirm(true);
  };

  return (
    <>
      {isValid(simulatorStore?.profile?.backgroundColor) && (
        <div style={{ position: 'relative' }}>
          <DeleteButtonStyle
            onClick={() => {
              handleUpdateBackgroundColor(undefined);
            }}
          >
            <DeleteImageStyle src="/image/adminProfile/close-round.png" />
          </DeleteButtonStyle>
        </div>
      )}
      <Row style={{ justifyContent: 'space-around', flexWrap: 'wrap' }}>
        <ColorPicker
          pickerStyle={{
            position: 'absolute',
            top: 200,
            left: 0,
            right: 0,
            margin: 'auto',
            padding: 10,
            width: 220,
          }}
          onColorChanged={(color: string) => handleUpdateBackgroundColor(color)}
          defaultValue={simulatorStore?.profile?.backgroundColor}
        >
          <ColorStyle
            style={{
              background: simulatorStore?.profile?.backgroundColor,
              marginRight: 0,
            }}
          >
            <PlusImageStyle src="/image/Add.svg" />
          </ColorStyle>
        </ColorPicker>
        <>
          {colors.map((color: string) => (
            <ColorStyle
              style={{ background: color, margin: '10px 0' }}
              onClick={() => handleUpdateBackgroundColor(color)}
            />
          ))}
        </>
      </Row>
      <WhiteButton type="SAVE" onClick={handleClickSave} style={{ marginTop: '40px' }} />
      <Confirm
        title={t('backgroundDelete')}
        closeText={t('cancellation')}
        visible={showConfirm}
        setVisible={setShowConfirm}
        onConfirm={handleClickConfirm}
      />
    </>
  );
});

const DeleteButtonStyle = styled.div`
  position: absolute;
  text-align: center;
  width: 40px;
  height: 40px;
  z-index: 1;
  top: 10px;
  left: 115px;
  cursor: pointer;
`;

const DeleteImageStyle = styled.img`
  width: 100%;
  height: 100%;
  @media ${(props) => props.theme.media.mobile} {
    margin: 0 0 0 -98px;
  }
`;

const ColorStyle = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  transition: all 0.1s ease-in-out;

  @media ${(props) => props.theme.media.mobile} {
    width: 100px;
    height: 100px;
  }
  &:hover {
    transition: all 0.1s ease-in-out;
    transform: scale(1.1);
  }
`;

const PlusImageStyle = styled.img`
  width: 80px;
  fill: #fff;
`;

export default BackgroundColorComponent;
