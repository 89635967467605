import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { Input } from 'components/commons';
import { useStore } from 'stores/StoreHelper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { JoinDto } from 'services/data-contracts';
import styled, { css } from 'styled-components';
import ATagStyle from 'features/mainFeatures/auth/components/ATagStyle';
import BlueBtn from 'features/mainFeatures/auth/components/BlueBtn';
import AlertTitle from '@mui/material/AlertTitle';
import Popover from '@mui/material/Popover';
import useForm from 'libs/hooks/useForm';
import Alert from '@mui/material/Alert';
import useScreenType from 'libs/hooks/useScreenType';

const LoginPage = observer(() => {
  const { t } = useTranslation();
  const { authStore, meStore, formValidationStore } = useStore();
  const navigate = useNavigate();
  const idRef = useRef<HTMLInputElement>(null);
  const screenType = useScreenType();

  const [isErrorAlert, setIsErrorAlert] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<JoinDto>();
  const [userNameErrorMessage, setUserNameErrorMessage] = useState<string>('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  const isFormValid = useForm(formValidationStore);

  const handleClickLogin = async () => {
    if (typeof loginData !== 'undefined') {
      const result: any = await authStore.login(loginData);
      if (result.result === true) {
        await meStore.getMe();
        navigate('/admin');
      } else {
        // 에러
        idRef.current?.focus();
        if (result.error?.status === 403) {
          navigate('/unverified');
        } else if (result.error?.status === 404) {
          toast(t('noUserFound'), { type: 'error', position: 'top-center' });
        } else {
          toast(t('idPasswordNotMatch'), { type: 'error', position: 'top-center' });
        }
      }
    }
  };

  const handleChangeLoginInfo = (key: 'userName' | 'password', inputValue: string) => {
    setLoginData((prevState) => ({ ...prevState, [key]: inputValue }));
  };

  const checkValidationUsername = () => {
    if (typeof loginData?.userName === 'undefined') {
      return;
    }
    const userNameExp = /^([A-Za-z]|[0-9]|_)+$/;
    const validationCheck = userNameExp.test(loginData?.userName);
    if (validationCheck === true) {
      setUserNameErrorMessage('');
    } else {
      setUserNameErrorMessage(t('onlyEnNumAllow'));
    }
  };

  const checkValidationPassword = () => {
    if (typeof loginData?.password === 'undefined') {
      return;
    }
    const passwordExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    const validationCheck = passwordExp.test(loginData?.password);
    if (validationCheck === true) {
      setPasswordErrorMessage('');
    } else {
      setPasswordErrorMessage(t('mustLeastCharacterEnNumSpecial'));
    }
  };

  const left = document.body.offsetWidth / 2;

  useEffect(() => {
    checkValidationUsername();
    checkValidationPassword();
  }, [loginData?.userName, loginData?.password]);

  useEffect(() => {
    /** admin에서 401떨어졌을때, 로그인으로 리다이렉션 후에 toast 띄우는 로직 (http-clint 참고) */
    const { search } = window.location;
    if (search.includes('incorrect_user_token')) {
      toast(t('loginExpire'), { type: 'error', position: 'top-center' });
      const newURL = window.location.href.split('?')[0];
      window.history.pushState('object', document.title, newURL);
    }
  }, []);

  useEffect(() => {
    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('refreshToken');
    localStorage.removeItem('me');
  }, []);

  useEffect(() => {
    return () => {
      formValidationStore.destroy();
    };
  }, []);

  return (
    <>
      <Popover
        open={isErrorAlert}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 80, left }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Alert severity={'error'}>
          <AlertTitle>{t('loginFail')}</AlertTitle>
          {/* <div>{errorMessage}</div> */}
        </Alert>
      </Popover>
      <LoginContainer>
        <div style={{ marginBottom: 30 }}>
          <img src="/images/charactor.svg" width="150" />
          <img src="/images/ohmoss_logo.svg" width="120" />
        </div>
        <div
          style={{ fontWeight: 900, fontSize: screenType === 'mobile' ? 25 : 30, marginBottom: 20 }}
        >
          {t('gateway')}
        </div>
        <div style={{ padding: 20, background: '#fac000', borderRadius: 20, width: '100%' }}>
          <div style={{ marginBottom: 20, fontSize: 20 }}>
            <b>중요 공지사항</b>
          </div>
          <div>
            오모스가 5월8일부터 베타 서비스를 종료하고 <br />
            새로운 버전으로 찾아갑니다.
          </div>
          <div style={{ marginTop: 20 }}>
            기존 데이터는 이관되며, 이관 후 비밀번호는 <br />
            초기화 되므로 재 설정이 필요합니다.
          </div>
        </div>
        <InputContainer>
          <Input
            required
            minLength={4}
            maxLength={25}
            // label="ID"
            customValidation={{
              expression: /^([A-Za-z]|[0-9]|_)+$/,
              message: userNameErrorMessage,
            }}
            id="IdInput"
            placeholder={t('id')}
            type="text"
            style={inputStyle}
            containerStyle={containerStyle}
            boxStyle={{ height: 85 }}
            messageLocation="bottom"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClickLogin();
              }
            }}
            onChangeValue={(value: string) => {
              handleChangeLoginInfo('userName', value);
              if (value === '') {
                setUserNameErrorMessage(t('enterYourID'));
              }
            }}
          />
          <Input
            required
            minLength={8}
            maxLength={20}
            // label="Password"
            customValidation={{
              expression: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
              message: passwordErrorMessage,
            }}
            id="passwordInput"
            placeholder={t('password')}
            type={'password'}
            autoComplete="off"
            style={inputStyle}
            containerStyle={containerStyle}
            boxStyle={{ height: 100 }}
            messageLocation="bottom"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClickLogin();
              }
            }}
            onChangeValue={(value: string) => {
              handleChangeLoginInfo('password', value);
              if (value === '') {
                setPasswordErrorMessage(t('enterYourPassword'));
              }
            }}
          />
        </InputContainer>
        <LoginBtn validState={isFormValid.result} onClick={handleClickLogin}>
          {t('logIn')}
        </LoginBtn>
        <LoginLinkContainer>
          <ATagStyle color={'#3e64ea'} href="/sign-up">
            {t('signUp')}
          </ATagStyle>
          <ATagStyle textDecoration={'underline'} href="/find-id">
            {t('idPasswordLost')}
          </ATagStyle>
        </LoginLinkContainer>
      </LoginContainer>
    </>
  );
});

const flexCenter = css`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const LoginContainer = styled.div`
  max-width: 500px;
  margin: auto;
  height: 100vh;
  ${flexCenter};
  flex-direction: column;
`;

const LoginTitle = styled.div`
  text-align: center;
  font-family: 'Barlow';
  font-size: 60px;
  color: #3e64ea;
  font-weight: 500;
  margin-bottom: 50px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 4em;
  }
`;

const LoginLinkContainer = styled.div`
  ${flexCenter}
  margin-top: 2em;
  width: 20em;
  > a {
    font-size: 16px;
    margin-bottom: 1em;
  }
  > a:first-child {
    margin-right: 20px;
  }
  @media ${(props) => props.theme.media.mobile} {
    width: inherit;
    flex-wrap: wrap;
  }
`;

const InputContainer = styled.div`
  margin: 20px;
  width: calc(100% - 40px);
`;

const LoginBtn = styled(BlueBtn)<{ validState?: any }>`
  height: 50px;
  margin: 0;
  width: 90%;
  border-radius: 15px;
  cursor: ${({ validState }) => (validState ? 'pointer' : 'pointer')};
  /* pointer-events: ${({ validState }) => (validState ? 'auto' : 'none')}; */
  background-color: ${({ validState }) => (validState ? '#3e64ea' : '#999999')};
`;

const containerStyle: React.CSSProperties = {
  background: 'white',
  display: 'flex',
  alignItems: 'center',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
  borderRadius: 10,
  width: '100%',
  height: 50,
  overflow: 'hidden',
};
const inputStyle: React.CSSProperties = {
  fontSize: '16px',
  // 크롬 자동완성시 input 배경색 변경X
  WebkitBoxShadow: '0 0 0 1000px white inset',
  boxShadow: '0 0 0 1000px white inset',
  height: '35px',
};
export default LoginPage;
