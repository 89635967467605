import React, { useEffect, useState } from 'react';
import { Badge } from '@mui/material';
import styled from 'styled-components';

type ItemType = 'links' | 'advanced';
const ProTitle = ({ mode }: { mode?: ItemType }) => {
  const [itemMode, setItemMode] = useState<ItemType>();

  useEffect(() => {
    setItemMode('links');
  }, []);

  return (
    <div style={{ height: 40 }}>
      <HeaderStyle>
        <HeaderItemStyle
          // onClick={() => {
          //   setItemMode('links');
          // }}
          selected={itemMode === 'links'}
        >
          Links
        </HeaderItemStyle>
        {/* <div
          onClick={() => {
            setItemMode('advanced');
          }}
        >
          <HeaderItemStyle selected={itemMode === 'advanced'}>
            
            Pro
          </HeaderItemStyle>
        </div> */}
      </HeaderStyle>
    </div>
  );
};

const HeaderStyle = styled.div`
  display: flex;
  align-items: baseline;
`;

const HeaderItemStyle = styled.div<{ selected: boolean }>`
  font-family: 'Raleway' !important;
  margin-right: 20px;
  transition: all 0.2s ease-out;

  font-size: ${(props) => (props.selected ? '40px' : '30px')};
  color: ${(props) => (props.selected ? 'black' : '#898989')};
  /* cursor: pointer; */
`;

const BadgeContainerStyle = styled.div`
  position: absolute;
  left: 240px;
  top: -5px;
`;
export { ProTitle, ItemType };
