import React from 'react';
import { CommonButtonStyle } from 'resources/styles/common/styles';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import TemplateFormContainer from 'features/adminFeatures/template/containers/TemplateFormContainer';
import useScreenType from 'libs/hooks/useScreenType';
import styled from 'styled-components';
import Modal from 'react-modal';
import _ from 'lodash';

type DefaultProps = {
  type: 'create' | 'edit';
  onClose: () => void;
  isOpen: boolean;
};

const TemplateFormModal = observer(({ type, isOpen, onClose }: DefaultProps) => {
  const screenType = useScreenType();
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onClose}
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.25)', zIndex: 100 },
        content: {
          border: 0,
          padding: 0,
          maxWidth: 800,
          margin: 'auto',
          borderRadius: 10,
          background: '#F6F6F6',
          width: screenType === 'window' ? 800 : '100%',
          inset: screenType === 'window' ? '40px' : '0px',
          marginTop: screenType === 'window' ? '40px' : '60px',
        },
      }}
    >
      <ModalContainerStyle className="row">
        <ModalTitleStyle>
          {type === 'create' ? t('templateAdd') : t('templateEdit')}
        </ModalTitleStyle>
        <EditModalCloseButtonStyle onClick={onClose}>{t('close')}</EditModalCloseButtonStyle>
      </ModalContainerStyle>
      <TemplateFormContainer type={type} onClose={onClose} />
    </Modal>
  );
});

const ModalContainerStyle = styled.div`
  align-items: center;
  margin: 20px 40px 10px 40px;
`;

const ModalTitleStyle = styled.div`
  flex: 1;
  font-size: 18px;
  font-weight: 600;
`;

const EditModalCloseButtonStyle = styled(CommonButtonStyle)`
  width: 80px;
  height: 40px;
`;

export default TemplateFormModal;
