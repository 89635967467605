// ProfileContainer.tsx(바뀐본)
import React, { useState, useEffect } from 'react';
import { CustomUserProfile, CardCommonProps } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { isValid } from 'libs/helper/utils';
import { SwiperSlide } from 'swiper/react';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { ToggleButton, ToggleButtonType } from 'components/commons/ToggleButton';
import ProfileFooterComponent from '../components/ProfileFooterComponent';
import ProfileComponent from '../components/ProfileComponent';
import styled from 'styled-components';

type DefaultProps = {
  onShowSimulator?: () => void;
};

const ProfileList: Array<ToggleButtonType> = [
  {
    id: 'Profile',
    iconSrc: '/image/image.png',
    description: 'Profile',
    checked: 'checked',
  },
  {
    id: 'Footer',
    iconSrc: '/image/colorfilter.svg',
    description: 'Footer',
  },
];

const ProfileContainer = observer(({ onShowSimulator }: DefaultProps) => {
  const { t } = useTranslation();
  const { meStore, fileStore, uiStore, simulatorStore } = useStore();
  const [profileModel, setProfileModel] = useState<CustomUserProfile>();
  const [profileType, setProfileType] = useState<string>('Profile');

  const handleClickType = (item: ToggleButtonType) => {
    setProfileType(item.id);
  };
  const getLinkContainer = () => {
    switch (profileType) {
      case 'Profile':
        return <ProfileComponent commonProps={commonProps} />;
      case 'Footer':
        return <ProfileFooterComponent commonProps={commonProps} />;
      default:
        break;
    }
  };
  const updateModel = (key: any, value: any) => {
    const newProfile: any = { ...simulatorStore.profile };
    (newProfile as any)[key] = value;
    simulatorStore.updateProfile(newProfile);

    setProfileModel((prevState: CustomUserProfile) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleClickSaveProfile = async () => {
    /** 저장된 number포맷 or bullet포맷 적용 */
    const newProfileModel = { ...profileModel };

    if (newProfileModel?.profileImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadImage(profileModel?.profileImage?.file);
      newProfileModel.profileImage.id = image.id;
    }
    if (newProfileModel?.backgroundImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadImage(profileModel?.backgroundImage?.file);
      newProfileModel.backgroundImage.id = image.id;
    }
    if (newProfileModel?.footerImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadImage(profileModel?.footerImage?.file);
      newProfileModel.footerImage.id = image.id;
    }

    newProfileModel.custom = JSON.stringify({
      titleProps: newProfileModel.titleProps,
      selfIntroductionProps: newProfileModel.selfIntroductionProps,
      template: newProfileModel.template,
      footerProps: newProfileModel.footerProps,
      titleYPosition: newProfileModel.titleYPosition,
    });

    /** 저장후, 변경된 userInfo의 정보를 가져온다 */
    meStore.saveMyProfile(newProfileModel);
    toast(t('yourProfileSave'), { position: 'top-center' });
    await meStore.getMyProfile();
    uiStore.drawer.confirm();
  };

  const commonProps: CardCommonProps = {
    profile: profileModel,
    updateModel,
    handleClickSaveProfile,
  };

  useEffect(() => {
    if (isValid(simulatorStore.profile)) {
      setProfileModel(simulatorStore?.profile);
    }
  }, [simulatorStore.profile]);

  return (
    <>
      <ProfileDescriptionStyle>{t('profileInform3')}</ProfileDescriptionStyle>
      <SwiperSlideContainerStyle>
        {ProfileList.map((itemProfileType) => (
          <SwiperSlide key={itemProfileType?.id}>
            <div
              key={itemProfileType?.id}
              onClick={() => {
                handleClickType(itemProfileType);
              }}
            >
              <ToggleButton
                item={itemProfileType}
                defaultChecked={profileType === itemProfileType.id}
              />
            </div>
          </SwiperSlide>
        ))}
      </SwiperSlideContainerStyle>
      <div>{getLinkContainer()}</div>
    </>
  );
});

const ProfileDescriptionStyle = styled.div`
  font-family: 'Noto Sans KR';
  font-size: 14px;
  color: #111111;
  margin: 10px 0 30px 0;
  font-weight: 300;
`;

const SwiperSlideContainerStyle = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px 0 0px;
  > div {
    margin: 0 30px 0 0px;
  }
  /* 테스트 */
  /* background-color: #fff; */
  /* 테스트 */
  align-items: center;
  border-radius: 10px;
  @media ${(props) => props.theme.media.mobile} {
    > div {
      margin: 0 -10px 0 -12px;
    }
  }
`;

export default ProfileContainer;
