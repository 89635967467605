import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  LinkEditorStyle,
  RegisterButtonStyle,
  ImageUploaderStyle,
  EditorWrapperStyle,
} from 'resources/styles/link/styles';
import { updateLink, updateModel } from 'components/items/commonLogic';
import { defaultLinkEditorProps } from 'types/CommonTypes';
import { ImageUploader } from 'components/commons';
import { LinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { isValid } from 'libs/helper/utils';
import { toast } from 'react-toastify';
import InputComponent from 'components/items/InputComponent';
import SimplePreview from 'components/items/SimplePreview';
import styled, { css } from 'styled-components';
import useForm from 'libs/hooks/useForm';
import { Checkbox } from '@mui/material';

import { Row } from 'components/commons/layouts';

const SimpleLinkEditor = forwardRef(
  ({ model, stores, onRegistComplete }: defaultLinkEditorProps<any>, ref) => {
    const isFormValid = useForm(stores.formValidationStore);
    const titleRef = useRef<any>(null);
    const urlRef = useRef<any>(null);
    const { t } = useTranslation();

    const [linkModel, setLinkModel] = useState<LinkDto>();
    const [title, setTitle] = useState<string>();
    const [url, setUrl] = useState<string>();

    useImperativeHandle(ref, () => ({
      reset,
    }));

    const reset = () => {
      titleRef.current.value = '';
      urlRef.current.value = '';
    };

    const handleSaveLink = async () => {
      const result = await updateLink(linkModel!, isValid(model), { ...stores });
      titleRef.current.reset();
      urlRef.current.reset();
      onRegistComplete(result!);
      if (result!) {
        if (isValid(model)) {
          toast(t('linkFixed'));
        } else {
          toast(t('linkRegistration'));
        }
      }
    };

    useEffect(() => {
      if (typeof model !== 'undefined') {
        setLinkModel(model);
        setTitle(model.title);
        setUrl(model.linkUrl);
      } else {
        setLinkModel({ linkType: 'SimpleLink', title: '', linkUrl: '', isActive: true });
        setTitle('');
        setUrl('');
      }
    }, [model]);

    return (
      <>
        <LinkEditorStyle>
          <EditorWrapperStyle>
            <ImageUploaderStyle>
              <ImageUploader
                attachType="image"
                show={true}
                style={{ textAlign: 'left' }}
                cropType={'square'}
                cropSize={{ width: 200, height: 200 }}
                onSelected={(thumbnail: any) => {
                  updateModel(setLinkModel, 'thumbnail', thumbnail[0]);
                }}
              >
                <img src="/image/camera.svg" alt="" />
              </ImageUploader>
            </ImageUploaderStyle>
            {typeof linkModel?.thumbnail?.publicUrl !== 'undefined' && (
              <TrashImageContainerStyle
                onClick={() => {
                  updateModel(setLinkModel, 'thumbnail', undefined);
                  toast(t('imageDelete'));
                }}
              >
                <img src="/image/trash.png" alt="" />
              </TrashImageContainerStyle>
            )}
            <InputContainerStyle>
              <InputComponent
                type="Title"
                ref={titleRef}
                id="title"
                required
                placeholder="Link title"
                defaultValue={title}
                divStyle={divStyle}
                inputStyle={inputStyle}
                onChangeValue={(value: string) => {
                  updateModel(setLinkModel, 'title', value);
                }}
              />
              <InputComponent
                type="Url"
                ref={urlRef}
                id="Url"
                format="url"
                placeholder="Link url"
                required
                defaultValue={url}
                divStyle={divStyle}
                inputStyle={inputStyle}
                onChangeValue={(value: string) => {
                  updateModel(setLinkModel, 'linkUrl', value);
                }}
              />
            </InputContainerStyle>
          </EditorWrapperStyle>
          <RegisterButtonStyle
            active={isFormValid.result}
            onClick={() => {
              handleSaveLink();
            }}
          >
            {isFormValid.result ? (
              <RegisterButtonImgStyle src="/image/check_active.svg" />
            ) : (
              <RegisterButtonImgStyle src="/image/check_inactive.svg" />
            )}
          </RegisterButtonStyle>
        </LinkEditorStyle>
        <Row>
          {/* <Checkbox
            style={{ marginTop: 20 }}
            defaultChecked={true}
            onClick={(e: any) => {
              updateModel(setLinkModel, 'isActive', e.target.checked);
            }}
          /> */}
          <SimplePreviewContainerStyle>
            <SimplePreview model={linkModel} type="inine" />
          </SimplePreviewContainerStyle>

          {/* <ToggleButton
            checked={true}
            onToggleClick={(isChecked: boolean) => {
              updateModel(setLinkModel, 'isActive', isChecked);
            }}
          /> */}
        </Row>
      </>
    );
  },
);

const displayAlignCenter = css`
  display: flex;
  align-items: center;
`;

const inputStyle = {
  height: '20px',
  background: 'white',
  fontSize: '16px',
  paddingLeft: 0,
  paddingTop: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const divStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const TrashImageContainerStyle = styled.div`
  position: relative;
  z-index: 1;
  top: -20px;
  left: -25px;
  border-radius: 5px;
  cursor: pointer;
`;

const InputContainerStyle = styled.div`
  width: 310px;
  margin: 0 0 0 10px;
  > div {
    height: 40px !important;
    ${displayAlignCenter}
  }
  > div > div {
    height: 25px !important;
    padding: 0 0 0 0 !important;
  }
  @media ${(props) => props.theme.media.mobile} {
    width: 160px;
  }
`;

const RegisterButtonImgStyle = styled.img.attrs({ width: '35px', height: '35px' })``;

const SimplePreviewContainerStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > div:last-child {
    width: 100%;
    > div > div {
      width: auto;
    }
  }
`;

// const TabContainerStyle = styled.div`
//   width: 100%;
//   height: 60px;
//   display: flex;
//   justify-content: space-around;
//   border: 1px solid #eeeeee;
//   border-radius: 50px;
//   align-items: center;
// `;

// const TabContainerWrapStyle = styled.div`
//   display: flex;
// `;

// const TabButtonStyle = styled.input.attrs({
//   type: 'radio',
// })`
//   display: none;
//   & + label {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
//     width: 150px;
//     > div {
//       color: #cdcdcd;
//     }
//     @media ${(props) => props.theme.media.mobile} {
//       width: 100px;
//     }
//   }
//   &:checked + label {
//     background-color: #fdfdfd;
//     cursor: pointer;
//     > div {
//       color: #111;
//     }
//     @media ${(props) => props.theme.media.mobile} {
//       width: 100px;
//     }
//   }
// `;

// // label, 스타일 수정을 위한 스타일 컴포넌트
// const TabButtonLabel = styled.label``;

// const TitleStyle = styled.div`
//   font-size: 16px;
//   font-family: 'Raleway';
// `;

export default SimpleLinkEditor;
