import React from 'react';
import { SocialLinkDto } from 'services/data-contracts';
import socialServiceList from 'types/socialServiceList';
import styled from 'styled-components';
import { observer } from 'mobx-react';

const SocialComponent = observer(({ socials }: { socials?: Array<SocialLinkDto> }) => {
  const getIcon = (socialType: SocialLinkDto) => {
    const icon = socialServiceList.find((social) => social.type === socialType.socialType);
    return icon?.value;
  };

  const handleClickSNS = (social: SocialLinkDto) => {
    window.open(social.linkUrl, '_blank');
  };

  return (
    <>
      {typeof socials !== 'undefined' && (
        <SocialContainerStyle>
          {socials?.map((social: SocialLinkDto) => (
            <SocialWrapStyle key={social?.id}>
              <SocialStyle
                onClick={() => {
                  handleClickSNS(social);
                }}
              >
                <SocialImageStyle src={`/image/sns/${getIcon(social)}`} alt="" />
              </SocialStyle>
            </SocialWrapStyle>
          ))}
        </SocialContainerStyle>
      )}
    </>
  );
});

const SocialContainerStyle = styled.div`
  margin: 10px 10px 10px 10px;
  justify-content: center;
  flex-direction: row;
  display: flex;
  border-radius: 30px;
  padding: 0 10px 0 10px;
`;

const SocialWrapStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 45px;
  cursor: pointer;
`;

const SocialStyle = styled.div`
  /* display: table-cell; */
  padding: 5px 5px 5px 5px;
  display: flex;
  vertical-align: middle;
  text-align: center;
`;

const SocialImageStyle = styled.img`
  height: 25px;
`;

export default SocialComponent;
