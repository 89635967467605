import styled from 'styled-components';

/**
 * @description 링크 개별 컨테이너 스타일
 */

const LinkContainerLayout = styled.div<{ on: boolean }>`
  border-radius: 50px;
  text-align: center;
  word-wrap: break-word;
  min-height: 40px;
  justify-content: space-between;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  margin: 5px 0 5px 0;
  border: ${(props) => (props.on ? '2px solid #282F40' : '1px solid #f4f4f4')};
  &:hover {
    background-color: #efefef;
  }
  @media ${(props) => props.theme.media.mobile} {
    min-height: 40px;
    height: 50px;
    margin: 0 0 10px 0;
  }
`;

export default LinkContainerLayout;
