import React, { forwardRef } from 'react';
import styled from 'styled-components';

const LinkContainerStyle = styled.div`
  position: fixed;
  z-index: 10000;
  left: 0;
  right: 0;
  bottom: 0px;
  top: 0;
  overflow: scroll;
`;

const TextAreaStyle = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  right: 0;
`;

export const TemplateTextArea = forwardRef(({ children, style }: any, ref: any) => {
  return (
    <TextAreaStyle style={style} ref={ref}>
      {children}
    </TextAreaStyle>
  );
});

const ImageBackgroundStyle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

export { LinkContainerStyle, TextAreaStyle, ImageBackgroundStyle };
