import React, { useEffect, useRef, useState } from 'react';
import { updateLink, updateModel } from 'components/items/commonLogic';
import { CommonRowStyle } from 'resources/styles/common/styles';
import { Map, defaultLinkEditorProps, MapLinkModel } from 'types/CommonTypes';
import { LinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { isValid } from 'libs/helper/utils';
import { GoogleMap } from 'components/maps';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';

import ToggleButton from 'controls/toggleButton/ToggleButton';
import getGeoCodeByAddress from 'libs/helper/geoCodeHelper';
import WhiteButton from 'components/commons/WhiteButton';
import Input from 'components/commons/Input';
import useForm from 'libs/hooks/useForm';
import styled from 'styled-components';

// TODO:지도가 확정일때만 Validation이 유효하여야 함, 만약 유효한 지도를 수정한 경우 기존 유효를 무효화 하여야 함.
const MapLinkEditor = observer(
  ({ model, stores, onRegistComplete }: defaultLinkEditorProps<MapLinkModel>) => {
    const { t } = useTranslation();
    const titleRef = useRef<any>(null);
    const addrRef = useRef<any>(null);
    const isFormValid = useForm(stores.formValidationStore);
    const [linkModel, setLinkModel] = useState<MapLinkModel>();
    const [validAddress, setValidAdress] = useState<boolean>(false);
    const [mapInfo, setMapInfo] = useState<Map | null | undefined>();
    const [searchResults, setSearchResults] = useState<any>();
    const [selectedPlace, setSelectedPlace] = useState<any>();

    const handleSaveLink = async () => {
      const result = await updateLink(linkModel!, isValid(model), { ...stores });
      onRegistComplete(result!);
      if (result!) {
        toast(t('linkRegistration'));
      }
    };

    const handleClickSearchAddr = async () => {
      const results = await getGeoCodeByAddress(addrRef.current);
      if (results === null) {
        toast(t('mapError'));
        return;
      }
      if (results.length === 0) {
        toast(t('enterCorrectAddress'), { position: 'top-center' });
        setSearchResults(undefined);
        setValidAdress(false);
      } else if (results.length === 1) {
        handleClickSelectMap(results[0]);
        setSearchResults(undefined);
      } else {
        setSearchResults(results);
      }
    };

    const handleClickSelectMap = (result: any) => {
      const { lat, lng } = result.geometry.location;
      setMapInfo({
        lat: lat,
        lng: lng,
        title: '',
        addr: '',
      });

      addrRef.current = result.formatted_address;
      setLinkModel((prev) => {
        return { ...prev, longitude: lng, latitude: lat, address: result.formatted_address };
      });
      // updateModel(setLinkModel, 'longitude', lng);
      // updateModel(setLinkModel, 'latitude', lat);
      // updateModel(setLinkModel, 'address', result.formatted_address);

      setValidAdress(true);
    };

    useEffect(() => {
      if (typeof model !== 'undefined') {
        if (typeof model.custom !== 'undefined') {
          setLinkModel({ ...model, customObject: JSON.parse(model.custom) });
        } else {
          setLinkModel(model);
        }
        setMapInfo({
          lat: model.latitude!,
          lng: model.longitude!,
          title: '',
          addr: '',
        });
        setValidAdress(true);
      } else {
        setLinkModel({ linkType: 'MapLink' });
        setMapInfo(null);
        if (titleRef.current !== null) {
          titleRef.current.value = '';
        }
        if (addrRef.current !== null) {
          addrRef.current = '';
        }
      }
    }, [model]);

    return (
      <>
        <MapLinkStyle>
          <GoogleMapContainerStyle>
            {typeof mapInfo !== 'undefined' && <GoogleMap map={mapInfo} />}
          </GoogleMapContainerStyle>
          <InputStyle>
            <Input
              ref={titleRef}
              id="validTitle"
              required
              onChangeValue={(value: string) => {
                updateModel(setLinkModel, 'title', value);
              }}
              defaultValue={linkModel?.title}
              type="text"
              placeholder={t('title')}
            />
          </InputStyle>
          <CommonRowStyle>
            <InputStyle>
              <Input
                id="validAddr"
                required
                onChangeValue={(value: string) => {
                  addrRef.current = value;
                  updateModel(setLinkModel, 'address', value);
                }}
                defaultValue={linkModel?.address}
                type="text"
                placeholder={t('address')}
              />
              <SearchButtonStyle onClick={handleClickSearchAddr}>
                <SearchImageStyle src="/image/search.svg" alt="" />
              </SearchButtonStyle>
            </InputStyle>
          </CommonRowStyle>
          <div style={{ margin: '20px 0' }}>
            <ToggleButton
              label={t('collapse')}
              checked={linkModel?.customObject?.isCollapsed}
              onToggleClick={(isChecked: boolean) => {
                updateModel(setLinkModel, 'customObject', { isCollapsed: isChecked });
              }}
            />
          </div>
          <div style={{ marginLeft: 5 }}>
            {searchResults?.length > 1 && (
              <div style={{ marginTop: 20, marginBottom: 10 }}>{t('searchResults')}</div>
            )}
            {searchResults?.map((result: any, index: number) => (
              <SearchResultStyle
                selected={result?.place_id === selectedPlace?.place_id}
                onClick={() => {
                  handleClickSelectMap(result);
                  setSelectedPlace(result);
                }}
              >
                {result?.formatted_address}
              </SearchResultStyle>
            ))}
          </div>
          <WhiteButton
            type="SAVE"
            onClick={handleSaveLink}
            disabled={!isFormValid.result}
            style={{ margin: '5px auto 0 auto' }}
          />
        </MapLinkStyle>
        {/* <Modal show={showModal}>
          <>
            <ModalHeader
              onClose={() => {
                setShowModal(false);
              }}
            >
              <div>{t('searchResults')}</div>
            </ModalHeader>
            {searchResults?.map((result: any) => (
              <div>{result?.formatted_address}</div>
            ))}
          </>
          <WhiteButton
            type="Cancel"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Modal> */}
      </>
    );
  },
);

const MapLinkStyle = styled.div`
  border: 1px solid #dddddd;
  padding: 6px 6px 6px 6px;
  border-radius: 6px;
`;

const GoogleMapContainerStyle = styled.div`
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 0 10px 0;
  display: flex;
  justify-content: center;
  > div {
    width: 500px;
  }
`;

const InputStyle = styled.div`
  display: flex;
  width: 100%;
  > div:first-child {
    margin: 0 0 10px 0;
  }
  > div > div > input {
    height: 40px !important;
  }
`;

const SearchButtonStyle = styled.div`
  background: #636363;
  border-radius: 6px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 10px;
  cursor: pointer;
`;

const SearchImageStyle = styled.img`
  width: 22px;
  height: 22px;
`;

const SearchResultStyle = styled.div<{ selected: boolean }>`
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: ${(props) => (props.selected ? '1px solid #bb00ff' : '1px solid #f2f2f2')};
  background: #fcfcfc;
`;
export default MapLinkEditor;
