import React from 'react';
import styled, { css } from 'styled-components';

type DefaultProps = {
  item: ToggleButtonType;
  width?: number;
  defaultChecked?: boolean;
};

type ToggleButtonType = {
  description: string;
  id: string;
  iconSrc: string;
  checked?: string;
  exclusive?: boolean;
};

const ToggleButton = ({ item, defaultChecked }: DefaultProps) => {
  return (
    <>
      <ToggleButtonStyle id={item.id} onClick={() => {}} />
      <ToggleButtonLabel on={defaultChecked!} htmlFor={item.id}>
        <IconStyle src={item.iconSrc} />
        <TitleStyle>{item.description}</TitleStyle>
      </ToggleButtonLabel>
    </>
  );
};

const ToggleButtonStyle = styled.div`
  width: 150px;
  height: 100%;
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const ToggleOrigin = css`
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 100px;
  height: 57px;
  border: 1px solid #f4f4f4;
  border-radius: 20px;
  > div {
    color: #999;
  }
  > img {
    opacity: 0.3;
  }
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;
const ToggleChecked = css`
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 6px solid #80e630;
  min-width: 120px;
  height: 51px;
  transition: 0.4s;
  transition-timing-function: ease;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  > div {
    color: #111;
  }
  > img {
    opacity: 1;
  }
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

// label, 스타일 수정을 위한 스타일 컴포넌트
const ToggleButtonLabel = styled.label<{ on: boolean }>`
  ${(props) => (props.on ? ToggleChecked : ToggleOrigin)}
`;

const IconStyle = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 100px;
  margin: 0 8px 0 0;
  transition: 0.2s;
  transition-timing-function: ease;
`;

const TitleStyle = styled.div`
  font-size: 16px;
  font-family: 'Raleway';
  transition: 0.2s;
  transition-timing-function: ease;
`;

export { ToggleButton, ToggleButtonType };
