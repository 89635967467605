/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState, useCallback, UIEvent, useLayoutEffect } from 'react';
import { CustomUserProfile, CustomType, LinkModel, CustomFile } from 'types/CommonTypes';
import { saveProfile } from 'features/adminFeatures/profile/logic/ProfileLogic';
import { SocialComponent } from 'features/adminFeatures/template/components';
import { ImageUploader } from 'components/commons';
import { FileDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import BackgroundImageComponent from 'features/adminFeatures/template/components/BackgroundImageComponent';
import BackgroundContainer from 'features/adminFeatures/background/containers/BackgroundContainer';
import TemplateEditorSwipe from 'features/adminFeatures/template/components/TemplateEditorSwipe';
import ProfileEditorSwipe from 'features/adminFeatures/profile/components/ProfileEditorSwipe';
import LinkCreateContainer from 'features/adminFeatures/link/containers/LinkCreateContainer';
import ProfileContainer from 'features/adminFeatures/profile/containers/ProfileContainer';
import LinksComponent from 'features/adminFeatures/template/components/LinksComponent';
import Controller from 'features/adminFeatures/commons/components/Controller';
import Avatar from 'components/commons/Avatar';
import useScreenType from 'libs/hooks/useScreenType';
import { t } from 'i18next';
import { ProTitle, ItemType } from '../components/ProTitle';

type SizeProps = {
  width: number;
  height: number;
};

type IntroProps = 'step1' | 'step2' | 'step3' | undefined;

type ControllerTypeProps = 'Background' | 'Profile';

const Editor = observer(() => {
  const { templateStore, uiStore, meStore, fileStore, linkStore, simulatorStore, userStore } =
    useStore();
  const screenType = useScreenType();
  /** ref */
  const editorRef = useRef<HTMLDivElement>(null);
  const profileButtonRef = useRef<HTMLDivElement>(null);
  const profileTitleRef = useRef<HTMLDivElement>(null);
  const profileIntroRef = useRef<HTMLDivElement>(null);
  const profileSubIntroRef = useRef<HTMLDivElement>(null);
  const backgroundButtonRef = useRef<HTMLDivElement>(null);
  const linkButtonRef = useRef<HTMLDivElement>(null);

  /** useState */
  const [style, setStyle] = useState<CustomType>({});
  const [useBackground, setUseBackground] = useState<boolean>();
  const [containerHover, setContainerHover] = useState<boolean>(false);
  const [didScroll, setDidScroll] = useState<boolean>(false);
  const [profileModel, setProfileModel] = useState<CustomUserProfile>();
  const [templateModel, setTemplateModel] = useState<any>();
  const [controllerType, setControllerType] = useState<ControllerTypeProps>('Background');
  const [isShowLinkDrawer, setIsShowLinkDrawer] = useState<boolean>(false);
  const [isShowBackgroundSwipe, setIsShowBackgroundSwipe] = useState<boolean>(false);
  const [isShowProfileSwipe, setIsShowProfileSwipe] = useState<boolean>(false);
  const [backgroundSwipeIndex, setBackgroundSwipeIndex] = useState<number>(0);
  const [profileSwipeIndex, setProfileSwipeIndex] = useState<number>(0);
  const [onIntro, setOnIntro] = useState<IntroProps>(undefined);
  const [noBgInfo, setNoBgInfo] = useState<boolean>(false);

  /** editor 기기 사이즈 */
  const deviceSize: SizeProps = { width: 340, height: 600 };

  /** profile 사이즈 & profile swipe 사이즈 */
  const profileSize: SizeProps = { width: 100, height: 100 };

  const init = async () => {
    const me: any = await meStore.getMe();
    if (typeof me !== 'undefined') {
      linkStore.getLinks();
      const editorIntro = localStorage.getItem('editorIntro');
      // await templateStore.setUserInfo(me);
      await simulatorStore.updateUserInfo(me);

      /** 유저 데이터가 없다면 intro 효과 시작 */
      if (
        typeof simulatorStore?.profile?.title === 'undefined' &&
        typeof simulatorStore?.profile?.selfIntroduction === 'undefined' &&
        typeof simulatorStore?.profile?.profileImage?.publicUrl === 'undefined' &&
        editorIntro === null
      ) {
        localStorage.setItem('editorIntro', 'on');
        setOnIntro('step1');
      } else {
        localStorage.setItem('editorIntro', 'off');
      }
    }
    if (
      typeof meStore.me?.profile?.backgroundImage === 'undefined' &&
      typeof meStore.me?.profile?.backgroundColor === 'undefined' &&
      meStore.me?.layoutStyle?.name === 'default'
    ) {
      setNoBgInfo(true);
    } else {
      setNoBgInfo(false);
    }
  };

  /** intro 효과 step별 텍스트처리 */
  const getStepTitle = useCallback(
    (target: 'step1' | 'step2' | 'step3' | undefined) => {
      switch (target) {
        case 'step1':
          return '배경 설정';
        case 'step2':
          return '프로필 설정';
        case 'step3':
          return '링크 설정';
        default:
          break;
      }
    },
    [onIntro],
  );

  /** intro 효과 step별 텍스트처리 */
  const getStepContent = useCallback(
    (target: 'step1' | 'step2' | 'step3' | undefined) => {
      switch (target) {
        case 'step1':
          return `프로필 사진과 어울리는\n배경이미지를 설정해주세요`;
        case 'step2':
          return '프로필 사진과 간단한\n소개글을 작성해주세요';
        case 'step3':
          return '마지막으로 원하시는\n다양한 링크를 추가해보세요';
        default:
          break;
      }
    },
    [onIntro],
  );

  const handleSaveProfile = async (thumbnail: CustomFile) => {
    uiStore.progress.show();
    simulatorStore.updateProfile({
      ...simulatorStore?.profile,
      profileImage: thumbnail,
    });

    await saveProfile({ ...profileModel, profileImage: thumbnail }, meStore, fileStore);
    uiStore.progress.close();
  };

  const handleMouseOver = () => {
    if (!isShowBackgroundSwipe) {
      setContainerHover(true);
    }
  };

  const handleMouseOut = () => {
    if (!isShowBackgroundSwipe) {
      setContainerHover(false);
    }
  };

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const currentScroll = e.currentTarget.scrollTop;

    /** 스크롤이 0이 아니라면 프로필 스와이프를 안보이게 처리 */
    if (currentScroll !== 0) {
      setIsShowProfileSwipe(false);
    }

    if (currentScroll > 50) {
      setDidScroll(true);
      setContainerHover(false);
    } else if (currentScroll < 50) {
      setDidScroll(false);
    }
  };

  /** controller의 글자를 클릭시 drawer 출현 이벤트 */
  const handleClickType = useCallback(() => {
    if (isShowBackgroundSwipe) {
      setIsShowBackgroundSwipe(false);
    }

    if (isShowProfileSwipe) {
      setIsShowProfileSwipe(false);
    }

    if (uiStore.drawer.drawerShow) {
      uiStore.drawer.close();
    } else {
      uiStore.drawer.show({
        title: controllerType,
        onCancel: () => init(),
        onConfirmed: () => {
          init();
          uiStore.drawer.close();
        },
        children:
          controllerType === 'Background' ? (
            <BackgroundContainer />
          ) : (
            <ProfileContainer onShowSimulator={() => {}} />
          ),
      });
    }
  }, [profileModel, controllerType, isShowBackgroundSwipe]);

  /** background 추가 버튼 이벤트 */
  const handleClickBgButton = () => {
    if (isShowProfileSwipe || isShowLinkDrawer) return;

    if (isShowBackgroundSwipe) {
      uiStore.drawer.close();
      init();
    }

    /** active 인덱스 초기화 */
    // setBackgroundSwipeIndex(0);
    setControllerType('Background');

    setIsShowBackgroundSwipe(!isShowBackgroundSwipe);
  };

  /** profile 추가 버튼 이벤트 */
  const handleClickProfile = () => {
    if (typeof profileModel?.profileImage?.publicUrl === 'undefined') {
      return;
    }
    if (isShowBackgroundSwipe || isShowLinkDrawer) return;

    /** 프로필 버튼을 눌렀을때, 스크롤 최상위로 이동시킴 (프로필 스와이프 레이아웃 이슈때문에) */
    if (editorRef.current?.scrollTop !== 0) {
      editorRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (isShowProfileSwipe) {
      uiStore.drawer.close();
      init();
    }
    /** active 인덱스 초기화 */
    setProfileSwipeIndex(0);
    setControllerType('Profile');

    setIsShowProfileSwipe(true);
  };

  /** link 추가 버튼 이벤트 */
  const handleClickLink = (link?: LinkModel) => {
    if (isShowBackgroundSwipe || isShowProfileSwipe) return;

    setIsShowLinkDrawer(true);
    uiStore.drawer.show({
      title: <ProTitle />,
      width: 550,
      onCancel: () => setIsShowLinkDrawer(false),
      onConfirmed: () => init(),
      children: (
        <>
          <LinkCreateContainer
            link={link}
            onSuccess={() => {}}
            onClose={() => uiStore.drawer.close()}
          />
        </>
      ),
    });
  };

  const onSlideChange = (type: ControllerTypeProps, activeIndex: number) => {
    if (type === 'Background') {
      setBackgroundSwipeIndex(activeIndex);
    } else if (type === 'Profile') {
      setProfileSwipeIndex(activeIndex);
    }
  };

  /** 스와이프 안의 이미지 클릭 이벤트 (이미지 적용) */
  const handleApplyProfile = (image: FileDto) => {
    setIsShowProfileSwipe(false);
    setProfileSwipeIndex(0);

    /** 임시로 더미데이터를 넣는다 (api나오면 수정할 것) */
    setProfileModel((prev) => {
      return { ...prev, profileImage: { ...prev?.profileImage, publicUrl: image?.publicUrl } };
    });

    /** 종료시킬때, background도 활성화라면 다시 background로 전환 */
    if (isShowBackgroundSwipe) {
      setControllerType('Background');
    }

    /** 기존 저장한 이미지랑 선택한 이미지가 다르다면 */
    if (profileModel?.profileImage?.id !== image?.id) {
      /** 히스토리 이미지 클릭시 해당 이미지로 프로필 저장 */
      handleSaveProfile(image);
    }
  };

  /** 백그라운드 히스토리 클릭 이벤트 */
  const handleApplyBackground = async (image: FileDto) => {
    setIsShowBackgroundSwipe(false);
    setTemplateModel((prev: any) => {
      return { ...prev, uri: image?.publicUrl };
    });

    /** 기존 이미지와 다르면, 배경 히스토리 클릭시 백그라운드 저장 */
    if (image?.id !== profileModel?.backgroundImage?.id) {
      const newProfileModel: CustomUserProfile = { ...profileModel };
      newProfileModel.backgroundImage = { id: image.id };
      await meStore.saveMyProfile(newProfileModel);
      await meStore.saveMyLayout({ template: { id: 561 } });
      // templateStore.setUserInfo({
      //   ...templateStore.userInfo,
      //   profile: { ...newProfileModel, backgroundImage: image },
      // });

      simulatorStore.updateProfile({ ...newProfileModel, backgroundImage: image });
    }
  };

  useLayoutEffect(() => {
    if (typeof simulatorStore !== 'undefined') {
      simulatorStore.setSimulatorMode(true);
    }
  }, [simulatorStore]);

  useLayoutEffect(() => {
    init();
  }, []);

  /** background 스와이프 여부에 따라서 기존 배경 데이터 초기화 & 다시 불러옴 로직 */
  useEffect(() => {
    if (isShowBackgroundSwipe) {
      setStyle({});
    } else {
      if (typeof templateStore.currentTemplate?.style === 'undefined') return;
      const styleValue = JSON.parse(templateStore.currentTemplate?.style);
      setStyle(styleValue);
    }
  }, [isShowBackgroundSwipe]);

  /** 테마를 보여줄건지, 배경색 or 배경이미지를 보여줄건지 판별 */
  const applyUserStyle = () => {
    if (typeof simulatorStore?.layoutStyle?.style !== 'undefined') {
      const styleValue = JSON.parse(simulatorStore?.layoutStyle?.style);
      if (
        typeof styleValue?.useBackgroundImage === 'undefined' ||
        styleValue.useBackgroundImage === 'true'
      ) {
        setUseBackground(true);
      } else {
        setUseBackground(false);
      }
      setStyle(styleValue);
    } else {
      setUseBackground(true);
      setStyle({});
    }
  };

  useLayoutEffect(() => {
    if (
      typeof simulatorStore?.profile?.backgroundImage === 'undefined' &&
      typeof simulatorStore?.profile?.backgroundColor === 'undefined'
    ) {
      setNoBgInfo(true);
    } else {
      setNoBgInfo(false);
    }
  }, [simulatorStore?.profile]);

  useEffect(() => {
    if (typeof simulatorStore.profile !== 'undefined') {
      setProfileModel(simulatorStore?.profile);
      applyUserStyle();
    }
    if (simulatorStore.profile?.template?.name !== 'default') {
      setNoBgInfo(false);
    } else if (simulatorStore.profile?.template?.name === 'default') {
      setNoBgInfo(true);
    }
  }, [simulatorStore.profile]);

  useEffect(() => {
    applyUserStyle();
  }, [simulatorStore.layoutStyle]);

  /** controllerType이 변경될때 감지해서 drawer 자식 컴포넌트 변경 */
  useEffect(() => {
    if (uiStore.drawer.drawerShow) {
      uiStore.drawer.show({
        title: controllerType,
        onCancel: () => init(),
        onConfirmed: () => {
          init();
          uiStore.drawer.close();
        },
        children:
          controllerType === 'Background' ? (
            <BackgroundContainer />
          ) : (
            <ProfileContainer onShowSimulator={() => {}} />
          ),
      });
    }
  }, [controllerType]);

  useEffect(() => {
    if (typeof meStore.me !== 'undefined') userStore.getUserInfo(meStore?.me?.userName!);
  }, [meStore.me]);

  return (
    <>
      <ContainerStyle size={deviceSize}>
        <IntroNextButtonStyle onIntro={onIntro}>
          <div>
            <IntroTriangle onIntro={onIntro} />
            <IntroTitleStyle>{getStepTitle(onIntro)}</IntroTitleStyle>
            <IntroContentStyle>{getStepContent(onIntro)}</IntroContentStyle>
          </div>
          <ArrowIconStyle
            src={'/image/arrow-right.svg'}
            onClick={() => {
              if (onIntro === 'step1') {
                setOnIntro('step2');
              } else if (onIntro === 'step2') {
                setOnIntro('step3');
              } else {
                setOnIntro(undefined);
              }
            }}
          />
        </IntroNextButtonStyle>
        <BackgroundButtonStyle
          ref={backgroundButtonRef}
          onIntro={onIntro}
          isShowBackgroundSwipe={isShowBackgroundSwipe}
          disabled={isShowProfileSwipe || isShowLinkDrawer}
          onClick={(event) => {
            if (typeof onIntro !== 'undefined') {
              return event.stopPropagation();
            }
            handleClickBgButton();
          }}
        >
          {isShowBackgroundSwipe ? (
            <AddIconStyle size={13} src="/image/close.png" />
          ) : (
            <AddIconStyle size={13} src="/image/plus.png" opacity={1} />
          )}
        </BackgroundButtonStyle>
        <EditorContainerStyle
          size={deviceSize}
          onIntro={onIntro}
          isShowProfileSwipe={isShowProfileSwipe}
          isShowBackgroundSwipe={isShowBackgroundSwipe}
          onScroll={(event) => {
            if (typeof onIntro !== 'undefined') {
              return event.stopPropagation();
            }
          }}
        >
          <TemplateContainerStyle
            id="container"
            containerHover={containerHover}
            customStyle={style}
            didScroll={didScroll}
            noBgInfo={noBgInfo}
          >
            <div
              ref={editorRef}
              id="content"
              className="content"
              style={{ height: '100%' }}
              onScroll={handleScroll}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              <ShapeContainerStyle
                customStyle={style}
                containerHover={containerHover}
                didScroll={didScroll}
              >
                <ShapeStyle customStyle={style} containerHover={containerHover} />
              </ShapeContainerStyle>
              <ProfileContainerStyle
                onIntro={onIntro}
                isData={typeof profileModel?.title !== 'undefined'}
                disabled={
                  isShowBackgroundSwipe ||
                  isShowLinkDrawer ||
                  isShowProfileSwipe ||
                  uiStore.drawer.drawerShow
                }
                isBlur={isShowProfileSwipe}
                onClick={(event) => {
                  if (typeof onIntro !== 'undefined') {
                    return event.stopPropagation();
                  }
                  handleClickProfile();
                }}
              >
                <ProfileImageContainerStyle ref={profileButtonRef} size={profileSize}>
                  {simulatorStore?.profile?.profileImage?.publicUrl ? (
                    <AvatarStyle>
                      <Avatar
                        profile={simulatorStore.profile}
                        size={screenType === 'mobile' ? 'large' : 'default'}
                      />
                    </AvatarStyle>
                  ) : (
                    <ImageUploader
                      attachType="image"
                      cropSize={{ width: 600, height: 600 }}
                      onSelected={(thumbnails: any) => {
                        setProfileModel((prev) => {
                          return {
                            ...prev,
                            profileImage: thumbnails[0],
                          };
                        });
                        if (typeof profileModel !== 'undefined') {
                          handleSaveProfile(thumbnails[0]);
                        }
                      }}
                    >
                      <AddIconStyle size={25} src="/image/plus.png" />
                    </ImageUploader>
                  )}
                </ProfileImageContainerStyle>
                <ProfileTitleStyle
                  ref={profileTitleRef}
                  isData={typeof profileModel?.title !== 'undefined'}
                  style={{ ...profileModel?.titleProps?.style }}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  {typeof profileModel?.title !== 'undefined'
                    ? profileModel?.title
                    : t('profileIntroduction')}
                </ProfileTitleStyle>
                <ProfileTextStyle
                  ref={profileIntroRef}
                  isData={typeof profileModel?.selfIntroduction !== 'undefined'}
                  style={{ ...profileModel?.selfIntroductionProps?.style }}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  onClick={() => {
                    setControllerType('Profile');
                    handleClickType();
                  }}
                >
                  {typeof profileModel?.selfIntroduction !== 'undefined'
                    ? profileModel?.selfIntroduction
                    : t('profileInform1')}
                </ProfileTextStyle>
                {typeof profileModel?.selfIntroduction === 'undefined' && (
                  <ProfileTextStyle
                    onClick={() => {
                      setControllerType('Profile');
                      handleClickType();
                    }}
                    ref={profileSubIntroRef}
                    isData={typeof profileModel?.selfIntroduction !== 'undefined'}
                  >
                    {t('profileInform2')}
                  </ProfileTextStyle>
                )}
                <SocialComponent socials={simulatorStore?.socialLinks} />
              </ProfileContainerStyle>
              {isShowProfileSwipe === false ? (
                <LinkContainerStyle>
                  <LinkButtonStyle
                    onIntro={onIntro}
                    ref={linkButtonRef}
                    isData={typeof simulatorStore.links !== 'undefined'}
                    onClick={(event) => {
                      if (typeof onIntro !== 'undefined') {
                        return event.stopPropagation();
                      }
                      handleClickLink();
                    }}
                  >
                    <AddIconStyle size={18} src="/image/plus.png" />
                  </LinkButtonStyle>

                  <LinkStyle customStyle={style}>
                    {/* <LinksComponent links={templateStore.userInfo?.links} /> */}
                    <LinksComponent links={userStore?.links} />
                  </LinkStyle>
                </LinkContainerStyle>
              ) : (
                <CloseProfileModeStyle
                  onClick={() => {
                    setIsShowProfileSwipe(false);
                  }}
                >
                  <AddIconStyle size={13} src="/image/close.png" />
                </CloseProfileModeStyle>
              )}
              {typeof profileModel?.footerTitle !== 'undefined' && (
                <FooterTitleStyle
                  isShowProfileSwipe={isShowProfileSwipe}
                  style={{ ...profileModel?.footerProps?.style }}
                >
                  {typeof profileModel?.footerImage !== 'undefined' && (
                    <FooterLogoImageStyle
                      noTitle={false}
                      src={profileModel?.footerImage?.publicUrl}
                      alt=""
                    />
                  )}
                  {profileModel?.footerTitle}
                </FooterTitleStyle>
              )}
              {/* api 구현되면 templateModel 조건부는 빼기 */}
              {useBackground && !isShowBackgroundSwipe && (
                <BackgroundImageComponent
                  useEmpty={false}
                  style={{ zIndex: -1 }}
                  // profile={templateStore.userInfo?.profile}
                  profile={simulatorStore?.profile}
                />
              )}
              {/* templateModel 더미 데이터 적용되는 모습 확인용 */}
              {!isShowBackgroundSwipe && templateModel?.uri && (
                <BackgroundImgContainerStyle size={deviceSize}>
                  <BackgroundImgStyle src={templateModel?.uri} />
                </BackgroundImgContainerStyle>
              )}
            </div>
          </TemplateContainerStyle>
        </EditorContainerStyle>

        {/* Controller */}
        <Controller
          type={controllerType}
          maxRange={
            controllerType === 'Background'
              ? meStore.backgroundImages.length - 1
              : meStore.profileImages.length - 1
          }
          width={deviceSize.width}
          range={controllerType === 'Background' ? backgroundSwipeIndex : profileSwipeIndex}
          isVisible={isShowBackgroundSwipe || isShowProfileSwipe}
          onControlChange={(range) => onSlideChange(controllerType, range)}
          onClickType={handleClickType}
        />
      </ContainerStyle>

      {/* profile 스와이프 컴포넌트 */}
      {isShowProfileSwipe && (
        <ProfileEditorSwipe
          width={profileSize.width}
          height={profileSize.height}
          index={profileSwipeIndex}
          onApplyProfile={(image: FileDto) => handleApplyProfile(image)}
          onSlideChange={(index) => onSlideChange('Profile', index)}
          onChangeControlType={() => setControllerType('Profile')}
          onProfileBinding={(thumbnail: CustomFile) => {
            setProfileModel((prev) => {
              return {
                ...prev,
                profileImage: thumbnail,
              };
            });
            if (typeof profileModel !== 'undefined') {
              handleSaveProfile(thumbnail);
            }
            setIsShowProfileSwipe(false);
          }}
        />
      )}

      {/* background 스와이프 컴포넌트 */}
      {isShowBackgroundSwipe && (
        <TemplateEditorSwipe
          index={backgroundSwipeIndex}
          width={deviceSize.width + 35}
          height={deviceSize.height + 9}
          onChangeControlType={() => setControllerType('Background')}
          onApply={(image: FileDto) => handleApplyBackground(image)}
          onSlideChange={(index) => onSlideChange('Background', index)}
        />
      )}
    </>
  );
});

const displayAlignCenter = css`
  display: flex;
  align-items: center;
`;

const ContainerStyle = styled.div<{ size: SizeProps }>`
  width: ${(props) => props.size.width}px;
  height: ${(props) => props.size.height}px;
  position: relative;

  @media ${(props) => props.theme.media.mobile} {
    width: calc(100% - 60px);
    height: calc(100% - 120px);
  }
`;

const BackgroundButtonStyle = styled.div<{
  isShowBackgroundSwipe: boolean;
  disabled: boolean;
  onIntro: IntroProps;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: ${(props) => (props.isShowBackgroundSwipe ? '#fff' : '#3c3d3e')};
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  position: absolute;
  z-index: 13;
  top: 0;
  left: 50%;
  transform: translate(-50%, -105%);
  ${(props) => !props.disabled && `cursor: pointer`};
  &:hover {
    ${(props) => !props.disabled && `background: white`};
  }
  &:hover + div {
    ${(props) => !props.disabled && `border: 8px solid white`};
    ${(props) => !props.disabled && `transition: all 0.5s ease-in-out`};
  }

  ${(props) => props.onIntro === 'step1' && `background: #fff`};
`;

const EditorContainerStyle = styled.div<{
  size: SizeProps;
  onIntro: IntroProps;
  isShowProfileSwipe: boolean;
  isShowBackgroundSwipe: boolean;
}>`
  width: ${(props) => props.size.width}px;
  height: ${(props) => props.size.height}px;
  border-radius: 40px;
  border: 8px solid ${(props) => (props.isShowBackgroundSwipe ? '#fff' : '#3c3d3e')};
  background-color: ${(props) => (props?.isShowBackgroundSwipe ? 'transparent' : 'black')};
  scroll-behavior: smooth;
  transition: all 0.5s ease-in-out;
  overflow-y: hidden;
  overflow-x: hidden;
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: ${(props) => (props.isShowBackgroundSwipe ? 'none' : 'auto')};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);

  > div {
    opacity: ${(props) => (props.isShowBackgroundSwipe ? 0.1 : 1)};
  }
  > div:first-child {
    ${(props) => props.isShowProfileSwipe && `opacity: 1`};
  }
  > div:last-child {
    ${(props) => props.isShowProfileSwipe && `opacity: 1`};
  }
  > div > div {
    ${(props) => props.isShowProfileSwipe && `background: transparent`};
    transition: all 0.5s ease-in-out;
  }

  ${(props) => props.onIntro === 'step1' && `border: 8px solid white`};
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    height: 100%;
    top: 50%;
  }
`;

const ProfileContainerStyle = styled.div<{
  disabled: boolean;
  isData: boolean;
  onIntro: IntroProps;
  isBlur: boolean;
}>`
  ${displayAlignCenter}
  flex-direction: column;
  width: 100%;
  margin: 50px 0 0 0;
  transition: all 0.5s ease-in-out;
  ${(props) => props.disabled && `pointer-events: none`};
  cursor: pointer;
  > div {
    margin-bottom: 5px;
  }
  > div:first-child {
    margin-bottom: 20px;
  }
  > div:nth-child(2) {
    ${(props) => props.isBlur && 'opacity: 0.3'};
  }
  > div:nth-child(3) {
    ${(props) => props.isBlur && 'opacity: 0.3'};
  }
  > div:nth-child(4) {
    ${(props) => props.isBlur && 'opacity: 0.3'};
  }
  &:hover > div:nth-child(1) {
    ${(props) =>
      !props.disabled && (props.isData ? `background: rgba(255,255,255,0.5)` : `background: white`)}
  }
  &:hover > div:nth-child(2) {
    ${(props) =>
      !props.disabled && (props.isData ? `background: rgba(255,255,255,0.5)` : `background: white`)}
  }
  &:hover > div:nth-child(3) {
    ${(props) =>
      !props.disabled && (props.isData ? `background: rgba(255,255,255,0.5)` : `background: white`)}
  }
  &:hover > div:nth-child(4) {
    ${(props) =>
      !props.disabled && (props.isData ? `background: rgba(255,255,255,0.5)` : `background: white`)}
  }

  > div {
    ${(props) => props.onIntro === 'step2' && `background: #fff`};
  }

  &:hover {
    > div {
      transition: all 0.5s ease-in-out;
      color: #3c3d3e;
    }
  }
`;

const ProfileImageContainerStyle = styled.div<{ size: SizeProps }>`
  background: rgba(255, 255, 255, 0.5);
  width: ${(props) => props.size.width}px;
  height: ${(props) => props.size.height}px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto 20px auto;
  ${displayAlignCenter}
  justify-content: center;
`;

const ProfileTitleStyle = styled.div<{ isData: boolean }>`
  flex: 1;
  ${(props) => props.isData && `width: calc(100% - 30px)`};
  justify-content: center;
  height: 28px;
  /* color: transparent; */
  font-weight: 500;
  text-align: center;
  background: ${(props) => (props.isData ? 'transparent' : 'rgba(255, 255, 255, 0.5)')};
  border-radius: 30px;
  padding: 0 8px 0 8px;
  cursor: pointer;
`;

const ProfileTextStyle = styled.div<{ isData: boolean }>`
  flex: 1;
  ${(props) => props.isData && `width: calc(100% - 30px)`};
  height: 26px;
  justify-content: center;
  /* color: transparent; */
  text-align: center;
  font-size: 13px;
  background: ${(props) => (props.isData ? 'transparent' : 'rgba(255, 255, 255, 0.5)')};
  border-radius: 30px;
  white-space: pre-line;
  padding: 2px 5px 2px 5px;
  margin: ${(props) => (props.isData ? '10px 0 0 0' : '3px 0 0 0')};
  cursor: pointer;
`;

const LinkContainerStyle = styled.div`
  position: relative;
  > div:first-child {
    margin-top: 20px;
  }
`;

const LinkButtonStyle = styled.div<{ isData: boolean; onIntro: IntroProps }>`
  /* background: ${(props) => (props.isData ? '#fff' : 'rgba(255, 255, 255, 0.5)')}; */
  background: rgb(231, 231, 231);
  background: linear-gradient(
    rgba(231, 231, 231, 1) 0%,
    rgba(201, 201, 201, 1) 99%,
    rgba(231, 231, 231, 1) 100%
  );
  width: 90%;
  height: 50px;
  border-radius: 26px;
  margin: 40px auto 20px auto;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  ${displayAlignCenter}
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  &:hover {
    transition: all 0.3s ease-in-out;
    background: rgba(255, 255, 255, 0.5);
  }

  ${(props) => props.onIntro === 'step3' && `background: #fff`};
`;

const AddIconStyle = styled.img<{ size: number; opacity?: number }>`
  opacity: ${(props) => props.opacity || 0.3};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const FollowListStyle = styled.div<{ customStyle?: CustomType }>`
  margin: 0 auto;
  > div {
    margin: 0 auto !important;
    ${(props) => props.customStyle?.linkBoxStyle}
  }
`;

const CloseContainerStyle = styled.div`
  max-width: 550px;
  padding: 0 20px 0 20px;
  margin: auto;
`;

const CloseIconStyle = styled.div`
  width: 100px;
  height: 40px;
  border-radius: 60px;
  background: #fff;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
`;

const CloseTitleStyle = styled.div`
  margin: 0 10px 0 0;
`;

const LinkStyle = styled.div<{ customStyle?: CustomType }>`
  > div > div > div {
    ${(props) => props.customStyle?.linkBoxStyle}
    &:before {
      ${(props) => props.customStyle?.linkBoxBefore}
    }
    &:hover {
      ${(props) => props.customStyle?.linkBoxHoverStyle}
      background: 'initial';
    }
  }
  > div > div:nth-child(4n + 1) > div {
    ${(props) => props.customStyle?.linkBoxStyle1}
  }
  > div > div:nth-child(4n + 2) > div {
    ${(props) => props.customStyle?.linkBoxStyle2}
  }
  > div > div:nth-child(4n + 3) > div {
    ${(props) => props.customStyle?.linkBoxStyle3}
  }
  > div > div:nth-child(4n + 4) > div {
    ${(props) => props.customStyle?.linkBoxStyle4}
  }
  > div > div > div > a,
  > div > div > div > a > div {
    ${(props) => props.customStyle?.linkTextStyle}
  }
  > div > div > div > a > div > div {
    ${(props) => props.customStyle?.boxShadow}
  }
`;

const FooterTitleStyle = styled.div<{ isShowProfileSwipe: boolean }>`
  padding: 0 15px 0 15px;
  margin: 20px 0 20px 0;
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  > img {
    margin: 0 auto 10px;
  }
`;

const FooterLogoImageStyle = styled.img<{ noTitle: boolean }>`
  width: 80px;
  text-align: center;
  border-radius: 30px;
`;

const BackgroundImgContainerStyle = styled.div<{ size: SizeProps }>`
  width: ${(props) => props.size.width + 2}px;
  height: ${(props) => props.size.height + 2}px;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    height: 100%;
  }
`;

const BackgroundImgStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TemplateContainerStyle = styled.div<{
  customStyle: CustomType;
  containerHover: boolean;
  didScroll: boolean;
  noBgInfo?: boolean;
}>`
  position: fixed;
  z-index: -1;
  ${(props) => props.customStyle.container}
  ${(props) => (props.containerHover ? '' : props.customStyle.containerHoverStyle)}
  transition: ${(props) => (props.containerHover ? 'all 0.5s ease-in' : 'all 0.5s ease-in')};
  background-color: ${(props) =>
    props.containerHover || props.didScroll
      ? props.customStyle.shape?.background || props.customStyle.shape?.color
      : props.customStyle.container?.background};
  background-size: cover;
  background-position: center;
  background-image: ${(props) =>
    props.noBgInfo ? `url('/images/background/background_editor.png')` : 'none'};
`;

const ShapeStyle = styled.div<{
  customStyle: CustomType;
  containerHover?: boolean;
}>`
  ${(props) => props.customStyle.shape}
  &::before,
  &::after {
    ${(props) => props.customStyle.shapeBeforeAfter}
    width: ${(props) => props.customStyle.bigShape?.width}px;
    height: ${(props) => props.customStyle.bigShape?.height}px;
  }
  &::before {
    ${(props) => props.customStyle.shapeBefore}
  }
  &::after {
    ${(props) => props.customStyle.shapeAfter}
  }
  ${(props) => (props.containerHover ? props.customStyle.shapeHover : '')}
  width: ${(props) => props.customStyle.bigShape?.width}px;
  height: ${(props) => props.customStyle.bigShape?.height}px;
  ${(props) => props.customStyle.myPageShape}
  @media screen and (max-width: 650px) {
    ${(props) => props.customStyle.middleShape}
    &:before,
    &:after {
      ${(props) => props.customStyle.middleShape}
    }
  }
  @media screen and (max-width: 400px) {
    ${(props) => props.customStyle.smallShape}
    &:before,
    &:after {
      ${(props) => props.customStyle.smallShape}
    }
  }
`;

const ShapeContainerStyle = styled.div<{
  customStyle: CustomType;
  containerHover: boolean;
  didScroll: boolean;
}>`
  height: 0;
  display: flex;
  position: fixed;
  z-index: -1;
  ${(props) => props.customStyle.shapeContainer};
  ${(props) =>
    props.didScroll || props.containerHover
      ? props.customStyle.downScroll || props.customStyle.shapeContainerHover
      : props.customStyle.upScroll}
  ${(props) => (props.containerHover === true ? props.customStyle.shapeContainerHover : '')}
  ${(props) => props.customStyle.myPageShapeContainer}
`;

const IntroNextButtonStyle = styled.div<{ onIntro: IntroProps }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  width: 215px;
  height: 105px;
  background-color: #fff;
  border-radius: 14px;
  padding: 0 20px 0 20px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 15;
  opacity: ${(props) => (typeof props.onIntro === 'undefined' ? 0 : 1)};
  ${(props) => typeof props.onIntro === 'undefined' && `display: none`};
  ${(props) => props.onIntro === 'step1' && `transform: translate(20%, -50%)`};
  ${(props) => props.onIntro === 'step2' && `transform: translate(-50%, -70%)`};
  ${(props) => props.onIntro === 'step3' && `transform: translate(-50%, 340%)`};
`;

const IntroTriangle = styled.div<{ onIntro: IntroProps }>`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  z-index: 15;
  ${(props) => props.onIntro === 'step1' && `top: 15px`};
  ${(props) => props.onIntro === 'step1' && `left: -22px`};
  ${(props) => props.onIntro === 'step1' && `border-right: 12px solid white`};

  ${(props) => props.onIntro === 'step2' && `bottom: -22px`};
  ${(props) => props.onIntro === 'step2' && `left: 50%`};
  ${(props) => props.onIntro === 'step2' && `transform: translate(-50%, -0%)`};
  ${(props) => props.onIntro === 'step2' && `border-top: 12px solid white`};

  ${(props) => props.onIntro === 'step3' && `top: -22px`};
  ${(props) => props.onIntro === 'step3' && `left: 50%`};
  ${(props) => props.onIntro === 'step3' && `transform: translate(-50%, -0%)`};
  ${(props) => props.onIntro === 'step3' && `border-bottom: 12px solid white`};
`;

const IntroTitleStyle = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 10px 0;
`;

const IntroContentStyle = styled.div`
  width: 100%;
  font-size: 15px;
  font-weight: 300;
  white-space: pre-wrap;
`;

const ArrowIconStyle = styled.img`
  width: 24px;
  height: 24px;
  margin: 0 0 0 15px;
  cursor: pointer;
`;

const CloseProfileModeStyle = styled.div`
  background: #fff;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -150%);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
`;

const AvatarStyle = styled.div`
  > div {
    margin: 0;
    > div {
      margin: 0;
    }
  }
`;

export default Editor;
