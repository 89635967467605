import React from 'react';
import Pagination from '@mui/material/Pagination';

const PaginationComponent = ({ handleClickPageNumber, pagination }: any) => {
  return (
    <div style={{ margin: 'auto' }}>
      <Pagination
        page={pagination.currentPage + 1}
        count={pagination.totalPages}
        hidePrevButton
        hideNextButton
        showFirstButton
        showLastButton
        onChange={(e, value) => {
          handleClickPageNumber(e, value);
        }}
      />
    </div>
  );
};

export default PaginationComponent;
