import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { UserDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import useScreenType from 'libs/hooks/useScreenType';
import FollowListItem from '../components/FollowListItem';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const FollowListContainer = observer(() => {
  const { followStore, userStore, templateStore } = useStore();
  const { userName } = useParams();
  const { t } = useTranslation();

  const followType = useRef<'following' | 'follower'>('follower');
  const screenType = useScreenType();
  const [followList, setFollowList] = useState<Array<UserDto>>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const handleFollow = (isFollow: boolean) => {
    if (typeof userName === 'undefined') {
      return;
    }
    followType.current = isFollow ? 'following' : 'follower';

    if (isFollow) {
      followStore.getFollowings(userName);
    } else {
      followStore.getFollowers(userName);
    }
  };

  const handleClickFollow = (userName: string, isFollow: boolean) => {
    if (typeof userName !== 'undefined') {
      const result = isFollow ? followStore.follow(userName) : followStore.unFollow(userName);
    }
  };

  useLayoutEffect(() => {
    if (typeof userName === 'undefined') {
      return;
    }
    followStore.getFollowers(userName);
    return () => {
      followStore.unmount();
    };
  }, []);

  useEffect(() => {
    if (followType.current === 'following') {
      setFollowList(followStore.userFollowing);
    } else {
      setFollowList(followStore.userFollowers);
    }
  }, [followStore.userFollowers, followStore.userFollowing]);

  return (
    <FollowListStyle
      className="global-width"
      style={{ margin: screenType === 'mobile' ? 15 : 'auto' }}
    >
      <div>
        <div className="row">
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleFollow(false);
            }}
          >
            Follower <b>{userStore.userInfo?.followInfo?.followers}</b>
          </div>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleFollow(true);
            }}
          >
            Following <b>{userStore.userInfo?.followInfo?.followings}</b>
          </div>
        </div>
        <div style={{ marginTop: 30 }}></div>
        <div
          style={{
            paddingLeft: screenType === 'window' ? 40 : 20,
            paddingRight: screenType === 'window' ? 40 : 20,
          }}
        >
          {followList?.map((user: UserDto, index: number) => (
            <div style={{ marginBottom: 15 }}>
              <FollowListItem
                onItemClick={() => {
                  templateStore.setCurrentMode('link');
                }}
                user={user}
                onFollow={(isFollow: boolean) => {
                  handleClickFollow(user.userName!, isFollow);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </FollowListStyle>
  );
});

const FollowListStyle = styled.div`
  background: #fff;
  border-radius: 15px;
  padding: 15px;
`;
export default FollowListContainer;
