import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, useLocation, useParams } from 'react-router-dom';
import { getTokens } from 'libs/helper/localStorageHelper';
import Gnb from 'features/commonFeatures/navigationBar/containers/Gnb';
import mainRoutes from 'routes/mainRoutes';
import { GnbAdmin } from 'features/commonFeatures/navigationBar/containers';
import ReactGA from 'react-ga4';

const MainContainer = () => {
  const { pathname } = useLocation();
  const [reached, setReached] = useState<boolean>(false);

  const listener = (e: any) => {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const zero = vh - window.scrollY;
    if (zero < 80) {
      setReached(true);
    } else {
      setReached(false);
    }
  };
  if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize('G-D8BYME7NGJ');
    useEffect(() => {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }, [pathname]);
  }

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [pathname]);

  const routes = () => (
    <Routes>
      {mainRoutes.map((route, index: number) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={
              <Auth isPrivate={route.authenticated}>
                <route.element />
              </Auth>
            }
          />
        );
      })}
    </Routes>
  );

  return (
    <>
      {
        // pathname !== `/${params}` &&
        !pathname.startsWith('/email') &&
          !pathname.startsWith('/verify') &&
          !pathname.startsWith('/admin') && (
            <div id="gnb_container">
              <GnbAdmin />
            </div>
          )
      }
      <div style={{ backgroundColor: '#fff' }}>
        <Routes>
          {mainRoutes.map((route, index: number) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <Auth isPrivate={route.authenticated}>
                    <route.element />
                  </Auth>
                }
              />
            );
          })}
        </Routes>
      </div>
      {/* {!pathname.startsWith('/email') &&
        !pathname.startsWith('/verify') &&
        !pathname.endsWith('terms') && (
          <div id="gnb_container">
            <div
              ref={gnbRef}
              className={`${reached === true ? 'gnb_container_bg on' : 'gnb_container_bg'}`}
            />
            <div className="gnb_container_wrapper">
              <Gnb />
            </div>
          </div>
        )}
      <div style={{ backgroundColor: '#fbfbfb' }}>{routes()}</div> */}
    </>
  );
};

const Auth = ({ isPrivate, children }: { isPrivate?: boolean; children: JSX.Element }) => {
  const token = getTokens().accessToken !== null;

  if (isPrivate !== false) {
    if (token) {
      return children;
    } else {
      return children;
      // return <Navigate to="/login" />;
    }
  } else {
    return children;
  }
};

export default MainContainer;
