import React, { CSSProperties } from 'react';
import { TextArea, TextToolBox } from 'components/commons';
import { useTranslation } from 'react-i18next';

const SelfIntroModal = ({
  value,
  style,
  onValueChange,
  onStyleChange,
}: {
  value?: string;
  style?: CSSProperties;
  onValueChange: (value: string) => void;
  onStyleChange: (style: CSSProperties) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: 20 }}>{t('profileIntroduction')}</div>
      <TextToolBox
        style={style}
        show
        onChangeStyle={(style: CSSProperties) => {
          onStyleChange(style);
        }}
      />
      <TextArea
        style={{
          height: 250,
          textAlign: 'center',
          ...style,
        }}
        placeholder={t('profileIntroduction')}
        containerStyle={containerStyle}
        defaultValue={value}
        onChangeValue={(value: string) => {
          onValueChange(value);
        }}
      />
    </>
  );
};

const containerStyle = {
  background: 'rgba(0, 0, 0, 0.05)',
  border: '1px dashed rgba(0, 0, 0, 0.2)',
  backdropFilter: 'blur(4px)',
};

export default SelfIntroModal;
