import React, { forwardRef, useEffect, useRef } from 'react';
import { LinkDto } from 'services/data-contracts';
import { replaceStringToUrl } from './commonLogic';

import { Input } from 'components/commons';

const UrlComponent = ({
  linkModel,
  required,
  id,
  onChangeValue,
}: {
  linkModel?: LinkDto;
  required?: boolean;
  id?: string;
  onChangeValue: (value: string) => void;
}) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.value = linkModel?.linkUrl || '';
    }
  }, [linkModel]);
  return (
    <>
      <Input
        ref={ref}
        required={required}
        id={id}
        placeholder="Url"
        labelStyle={{ marginTop: 15 }}
        format="url"
        onBlur={(isFormValid: boolean) => {
          if (required === true) {
            if (isFormValid !== true) {
              return;
            }
          }
          const value = replaceStringToUrl(ref.current.value);
          ref.current.value = value;
          onChangeValue(value!);
        }}
        onChangeValue={(value: string) => {
          onChangeValue(value);
        }}
        defaultValue={linkModel?.linkUrl || ''}
        type="text"
      />
    </>
  );
};

export default UrlComponent;
