/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TemplateDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Template<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 템플릿 조회
   *
   * @tags template
   * @name GetTemplate
   * @summary 템플릿 조회
   * @request GET:/v1/templates/{templateType}/{templateId}
   * @secure
   */
  getTemplate = (
    templateType: string,
    templateId: string,
    query: { templateId: number },
    params: RequestParams = {},
  ) =>
    this.request<TemplateDto, any>({
      path: `/v1/templates/${templateType}/${templateId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 템플릿 수정
   *
   * @tags template
   * @name UpdateTemplate
   * @summary 템플릿 수정
   * @request PUT:/v1/templates/{templateType}/{templateId}
   * @secure
   */
  updateTemplate = (
    templateType: 'Layout' | 'Box',
    templateId: number,
    data: TemplateDto,
    params: RequestParams = {},
  ) =>
    this.request<TemplateDto, any>({
      path: `/v1/templates/${templateType}/${templateId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 템플릿 삭제
   *
   * @tags template
   * @name DeleteTemplate
   * @summary 템플릿 삭제
   * @request DELETE:/v1/templates/{templateType}/{templateId}
   * @secure
   */
  deleteTemplate = (
    templateType: 'Layout' | 'Box',
    templateId: number,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/v1/templates/${templateType}/${templateId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 템플릿목록 조회
   *
   * @tags template
   * @name GetTemplates
   * @summary 템플릿목록 조회
   * @request GET:/v1/templates/{templateType}
   * @secure
   */
  getTemplates = (
    templateType: 'Layout' | 'Box',
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<TemplateDto[], any>({
      path: `/v1/templates/${templateType}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 템플릿 등록
   *
   * @tags template
   * @name CreateTemplate
   * @summary 템플릿 등록
   * @request POST:/v1/templates/{templateType}
   * @secure
   */
  createTemplate = (
    templateType: 'Layout' | 'Box',
    data: TemplateDto,
    params: RequestParams = {},
  ) =>
    this.request<TemplateDto, any>({
      path: `/v1/templates/${templateType}`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
