import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

interface BasicProps {
  src?: string;
  alt?: string;
  title: string;
  style?: React.CSSProperties;
  onClick?: (itme?: any | undefined) => void | undefined;
}

const BasicButton = ({ src, alt, title, style, onClick }: BasicProps) => {
  const handleClicked = (e: any) => {
    // 페이지 유형
  };
  return (
    <ButtonWrapperStyle
      htmlFor={title}
      className="pageType-field-label"
      style={style}
      onClick={() => {
        !_.isUndefined(onClick) && onClick();
      }}
    >
      {!_.isUndefined(src) && (
        <img src={src} alt={alt} width={20} height={20} style={{ marginRight: '5px' }} />
      )}
      {title}
    </ButtonWrapperStyle>
  );
};

const ButtonWrapperStyle = styled.label`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 1.52em;
  /* background: #f6f6f6; */
  background-color: white;
  border-radius: 10px;
  padding: 10px 14px 10px 14px;
  border: 1px solid #666666;
  color: #333333;
  margin: 0 0 1rem 0;

  &:hover {
    opacity: 0.4;
  }
  @media ${(props) => props.theme.media.mobile} {
    /* width: ; */
  }
`;

export default BasicButton;
