import React, { useEffect, useState, useRef, SetStateAction, Dispatch } from 'react';
import { CommonButtonStyle } from 'resources/styles/common/styles';
import { LinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import ListOfLinksContainer from 'features/adminFeatures/link/containers/ListOfLinksContainer';
import LinkCreateContainer from 'features/adminFeatures/link/containers/LinkCreateContainer';
import useScreenType from 'libs/hooks/useScreenType';
import styled from 'styled-components';
import Modal from 'react-modal';

const LinksContainer = observer(() => {
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [selectedLink, setSelectedLink] = useState<LinkDto>();
  const [showConfirmDeleteLink, setShowConfirmDeleteLink] = useState<boolean>(false);
  const { meStore, uiStore } = useStore();
  const linkIdRef = useRef<number>();
  const screenType = useScreenType();
  const { linkStore } = useStore();
  const { t } = useTranslation();

  const handleOnSuccessAddLink = (type: 'create' | 'edit') => {
    toast.success(
      `${
        type === 'create'
          ? (t('youCreatedLink'), { position: 'top-center' })
          : (t('correctedNormally'), { position: 'top-center' })
      }`,
    );
    setTimeout(() => {
      linkStore.getLinks();
    }, 1000);
  };

  const handleOnItemDelete = (linkId: number) => {
    setShowConfirmDeleteLink(true);
    linkIdRef.current = linkId;
  };

  const handleClickEditLink = (link: LinkDto) => {
    setShowEditor(true);
    setSelectedLink(link);
  };

  const deleteLinkItem = () => {
    if (linkIdRef.current) {
      linkStore.deleteLink(linkIdRef.current);
      toast.success(t('linkRemove'), { position: 'top-center' });
      linkIdRef.current = undefined;
      setShowConfirmDeleteLink(false);

      setTimeout(() => {
        linkStore.getLinks();
      }, 1000);
    }
  };

  const closeModal = (targetState: Dispatch<SetStateAction<boolean>>) => {
    targetState(false);
  };

  useEffect(() => {
    meStore.getMyProfile();
  }, [meStore]);

  const handleClickCreateLink = () => {
    uiStore.drawer.show({
      title: 'Link',
      children: (
        <LinkCreateContainer
          // selectedLink={selectedLink}
          onClose={() => {
            closeModal(setShowEditor);
          }}
          onSuccess={(type: 'create' | 'edit') => {
            closeModal(setShowEditor);
            handleOnSuccessAddLink(type);
          }}
        />
      ),
    });
  };
  return (
    // <div onClick={handleClickCreateLink}>CreatLink</div>
    <Container>
      <CreateButton
        onClick={() => {
          setSelectedLink(undefined);
          setShowEditor(true);
        }}
      >
        {t('createLink')}
      </CreateButton>
      <ListOfLinksContainer
        draggable={true}
        onLinkDelete={(linkId: number) => {
          handleOnItemDelete(linkId);
        }}
        onLinkEdit={(link: LinkDto) => {
          handleClickEditLink(link);
        }}
      />
      <Modal
        isOpen={showConfirmDeleteLink}
        onRequestClose={() => closeModal(setShowConfirmDeleteLink)}
        ariaHideApp={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1000,
            border: 20,
            borderRadius: 0,
          },
          content: {
            maxWidth: 400,
            height: 200,
            margin: 'auto',
          },
        }}
      >
        <div>{t('linkDelete')}</div>
        <DeleteModalButtonContainer>
          <DeleteModalButton onClick={deleteLinkItem}>{t('confirm')}</DeleteModalButton>
          <DeleteModalButton onClick={() => closeModal(setShowConfirmDeleteLink)}>
            {t('cancellation')}
          </DeleteModalButton>
        </DeleteModalButtonContainer>
      </Modal>
      <Modal
        isOpen={showEditor}
        onRequestClose={() => closeModal(setShowEditor)}
        ariaHideApp={false}
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)', zIndex: 0 },
          content: {
            maxWidth: 800,
            margin: 'auto',
            padding: 0,
            background: '#F6F6F6',
            width: screenType === 'window' ? 800 : '100%',
            inset: screenType === 'window' ? '40px' : '0px',
            marginTop: screenType === 'window' ? '40px' : '60px',
            border: 0,
            borderRadius: 0,
          },
        }}
      >
        <EditWrap className="row">
          <div style={{ flex: 1, fontSize: 14, fontWeight: '500' }}>
            {typeof selectedLink === 'undefined' ? t('createNewLink') : t('upDateLink')}
          </div>
          <EditModalCloseButton onClick={() => closeModal(setShowEditor)}>
            {t('close')}
          </EditModalCloseButton>
        </EditWrap>
        <LinkCreateContainer
          // selectedLink={selectedLink}
          onClose={() => {
            closeModal(setShowEditor);
          }}
          onSuccess={(type: 'create' | 'edit') => {
            closeModal(setShowEditor);
            handleOnSuccessAddLink(type);
          }}
        />
      </Modal>
    </Container>
  );
});

const Container = styled.div`
  margin: 20px;
  @media ${(props) => props.theme.media.mobile} {
    padding: 10px 0px 0 0;
  }
`;

const CreateButton = styled(CommonButtonStyle)`
  @media ${(props) => props.theme.media.mobile} {
    font-size: 15px;
    height: 45px;
  }
`;

const DeleteModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 120px 0 0 0;
`;

const DeleteModalButton = styled(CommonButtonStyle)`
  margin: 0 5px 0 5px;
`;

const EditWrap = styled.div`
  align-items: center;
  margin: 10px;
`;

const EditModalCloseButton = styled(CommonButtonStyle)`
  width: 100px;
  height: 40px;
`;

export default LinksContainer;
