import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 0 0 8px 0;
`;

export const Tab = styled.div<{ isLastItem?: boolean }>`
  flex: 1;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  margin: 0 ${(props) => (props?.isLastItem ? 0 : 8)}px 0 0;
  cursor: pointer;
`;

export const TabImg = styled.img`
  width: 24px;
  height: 24px;
  text-align: center;
`;

export const TabName = styled.div`
  font-size: 12px;
  padding-top: 5px;
  color: #111111;
`;

export const DeleteButtonStyle = styled.div`
  width: 18px;
  height: 18px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
`;

export const DeleteImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const MenuBoxContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
`;

export const MenuListContainer = styled.div<{ isLastItem?: boolean }>`
  flex: 1;
  margin: ${(props) => (props?.isLastItem ? '0 0 0 0' : '0 8px 0 0')};
  position: relative;
`;

export const SingleButtonStyle = styled.div`
  z-index: 100;
  right: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  position: absolute;
  line-height: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 30px;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
