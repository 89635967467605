import React, { useEffect, useState, useRef } from 'react';

import { Backdrop, CircularProgress } from '@mui/material';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { TemplateWrapper } from 'features/adminFeatures/template/components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import UserNotFound from 'pages/UserNotFound';
import styled from 'styled-components';
import QRCodePrintModal from 'components/commons/QRCodePrintModal';
import QRCode from 'react-qr-code';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { CustomUserProfile } from 'types/CommonTypes';
import i18next from 'i18next';

const MainPage = observer(() => {
  const { t } = useTranslation();
  const params = useParams();
  const qrRef = useRef<any>();

  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight - 40,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const { userStore, templateStore } = useStore();

  const init = async () => {
    setLoading(true);
    templateStore.setCurrentMode('link');
    const tempUser = params[Object.keys(params)[0]];
    if (typeof tempUser !== 'undefined') {
      const tempUserInfo: any = await userStore.getUserInfo(
        i18next.language === 'jp' ? 'mossjapan' : 'moss',
      );

      if (typeof tempUserInfo?.profile !== 'undefined') {
        templateStore.setUserInfo(tempUserInfo);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    init();

    return () => {
      templateStore.setUserInfo(undefined);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight - 40,
      });
    }

    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {loading ? (
        <Backdrop open style={{ zIndex: 20 }}>
          <CircularProgress />/
        </Backdrop>
      ) : typeof userStore.userInfo !== 'undefined' ? (
        <div>
          {showShareModal && (
            <div className="center-all" ref={qrRef}>
              <QRCode value={`https://ohmoss.com/${userStore.userInfo?.profile?.userName}`} />
            </div>
          )}
          <TemplateWrapper />
          <QRCodePrintModal
            qrRef={qrRef}
            onClose={() => {
              setShowShareModal(false);
            }}
            userName={userStore.userInfo?.profile?.userName}
            show={showShareModal}
          >
            <div
              style={{
                textAlign: 'center',
                cursor: 'pointer',
                border: '2px dashed #e9d4ff',
                margin: 10,
                padding: 10,
                borderRadius: 10,
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://ohmoss.com/${userStore.userInfo?.profile?.userName}`,
                );
                toast.success(t('copyClipBoard'), { position: 'top-center' });
                setShowShareModal(false);
              }}
            >{`https://ohmoss.com/${userStore.userInfo?.profile?.userName}`}</div>
          </QRCodePrintModal>
        </div>
      ) : (
        <UserNotFound />
      )}
    </>
  );
});

const UserGNBStyle = styled.div`
  position: fixed;
  top: 0;
  height: 100;
  width: 100%;
  z-index: 100;
  justify-content: space-between;
`;

const ShareButtonStyle = styled.div`
  z-index: 100;
  right: 30px;
  top: 30px;
  width: 45px;
  margin: 20px;
  height: 45px;
  line-height: 55px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

export default MainPage;
