import React from 'react';
import { observer } from 'mobx-react';
import { UserProfileDto } from 'services/data-contracts';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import Follow from './Follow';

const FollowListItem = observer(
  ({
    user,
    onFollow,
    onItemClick,
  }: {
    user: UserProfileDto;
    onFollow: (isFollow: boolean) => void;
    onItemClick: () => void;
  }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
      onItemClick();
      const url = window.location;
      const _url = `/${user.userName}`;
      navigate(_url);
    };
    return (
      <div className="row">
        <div style={{ width: 120 }}>
          <Avatar style={{ width: 60, height: 60 }}>
            <img src={user?.profileImage?.publicUrl} width={100} />
          </Avatar>
        </div>
        <div className="center-all" style={{ justifyContent: 'flex-start', cursor: 'pointer' }}>
          <div onClick={handleNavigate}>{user.userName}</div>
        </div>
        <div className="center-all" style={{ justifyContent: 'flex-start', width: 30 }}>
          <Follow
            follow={user.followerdByViewer}
            onClick={(isFollow: boolean) => {
              onFollow(isFollow);
            }}
          />
        </div>
      </div>
    );
  },
);
export default FollowListItem;
