import { makeAutoObservable } from 'mobx';
import { LinkDto, SocialLinkDto } from 'services/data-contracts';
import { Payment as PaymentService } from 'services/Payment';
import { REST_API_URL } from 'stores/AppStore';

import _ from 'lodash';
import { CustomUserInfoDto } from 'types/CommonTypes';

class PaymentStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: PaymentService<unknown> = new PaymentService({
    baseURL: REST_API_URL,
  });

  userInfo: CustomUserInfoDto;

  links: LinkDto[] = [];

  link: LinkDto;

  socialLinks: SocialLinkDto[] = [];

  /**
   * @description 공개된 사용자 정보를 취득한다.
   * @param userName
   */
  *confirmOrder(paymentKey: string, orderId: number, tossOrderId: string, amount: number) {
    const { data } = yield this.api.approveTosspay(
      { orderId },
      {
        paymentKey,
        orderId: tossOrderId,
        amount,
      },
    );
    return data;
  }

  *requestOrder() {
    // const {data } = yield this.api.pay()
  }

  *cancelOrder(orderId: string) {
    const { data } = yield this.api.cancelPayment(orderId);
    return data;
  }
}

export default PaymentStore;
