import React, { useState } from 'react';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function CardHeader({
  children,
  onExpand,
}: {
  children: React.ReactNode;
  onExpand?: (value: boolean) => void;
}) {
  const [show, setShow] = useState<boolean>(false);
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ height: 60, background: '#fff' }} className="center-all">
        {children}
      </div>
      {typeof onExpand === 'function' && (
        <ExpanderStyle
          onClick={() => {
            if (typeof onExpand === 'function') {
              setShow((prev: boolean) => !prev);
              onExpand(!show);
            }
          }}
        >
          <ExpandMoreIconStyle show={show} />
        </ExpanderStyle>
      )}
    </div>
  );
}

const ExpanderStyle = styled.div<{ show?: boolean }>`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ExpandMoreIconStyle = styled(ExpandMoreIcon)<{ show?: boolean }>`
  transition: all 0.5s ease-in-out;
  transform: ${(props) => (props.show ? 'rotate(180deg)' : ' rotate(0deg)')};
`;

export default CardHeader;
