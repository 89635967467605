import React from 'react';
import { UserProfileDto } from 'services/data-contracts';
import styled from 'styled-components';
import useScreenType from 'libs/hooks/useScreenType';

const FollowSummary = ({
  summary,
  onClick,
}: {
  summary?: Array<UserProfileDto>;
  onClick: () => void;
}) => {
  const screenType = useScreenType();

  return (
    <FollowSummaryContainerStyle isMobile={screenType === 'mobile'}>
      <FollowSummaryStyle onClick={onClick}>
        {summary?.map((user: UserProfileDto) => (
          <AvartarContainerStyle key={user.id}>
            <AvartarStyle>
              <AvartarPublicUrlStyle src={user.profileImage?.publicUrl} />
            </AvartarStyle>
          </AvartarContainerStyle>
        ))}
      </FollowSummaryStyle>
    </FollowSummaryContainerStyle>
  );
};

const FollowSummaryContainerStyle = styled.div<{ isMobile: boolean }>`
  position: relative;
  width: 40px;
  top: 7px;
  left: -20px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding-right: ${(props) => (props.isMobile ? '4px' : '8px')};
`;

const FollowSummaryStyle = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
`;

const AvartarContainerStyle = styled.div`
  width: 15px;
`;

const AvartarStyle = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 30px;
  overflow: hidden;
`;

const AvartarPublicUrlStyle = styled.img`
  width: 40px;
`;

export default FollowSummary;
