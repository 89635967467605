import React, { useState } from 'react';
import { ToggleButton, ToggleButtonType } from 'components/commons/ToggleButton';
import { Row } from 'components/commons/layouts';
import { observer } from 'mobx-react';
import BackgroundColorComponent from 'features/adminFeatures/background/components/BackgroundColorComponent';
import BackgroundImageComponent from 'features/adminFeatures/background/components/BackgroundImageComponent';
import TemplateContainer from 'features/adminFeatures/template/containers/TemplateContainer';
import styled from 'styled-components';
import { t } from 'i18next';

const BackgroundList: Array<ToggleButtonType> = [
  {
    id: 'Image',
    iconSrc: '/image/image.png',
    description: 'Image',
    checked: 'checked',
  },
  {
    id: 'Color',
    iconSrc: '/image/colorfilter.svg',
    description: 'Color',
  },
  {
    id: 'Theme',
    iconSrc: '/image/magicpen.svg',
    description: 'Theme',
  },
];

const BackgroundContainer = observer(() => {
  const [backgroundType, setBackgroundType] = useState<string>('Image');

  const handleClickType = (item: ToggleButtonType) => {
    setBackgroundType(item.id);
  };

  const getLinkContainer = () => {
    switch (backgroundType) {
      case 'Image':
        return <BackgroundImageComponent />;
      case 'Color':
        return <BackgroundColorComponent />;
      case 'Theme':
        return <TemplateContainer />;
      default:
        break;
    }
  };

  return (
    <BackgroundContainerStyle>
      <BackgroundDescriptionStyle>
        {t('profileInform4')}
        <br />
        {t('profileInform5')}
      </BackgroundDescriptionStyle>
      <ToggleContainerButtonStyle>
        <Row style={{ marginBottom: 25 }}>
          {BackgroundList.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                handleClickType(item);
              }}
            >
              <ToggleButton item={item} defaultChecked={backgroundType === item.id} />
            </div>
          ))}
        </Row>
      </ToggleContainerButtonStyle>

      <GetLinkContainerStyle>
        <div>{getLinkContainer()}</div>
      </GetLinkContainerStyle>
    </BackgroundContainerStyle>
  );
});

const BackgroundContainerStyle = styled.div`
  margin: 0 0 50px 0;
`;

const BackgroundDescriptionStyle = styled.div`
  font-family: 'Noto Sans KR';
  font-size: 14px;
  color: black;
  margin: 10px 0 30px 0;
  font-weight: 300;
`;

const ToggleContainerButtonStyle = styled.div`
  > div > div {
    width: 100%;
    margin: 0 20px 0 0;
  }
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    > div > div {
      width: 100%;
      margin: 0 20px 0 0;
    }
  }
`;

const GetLinkContainerStyle = styled.div`
  margin: 0 0 50px 0;
`;

export default BackgroundContainer;
