import React, { useRef, CSSProperties, useState, useEffect } from 'react';
import {
  SelfIntroduction,
  SocialComponent,
  Title,
} from 'features/adminFeatures/template/components';
import { TextAreaStyle } from 'features/adminFeatures/template/components/TemplateStyle';
import { TextArea, TextToolBox } from 'components/commons';
import { CardCommonProps } from 'types/CommonTypes';
import { SectionBox } from 'resources/controls';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from 'components/items';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import ProfileSocialComponent from './ProfileSocialComponent';
import WhiteButton from 'components/commons/WhiteButton';
import Avatar from 'components/commons/Avatar';
import Input from 'components/commons/Input';
import styled from 'styled-components';
import ToggleButton from 'controls/toggleButton/ToggleButton';
const ProfileComponent = observer(({ commonProps }: { commonProps: CardCommonProps }) => {
  const { t } = useTranslation();
  const { simulatorStore } = useStore();
  const titleRef = useRef<HTMLInputElement>(null);

  // useEffect(() => {
  //   if (
  //    // userInfo?.profile.profileImage?.borderInfo?.useBorder === true &&
  //     //inputRef.current !== null
  //   // )
  //   {
  //     // setClickToggle(true);
  //     // inputRef.current.checked = true;
  //   }
  // }, [templateStore.userInfo]);

  return (
    <ProdfileContainerStyle>
      <ProfileInfoStyle>
        <ProfileContainerStyle>
          <ProfileHeaderStyle>
            <div>
              <ToggleButton
                label={t('borderLine')}
                onToggleClick={(isChecked: boolean) => {
                  commonProps.updateModel('profileImage', {
                    ...commonProps.profile?.profileImage,
                    borderInfo: {
                      ...commonProps.profile?.profileImage?.borderInfo,
                      useBorder: isChecked,
                    },
                  });
                }}
              />
              {t('borderLine')}
              {/* <ToggleInputStyle
                ref={inputRef}
                type="checkbox"
                id="toggle"
                hidden
                onClick={() => {
                  if (typeof inputRef.current?.checked !== 'undefined') {
                    setClickToggle(inputRef.current?.checked);
                  }
                  commonProps.updateModel('profileImage', {
                    ...commonProps.profile?.profileImage,
                    borderInfo: {
                      ...commonProps.profile?.profileImage?.borderInfo,
                      useBorder: inputRef.current?.checked,
                    },
                  });
                }}
              />
              <ToggleLabelStyle htmlFor="toggle" className="toggleSwitch">
                <span />
                <ToggleTextStyle clickToggle={clickToggle}>
                  {clickToggle ? 'ON' : 'OFF'}
                </ToggleTextStyle>
              </ToggleLabelStyle> */}
              <ColorPicker
                pickerStyle={{
                  position: 'absolute',
                  top: 220,
                  left: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  zIndex: 100,
                }}
                onColorChanged={(color: string) => {
                  commonProps.updateModel('profileImage', {
                    ...commonProps.profile?.profileImage,
                    borderInfo: {
                      ...commonProps.profile?.profileImage?.borderInfo,
                      borderColor: color,
                    },
                  });
                }}
                defaultValue={simulatorStore?.profile?.profileImage?.borderInfo?.borderColor}
              >
                <ColorPickerContainerStyle>
                  <ColorPickerImageStyle src="/image/palate_inactive.png" alt="" />
                </ColorPickerContainerStyle>
              </ColorPicker>
            </div>
          </ProfileHeaderStyle>
          <ProfileWrapStyle>
            <Avatar size="medium" profile={simulatorStore?.profile} />
            <TitleAreaStyle>
              <Title profile={simulatorStore?.profile} />
              <SelfIntroduction profile={simulatorStore?.profile} />
              <SocialComponent socials={simulatorStore?.socialLinks} />
            </TitleAreaStyle>
          </ProfileWrapStyle>
          <SectionBox>
            <TextToolBox
              show
              showImageIcon
              style={commonProps.profile?.titleProps?.style}
              containerStyle={toolBoxStyle}
              imageUrl={commonProps.profile?.profileImage?.publicUrl}
              onChangeImage={(action: 'add' | 'delete', thumbnail?: any) => {
                if (action === 'delete') {
                  commonProps.updateModel('profileImage', undefined);
                } else if (action === 'add') {
                  commonProps.updateModel('profileImage', {
                    ...thumbnail,
                    borderInfo: simulatorStore?.profile.profileImage?.borderInfo,
                  });
                }
              }}
              onChangeStyle={(style: CSSProperties) => {
                commonProps.updateModel('titleProps', {
                  style: { ...commonProps.profile?.titleProps?.style, ...style },
                });
              }}
            />
            <Input
              ref={titleRef}
              placeholder={t('title')}
              type="text"
              containerStyle={{ border: 0 }}
              style={{
                color: '#111',
                fontSize: 16,
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                padding: '0 24px',
                margin: '0 5px',
              }}
              defaultValue={commonProps.profile?.title}
              onChangeValue={(value: string) => {
                commonProps.updateModel('title', value);
              }}
            />
            <TextToolBox
              show
              style={commonProps.profile?.selfIntroductionProps?.style}
              containerStyle={toolBoxStyle}
              onChangeStyle={(style: CSSProperties) => {
                commonProps.updateModel('selfIntroductionProps', {
                  style: { ...commonProps.profile?.selfIntroductionProps?.style, ...style },
                });
              }}
            />
            <TextArea
              placeholder={t('content')}
              type="textarea"
              containerStyle={{ border: 0 }}
              style={{
                height: 'auto',
                fontSize: 16,
                color: '#111',
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                backgroundColor: '#F8F8F8',
                margin: '5px 5px 0 5px',
                padding: 10,
              }}
              defaultValue={commonProps.profile?.selfIntroduction}
              onChangeValue={(value: string) => commonProps.updateModel('selfIntroduction', value)}
            />
          </SectionBox>
        </ProfileContainerStyle>
        <ProfileSocialComponent />
      </ProfileInfoStyle>
      <WhiteButton
        type="SAVE"
        onClick={commonProps.handleClickSaveProfile}
        style={{ marginTop: '30px' }}
      />
    </ProdfileContainerStyle>
  );
});

const ProdfileContainerStyle = styled.div`
  margin: 50px 0 50px 0;
`;

const ProfileInfoStyle = styled.div`
  border-radius: 24px;
  margin: 20px 0 0 0;
  position: relative;
`;

const ProfileContainerStyle = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 6px;
  margin: 0 0 50px 0;
  > button {
    border: none;
    border-radius: 0;
    border-top: 1px solid #eeeeee;
  }
  @media ${(props) => props.theme.media.mobile} {
    margin: 0 0 30px 0;
  }
`;

const ProfileHeaderStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 5px 5px 5px 5px;
  > div {
    display: flex;
    margin: 0 10px 0 0;
    align-items: center;
    font-size: 14px;
    color: #ddd;
  }
`;

const ColorPickerContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid #eeeeee;
  border-radius: 50%;
  margin: 0 5px 0 0;
  cursor: pointer;
`;

const ColorPickerImageStyle = styled.img`
  width: 25px;
  height: 25px;
`;

const ProfileWrapStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  > div > div {
    margin: 0 0 0 0;
  }
`;

const TitleAreaStyle = styled(TextAreaStyle)`
  display: flex;
  flex-direction: column;
  width: 90%;
  text-align: center;
  position: relative;
  z-index: 10;
  > div:last-child {
    margin: 30px 0 30px 0;
  }
`;

const toolBoxStyle = {
  borderRadius: 12,
  border: 'none',
};

export default ProfileComponent;
