import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import { TabContainer, Tab, TabImg, TabName } from 'resources/styles/common/button';
import { Input, TextArea, ImageUploader, MobileModal } from 'components/commons';
import { ProfileTitleModal, SelfIntroModal, FooterModal } from './modals';
import { CustomUserProfile } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { isValid } from 'libs/helper/utils';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import BackgroundImageComponent from 'features/adminFeatures/template/components/BackgroundImageComponent';
import WebShareBottomSheet from 'features/adminFeatures/profile/components/WebShareBottomSheet';
import MobileCommonFooter from 'features/adminFeatures/commons/components/MobileCommonFooter';
import IconButton from 'resources/controls/IconButton';
import 'react-spring-bottom-sheet/dist/style.css';
import Avatar from 'components/commons/Avatar';
import useForm from 'libs/hooks/useForm';
import styled from 'styled-components';

type Props = {
  onShowSimulator: () => void;
  onCloseSimulator: () => void;
};

const ProfileContainerMobile = observer(({ onShowSimulator, onCloseSimulator }: Props) => {
  const [colorSimulationMode, setColorSimulationMode] = useState<boolean>(false);
  const [selfIntroModalShow, setSelfIntroModalShow] = useState<boolean>(false);
  const [isShowShareModal, setIsShowShareModal] = useState<boolean>(false);
  const [editorModalShow, setEditorModalShow] = useState<boolean>(false);
  const [footerModalShow, setFooterModalShow] = useState<boolean>(false);
  const [profileModel, setProfileModel] = useState<CustomUserProfile>();
  const { meStore, fileStore, templateStore, formValidationStore } = useStore();
  const preventUpdateModel = useRef<boolean>(false);
  const isFormValid = useForm(formValidationStore);
  const profileTextPosition = useRef<number>();
  const { t } = useTranslation();
  const profileTextStartYPosition = 100;
  const handleDrag = (e: any, ui: any) => {
    profileTextPosition.current = ui.y;
  };

  const profileTabs = () => {
    return (
      <TabContainer>
        <Tab>
          <ImageUploader
            attachType="image"
            cropSize={{ width: 2000, height: 2000 }}
            currentImage={profileModel?.backgroundImage}
            currentColor={profileModel?.backgroundColor}
            useCrop={false}
            useColor={true}
            onColorChanged={(color: string) => {
              setColorSimulationMode(true);
              if (typeof color === 'undefined') {
                updateModel('backgroundColor', '#efefef');
              } else {
                updateModel('backgroundColor', color);
              }
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
            onSelected={(thumbnails: any) => {
              setColorSimulationMode(false);
              updateModel('backgroundColor', undefined);
              updateModel('backgroundImage', thumbnails[0]);
            }}
          >
            <TabImg src="/image/adminProfile/background-image-icon.png" />
            <TabName>{t('backDrop')}</TabName>
          </ImageUploader>
        </Tab>
        <Tab isLastItem>
          <div
            style={{ textAlign: 'center', width: '100%' }}
            onClick={() => {
              setFooterModalShow(true);
            }}
          >
            <TabImg src="/image/adminProfile/background-image-icon.png" />
            <TabName>{t('footer')}</TabName>
          </div>
        </Tab>
      </TabContainer>
    );
  };

  const updateModel = (key: any, value: any) => {
    setProfileModel((prevState: CustomUserProfile) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const handleClickSaveProfile = async () => {
    const _profileModel = { ...profileModel };
    if (colorSimulationMode === true) {
      _profileModel.backgroundImage = undefined;
    }
    if (_profileModel?.profileImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadImage(profileModel?.profileImage?.file);
      _profileModel.profileImage.id = image.id;
    }
    if (_profileModel?.backgroundImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadImage(profileModel?.backgroundImage?.file);
      _profileModel.backgroundImage.id = image.id;
    }
    if (_profileModel?.footerImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadImage(profileModel?.footerImage?.file);
      _profileModel.footerImage.id = image.id;
    }

    _profileModel.custom = JSON.stringify({
      titleProps: _profileModel.titleProps,
      selfIntroductionProps: _profileModel.selfIntroductionProps,
      template: _profileModel.template,
      footerProps: _profileModel.footerProps,
      titleYPosition: _profileModel.titleYPosition,
    });

    meStore.saveMyProfile(_profileModel);
    toast(t('yourProfileSave'), { position: 'top-center' });
  };

  useEffect(() => {
    if (preventUpdateModel.current === true) {
      return;
    }
    if (isValid(templateStore.userInfo)) {
      setProfileModel(templateStore.userInfo?.profile);
    }
  }, [templateStore.userInfo]);

  useEffect(() => {
    if (typeof profileModel !== 'undefined') {
      preventUpdateModel.current = true;
      templateStore.setUserInfo({ ...templateStore.userInfo, profile: profileModel });
    }
  }, [profileModel]);

  return (
    <>
      <div>
        {typeof profileModel !== 'undefined' && (
          <div style={{ background: '#efefef' }}>
            <BackgroundImageComponent
              colorMode={colorSimulationMode}
              profile={profileModel}
              useEmpty={false}
            />
            <div style={{ position: 'relative', width: 100, margin: 'auto' }}>
              <Avatar />
              <AbsoluteLayoutStyle
                className="row center-all"
                style={{ bottom: 0, zIndex: 10, right: -40 }}
              >
                <ImageUploader
                  attachType="image"
                  useCrop={true}
                  currentImage={profileModel.profileImage}
                  cropSize={{ width: 400, height: 400 }}
                  onSelected={(thumbnails: any) => {
                    if (thumbnails.length === 0) {
                      updateModel('profileImage', null);
                    } else {
                      updateModel('profileImage', thumbnails[0]);
                    }
                  }}
                >
                  <IconButton
                    image="/image/edit.png"
                    width={50}
                    containerStyle={{ background: '#fff', borderRadius: 40, width: 50, height: 50 }}
                  ></IconButton>
                </ImageUploader>
              </AbsoluteLayoutStyle>
            </div>
            {/* <Draggable
              axis="y"
              handle="strong"
              defaultPosition={{
                x: 0,
                y: profileModel?.titleYPosition || profileTextStartYPosition,
              }}
              bounds={{
                // top: profileTextStartYPosition,
                // bottom: window.innerHeight - 300,
                top: 0,
                bottom: 0,
              }}
              onDrag={handleDrag}
            > */}
            <div>
              <div style={{ position: 'relative', zIndex: 100, marginTop: 30 }}>
                {/* <strong style={moveHandleStyle} /> */}
                <div className="row">
                  <Input
                    readOnly
                    required
                    placeholder={t('profileTitle')}
                    id="profileTitle"
                    containerStyle={containerStyle}
                    style={{ ...profileModel?.titleProps?.style, textAlign: 'center' }}
                    value={profileModel?.title}
                  />
                  <AbsoluteLayoutStyle
                    style={{ right: 5 }}
                    onClick={() => {
                      setEditorModalShow(true);
                    }}
                  >
                    <IconButton
                      image="/image/edit.png"
                      width={40}
                      containerStyle={{
                        background: '#fff',
                        borderRadius: 40,
                        width: 40,
                        height: 40,
                      }}
                    ></IconButton>
                  </AbsoluteLayoutStyle>
                </div>
                <div className="row" style={{ marginTop: 10 }}>
                  <TextArea
                    readOnly
                    style={{
                      height: 'auto',
                      textAlign: 'center',
                      ...profileModel?.selfIntroductionProps?.style,
                    }}
                    placeholder={t('profileIntroduction')}
                    containerStyle={containerStyle}
                    value={profileModel?.selfIntroduction}
                    onChangeValue={(value: string) => {
                      updateModel('selfIntroduction', value);
                    }}
                  />
                  <AbsoluteLayoutStyle
                    style={{ right: 5, marginTop: 10 }}
                    onClick={() => {
                      setSelfIntroModalShow(true);
                    }}
                  >
                    <IconButton
                      image="/image/edit.png"
                      width={40}
                      containerStyle={{
                        background: '#fff',
                        borderRadius: 40,
                        width: 40,
                        height: 40,
                      }}
                    ></IconButton>
                  </AbsoluteLayoutStyle>
                </div>
              </div>
            </div>
            {/* </Draggable> */}
          </div>
        )}
        <MobileCommonFooter
          onSaveClick={handleClickSaveProfile}
          isValidForm={isFormValid.result}
          onShowSimulator={onShowSimulator}
        >
          {profileTabs()}
        </MobileCommonFooter>
      </div>
      <MobileModal
        height={'100%'}
        isShow={editorModalShow}
        onClose={() => {
          setEditorModalShow(false);
        }}
      >
        <ProfileTitleModal
          value={profileModel?.title}
          style={profileModel?.titleProps?.style}
          onValueChange={(value: string) => {
            updateModel('title', value);
          }}
          onStyleChange={(style: CSSProperties) => {
            updateModel('titleProps', {
              style: { ...profileModel?.titleProps?.style, ...style },
            });
          }}
        />
      </MobileModal>
      <MobileModal
        isShow={selfIntroModalShow}
        height={'100%'}
        onClose={() => {
          setSelfIntroModalShow(false);
        }}
      >
        <SelfIntroModal
          value={profileModel?.selfIntroduction}
          style={profileModel?.selfIntroductionProps?.style}
          onValueChange={(value: string) => {
            updateModel('selfIntroduction', value);
          }}
          onStyleChange={(style: CSSProperties) => {
            updateModel('selfIntroductionProps', {
              style: { ...profileModel?.selfIntroductionProps?.style, ...style },
            });
          }}
        />
      </MobileModal>
      <MobileModal
        isShow={footerModalShow}
        height={'100%'}
        onClose={() => {
          setFooterModalShow(false);
        }}
      >
        <FooterModal
          model={profileModel}
          onValueChange={(value: string) => {
            updateModel('footerTitle', value);
          }}
          onStyleChange={(style: CSSProperties) => {
            updateModel('footerProps', {
              ...profileModel?.footerProps,
              style: { ...profileModel?.footerProps?.style, ...style },
            });
          }}
          onImageChange={(image: Blob) => {
            updateModel('footerImage', image);
          }}
        />
      </MobileModal>
      {/* web일 경우 share bottomSheet */}
      <WebShareBottomSheet
        visible={isShowShareModal}
        username={meStore.myProfile?.userName}
        onClose={() => setIsShowShareModal(false)}
      />
    </>
  );
});

const containerStyle = {
  background: 'rgba(0, 0, 0, 0)',
  border: 0,
};

const moveHandleStyle = {
  cursor: 'move',
  width: 50,
  height: 50,
  display: 'flex',
  margin: 'auto',
  backgroundImage: 'url(/image/draghandle.png)',
};

const AbsoluteLayoutStyle = styled.div`
  position: absolute;
`;

export default ProfileContainerMobile;
