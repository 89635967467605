import React, { useEffect, useState } from 'react';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { GnbUserHome } from 'features/commonFeatures/navigationBar/containers';

const PaymentPage = observer(() => {
  const clientKey = 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq';

  const { userStore, templateStore } = useStore();
  const params = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>();

  const init = async () => {
    const tossPayments = await loadTossPayments(clientKey);
    tossPayments.requestPayment('카드', {
      amount: 15000,
      orderId: 'a2iy_q7e9UjXQFsIMHulV',
      orderName: '토스 티셔츠 외 2건',
      customerName: '박토스',
      successUrl: 'http://localhost:8080/success',
      failUrl: 'http://localhost:8080/fail',
    });
  };

  useEffect(() => {
    init();
    return () => {};
  }, [params]);

  return <></>;
});

const ContainerStyle = styled.div`
  padding: 80px 0 0 0;
  margin: 0 0 46px 0;
`;

export default PaymentPage;
