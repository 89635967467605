import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';

const GoogleLoginPage = observer(() => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { authStore } = useStore();

  const loginInGoogle = async () => {
    // await authStore.loginInGoogle(searchParams.get("code"));
    // authStore.status === 200 ? navigate("/listing") : navigate("/login");
  };

  useEffect(() => {
    loginInGoogle();
  }, []);

  return <div>구글 로그인중입니다.</div>;
});

export default GoogleLoginPage;
