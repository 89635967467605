import { observer } from 'mobx-react';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import Theme from 'resources/styles/common/theme';
import styled from 'styled-components';
import Modal from 'react-modal';
import useScreenType from 'libs/hooks/useScreenType';

interface ConfirmInterface {
  visible: boolean;
  setVisible: Dispatch<boolean>;
  height?: number;
  title: string;
  subtitle?: string;
  confirmText?: string;
  closeText?: string;
  onConfirm?: () => void;
  onDismiss?: () => void;
}

const Confirm = observer(
  ({
    visible,
    setVisible,
    title,
    subtitle,
    onConfirm,
    onDismiss,
    confirmText,
    closeText,
  }: ConfirmInterface) => {
    const screenType = useScreenType();
    const handleClickClose = async () => {
      if (typeof onDismiss !== 'undefined') {
        await onDismiss();
      }
      setVisible(false);
    };

    const handleClickConfirm = async () => {
      if (typeof onConfirm !== 'undefined') {
        await onConfirm();
      }

      if (typeof onConfirm === 'undefined') {
        if (typeof onDismiss !== 'undefined') {
          await onDismiss();
        }
      }
      setVisible(false);
    };

    const { t } = useTranslation();

    return (
      <Modal
        isOpen={visible}
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)', zIndex: 100 },
          content: {
            margin: 'auto',
            height: `${screenType === 'window' ? '214px' : '200px'}`,
            width: `${screenType === 'window' ? '400px' : ''}`,
            border: '0',
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            borderRadius: 8,
          },
        }}
        ariaHideApp={false}
      >
        <ContentStyle>
          <TitleStyle isSingle={typeof subtitle === 'undefined'}>{title}</TitleStyle>
          {typeof subtitle !== 'undefined' && <SubtitleStyle>{subtitle}</SubtitleStyle>}
        </ContentStyle>
        <ButtonAreaStyle>
          {typeof onConfirm !== 'undefined' && (
            <ButtonStyle backgroundColor={'#DDDDDD'} onClick={handleClickClose}>
              {closeText || t('close')}
            </ButtonStyle>
          )}
          <ButtonStyle
            backgroundColor={typeof onConfirm !== 'undefined' ? Theme.colors.pointColor : 'white'}
            fontColor={typeof onConfirm !== 'undefined' ? Theme.colors.pointColor : 'black'}
            onClick={handleClickConfirm}
          >
            {confirmText || t('confirm')}
          </ButtonStyle>
        </ButtonAreaStyle>
      </Modal>
    );
  },
);

const ContentStyle = styled.div`
  height: 74px;
  padding: 20px 20px 20px 20px;
`;

const TitleStyle = styled.div<{ isSingle?: boolean }>`
  /* margin: 0 0 ${(props) => (props.isSingle ? 0 : 30)}px 0; */
  /* text-align: ${(props) => (props.isSingle ? 'center' : 'left')}; */

  @media ${(props) => props.theme.media.mobile} {
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
  }
  @media ${(props) => props.theme.media.desk} {
    font-size: 18px;
    text-align: center;
    margin: 20px 0 0 0;
  }
`;

const SubtitleStyle = styled.div`
  font-size: 16px;
  margin: 20px 0 0 0;
  color: #666666;
  text-align: center;
`;

const ButtonAreaStyle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 50px;
  padding: 20px 20px 20px 20px;
`;

const ButtonStyle = styled.div<{ backgroundColor?: string; fontColor?: string }>`
  display: flex;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f6f6f6;
  color: ${(props) => props.fontColor};
  align-items: center;
  justify-content: center;
  font-weight: 500;
  @media ${(props) => props.theme.media.mobile} {
    width: 120px;
    height: 50px;
  }
  @media ${(props) => props.theme.media.desk} {
    width: 175px;
    height: 60px;
  }
`;

export default Confirm;
