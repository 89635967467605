import React, { useRef, ChangeEvent } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

type Props = {
  range: number;
  width: number;
  maxRange: number;
  isVisible: boolean;
  type: 'Background' | 'Profile';
  onClickType: () => void;
  onControlChange: (range: number) => void;
};

const Controller = observer(
  ({ range, width, maxRange = 0, type, isVisible, onClickType, onControlChange }: Props) => {
    const { uiStore } = useStore();
    const bottomControlRef = useRef<HTMLInputElement>(null);

    /** range가 변경될때 그 값을 담는다 */
    const onChangeRange = (event: ChangeEvent<HTMLInputElement>) => {
      onControlChange(Number(event.target.value));
    };

    return (
      <ControllerStyle isVisible={isVisible} width={width} onClick={onClickType}>
        <TopControllerStyle>
          {/* <ArrowStyle src="/image/leftArrow.png" onClick={handleClickLeftArrow} /> */}
          <TopControllerTextStyle>
            {type}
            <img src="/image/edit.png" alt="" width={50} />
          </TopControllerTextStyle>
          {/* <ArrowStyle src="/image/rightArrow.png" onClick={handleClickRightArrow} /> */}
        </TopControllerStyle>
        {/* <BottomControllerStyle
          type="range"
          min="0"
          value={range}
          max={maxRange}
          ref={bottomControlRef}
          onChange={(event) => onChangeRange(event)}
        /> */}
      </ControllerStyle>
    );
  },
);

const displayAlignCenter = css`
  display: flex;
  align-items: center;
`;

const ArrowStyle = styled.img.attrs({
  alt: '',
  width: 7,
  height: 14,
})`
  padding: 0 24px;
  cursor: pointer;
`;

const ControllerStyle = styled.div<{ isVisible: boolean; width: number }>`
  /* border-radius: 0px 0px 31px 31px; */
  border-radius: 30px;
  left: 10px;
  right: 10px;
  background-color: #ff8e70;
  ${displayAlignCenter}
  flex-direction: column;
  padding: 13px 0 13px 0;
  position: absolute;
  letter-spacing: 2px;
  z-index: 12;
  bottom: 10px;
  color: #fff;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  ${(props) => !props.isVisible && `display: none`};
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #ffae98;
  }
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const TopControllerStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  text-align: center;

  ${displayAlignCenter};
`;

const TopControllerTextStyle = styled.div`
  ${displayAlignCenter}
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 700px;
  transition: all 0.5s ease-in-out;
  border-radius: 15px;

  > img {
    margin: 0 0 0 5px;
  }
`;

const BottomControllerStyle = styled.input`
  -webkit-appearance: none;
  margin: 30px 0 30px 0;
  width: 90%;
  background: transparent;
  &::-webkit-slider-runnable-track {
    height: 40%;
    cursor: pointer;
    border-radius: 3px;
    background: #ebebeb;
    ${displayAlignCenter}
    justify-content: center;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #80e630;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    margin: -8px 0 0 0;
    cursor: pointer;
  }
`;

export default Controller;
