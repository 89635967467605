import React, { useEffect, useState } from 'react';
import { ROOT_HOST, TOSSPAY_KEY } from 'stores/AppStore';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { OrderDto } from 'services/data-contracts';

const TossPayment = observer(
  ({ title, qty, amount }: { title?: string; qty?: number; amount?: number }) => {
    const { paymentStore } = useStore();
    const { userName, id } = useParams();

    const init = async () => {
      if (typeof title === 'undefined') {
        return;
      }

      if (typeof qty === 'undefined') {
        return;
      }

      if (typeof amount === 'undefined') {
        return;
      }
      if (typeof TOSSPAY_KEY !== 'undefined') {
        const time = Date.now();
        const tossPayments = await loadTossPayments(TOSSPAY_KEY);
        tossPayments.requestPayment('카드', {
          amount: qty * amount,
          orderId: `karbot${time}`,
          orderName: title,
          customerName: userName,
          successUrl: `${ROOT_HOST}payments/success?username=${userName}`,
          failUrl: `${ROOT_HOST}payments/fail`,
        });
      }
    };

    const order = () => {
      if (typeof qty === 'undefined') {
        return;
      }

      if (typeof amount === 'undefined') {
        return;
      }
      //paymentStore.requestOrder();
      const order: OrderDto = {
        shop: {
          id: 346,
        },
        tableNo: id,
        amount: qty * amount,
        items: [
          {
            item: {
              id: 10,
            },
            qty: 2,
            amount: 30000,
          },
          {
            item: {
              id: 15,
            },
            qty: 1,
            amount: 125000,
          },
        ],
      };
    };

    useEffect(() => {
      init();
      return () => {};
    }, []);

    return <></>;
  },
);

export default TossPayment;
