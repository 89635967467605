import React, { CSSProperties, useEffect, useState } from 'react';
import { TextArea, TextToolBox, ImageUploader } from 'components/commons';
import { CustomUserProfile } from 'types/CommonTypes';
import BackgroundButton from 'components/commons/BackgroundButton';
import 'react-spring-bottom-sheet/dist/style.css';

const FooterModal = ({
  model,
  onValueChange,
  onStyleChange,
  onImageChange,
}: {
  model?: CustomUserProfile;
  onValueChange: (value: string) => void;
  onStyleChange: (style: CSSProperties) => void;
  onImageChange: (image: Blob | null) => void;
}) => {
  const [footerImage, setFooterImage] = useState<string | undefined>();

  useEffect(() => {
    if (typeof model?.footerImage !== 'undefined') {
      setFooterImage(model?.footerImage?.publicUrl);
    }
  }, [model]);

  return (
    <>
      <TextToolBox
        style={model?.footerProps?.style}
        show={true}
        onChangeStyle={(style: CSSProperties) => {
          onStyleChange(style);
        }}
      />

      <TextArea
        style={{
          height: 60,
          textAlign: 'center',
          ...model?.footerProps?.style,
        }}
        containerStyle={containerStyle}
        defaultValue={model?.footerTitle}
        onChangeValue={(value: string) => {
          onValueChange(value);
        }}
      />
      <ImageUploader
        attachType="image"
        cropSize={{ width: 800, height: 800 }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: 100,
          margin: 'auto',
          marginBottom: 20,
        }}
        onSelected={(thumbnails: any) => {
          if (thumbnails.length === 0) {
            // updateModel('profileImage', null);
          } else {
            //updateModel('profileImage', thumbnails[0]);
          }

          setFooterImage(thumbnails[0].publicUrl);
          onImageChange(thumbnails[0]);
        }}
      >
        <BackgroundButton
          image={footerImage}
          onImageDeleted={() => {
            setFooterImage(undefined);
            onImageChange(null);
          }}
        />
      </ImageUploader>
    </>
  );
};

const containerStyle = {
  background: 'rgba(0, 0, 0, 0.05)',
  border: '1px dashed rgba(0, 0, 0, 0.2)',
  backdropFilter: 'blur(4px)',
};

export default FooterModal;
