import React, { useEffect, useState } from 'react';
import { ItemContainerStyle } from 'features/adminFeatures/template/components/ItemContainerStyle';
import { GoogleMap } from 'components/maps';
import { Map, MapLinkModel } from 'types/CommonTypes';
import styled from 'styled-components';
import CardHeader from './CardHeader';

function MapLinkComponent({ item, height }: { item: MapLinkModel; height: number }) {
  const [map, setMap] = useState<Map>();
  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(false);

  const handleExpand = (value: boolean) => {
    setShowMap(value);
  };

  useEffect(() => {
    if (typeof item !== 'undefined') {
      const c = typeof item.custom !== 'undefined' ? JSON.parse(item.custom) : {};
      setIsCollapse(c.isCollapsed);
      setMap({
        lat: Number(item.latitude),
        lng: Number(item.longitude),
        title: item.title as string,
        addr: item.address as string,
      });
    }
  }, [item]);

  useEffect(() => {
    console.log(height);
  }, [height]);

  return (
    <ItemContainerStyle>
      {isCollapse ? (
        <>
          <CardHeader
            onExpand={(value: boolean) => {
              handleExpand(value);
            }}
          >
            {map?.title}
          </CardHeader>
          <MapContentStyle collapsed={!showMap} height={height}>
            {typeof map !== 'undefined' && <GoogleMap map={map} />}
          </MapContentStyle>
          <BottomStyle collapsed={!showMap}>
            <CardHeader>{item?.address}</CardHeader>
          </BottomStyle>
        </>
      ) : (
        <>
          <MapContentStyle height={height}>
            {typeof map !== 'undefined' && <GoogleMap map={map} />}
          </MapContentStyle>
          <CardHeader>{map?.title}</CardHeader>
        </>
      )}
    </ItemContainerStyle>
  );
}

const MapContentStyle = styled.div<{ collapsed?: boolean; height: number }>`
  height: ${(props) => (props.collapsed ? 0 : `${props.height}px`)};
  transition: all 0.5s ease-in-out;
`;

const BottomStyle = styled.div<{ collapsed?: boolean }>`
  height: ${(props) => (props.collapsed ? 0 : '60px')};
  transition: all 0.5s ease-in-out;
`;
export default React.memo(MapLinkComponent);
