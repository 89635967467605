import React, { useRef, useState, useEffect } from 'react';
import {
  LinkEditorStyle,
  RegisterButtonStyle,
  ImageUploaderStyle,
  EditorWrapperStyle,
} from 'resources/styles/link/styles';
import { updateLink, updateModel } from 'components/items/commonLogic';
import { defaultLinkEditorProps } from 'types/CommonTypes';
import { LinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { isValid } from 'libs/helper/utils';
import { toast } from 'react-toastify';
import { ImageUploader } from 'components/commons';
import InputComponent from 'components/items/InputComponent';
import SimplePreview from 'components/items/SimplePreview';
import useForm from 'libs/hooks/useForm';
import styled, { css } from 'styled-components';

const TelLinkEditor = ({ model, stores, onRegistComplete }: defaultLinkEditorProps<any>) => {
  const isFormValid = useForm(stores.formValidationStore);
  const [linkModel, setLinkModel] = useState<LinkDto>();
  const { t } = useTranslation();
  // const titleRef = useRef<any>(null);
  // const telRef = useRef<any>(null);

  // const stores = useStore();
  const handleSaveLink = async () => {
    const result = await updateLink(linkModel!, isValid(model), { ...stores });
    onRegistComplete(result!);
    if (result!) {
      toast(t('linkRegistration'));
    }
  };

  useEffect(() => {
    if (typeof model !== 'undefined') {
      setLinkModel(model);
    } else {
      setLinkModel({ linkType: 'TelLink' });
    }
  }, [model]);

  useEffect(() => {
    return () => {
      // titleRef.current.value = '';
      // telRef.current.value = '';
    };
  }, []);

  return (
    <>
      <LinkEditorStyle>
        <EditorWrapperStyle>
          <ImageUploaderStyle>
            <ImageUploader
              attachType="image"
              style={{ textAlign: 'left' }}
              cropSize={{ width: 200, height: 200 }}
              onSelected={(thumbnail: any) => {
                updateModel(setLinkModel, 'thumbnail', thumbnail[0]);
              }}
            >
              <CameraImageStyle src="/image/camera.svg" alt="" />
            </ImageUploader>
          </ImageUploaderStyle>
          {typeof linkModel?.thumbnail?.publicUrl !== 'undefined' && (
            <TrashImageContainerStyle
              onClick={() => {
                updateModel(setLinkModel, 'thumbnail', undefined);
                toast(t('imageDelete'));
              }}
            >
              <TrashImageStyle src="/image/trash.png" alt="" />
            </TrashImageContainerStyle>
          )}
          <InputContainerStyle>
            <InputComponent
              type="Title"
              id="TelTitle"
              required
              placeholder="Link title"
              defaultValue={linkModel?.title}
              divStyle={divStyle}
              inputStyle={inputStyle}
              onChangeValue={(value: string) => {
                updateModel(setLinkModel, 'title', value);
              }}
            />
            <InputComponent
              type="Number"
              inputType="number"
              /** 모바일 ios에서도 키패드가 나오려면 pattern 이 필요합니다 */
              pattern="\d*"
              id="phoneNumber"
              required
              placeholder="Phone number"
              defaultValue={linkModel?.phoneNumber}
              divStyle={divStyle}
              inputStyle={inputStyle}
              onChangeValue={(value: string) => {
                updateModel(setLinkModel, 'phoneNumber', value);
              }}
            />
          </InputContainerStyle>
        </EditorWrapperStyle>
        <RegisterButtonStyle active={isFormValid.result} onClick={handleSaveLink}>
          {isFormValid.result ? (
            <RegisterButtonImgStyle src="/image/check_active.svg" />
          ) : (
            <RegisterButtonImgStyle src="/image/check_inactive.svg" />
          )}
        </RegisterButtonStyle>
      </LinkEditorStyle>
      <SimplePreviewContainerStyle>
        <SimplePreview model={linkModel} type="inine" />
      </SimplePreviewContainerStyle>
    </>
  );
};

const displayAlignCenter = css`
  display: flex;
  align-items: center;
`;

const divStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const inputStyle = {
  height: '20px',
  background: 'white',
  fontSize: '16px',
  paddingLeft: 0,
  paddingTop: 0,
};

const CameraImageStyle = styled.img`
  width: 25px;
  height: 25px;
`;

const TrashImageContainerStyle = styled.div`
  position: relative;
  z-index: 1;
  top: -20px;
  left: -25px;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
`;

const TrashImageStyle = styled.img`
  width: 15px;
  height: 15px;
`;

const InputContainerStyle = styled.div`
  width: 310px;
  margin: 0 0 0 10px;
  > div {
    height: 40px !important;
    ${displayAlignCenter}
  }
  > div > div {
    height: 25px !important;
    padding: 0 0 0 0 !important;
  }
  @media ${(props) => props.theme.media.mobile} {
    width: 160px;
  }
`;

const RegisterButtonImgStyle = styled.img.attrs({ alt: '', width: '35px', height: '35px' })``;

const SimplePreviewContainerStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > div:last-child {
    width: 100%;
    > div > div {
      width: auto;
    }
  }
`;

export default TelLinkEditor;
