import React, { useState, useRef, useCallback, useEffect } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import useClickOutside from 'libs/hooks/useOnClickOutside';
import styled from 'styled-components';

const ColorPicker = ({
  pickerStyle,
  containerStyle,
  children,
  className,
  defaultValue,
  show,
  onClose,
  onColorChanged,
}: {
  pickerStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  className?: string;
  defaultValue?: string;
  show?: boolean;
  children: React.ReactNode;
  color?: string;
  onClose?: () => void;
  onColorChanged: (color: string) => void;
}) => {
  const popover = useRef<HTMLDivElement>(null);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [hexColor, setHexColor] = useState<string>();

  const close = useCallback(() => {
    setShowPicker(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [showPicker]);

  useClickOutside(popover, close);

  useEffect(() => {
    if (typeof show !== 'undefined') {
      setShowPicker(show);
    }
  }, [show]);

  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      setHexColor(defaultValue);
    } else {
      setHexColor('#000000');
    }
  }, [defaultValue]);

  return (
    <PickerButtonStyle
      style={containerStyle}
      onClick={() => setShowPicker(true)}
      className="center-all2"
    >
      {children}
      {showPicker && (
        <PickerStyle
          className={typeof className !== 'undefined' ? className : 'popover'}
          ref={popover}
          style={pickerStyle}
        >
          <PickInnerStyle>
            <HexColorPicker
              style={{ margin: 'auto' }}
              color={defaultValue}
              onChange={(color: string) => {
                setHexColor(color);
                onColorChanged(color);
              }}
            />
            <HexContainerStyle className="row">
              <HexTitleStyle>HEX</HexTitleStyle>
              <HexColorInput
                style={{ width: '60%', textAlign: 'center', height: 30, border: '1px solid #eee' }}
                color={hexColor}
                onChange={(color: string) => {
                  setHexColor(color);
                  onColorChanged(color);
                }}
              />
            </HexContainerStyle>
          </PickInnerStyle>
        </PickerStyle>
      )}
    </PickerButtonStyle>
  );
};

const PickerButtonStyle = styled.div``;

const PickerStyle = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;

const PickInnerStyle = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  background: #fff;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 18px;
    text-align: center;
    margin: -22px 0 0 0;
  }
`;

const HexContainerStyle = styled.div`
  width: 100%;
  text-align: center;
  background: #fff;
  height: 35px;
  line-height: 2px;
  margin: 10px 0 0 0;
`;

const HexTitleStyle = styled.div`
  display: flex;
  align-items: center;
`;

export default React.memo(ColorPicker);
