import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { GnbUserHome } from 'features/commonFeatures/navigationBar/containers';
import { validAll } from 'libs/helper/utils';

const OrderInfoPage = observer(() => {
  const params = useParams();
  const { uiStore } = useStore();
  const { t } = useTranslation();

  const init = () => {};

  useEffect(() => {
    init();
  }, [params]);

  return <>주문내역확인</>;
});

export default OrderInfoPage;
