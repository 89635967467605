import React, { useEffect, useState, useRef } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { CommonCenterAllStyle } from 'resources/styles/common/styles';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import QRCodePrintModal from 'components/commons/QRCodePrintModal';
import styled from 'styled-components';

type Props = {
  username?: string;
};

const QRContainer = observer(({ username }: Props) => {
  const { t } = useTranslation();
  const { meStore } = useStore();
  const [showModal, setShowModal] = useState<boolean>(false);
  const qrRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    meStore.getMyProfile();
  }, [meStore]);

  return (
    <>
      {typeof meStore.myProfile !== 'undefined' && (
        <Container>
          <CommonCenterAllStyle ref={qrRef}>
            <QRCode
              size={274}
              value={`https://ohmoss.com/${
                typeof username !== 'undefined' ? username : meStore.myProfile?.userName
              }`}
            />
          </CommonCenterAllStyle>
          <LinkText>{`https://ohmoss.com/${
            typeof username !== 'undefined' ? username : meStore.myProfile?.userName
          }`}</LinkText>
          <CommonCenterAllStyle>
            <SaveButton
              // onClick={() => exportComponentAsPNG(qrRef)}
              onClick={() => setShowModal(true)}
            >
              {t('saveQr')}
            </SaveButton>
          </CommonCenterAllStyle>
        </Container>
      )}
      <QRCodePrintModal
        qrRef={qrRef}
        onClose={() => {
          setShowModal(false);
        }}
        userName={typeof username !== 'undefined' ? username : meStore.myProfile?.userName}
        show={showModal}
      />
    </>
  );
});

const Container = styled.div`
  height: 451px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  padding: 30px 30px 30px 30px;
  margin: 50px 0 0 0;
`;

const LinkText = styled.div`
  font-size: 16px;
  justify-content: center;
  align-items: center;
  margin: 30px 0 30px 0px;
`;

const SaveButton = styled.div<{ marginLeft?: number }>`
  color: #3e64ea;
  font-size: 14px;
  width: 126px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3e64ea;
  border-radius: 20px;
  cursor: pointer;
`;

export default QRContainer;
