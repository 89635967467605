import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FileDto, UserBackgroundImageDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import useScreenType from 'libs/hooks/useScreenType';
import styled from 'styled-components';
import { t } from 'i18next';

type DefaultProps = {
  index: number;
  width: number;
  height: number;
  onApply: (image?: FileDto) => void;
  onSlideChange: (swipe: any) => void;
  onChangeControlType: () => void;
};

const TemplateEditorSwipe = observer(
  ({ index = 0, width, height, onApply, onSlideChange, onChangeControlType }: DefaultProps) => {
    const screenType = useScreenType();
    const [swiper, setSwiper] = useState<any>(null);
    const { meStore, simulatorStore } = useStore();

    const init = async () => {
      try {
        await meStore.getBackgroundImages();
      } catch (e) {
        toast('배경사진 불러오기에 실패했습니다', { position: 'top-center' });
      }
    };

    /** 해당 방식은 swiper/react 원격 조정방식 */
    // useEffect(() => {
    //   if (swiper) {
    //     swiper.slideTo(index);
    //   }
    // }, [index]);

    useEffect(() => {
      if (meStore.backgroundImages.length === 0) {
        return;
      }
      const id = simulatorStore?.profile?.backgroundImage?.id;
      if (typeof id !== 'undefined') {
        const index = meStore.backgroundImages.findIndex((item: UserBackgroundImageDto) => {
          return item.image?.id === id;
        });
        if (index > -1) {
          if (swiper !== null) swiper.slideTo(index);
        }
      }
    }, [meStore.backgroundImages]);

    useEffect(() => {
      if (swiper !== null) init();
    }, [swiper]);

    return (
      <SwiperContainerStyle width={width * 3}>
        <Swiper
          style={{
            width: width * 3,
            height: screenType === 'window' ? height : '100%',
            marginTop: -1,
            padding: 10,
            margin: 10,
          }}
          centeredSlides
          spaceBetween={10}
          slidesPerView={3}
          grabCursor
          onSlideChange={(swiper) => {
            onSlideChange(swiper.activeIndex);
            onChangeControlType();
          }}
          /** 해당 방식은 swiper/react 원격 조정방식 */
          onSwiper={setSwiper}
        >
          {meStore.backgroundImages.map((item) => (
            <SwiperSlide key={item?.image?.id} onClick={() => onApply(item?.image)}>
              <SlideImgStyle src={item?.image?.publicUrl} />
            </SwiperSlide>
          ))}
        </Swiper>
        {meStore.backgroundImages.length > 0 && (
          <GuideTextStyle>{t('clickToApply')}</GuideTextStyle>
        )}
      </SwiperContainerStyle>
    );
  },
);

const SwiperContainerStyle = styled.div<{ width: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width}px;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  @media ${(props) => props.theme.media.mobile} {
    width: calc(300%);
    height: calc(100% - 120px);
  }
`;

const SwiperStyle = styled(Swiper)<{ currentWidth: number; currentHeight: number }>`
  width: ${(props) => props.currentWidth * 3}px;
  height: ${(props) => props.currentHeight}px;
  @media ${(props) => props.theme.media.mobile} {
    /* 모바일 사이즈 조절(약간 보이게 TGKIM) */
    width: calc(100% - 120px);
    height: 100%;
  }
`;

const SlideImgStyle = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 40px;
  border: 8px solid transparent;
`;

const GuideTextStyle = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: yellow;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 7px 0px #000;
`;
export default TemplateEditorSwipe;
