import { RoutePropInterface } from 'types/CommonTypes';
import * as Pages from 'pages';
import * as AdminPages from 'pages/adminPages';

const mainRoutes: RoutePropInterface[] = [
  {
    path: '/',
    title: 'livelink',
    element: Pages.HomePage,
  },
  {
    path: '/admin/',
    title: 'livelink-admin-main',
    element: AdminPages.AdminMainPage,
  },
  {
    path: '/admin/spacepage/:type',
    title: 'livelink-admin',
    element: AdminPages.SpacePage,
  },
  {
    path: '/admin/profilepage',
    title: 'livelink-profile',
    element: AdminPages.ProfilePage,
  },
  {
    path: '/admin/account',
    title: '계정',
    element: AdminPages.AccountPage,
  },
  {
    path: '/admin/account/info',
    title: '계정 정보',
    element: AdminPages.AccountInfoPage,
  },
  {
    path: '/admin/sociallinks',
    title: 'livelink-sociallinks',
    element: AdminPages.SocialLinksPage,
  },
  {
    path: '/:userName',
    title: 'livelink',
    authenticated: false,
    element: Pages.UserHomePage,
  },
  {
    path: '/:userName/menus/:id',
    title: '메뉴모아보기',
    authenticated: false,
    element: Pages.UserMenuPage,
  },
  {
    path: '/company/info',
    title: '회사소개',
    element: Pages.CompanyInfoPage,
  },
  {
    path: '/:userName/pages/:linkId',
    title: '페이지',
    element: Pages.PageLinkPage,
  },
  {
    path: '/:userName/:follow',
    title: 'Follow',
    element: Pages.UserHomePage,
  },
  {
    path: '/report',
    title: '신고하기',
    element: Pages.ReportPage,
  },
  // {
  //   path: '/createlink',
  //   title: '링크추가',
  //   element: Pages.CreateLinkPage,
  // },
  {
    path: '/login',
    title: '로그인',
    authenticated: false,
    element: Pages.LoginPage,
  },
  // {
  //   path: '/login/kakao',
  //   title: '카카오 로그인',
  //   element: Pages.KakaoLoginPage,
  // },
  // {
  //   path: '/login/google',
  //   title: '구글 로그인',
  //   element: Pages.GoogleLoginPage,
  // },

  // {
  //   path: "/faq",
  //   title: "FAQ",
  //   element: Pages.FaqPage,
  // },

  {
    path: '/my',
    title: '마이페이지',
    element: Pages.MyPage,
  },
  {
    path: '/edit-profile',
    title: '프로필 소개 수정',
    element: Pages.EditProfilePage,
  },
  {
    path: '/sign-up',
    title: '회원가입 페이지',
    element: Pages.SignUpPage,
  },
  {
    path: '/use-terms',
    title: '이용약관',
    element: Pages.UseTermsPage,
  },
  {
    path: '/service-terms',
    title: '서비스 이용방침',
    element: Pages.ServiceTermsPage,
  },
  {
    path: '/private-terms',
    title: '개인정보 처리방침',
    element: Pages.PrivateTermsPage,
  },
  {
    path: '/location-terms',
    title: '위치기반서비스 이용약관',
    element: Pages.LocationTermsPage,
  },
  {
    path: '/marketing-terms',
    title: '마케팅 활용 및 수신동의',
    element: Pages.MarketingTermsPage,
  },
  {
    path: '/email-auth',
    title: '인증메일 발송 정보',
    element: Pages.EmailAuthPage,
  },
  {
    path: '/verify',
    title: '인증메일 발송',
    element: Pages.VerifyPage,
  },
  {
    path: '/email',
    title: '인증메일 화면',
    element: Pages.EmailPage,
  },
  {
    path: '/find-id',
    title: '아이디/비밀번호 찾기',
    element: Pages.FindIdPage,
  },
  {
    path: '/reset-password',
    title: '패스워드 변경',
    element: Pages.ResetPassword,
  },
  {
    path: '/qr-code',
    title: 'QR코드',
    element: Pages.QRCodePage,
  },
  {
    path: '/en-useTerms',
    title: '미국 이용약관',
    element: Pages.EnUseTermsPage,
  },
  {
    path: '/cn-useTerms',
    title: '중국 이용약관',
    element: Pages.CnUseTermsPage,
  },
  {
    path: '/jp-useTerms',
    title: '일본 이용약관',
    element: Pages.JpUseTermsPage,
  },
  {
    path: '/payments',
    title: '결제화면',
    element: Pages.PaymentPage,
  },
  {
    path: '/payments/success',
    title: '결제성공',
    element: Pages.PaymentSuccessPage,
  },
  {
    path: '/payments/fail',
    title: '결제실패',
    element: Pages.PaymentFailPage,
  },
  {
    path: '/my/orderinfo',
    title: '주문정보',
    element: Pages.OrderInfoPage,
  },
];

export default mainRoutes;
