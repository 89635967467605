import React, { useEffect, useState } from 'react';
import { UserInfoDto } from 'services/data-contracts';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import TemplateManagerContainer from 'features/adminFeatures/template/containers/TemplateManagerContainer';
import SocialLinksContainer from 'features/adminFeatures/link/containers/SocialLinksContainer';
import TemplateContainer from 'features/adminFeatures/template/containers/TemplateContainer';
import LinksContainer from 'features/adminFeatures/link/containers/LinksContainer';
import Simulator from 'features/adminFeatures/simulator/containers/Simulator';
import ProfilePage from 'pages/adminPages/ProfilePage';
import useScreenType from 'libs/hooks/useScreenType';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

/**
 * @deprecated
 */
const SpacePage = observer(() => {
  const { type } = useParams();
  const screenType = useScreenType();
  const { meStore, templateStore, linkStore } = useStore();
  const [isShowSimulator, setIsShowSimulator] = useState<boolean>(false);

  const getPageByType = () => {
    if (type === 'links') {
      return <LinksContainer />;
    } else if (type === 'profile') {
      return (
        <ProfilePage
          onShowSimulator={() => setIsShowSimulator(true)}
          onCloseSimulator={() => setIsShowSimulator(false)}
        />
      );
    } else if (type === 'sociallinks') {
      return <SocialLinksContainer />;
    } else if (type === 'template') {
      return (
        <TemplateContainer
          onShowSimulator={() => setIsShowSimulator(true)}
          onCloseSimulator={() => setIsShowSimulator(false)}
        />
      );
    } else if (type === 'templatemanager') {
      return (
        <TemplateManagerContainer
          onShowSimulator={() => setIsShowSimulator(true)}
          onCloseSimulator={() => setIsShowSimulator(false)}
        />
      );
    }
  };

  useEffect(() => {
    if (typeof linkStore.links !== 'undefined') {
      //templateStore.setUserInfo({ ...templateStore.userInfo, links: linkStore.links });
    }
  }, [linkStore.links]);

  useEffect(() => {
    if (isShowSimulator && type !== 'links') {
      setIsShowSimulator(false);
    }
  }, [type]);

  useEffect(() => {
    templateStore.setSimulatorMode(true);
    const init = async () => {
      // profile 취득
      const me: any = await meStore.getMe();
      if (typeof me !== 'undefined') {
        // const data: UserInfoDto | any = await userStore.getUserInfo(profile?.userName);
        // const test = await userStore.getUserInfo(profile?.userName);
        // template Store에 기본 모델을 바인딩하여 변경을 바로 반영할수 있도록 한다.
        // templateStore.setModel(Object.assign(data, defaultCustomProps));
        // templateStore.setModel(data?.profile);
        templateStore.setUserInfo(me);
        templateStore.setSimulatorMode(true);
        // templateStore.setTemplate(data?.profile?.template);
      }
    };
    init();
  }, []);

  return (
    <>
      <ContainerStyle>
        {screenType === 'mobile' ? (
          <>
            <LeftMobileContainerStyle>{getPageByType()}</LeftMobileContainerStyle>
            <MobileContainerStyle isShowSimulator={isShowSimulator}>
              {isShowSimulator && <Simulator isShowSimulator={isShowSimulator} />}
              <CloseContainerStyle
                style={{
                  position: 'fixed',
                  bottom: isShowSimulator ? 10 : -80,
                  width: 50,
                  height: 50,
                  background: '#fff',
                  borderRadius: 50,
                  textAlign: 'center',
                  lineHeight: 4,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  cursor: 'pointer',
                  zIndex: 100000,
                  transitionDuration: '0.45s',
                  transitionTimingFunction: 'ease',
                }}
                onClick={() => {
                  setIsShowSimulator(false);
                }}
              >
                <CloseIcon style={{ fontSize: 30 }} />
              </CloseContainerStyle>
            </MobileContainerStyle>
          </>
        ) : (
          <>
            <LeftContainerStyle>{getPageByType()}</LeftContainerStyle>
            <SimulatorContainerStyle isShowSimulator>
              <Simulator isShowSimulator={isShowSimulator} />
            </SimulatorContainerStyle>
          </>
        )}
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
`;

const LeftMobileContainerStyle = styled.div`
  margin-top: 60px;
  width: 100%;
  z-index: 0;
`;

const LeftContainerStyle = styled.div`
  padding-top: 80px;
  width: 600px;
`;

const SimulatorContainerStyle = styled.div<{ isShowSimulator: boolean }>`
  width: 600px;
  height: 100%;
  z-index: 0;
  right: 0;
  max-width: 600px;
  margin: 50px 0 0 0;
  height: 800px;
`;

const MobileContainerStyle = styled.div<{ isShowSimulator: boolean }>`
  background: #000;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: ${(props) => (props.isShowSimulator ? 0 : window.innerWidth)}px;
  bottom: 0;
`;

const CloseContainerStyle = styled.div``;

export default SpacePage;
