import React, { CSSProperties, useEffect, useState, memo } from 'react';
import { isValid, isEmpty } from 'libs/helper/utils';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { CustomUserProfile } from 'types/CommonTypes';
import { defaultServiceImage } from 'types/BackgroundTypeList';
/**
 * @description 배경이미지를 표현한다.!!
 */
const BackgroundImageComponent = observer(
  ({
    style,
    profile,
    useEmpty,
    colorMode = false,
    onClick,
  }: {
    style?: CSSProperties;
    profile?: CustomUserProfile;
    useEmpty: boolean;
    colorMode?: boolean;
    onClick?: () => void;
  }) => {
    const [backgroundImage, setBackgroundImage] = useState<string>();

    useEffect(() => {
      if (isValid(profile)) {
        if (isValid(profile?.backgroundImage) && !colorMode) {
          setBackgroundImage(profile?.backgroundImage?.publicUrl);
        } else if (!isValid(profile?.backgroundColor)) {
          setBackgroundImage(defaultServiceImage.publicUrl);
        } else {
          setBackgroundImage(undefined);
        }
      }
    }, [profile]);

    return (
      <ImageBackgroundContainerStyle
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick();
          }
        }}
        style={{
          backgroundColor:
            typeof profile?.backgroundImage === 'undefined' ? profile?.backgroundColor : 'none',
          ...style,
        }}
      >
        {typeof backgroundImage !== 'undefined' && (
          <div style={{ width: '100%', height: '100%' }}>
            <ImageBackgroundStyle src={backgroundImage} />
          </div>
        )}
      </ImageBackgroundContainerStyle>
    );
  },
);

const ImageBackgroundContainerStyle = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
`;

const ImageBackgroundStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default memo(BackgroundImageComponent);
