import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { CustomUserProfile } from 'types/CommonTypes';
import GnbLanguage from 'features/commonFeatures/navigationBar/containers/GnbLanguage';
import MobileDrawer from 'components/commons/MobileDrawer';
import useScreenType from 'libs/hooks/useScreenType';
import MenuPopup from 'components/commons/MenuPopup';
import DehazeIcon from '@mui/icons-material/Dehaze';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import _ from 'lodash';

const GnbAdmin = observer(() => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [languageOpen, setLanguageOpen] = useState<boolean>(false);
  const [myProfile, setMyProfile] = useState<CustomUserProfile>();
  const { authStore, simulatorStore } = useStore();
  const screenType = useScreenType();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const menuList = [
    {
      title: `@${myProfile?.userName}`,
      onClick: () => {
        navigate(`/${myProfile?.userName}`);
        handleClickCloseMenu();
      },
    },
    {
      title: `Admin`,
      onClick: () => {
        navigate('/admin');
        handleClickCloseMenu();
      },
    },

    {
      title: t('logOut'),
      onClick: () => {
        handleClickLogout();
        handleClickCloseMenu();
      },
    },
    {
      title: t('account'),
      onClick: () => {
        navigate('/admin/account');
      },
    },
    {
      title: t('orderInfo'),
      onClick: () => {
        navigate('/user/orderinfo');
      },
    },
    {
      title: t('languages'),
      onClick: () => {
        setLanguageOpen(true);
      },
    },
  ];

  const menuListNoToken = [
    {
      title: t('signUp'),
      onClick: () => {
        navigate('/sign-up');
        handleClickCloseMenu();
      },
    },
    {
      title: t('logIn'),
      onClick: () => {
        navigate('/login');
        handleClickCloseMenu();
      },
    },
    {
      title: t('languages'),
      onClick: () => {
        setLanguageOpen(true);
      },
    },
  ];

  const handleGoMyProfile = () => {
    navigate('/');
    // navigate(`/${meStore.myProfile?.userName}`);
  };

  const handleClickMyProfile = () => {
    handleClickCloseMenu();
    // navigate('/admin/spacepage/profile');
    navigate('/admin');
    // navigate(`/${!_.isUndefined(me) && !_.isNull(me) && JSON.parse(me)?.userName}`);
  };

  const handleClickLogout = () => {
    authStore.logout();
    handleClickCloseMenu();
  };

  const handleClickOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setLanguageOpen(false);
  };

  const handleClickCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = (languageOpen: boolean) => {
    setLanguageOpen(languageOpen);
  };

  const checkMe = async () => {
    const me = await localStorage.getItem('me');
    if (me !== null) {
      const _me = JSON.parse(me);
      setMyProfile(_me?.profile);
      document.title = `oh!moss :: ${_me?.profile?.userName} admin`;
    }
  };

  const Logo = () => {
    return (
      <LogoContainerStyle onClick={handleGoMyProfile}>
        <LogoTitleStyle isAdmin={pathname.startsWith('/admin')}>
          <span>
            <img
              style={{ opacity: 0.8 }}
              width={110}
              src={
                pathname === '/admin' || pathname === '/'
                  ? '/images/ohmoss_logo_white.svg '
                  : '/images/ohmoss_logo.svg'
              }
            />
          </span>
          {/* <span style={{ fontSize: 15, fontWeight: 500, color: 'orange' }}> Beta </span> */}
        </LogoTitleStyle>
      </LogoContainerStyle>
    );
  };

  useEffect(() => {
    if (typeof simulatorStore.profile !== 'undefined') {
      setMyProfile(simulatorStore.profile);
    }
  }, [simulatorStore.profile]);

  useEffect(() => {
    checkMe();
  }, [pathname]);

  return (
    <GnbLayoutStyle>
      {screenType === 'window' ? (
        <>
          <Logo />
          {typeof myProfile === 'undefined' || pathname === '/login' ? (
            <MenuContainerStyle>
              <LanguageStyle>
                <GnbLanguage />
              </LanguageStyle>
              <ChildMenuStyle
                isActivated={pathname === '/sign-up'}
                onClick={() => {
                  navigate('/sign-up');
                }}
              >
                <MenuNameStyle>{t('signUp')}</MenuNameStyle>
              </ChildMenuStyle>
              <ChildMenuStyle
                isActivated={pathname === '/login'}
                onClick={() => {
                  navigate('/login');
                }}
              >
                <MenuNameStyle>{t('logIn')}</MenuNameStyle>
              </ChildMenuStyle>
            </MenuContainerStyle>
          ) : (
            <>
              <ProfileContainerStyle>
                <Button
                  onClick={handleClickOpenMenu}
                  size="small"
                  sx={{ height: '70px' }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <AvatarStyle
                    src={
                      !_.isUndefined(myProfile?.profileImage) && myProfile?.profileImage !== null
                        ? myProfile?.profileImage?.publicUrl
                        : '/image/dummyUser.png'
                    }
                  />
                </Button>
                <MenuPopup
                  languageOpen={languageOpen}
                  open={open}
                  anchorEl={anchorEl}
                  menuList={typeof myProfile === 'undefined' ? menuListNoToken : menuList}
                  handleClose={handleClickCloseMenu}
                />
              </ProfileContainerStyle>
            </>
          )}
        </>
      ) : (
        // <ShareButtonStyle>
        //   <Button
        //     onClick={handleClickOpenMenu}
        //     size="small"
        //     aria-controls={open ? 'account-menu' : undefined}
        //     aria-haspopup="true"
        //     aria-expanded={open ? 'true' : undefined}
        //     sx={{ color: 'black', verticalAlign: 'baseline', minWidth: '0px' }}
        //   >
        //     <DehazeIcon />
        //     <MobileDrawer
        //       open={open}
        //       languageOpen={languageOpen}
        //       menuList={menuList}
        //       handleClickOpen={handleClickOpen}
        //       handleClose={handleClickCloseMenu}
        //     />
        //   </Button>
        // </ShareButtonStyle>
        <>
          <div style={{ position: 'fixed', left: 10, top: 10 }}>
            <Logo />
          </div>
          <MobileMenuStyle>
            <ButtonStyle
              style={{ color: '#fff' }}
              onClick={handleClickOpenMenu}
              size="large"
              sx={{ height: '70px', color: 'black' }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <DehazeIcon style={{ color: screenType === 'mobile' ? '#000' : '#fff' }} />
            </ButtonStyle>
            <MobileDrawer
              open={open}
              languageOpen={languageOpen}
              menuList={typeof myProfile === 'undefined' ? menuListNoToken : menuList}
              handleClickOpen={handleClickOpen}
              handleClose={handleClickCloseMenu}
            />
          </MobileMenuStyle>
        </>
      )}
    </GnbLayoutStyle>
  );
});

const GnbLayoutStyle = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  height: 60px;
  margin-top: auto;
  /* background-color: white; */
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
  @media ${(props) => props.theme.media.mobile} {
    background-color: transparent;
    width: 100px;
    justify-content: flex-end;
    left: unset;
  }
`;

const LogoContainerStyle = styled.div`
  width: 15%;
  text-align: center;
  min-width: 100px;
  line-height: 55px;
  float: left;

  cursor: pointer;
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    margin: 0 10px 0 10px;
    text-align: start;
    float: none;
  }
  @media ${(props) => props.theme.media.desk} {
    margin: 0 10px 0 32px;
    margin-top: 10px;
  }
`;

const LogoTitleStyle = styled.div<{ isAdmin: boolean }>`
  display: inline;
  font-family: 'Barlow';
  font-size: 25px;
  font-weight: 600;
  @media ${(props) => props.theme.media.mobile} {
    display: ${(props) => (props.isAdmin && !props.theme.media.mobile ? 'block' : 'none')};
  }
`;

const MenuContainerStyle = styled.div`
  display: flex;
  /* background-color: white; */
  float: right;
  @media ${(props) => props.theme.media.mobile} {
    display: flex;
    width: 100%;
  }
`;

const LanguageStyle = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 45px;
  height: 45px;
  right: 160px;
  margin: 10px 20px 20px 20px;
`;

const ChildMenuStyle = styled.div<{ isActivated: boolean }>`
  width: 15%;
  min-width: 90px;
  height: ${(props) => (props.isActivated ? '57px' : '60px')};
  text-align: center;
  float: left;
  cursor: pointer;
  border-bottom: ${(props) => (props.isActivated ? '3px solid #3e64ea' : '')};
  &:hover {
    background-color: #3e64ea;
    color: #fff;
  }
`;

const MenuNameStyle = styled.p`
  margin: 20px 0 0 0;
`;

const ProfileContainerStyle = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  float: right;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  @media ${(props) => props.theme.media.mobile} {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const MobileMenuStyle = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonStyle = styled(Button)`
  @media ${(props) => props.theme.media.mobile} {
    color: white;
  }
`;

const AvatarStyle = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #efefef;
  margin-right: 20px;
  margin-top: 20px;
  box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.3);
`;

const ShareButtonStyle = styled.div`
  z-index: 100;
  right: 30px;
  top: 30px;
  width: 45px;
  margin: 20px 20px 20px 20px;
  height: 45px;
  line-height: 58px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
  opacity: 1;
  transition: width 0.2s, opacity 0.3s;
  align-items: flex-start;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
export default GnbAdmin;
