import React, { useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import FollowSummary from '../components/FollowSummary';
import Follow from '../components/Follow';

export const FollowButtonsContainer = observer(() => {
  const { followStore, meStore, templateStore, userStore } = useStore();
  const { userName } = useParams();

  const handleClickFollowList = () => {
    templateStore.setCurrentMode('follow');
  };
  const handleClickFollow = (isFollow: boolean) => {
    if (typeof meStore?.me === 'undefined') {
      return;
    }
    if (typeof userName !== 'undefined') {
      const result = isFollow ? followStore.follow(userName) : followStore.unFollow(userName);
    }
  };

  useEffect(() => {
    if (typeof userName !== 'undefined') {
      followStore.getFollowers(userName);
    }
  }, [userName]);
  useEffect(() => {
    const tt = userStore.userInfo;
  }, [userStore.userInfo]);

  return (
    <div className="row">
      <Follow
        follow={userStore.userInfo?.followInfo?.followedByViewer}
        onClick={(isFollow: boolean) => {
          handleClickFollow(isFollow);
        }}
      />
      <FollowSummary
        summary={followStore.userSummary}
        onClick={() => {
          handleClickFollowList();
        }}
      />
    </div>
  );
});
