/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  LinkDto,
  OrderDto,
  SocialLinkDto,
  UserBackgroundImageDto,
  UserConfigDto,
  UserInfoDto,
  UserProfileDto,
  UserProfileImageDto,
  UserStyleDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Me<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 내 소셜링크 수정
   *
   * @tags me
   * @name UpdateSocialLink
   * @summary 내 소셜링크 수정
   * @request PUT:/v1/me/social-links/{socialLinkId}
   * @secure
   */
  updateSocialLink = (socialLinkId: number, data: SocialLinkDto, params: RequestParams = {}) =>
    this.request<SocialLinkDto, any>({
      path: `/v1/me/social-links/${socialLinkId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 소셜링크 삭제
   *
   * @tags me
   * @name DeleteSocialLink
   * @summary 내 소셜링크 삭제
   * @request DELETE:/v1/me/social-links/{socialLinkId}
   * @secure
   */
  deleteSocialLink = (socialLinkId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/me/social-links/${socialLinkId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내게 요청한 팔로워 거부
   *
   * @tags me
   * @name RejectRequestedFollower
   * @summary 내게 요청한 팔로워 거부
   * @request PUT:/v1/me/requested-followers/{requestUserName}/reject
   * @secure
   */
  rejectRequestedFollower = (requestUserName: string, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/me/requested-followers/${requestUserName}/reject`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내게 요청한 팔로워 승인
   *
   * @tags me
   * @name CompleteRequestedFollower
   * @summary 내게 요청한 팔로워 승인
   * @request PUT:/v1/me/requested-followers/{requestUserName}/complete
   * @secure
   */
  completeRequestedFollower = (requestUserName: string, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/me/requested-followers/${requestUserName}/complete`,
      method: 'PUT',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 링크 조회
   *
   * @tags me
   * @name GetLink1
   * @summary 내 링크 조회
   * @request GET:/v1/me/links/{linkId}
   * @secure
   */
  getLink1 = (linkId: number, params: RequestParams = {}) =>
    this.request<LinkDto, any>({
      path: `/v1/me/links/${linkId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 링크 수정
   *
   * @tags me
   * @name UpdateLink
   * @summary 내 링크 수정
   * @request PUT:/v1/me/links/{linkId}
   * @secure
   */
  updateLink = (linkId: number, data: LinkDto, params: RequestParams = {}) =>
    this.request<LinkDto, any>({
      path: `/v1/me/links/${linkId}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 링크 삭제
   *
   * @tags me
   * @name DeleteLink
   * @summary 내 링크 삭제
   * @request DELETE:/v1/me/links/{linkId}
   * @secure
   */
  deleteLink = (linkId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/me/links/${linkId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 링크 상태 수정
   *
   * @tags me
   * @name UpdateLinkActive
   * @summary 내 링크 상태 수정
   * @request PATCH:/v1/me/links/{linkId}
   * @secure
   */
  updateLinkActive = (linkId: number, query: { isActive: boolean }, params: RequestParams = {}) =>
    this.request<LinkDto, any>({
      path: `/v1/me/links/${linkId}`,
      method: 'PATCH',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 링크 순번 수정
   *
   * @tags me
   * @name UpdateLinkSeq
   * @summary 내 링크 순번 수정
   * @request PUT:/v1/me/links/{linkId}/seq
   * @secure
   */
  updateLinkSeq = (linkId: number, data: LinkDto, params: RequestParams = {}) =>
    this.request<LinkDto, any>({
      path: `/v1/me/links/${linkId}/seq`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 링크목록 순번 수정
   *
   * @tags me
   * @name UpdateLinkSeq1
   * @summary 내 링크목록 순번 수정
   * @request PUT:/v1/me/links/seq
   * @secure
   */
  updateLinkSeq1 = (data: LinkDto, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/me/links/seq`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 레이아웃 스타일 조회
   *
   * @tags me
   * @name GetLayoutStyle
   * @summary 내 레이아웃 스타일 조회
   * @request GET:/v1/me/style/layout
   * @secure
   */
  getLayoutStyle = (params: RequestParams = {}) =>
    this.request<UserStyleDto, any>({
      path: `/v1/me/style/layout`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 레이아웃 스타일 저장
   *
   * @tags me
   * @name SaveLayoutStyle
   * @summary 내 레이아웃 스타일 저장
   * @request POST:/v1/me/style/layout
   * @secure
   */
  saveLayoutStyle = (data: UserStyleDto, params: RequestParams = {}) =>
    this.request<UserStyleDto, any>({
      path: `/v1/me/style/layout`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 링크Box 스타일 조회
   *
   * @tags me
   * @name GetBoxStyle
   * @summary 내 링크Box 스타일 조회
   * @request GET:/v1/me/style/box
   * @secure
   */
  getBoxStyle = (params: RequestParams = {}) =>
    this.request<UserStyleDto, any>({
      path: `/v1/me/style/box`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 링크Box 스타일 저장
   *
   * @tags me
   * @name SaveBoxStyle
   * @summary 내 링크Box 스타일 저장
   * @request POST:/v1/me/style/box
   * @secure
   */
  saveBoxStyle = (data: UserStyleDto, params: RequestParams = {}) =>
    this.request<UserStyleDto, any>({
      path: `/v1/me/style/box`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 소셜링크 목록 조회
   *
   * @tags me
   * @name GetSocialLinks
   * @summary 내 소셜링크 목록 조회
   * @request GET:/v1/me/social-links
   * @secure
   */
  getSocialLinks = (params: RequestParams = {}) =>
    this.request<SocialLinkDto[], any>({
      path: `/v1/me/social-links`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 소셜링크 등록
   *
   * @tags me
   * @name CreateSocialLink
   * @summary 내 소셜링크 등록
   * @request POST:/v1/me/social-links
   * @secure
   */
  createSocialLink = (data: SocialLinkDto, params: RequestParams = {}) =>
    this.request<SocialLinkDto, any>({
      path: `/v1/me/social-links`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 프로필 조회
   *
   * @tags me
   * @name GetProfile
   * @summary 내 프로필 조회
   * @request GET:/v1/me/profile
   * @secure
   */
  getProfile = (params: RequestParams = {}) =>
    this.request<UserProfileDto, any>({
      path: `/v1/me/profile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 프로필 저장
   *
   * @tags me
   * @name SaveProfile
   * @summary 내 프로필 저장
   * @request POST:/v1/me/profile
   * @secure
   */
  saveProfile = (data: UserProfileDto, params: RequestParams = {}) =>
    this.request<UserProfileDto, any>({
      path: `/v1/me/profile`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 링크목록 조회
   *
   * @tags me
   * @name GetLinks
   * @summary 내 링크목록 조회
   * @request GET:/v1/me/links
   * @secure
   */
  getLinks = (
    query?: { isActive?: boolean; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<LinkDto[], any>({
      path: `/v1/me/links`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 링크 등록
   *
   * @tags me
   * @name CreateLink
   * @summary 내 링크 등록
   * @request POST:/v1/me/links
   * @secure
   */
  createLink = (data: LinkDto, params: RequestParams = {}) =>
    this.request<LinkDto, any>({
      path: `/v1/me/links`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 설정 조회
   *
   * @tags me
   * @name GetConfig
   * @summary 내 설정 조회
   * @request GET:/v1/me/config
   * @secure
   */
  getConfig = (params: RequestParams = {}) =>
    this.request<UserConfigDto, any>({
      path: `/v1/me/config`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 설정 저장
   *
   * @tags me
   * @name SaveConfig
   * @summary 내 설정 저장
   * @request POST:/v1/me/config
   * @secure
   */
  saveConfig = (data: UserConfigDto, params: RequestParams = {}) =>
    this.request<UserConfigDto, any>({
      path: `/v1/me/config`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 정보 조회
   *
   * @tags me
   * @name GetMe
   * @summary 내 정보 조회
   * @request GET:/v1/me
   * @secure
   */
  getMe = (
    query?: { isActive?: boolean; page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<UserInfoDto, any>({
      path: `/v1/me`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내가 요청 중인 팔로잉 목록
   *
   * @tags me
   * @name GetRequestedFollowings
   * @summary 내가 요청 중인 팔로잉 목록
   * @request GET:/v1/me/requested-followings
   * @secure
   */
  getRequestedFollowings = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<UserProfileDto[], any>({
      path: `/v1/me/requested-followings`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내게 요청한 팔로워 목록
   *
   * @tags me
   * @name GetRequestedFollowers
   * @summary 내게 요청한 팔로워 목록
   * @request GET:/v1/me/requested-followers
   * @secure
   */
  getRequestedFollowers = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<UserProfileDto[], any>({
      path: `/v1/me/requested-followers`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내게 리젝당한 팔로워 목록
   *
   * @tags me
   * @name GetRejectedFollowers
   * @summary 내게 리젝당한 팔로워 목록
   * @request GET:/v1/me/rejected-followers
   * @secure
   */
  getRejectedFollowers = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<UserProfileDto[], any>({
      path: `/v1/me/rejected-followers`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 프로필이미지 목록
   *
   * @tags me
   * @name GetProfileImages
   * @summary 내 프로필이미지 목록
   * @request GET:/v1/me/profile/profile-images
   * @secure
   */
  getProfileImages = (params: RequestParams = {}) =>
    this.request<UserProfileImageDto[], any>({
      path: `/v1/me/profile/profile-images`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 배경이미지 목록
   *
   * @tags me
   * @name GetBackgroundImages
   * @summary 내 배경이미지 목록
   * @request GET:/v1/me/profile/background-images
   * @secure
   */
  getBackgroundImages = (params: RequestParams = {}) =>
    this.request<UserBackgroundImageDto[], any>({
      path: `/v1/me/profile/background-images`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 주문목록 조회
   *
   * @tags me
   * @name GetOrders
   * @summary 내 주문목록 조회
   * @request GET:/v1/me/orders
   * @secure
   */
  getOrders = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<OrderDto[], any>({
      path: `/v1/me/orders`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 주문 조회
   *
   * @tags me
   * @name GetOrder
   * @summary 내 주문 조회
   * @request GET:/v1/me/orders/{orderId}
   * @secure
   */
  getOrder = (orderId: number, params: RequestParams = {}) =>
    this.request<OrderDto, any>({
      path: `/v1/me/orders/${orderId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 주문 삭제
   *
   * @tags me
   * @name DeleteOrder
   * @summary 내 주문 삭제
   * @request DELETE:/v1/me/orders/{orderId}
   * @secure
   */
  deleteOrder = (orderId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/me/orders/${orderId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 팔로잉 목록
   *
   * @tags me
   * @name GetFollowings1
   * @summary 내 팔로잉 목록
   * @request GET:/v1/me/followings
   * @secure
   */
  getFollowings1 = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<UserProfileDto[], any>({
      path: `/v1/me/followings`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 팔로워 목록
   *
   * @tags me
   * @name GetFollowers1
   * @summary 내 팔로워 목록
   * @request GET:/v1/me/followers
   * @secure
   */
  getFollowers1 = (
    query?: { page?: number; size?: number; sort?: string[] },
    params: RequestParams = {},
  ) =>
    this.request<UserProfileDto[], any>({
      path: `/v1/me/followers`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 프로필이미지 삭제
   *
   * @tags me
   * @name DeleteProfileImage
   * @summary 내 프로필이미지 삭제
   * @request DELETE:/v1/me/profile/profile-images/{profileImageId}
   * @secure
   */
  deleteProfileImage = (profileImageId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/me/profile/profile-images/${profileImageId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 내 배경이미지 삭제
   *
   * @tags me
   * @name DeleteBackgroundImage
   * @summary 내 배경이미지 삭제
   * @request DELETE:/v1/me/profile/background-images/{backgroundImageId}
   * @secure
   */
  deleteBackgroundImage = (backgroundImageId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/me/profile/background-images/${backgroundImageId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
}
