import React from 'react';
import { CommonRowStyle, CommonButtonStyle } from 'resources/styles/common/styles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const MobileCommonFooter = ({
  children,
  isValidForm,
  onSaveClick,
  onShowSimulator,
}: {
  children?: React.ReactElement;
  isValidForm: boolean;
  onSaveClick: () => void;
  onShowSimulator?: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ position: 'relative' }}>
        <FooterContainer>
          {typeof children !== 'undefined' && children}
          <CommonRowStyle>
            <PreviewButton
              onClick={() => {
                if (typeof onShowSimulator === 'function') {
                  onShowSimulator();
                }
              }}
            >
              {t('preView')}
            </PreviewButton>
            <FooterButton
              type={'button'}
              disabled={!isValidForm}
              isDisabled={!isValidForm}
              onClick={onSaveClick}
            >
              {t('saveProfile')}
            </FooterButton>
          </CommonRowStyle>
        </FooterContainer>
      </div>
    </>
  );
};

const FooterContainer = styled.div`
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: 1000;
`;

const PreviewButton = styled(CommonButtonStyle)`
  background-color: #fff;
  color: #111;
  border: 0;
`;

const FooterButton = styled(CommonButtonStyle)<{ isDisabled: boolean }>`
  color: ${(props) => (props.isDisabled ? '#ccc' : '#fff')};
  background-color: ${(props) => (props.isDisabled ? '#eee' : props.theme.colors.pointColor)};
  border: 0;
  margin: 0 0 0 8px;
`;

export default MobileCommonFooter;
