import React, { RefObject, useEffect, useState } from 'react';
import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from 'react-component-export-image';
import { useTranslation } from 'react-i18next';
import { QRCode } from 'react-qrcode-logo';
// import QRCode from 'react-qr-code';
import ModalHeader from 'components/commons/ModalHeader';
import styled from 'styled-components';
import Modal from 'react-modal';

const QRCodePrintModal = ({
  userName,
  show,
  qrRef,
  children,
  onClose,
}: {
  userName?: string;
  show: boolean;
  qrRef: RefObject<HTMLDivElement>;
  children?: React.ReactNode;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <Modal
      isOpen={showModal}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          margin: 0,
          padding: 0,
          zIndex: 100,
        },
        content: {
          maxWidth: 500,
          height: 400,
          margin: 'auto',
          padding: 0,
        },
      }}
    >
      <ModalHeader
        onClose={() => {
          setShowModal(false);
          onClose();
        }}
      >
        {t('pleaseSelectTypePrint')}
      </ModalHeader>
      <QrStyledStyle>
        <QRCode
          value={`https://ohmoss.com/${userName}`}
          size={185}
          logoImage="/image/livelink.png"
          logoWidth={50}
          logoHeight={50}
          ecLevel="L"
          eyeRadius={20}
          removeQrCodeBehindLogo={false}
        />
      </QrStyledStyle>

      {typeof children !== 'undefined' && <div>{children}</div>}
      {typeof children === 'undefined' && <div style={{ margin: '50px' }} />}

      {/* <div className="row" style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 6 }}> */}
      <div className="row" style={{ paddingRight: 10, paddingTop: 6 }}>
        {/* <div
          className="button default"
          onClick={() => {
            exportComponentAsPDF(qrRef, {
              fileName: userName,
              html2CanvasOptions: {},
              pdfOptions: { w: 210, h: 210 },
            });
            setShowModal(false);
            onClose();
          }}
        >
          PDF
        </div> */}
        <div
          className="button default"
          style={{ margin: '0 10px' }}
          onClick={() => {
            exportComponentAsPNG(qrRef, {
              fileName: `${userName}_QR`,
            });
            setShowModal(false);
            onClose();
          }}
        >
          PNG
        </div>
        <div
          className="button default"
          onClick={() => {
            exportComponentAsJPEG(qrRef, {
              fileName: `${userName}_QR`,
            });
            setShowModal(false);
            onClose();
          }}
        >
          JPG
        </div>
      </div>
    </Modal>
  );
};

const QrStyledStyle = styled.div`
  text-align: center;
`;

export default QRCodePrintModal;
