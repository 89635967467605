import React, { useState, useEffect } from 'react';
import { TelLinkStyle } from 'features/adminFeatures/template/components/ItemContainerStyle';
import { LinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';

const TelLink = ({ item }: { item: LinkDto }) => {
  const IMAGE_WIDTH = 50;
  const { t } = useTranslation();

  return (
    <TelLinkStyle>
      <div className="center-all" style={{ height: 60 }}>
        {typeof item.thumbnail !== 'undefined' && (
          <div
            style={{
              height: IMAGE_WIDTH,
              width: IMAGE_WIDTH,
              left: 25,
              position: 'absolute',
            }}
          >
            <img
              alt=""
              src={item.thumbnail?.publicUrl}
              width={IMAGE_WIDTH}
              height={IMAGE_WIDTH}
              style={{ borderRadius: 8 }}
            />
          </div>
        )}
        <a href={`tel:${item?.phoneNumber}`} className="simple-a-link" style={{ lineHeight: 8 }}>
          <span style={{ position: 'relative', top: 6, marginRight: 10 }}>
            <img src="../../image/call-received.png" alt="" />
          </span>
          <span>
            {item.title}
            {t('callTo')}
          </span>
        </a>
      </div>
    </TelLinkStyle>
  );
};

export default TelLink;
