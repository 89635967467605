import styled from 'styled-components';

const PrimaryButtonStyle = styled.button`
  justify-content: center;
  align-items: center;
  height: 50px;
  font-weight: 500;
  cursor: pointer;
  width: calc(100% - 20px);
  margin: 10px;
  font-size: 20px;
  border-radius: 8px;
  border: none;
  background: #3e64ea;
  color: #fff;
  &:disabled {
    background: #efefef !important;
    color: rgb(182, 182, 182) !important;
  }
`;

export default PrimaryButtonStyle;
