import React, { useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Confirm2 from 'components/commons/Confirm2';
import Progress from 'components/commons/Progress';
import theme from 'resources/styles/common/theme';
import Drawer from 'components/commons/Drawer';
import ScrollTop from 'libs/commons/ScrollTop';
import ErrorBoundary from 'ErrorBoundary';
import LayoutWrapper from 'LayoutWrapper';
import * as Pages from 'pages';
import 'react-toastify/dist/ReactToastify.css';
import 'resources/css/Common.css';
import 'resources/css/Links.css';
import 'resources/css/Core.css';
import 'resources/css/website.css';
import 'resources/css/mobile.css';

/** RN 관련 interface 등록 */
declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const App = observer(() => {
  const { uiStore } = useStore();
  const { i18n } = useTranslation();
  /** RN -> Web 메세지 받기 */
  const onMessageHandler = (e: any) => {
    const event = JSON.parse(e.data);

    /** RN에서 받은 메세지를 감지해서 drawer를 종료시킨다 */
    if (event?.RNBackHandler && uiStore.drawer.drawerShow) {
      uiStore.drawer.close();
    }
  };

  useEffect(() => {
    /** drawer가 열린것을 감지해서 RN으로 열렸다는것을 플래그를 보낸다 */
    if (uiStore.drawer.drawerShow) {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ isDrawer: true }));
    } else {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({ isDrawer: false }));
    }
  }, [uiStore.drawer.drawerShow]);

  /** RN (Android) BackHandler 감지 구현 */
  useEffect(() => {
    const isUIWebView = () => {
      return navigator.userAgent.toLowerCase().match(/\(ip.*applewebkit(?!.*(version|crios))/);
    };
    const receiver = isUIWebView() ? window : document;

    receiver.addEventListener('message', onMessageHandler);
    return () => {
      receiver.removeEventListener('message', onMessageHandler);
    };
  }, []);

  useLayoutEffect(() => {
    // const t = navigator.language;
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const t1 = navigator.geolocation;
    // const ab = t1.getCurrentPosition((r) => {
    //   const abcc = r;
    // });

    //  1. 먼저 스토리지의 언어를 취득한다.
    //  2. 없으면 브라우저 언어나 타임존을 체크한다.

    const rLang = localStorage.getItem('i18nextLng');
    let cLang = '';
    if (rLang !== null) {
      cLang = rLang;
    } else {
      const lang = 'kr'; // 상기 2번에서 선택된 값.
      cLang = lang;
      localStorage.setItem('language', lang);
    }
    i18n.changeLanguage(cLang);
    document.documentElement.lang = cLang;

    // Asia / Tokyo;
  }, []);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollTop />
          <Routes>
            <Route path="/*" element={<LayoutWrapper />} />
            <Route path="*" element={Pages.NotFoundPage} />
          </Routes>
          <ToastContainer limit={1} pauseOnFocusLoss={false} />
          <Confirm2 />
          <Drawer />
          <Progress />
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  );
});

export default App;
