import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { JoinDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { Input } from 'components/commons';
import { observer } from 'mobx-react';
import MyPageTypeComponent from 'features/mainFeatures/auth/components/MyPageTypeComponent';
import TermsModalContainer from 'features/mainFeatures/terms/containers/TermsModalContainer';
import ATagStyle from 'features/mainFeatures/auth/components/ATagStyle';
import BlueBtn from 'features/mainFeatures/auth/components/BlueBtn';
import useForm from 'libs/hooks/useForm';
import Modal from '@mui/material/Modal';
import _ from 'lodash';

interface categoryDto {
  id: number;
  title: string;
  pageType: 'string';
}
interface MyPageVerticalDto {
  id: number;
  title: string;
  src: string;
  categories: categoryDto[];
}

const SignUpPage = observer(() => {
  const { t } = useTranslation();
  const { authStore, formValidationStore } = useStore();
  const navigate = useNavigate();
  const isFormValid = useForm(formValidationStore);

  const [joinInfo, setJoinInfo] = useState<JoinDto>();
  // const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [notDupUserName, setNotDupUserName] = useState<boolean>(false);

  const [userNameErrorMessage, setUserNameErrorMessage] = useState<string>('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');

  const [showPassword, setShowPassword] = useState<boolean>();

  const [selectedFirstType, setSelectedFirstType] = useState<MyPageVerticalDto>(); // vertical1
  const [selectedSecondType, setSelectedSecondType] = useState<string>(); // vertical2

  const [termValidText, setTermValidText] = useState<string>('');

  const userNameRef = useRef<HTMLInputElement>(null);
  const checkRef = useRef<HTMLInputElement>(null);

  /** 약관 자세히 보기 */
  const [isOpenTermsModal, setIsOpenTermsModal] = useState<boolean>(false);

  const handleChangeJoinInfo = (
    key: 'userName' | 'email' | 'password' | 'vertical1' | 'vertical2',
    inputValue: string,
  ) => {
    setJoinInfo((prevState) => ({ ...prevState, [key]: inputValue }));
  };

  const checkValidationUsername = async () => {
    if (typeof joinInfo?.userName === 'undefined') {
      return;
    }
    const userNameExp = /^([A-Za-z]|[0-9]|_)+$/;
    const validationCheck = userNameExp.test(joinInfo?.userName);
    const result: any = await authStore.duplicateUserName(joinInfo?.userName);
    if (validationCheck === true && result === true) {
      setUserNameErrorMessage('');
      setNotDupUserName(true);
    } else if (result === false) {
      delete joinInfo?.userName;
      setUserNameErrorMessage(t('idInUse'));
      userNameRef.current?.focus();
      setNotDupUserName(false);
    } else {
      setUserNameErrorMessage(t('onlyEnNumAllow'));
    }
  };

  const checkValidationEmail = () => {
    if (typeof joinInfo?.email === 'undefined') {
      return;
    }
    const userNameExp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const validationCheck = userNameExp.test(joinInfo?.email);
    if (validationCheck === true) {
      setEmailErrorMessage('');
    } else {
      setEmailErrorMessage(t('notFormatEmail'));
    }
  };

  const checkValidationPassword = () => {
    if (typeof joinInfo?.password === 'undefined') {
      return;
    }
    const passwordExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    const validationCheck = passwordExp.test(joinInfo?.password);
    if (validationCheck === true) {
      setPasswordErrorMessage('');
    } else {
      setPasswordErrorMessage(t('mustLeastCharacterEnNumSpecial'));
    }
  };

  const handleClickVertical1 = (item: MyPageVerticalDto) => {
    handleChangeJoinInfo('vertical1', item.title);
    setSelectedFirstType(item);
  };
  const handleClickVertical2 = (title: string) => {
    handleChangeJoinInfo('vertical2', title);
    setSelectedSecondType(title);
  };

  /**
   * @todo api상에서 약관동의 내용이 사라짐 이부분 물어본 다음 추가 작업 할 것!
   */
  // const handleClickTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
  const handleClickTerms = (e: React.FormEvent<HTMLDivElement>) => {
    console.log(e);
  };

  const handleAcceptTerms = () => {
    setAcceptTerms(!acceptTerms);
    if (acceptTerms === true) {
      setTermValidText(t('agreeTermsConditions'));
    } else {
      setTermValidText('');
    }
  };

  const handleClickJoin = async () => {
    if (typeof joinInfo !== 'undefined') {
      const join: any = await authStore.join(joinInfo);
      if (join.result === true) {
        navigate('/email-auth');
      } else {
        navigate('/sign-up');
      }
    }
  };

  useEffect(() => {
    checkValidationUsername();
    checkValidationEmail();
    checkValidationPassword();
  }, [joinInfo?.userName, joinInfo?.email, joinInfo?.password]);

  useEffect(() => {
    return () => {
      formValidationStore.destroy();
    };
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div>
        <img src="/images/welcome.svg" alt="" />
      </div>
      <div style={{ fontSize: 30, fontWeight: 600 }}>안녕하세요! 오모스입니다.</div>

      <div style={{ width: 600, textAlign: 'center', marginTop: 50, fontSize: 20 }}>
        <div style={{ padding: 30, background: '#efefef', borderRadius: 20 }}>
          그동안 베타로 운영되던 오모스가 드디어 5월 8일부터 <br />
          새롭게 단장한 모습으로 서비스를 시작합니다!
        </div>
        <div style={{ fontSize: 16 }}>
          <div style={{ marginTop: 20 }}>
            링크인 바이오서비스 이외에도 피드작성, 고객관리 등<br /> 다양한 기능이 제공될
            예정입니다.
          </div>
          <div style={{ marginTop: 20 }}>
            아쉽게도 신규 회원가입은 며칠만 기다려주시면 감사하겠습니다!
          </div>
          <div style={{ marginTop: 50 }}>
            아울러, 현재 운영되고 있는 ohmoss.com은 <br />
            old.ohmoss.com으로 한달간 운영예정입니다.
          </div>
        </div>
        <div style={{ marginTop: 50, fontSize: 16, marginBottom: 120 }}>
          문의사항은 <a href="mailto:cs@nbhd.kr">cs@nbhd.kr</a>로 연락주시면 신속히 답변 해
          드리겠습니다!
        </div>
      </div>
    </div>
  );
});

export default SignUpPage;
