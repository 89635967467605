import React, { useState, useEffect, useRef } from 'react';
import { updateLink, updateModel } from 'components/items/commonLogic';
import { defaultLinkEditorProps, PageLinkModel } from 'types/CommonTypes';
import { LinkDto } from 'services/data-contracts';
// import { Editor } from '@tinymce/tinymce-react';
import { useTranslation } from 'react-i18next';
import ToggleButton from 'controls/toggleButton/ToggleButton';
import { Input } from 'components/commons';
import { toast } from 'react-toastify';
import WhiteButton from 'components/commons/WhiteButton';
import useForm from 'libs/hooks/useForm';
import styled from 'styled-components';
import _ from 'lodash';
import { observer } from 'mobx-react';
import Editor from 'components/commons/webEditor/Editor';

const PageEditor = observer(
  ({ model, stores, onRegistComplete }: defaultLinkEditorProps<PageLinkModel>) => {
    const [linkModel, setLinkModel] = useState<PageLinkModel>();
    const [editorValue, setEditorValue] = useState<string>();
    const [isNew, setIsNew] = useState<boolean>(true);

    const titleRef = useRef<any>(null);
    const isFormValid = useForm(stores.formValidationStore);

    const { t } = useTranslation();

    const handleSaveLink = async () => {
      const _linkModel = { ...linkModel };
      // _linkModel.page!.content! = editorValue.current;
      const result = await updateLink(_linkModel, !isNew, { ...stores });
      onRegistComplete(result!);
      if (result!) {
        toast(t('linkRegistration'));
      }
    };

    // const handleUploadImage = (any: any) => {};
    const uploadImageHandler = async (blobInfo: any) => {
      const blobImage = blobInfo.blobUri();
      const response = await fetch(blobImage);
      const blob = await response.blob();
      const data: any = await stores.fileStore.uploadImage(blob);

      // return new Promise(async (resolve, reject) => {
      //   resolve(data.publicUrl);
      // });
      return new Promise((resolve, reject) => {
        resolve(data.publicUrl);
      });
    };

    useEffect(() => {
      if (typeof model !== 'undefined') {
        setIsNew(false);
        if (typeof model.custom !== 'undefined') {
          setLinkModel({ ...model, customObject: JSON.parse(model.custom) });
        } else {
          setLinkModel(model);
        }

        setEditorValue(model?.page?.content);
      } else {
        setLinkModel({ linkType: 'Page' });
      }
    }, [model]);

    useEffect(() => {
      return () => {
        titleRef.current.value = '';
      };
    }, []);

    useEffect(() => {
      console.log(linkModel);
    }, [linkModel]);

    return (
      <>
        <PageContainerStyle>
          <PageStyle>
            <PageTitleStyle>
              <Input
                required
                placeholder="Title"
                id="title"
                ref={titleRef}
                onChangeValue={(value: string) => {
                  const page = { ...linkModel?.page, title: value };
                  updateModel(setLinkModel, 'page', page);
                  updateModel(setLinkModel, 'title', value);
                }}
                defaultValue={linkModel?.title}
                type="text"
              />
            </PageTitleStyle>
            <PageEditStyle>
              <Editor
                defaultValue={editorValue}
                onEditorChange={(content: string) => {
                  updateModel(setLinkModel, 'page', { ...linkModel?.page, content });
                }}
              />
            </PageEditStyle>
            <div style={{ marginTop: 10, marginLeft: 10 }}>
              <ToggleButton
                label="Use full screen"
                checked={linkModel?.customObject?.isFullScreen}
                onToggleClick={(isChecked: boolean) => {
                  updateModel(setLinkModel, 'customObject', { isFullScreen: isChecked });
                }}
              />
            </div>

            <WhiteButton
              disabled={!isFormValid.result}
              type={isNew ? 'SAVE' : 'Update'}
              onClick={handleSaveLink}
              style={{ marginTop: 15 }}
            />
          </PageStyle>
          {/* <WhiteButton type="Preview" /> */}
        </PageContainerStyle>
      </>
    );
  },
);

const PageContainerStyle = styled.div`
  border: 1px solid #dddddd;
  padding: 6px 6px 6px 6px;
  border-radius: 6px;
`;

const PageStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 650px;
  border-radius: 6px;
`;

const PageTitleStyle = styled.div`
  width: 100%;
  height: 48px;
  background: #f8f8f8;
  margin: 0 0 30px 0;
  border-radius: 6px;
`;

// const PageFullStyle = styled.div`
//   flex-direction: row;
//   display: flex;
//   justify-content: space-evenly;
// `;

const PageEditStyle = styled.div`
  width: 100%;
  height: 500px;
`;

export default PageEditor;
