import { makeAutoObservable } from 'mobx';
import { Me as MeService } from 'services/Me';
import {
  LinkDto,
  SocialLinkDto,
  UserProfileDto,
  UserInfoDto,
  UserStyleDto,
  UserProfileImageDto,
  UserBackgroundImageDto,
} from 'services/data-contracts';
import { REST_API_URL } from 'stores/AppStore';
import { CustomUserProfile, CustomUserInfoDto } from 'types/CommonTypes';

class MeStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: MeService<unknown> = new MeService({
    baseURL: REST_API_URL,
  });

  myProfile?: CustomUserProfile;

  myLinks: Array<LinkDto> = [];

  socials: Array<SocialLinkDto> = [];

  myStyle: UserStyleDto;

  me: UserInfoDto;

  profileImages: UserProfileImageDto[] = [];

  backgroundImages: Array<UserBackgroundImageDto> = [];

  /** ========================================================================================== */

  *getMyProfile() {
    const { data } = yield this.api.getProfile();
    const { links } = yield this.api.getLinks();

    this.myProfile = data;
    this.myLinks = links;
    return data;
  }

  *getMySNS() {
    const { data } = yield this.api.getSocialLinks();
    this.socials = data;
  }

  *saveMyProfile(userProfile: UserProfileDto) {
    const { data } = yield this.api.saveProfile(userProfile);
  }

  *getMyLayout() {
    const { data } = yield this.api.getLayoutStyle();
    this.myStyle = data;
  }

  *saveMyLayout(style: UserStyleDto) {
    const { data } = yield this.api.saveLayoutStyle(style);
  }

  *getProfileImages() {
    const { data } = yield this.api.getProfileImages();
    this.profileImages = data;
  }

  *getBackgroundImages() {
    const { data } = yield this.api.getBackgroundImages();
    this.backgroundImages = data;
  }

  *getLinks() {
    const { data } = yield this.api.getLinks({ page: 0, size: 1000 });
    this.myLinks = data;
  }
  /**
   * @description 내 정보 조회
   */
  *getMe() {
    const { data } = yield this.api.getMe();
    localStorage.setItem('me', JSON.stringify({ email: data.email, profile: data.profile }));

    /** @description 커스텀 데이터를 파싱한다. */
    const parsedCustom =
      typeof data.profile?.custom !== 'undefined' ? JSON.parse(data.profile?.custom) : {};
    Object.assign(data.profile, parsedCustom) as CustomUserInfoDto;
    if (data.profile.userName === 'karbot') {
      data.profile.userType = 'pro';
    }
    /** @description Background Image가 가장 우선한다. */
    if (typeof data.profile.backgroundImage === 'undefined') {
      if (typeof data.profile.addInImage !== 'undefined') {
        data.profile.backgroundImage = data.profile.addInImage;
        data.profile.addInImage = undefined;
      }
    }
    this.me = data;
    return data;
  }

  *updateLinkActive(linkId: number, isActive: boolean) {
    const { data } = yield this.api.updateLinkActive(linkId, { isActive: isActive });
    return typeof data.id !== 'undefined' ? true : false;
  }
}

export default MeStore;
