/**
 * @description null이나 undefined가 아닌지 체크
 * @param value
 * @returns
 */
export const isValid = (value: any) => {
  const t = typeof value !== 'undefined' && value !== null ? true : false;
  return t;
};

export const isEmpty = (value: any) => {
  return typeof value !== 'undefined' && value !== null && value !== '' ? true : false;
};

export const validAll = (values: Array<any>) => {
  let isValid = true;
  values.forEach((value: any) => {
    if (typeof value === 'undefined' || value === null) {
      isValid = false;
    }
  });

  return isValid;
};

export const cloneDeep = (value: any) => {
  return typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value;
};

export const hasAnyValue = (value: any, key: Array<string>) => {};

// export { isValid, isEmpty, cloneDeep };
