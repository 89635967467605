import PaddingLayout from 'features/mainFeatures/terms/components/PaddingLayout';
import TermsSection from 'features/mainFeatures/terms/components/TermsSection';
import TermsTitle from 'features/mainFeatures/terms/components/TermsTitle';
import React from 'react';

const PrivateTerms = () => {
  return (
    <PaddingLayout>
      <TermsSection>
        <p>
          엔비에이치디 주식회사(이하 “회사” 또는 “{process.env.REACT_APP_SERVEICE_NAME}”라 함)는
          개인정보 보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한
          이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
        </p>
      </TermsSection>
      <TermsSection>
        <TermsTitle>개인정보처리방침 목차</TermsTitle>
        <ol>
          <li>개인정보의 처리 목적 </li>
          <li>개인정보 처리 및 보유기간 </li>
          <li>개인정보의 제3자 제공 </li>
          <li>정보주체의 권리∙의무 및 행사방법 </li>
          <li>처리하는 개인정보의 항목 </li>
          <li>개인정보 파기절차 및 방법</li>
          <li>개인정보의 안전성 확보조치</li>
          <li>권익침해 구제방법 </li>
          <li>개인정보 자동 수집 장치의 설치운영 및 그 거부에 관한 사항</li>
          <li>개인정보 처리방침 변경</li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>1. 개인정보의 처리 목적</TermsTitle>
        <p>
          회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적
          이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에
          따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </p>
        <ol>
          <li>
            회원 가입 및 관리
            <ol type="a">
              <li>
                회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리,
                제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지·통지 등을
                목적으로 개인정보를 처리합니다.
              </li>
            </ol>
          </li>
          <li>
            유해사이트 방지
            <ol type="a">
              <li>건전한 컨텐츠 제공 등을 목적으로 개인정보를 처리합니다.</li>
            </ol>
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>2. 개인정보 처리 및 보유기간</TermsTitle>
        <p>
          회사는 정보주체로부터 개인정보를 수집할 때 동의받은 개인정보 보유, 이용기간 또는 법령에
          따른 개인정보 보유, 이용기간 내에서 개인정보를 처리, 보유합니다.
        </p>
        <ol>
          <li>
            보관정보
            <ol type="a">
              <li>회원가입시 입력한 정보(ID, 이메일)</li>
            </ol>
          </li>
          <li>
            보유기간
            <ol type="a">
              <li>서비스 이용계약 동의시점부터 계약해지(탈퇴) 시점까지</li>
            </ol>
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>3. 개인정보의 제3자 제공</TermsTitle>
        <p>
          회사는 정보주체의 별도 동의, 법률의 특별한 규정 등 개인정보 보호법 제 17조에 해당하는 경우
          외에는 개인정보를 제 3자에게 제공하지 않습니다.
        </p>
        <ol>
          <li>
            제3자 제공 업무 발생 시, 필요시점에 회원에게 별도로 알리고 동의받는 절차가 진행됩니다.
          </li>
          <li>
            제3자 제공 동의를 거부하실 수 있으며, 동의 거부는 회원가입과 서비스 사용에 영향을 미치지
            않습니다.
          </li>
          <li>
            개인정보 제공 현황은 제휴관계의 개시 또는 종료에 따라 변동될 수 있으며 개인정보 제공과
            함께 목록에 게시됩니다. 제휴관계가 종료되면 개인정보의 파기와 함께 목록에서 삭제됩니다.
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>4. 정보주체의 권리∙의무 및 행사방법</TermsTitle>
        <ol>
          <li>
            정보주체는 회사에 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수
            있습니다.
          </li>
          <li>
            1항에 따른 열람·정정 권리 행사는 아래와 같이 하실 수 있으며 회사는 이에 대해 지체없이
            조치하겠습니다.
          </li>
          <ol type="a">
            <li>
              전자우편
              <ol type="i">
                <li>moss@ohmoss.com</li>
              </ol>
            </li>
            <li>
              FAX
              <ol type="i">
                <li>070-8282-9191</li>
              </ol>
            </li>
          </ol>
          <li>
            1항에 따른 삭제·처리정지 권리 행사는 회원탈퇴 또는 회원센터를 통해 처리할 수 있으며
            회사는 이에 대해 지체없이 조치하겠습니다.
            <ol type="a">
              <li>직접 탈퇴 방법 : 내프로필 &gt; 설정 &gt; 탈퇴하기</li>
            </ol>
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>5. 개인정보 처리항목</TermsTitle>
        <p>
          회사는 서비스 이용에 반드시 필요한 개인정보를 다음과 같은 목적을 위하여 처리합니다.
          처리하는 개인정보는 다음의 목적 이외의 용도로는 처리되지 않으며, 처리 목적과 항목이
          변경되는 경우에는 필요한 조치를 이행할 것입니다. 서비스 이용과정이나 개인정보 처리과정에서
          다음과 같은 정보들이 자동생성/수집되고 이용될 수 있습니다.{' '}
        </p>
        <ol>
          <li>
            서비스 이용내역, 불량이용기록, 접속로그, 상담내용 : 서비스 제공 관련 회원상담, 서비스
            개선 등의 목적
          </li>
          <li>
            접속 IP 주소 : 의심거래 이용자, 유해사이트 개설자 등에 대한 운영관리 목적 및 수사협조
            목적
          </li>
          <li>위치정보 : 위치기반서비스 이용약관에 명시된 목적과 부정이용 방지 목적</li>
        </ol>
        <p>
          ※ 안내 : 위치기반서비스는 단말기의 설정을 변경함으로써 위치정보의 수집을 차단할 수
          있습니다.
        </p>
      </TermsSection>
      <TermsSection>
        <TermsTitle>6. 개인정보 파기절차 및 방법</TermsTitle>
        <p>
          회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 지체없이 파기하고 있습니다. 단,
          이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정기간
          정보보관 의무를 부과하는 경우에는 해당 기간동안 개인정보를 안전하게 분리하여 보관합니다.
          파기의 절차, 기한, 방법은 다음과 같습니다
        </p>
        <ol>
          <li>
            파기절차
            <ol type="a">
              <li>
                이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부
                방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 지체없이 파기됩니다. 이 때,
                DB로 옮겨진 개인정보는 법률에 의한 경우가 아닌 다른 목적으로 이용되지 않습니다.
              </li>
            </ol>
          </li>
          <li>
            파기기한
            <ol type="a">
              <li>
                이용자의 개인정보는 그 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5 일
                이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가
                불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일
                이내에 그 개인정보를 파기합니다.
              </li>
            </ol>
          </li>
          <li>
            파기방법
            <ol type="a">
              <li>
                전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에
                출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
              </li>
              <li>
                또한, 회사는 '개인정보 유효기간제'에 따라 1년간 서비스를 이용하지 않은 이용자의
                개인정보를 파기하고 있습니다.
              </li>
            </ol>
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>7. 개인정보의 안전성 확보조치</TermsTitle>
        <p>
          회사는 개인정보 보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및
          물리적 조치를 하고 있습니다.
        </p>
        <ol>
          <li>
            개인정보 취급 직원의 최소화 및 교육
            <ol type="a">
              <li>
                개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는
                대책을 지행하고 있습니다.
              </li>
            </ol>
          </li>
          <li>
            내부관리계획의 수립 및 시행
            <ol type="a">
              <li>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</li>
            </ol>
          </li>
          <li>
            해킹 등에 대비한 기술적 대책
            <ol type="a">
              <li>
                회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
                보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에
                시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
              </li>
            </ol>
          </li>
          <li>
            개인정보의 암호화
            <ol type="a">
              <li>
                이용자의 개인정보 및 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알 수
                있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는
                등 별도 보안기능을 사용하고 있습니다.
              </li>
            </ol>
          </li>
          <li>
            접속기록의 보관 및 위·변조 방지
            <ol type="a">
              <li>
                개인정보처리시스템에 접속한 기록을 최소 2년이상 보관, 관리하고 있으며, 접속 기록이
                위·변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
              </li>
            </ol>
          </li>
          <li>
            개인정보에 대한 접근제한
            <ol type="a">
              <li>
                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한 부여, 변경, 말소를 통하여
                개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을
                이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
              </li>
            </ol>
          </li>
          <li>
            문서보안을 위한 잠금장치 사용
            <ol type="a">
              <li>
                개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고
                있습니다.
              </li>
            </ol>
          </li>
          <li>
            비인가자에 대한 출입 통제
            <ol type="a">
              <li>
                개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를
                수립, 운영하고 있습니다.
              </li>
            </ol>
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>8. 권익침해 구제방법</TermsTitle>
        <p>
          아래의 기관은 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에
          만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
        </p>
        <ol>
          <li>
            개인정보 침해신고센터(한국인터넷진흥원 운영)
            <ol type="a">
              <li>소관업무: 개인정보 침해사실 신고, 상담 신청</li>
              <li>홈페이지 : http://privacy.kisa.or.kr</li>
              <li>전화 : (국번없이) 118</li>
              <li>주소 : (58324) 전남 나주시 진흥길 9 3층 개인정보침해신고센터</li>
            </ol>
          </li>
          <li>
            개인정보 분쟁조정위원회
            <ol type="a">
              <li>소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</li>
              <li>홈페이지 : http://www.kopico.go.kr</li>
              <li>전화 : (국번없이) 1833-6972</li>
              <li>주소 : (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층</li>
            </ol>
          </li>
          <li>
            대검찰청 사이버범죄 수사단
            <ol type="a">
              <li>전화 : 02-3480-3573</li>
              <li>홈페이지 : www.spo.go.kr</li>
            </ol>
          </li>
          <li>
            경찰청 사이버안전국
            <ol type="a">
              <li>전화 : (국번없이) 182</li>
              <li>홈페이지 : http://cyberbureau.police.go.kr</li>
            </ol>
          </li>
          <li>
            접속기록의 보관 및 위·변조 방지
            <ol type="a">
              <li>
                개인정보처리시스템에 접속한 기록을 최소 2년이상 보관, 관리하고 있으며, 접속 기록이
                위·변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
              </li>
            </ol>
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>9. 개인정보 자동 수집 장치의 설치운영 및 그 거부에 관한 사항</TermsTitle>
        <p>
          회사는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는
          '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(https)가 이용자의
          컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 내의 하드디스크에 저장되기도
          합니다. 이용자가 웹사이트에 접속을 하면 회사의 서버는 이용자들의 쿠키의 내용을 읽고,
          이용자의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를
          제공할 수 있습니다. 쿠키는 이용자의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는
          않습니다. 또한, 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹
          브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을
          거치거나, 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키 설치를 거부할 경우 웹 사용이
          불편해질 수 있으며 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수 있습니다.
        </p>
        <ol>
          <li>
            설정 방법의 예
            <ol type="a">
              <li>인터넷 익스플로러(Internet Explorer)의 경우</li>
              <li>도구 메뉴 ▷ 인터넷 옵션 ▷ 개인정보 ▷ 쿠키 차단 수준</li>
              <li>크롬(Chrome)의 경우</li>
              <li>설정 메뉴 ▷ 고급 설정 표시 ▷ 개인정보의 콘텐츠 설정 ▷ 쿠키 차단 수준 설정</li>
            </ol>
          </li>
        </ol>
      </TermsSection>
      <TermsSection>
        <TermsTitle>10. 개인정보 처리방침 변경</TermsTitle>
        <p>회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항을 통하여 공지할 것입니다.</p>
        <ol>
          <li>공지일자 : 2022년 5월 2일</li>
          <li>시행일자 : 2022년 5월 2일</li>
        </ol>
      </TermsSection>
    </PaddingLayout>
  );
};

export default PrivateTerms;
