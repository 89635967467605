import React, { useEffect, useState } from 'react';
import { Confirm, ImageUploader } from 'components/commons';
import { updateModel } from 'components/items/commonLogic';
import { CustomFile, CustomUserProfile } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { isValid } from 'libs/helper/utils';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';
import { BackgroundTypeList, defaultServiceImage } from 'types/BackgroundTypeList';
import WhiteButton from 'components/commons/WhiteButton';
import useForm from 'libs/hooks/useForm';
import styled from 'styled-components';
import { FileDto } from 'services/data-contracts';

const BackgroundImageComponent = observer(() => {
  const stores = useStore();
  const { t } = useTranslation();
  const isFormValid = useForm(stores.formValidationStore);
  const [profileModel, setProfileModel] = useState<CustomUserProfile | undefined>();
  const { meStore, simulatorStore, fileStore, uiStore } = useStore();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string>();

  const handleUpdateBackgroundImage = (value: any) => {
    // templateStore.setUserInfo({
    //   ...templateStore.userInfo,
    //   profile: {
    //     ...templateStore?.userInfo?.profile,
    //     backgroundImage: value,
    //     backgroundColor: undefined,
    //     template: undefined,
    //   },
    // });

    simulatorStore.updateProfile({
      ...simulatorStore?.profile,
      backgroundColor: undefined,
      backgroundImage: value,
      template: undefined,
    });
    // templateStore.setTemplate({});
  };

  // const handleSetDefaultBg = () => {
  //   templateStore.setUserInfo({
  //     ...templateStore?.userInfo,
  //     profile: {
  //       backgroundImage: {
  //         id: 2162,
  //         contentType: 'image/png',
  //         publicUrl:
  //           'https://livelink-prod.s3.ap-northeast-2.amazonaws.com/background/d8c314ac-322a-44a2-9281-8f99bda41a57',
  //       },
  //     },
  //   });
  //   templateStore.setTemplate({ id: 561 });
  // };

  const handleClickSave = () => {
    setShowConfirm(true);
  };

  const getImageType = (): 'background' | 'addIn' | undefined => {
    if (isValid(profileModel?.backgroundImage)) {
      return 'background';
    }

    if (isValid(profileModel?.addInImage)) {
      return 'addIn';
    }

    return undefined;
  };

  const handleClickConfirm = async () => {
    const newProfileModel: CustomUserProfile = { ...profileModel };
    if (newProfileModel?.backgroundImage?.file?.constructor.name === 'Blob') {
      const image: any = await fileStore.uploadBackgroundImage(profileModel?.backgroundImage?.file);
      newProfileModel.backgroundImage.id = image.id;
      await meStore.saveMyLayout({ template: { id: 561 } });
    }
    if (typeof simulatorStore?.profile?.template?.id !== 'undefined') {
      meStore.saveMyLayout({ template: { id: simulatorStore?.profile.template.id } });
    }

    newProfileModel.custom = JSON.stringify({
      titleProps: newProfileModel.titleProps,
      selfIntroductionProps: newProfileModel.selfIntroductionProps,
      template: newProfileModel.template,
      footerProps: newProfileModel.footerProps,
      titleYPosition: newProfileModel.titleYPosition,
      addInImage:
        newProfileModel.backgroundImage?.fileType === 'Background'
          ? newProfileModel.backgroundImage
          : undefined,
    });

    if (newProfileModel.backgroundImage?.fileType === 'Background') {
      newProfileModel.backgroundImage = undefined;
    }

    await meStore.saveMyProfile(newProfileModel);
    // templateStore.setUserInfo({
    //   ...templateStore.userInfo,
    //   profile: { ...newProfileModel, backgroundImage: newProfileModel.backgroundImage },
    // });

    simulatorStore.updateProfile({
      ...newProfileModel,
      backgroundImage: newProfileModel.backgroundImage,
    });
    toast(t('yourProfileSave'), { position: 'top-center' });
    uiStore.drawer.close();
  };

  useEffect(() => {
    if (isValid(simulatorStore.profile)) {
      setProfileModel(simulatorStore?.profile);
      if (isValid(simulatorStore?.profile?.backgroundImage)) {
        setCurrentImage(simulatorStore?.profile?.backgroundImage?.publicUrl);
      } else if (isValid(simulatorStore?.profile?.addInImage)) {
        setCurrentImage(simulatorStore?.profile?.addInImage?.publicUrl);
      } else {
        setCurrentImage(defaultServiceImage.publicUrl);
      }
    }
  }, []);

  useEffect(() => {
    if (typeof getImageType() === 'undefined') {
      setShowDeleteButton(false);
    } else {
      setShowDeleteButton(true);
    }
  }, [profileModel?.backgroundImage, profileModel?.addInImage]);

  return (
    <BackgroundContainerStyle>
      <DeleteButtonStyle
        show={showDeleteButton}
        onClick={() => {
          handleUpdateBackgroundImage(undefined);
          setCurrentImage(defaultServiceImage.publicUrl);
          updateModel(setProfileModel, 'backgroundImage', undefined);
          setShowDeleteButton(false);
        }}
      >
        <DeleteImageStyle src="/image/adminProfile/close-round.png" />
      </DeleteButtonStyle>
      <ImageUploader
        attachType="image"
        useCrop={false}
        cropSize={{ width: 2000, height: 2000 }}
        currentImage={profileModel?.backgroundImage as FileDto}
        cropType="square"
        useColor
        onSelected={(thumbnail: Array<CustomFile>) => {
          handleUpdateBackgroundImage(thumbnail[0]);
          updateModel(setProfileModel, 'backgroundImage', thumbnail[0]);
          setCurrentImage(thumbnail[0].publicUrl);
        }}
      >
        <ImageUploadButtonStyle backgroundImage={currentImage}>
          <MobileAreaContainerStyle>
            <MobileAreaStyle>
              <MobileAreaImageStyle src="/image/camera.svg" alt="" />
            </MobileAreaStyle>
          </MobileAreaContainerStyle>
        </ImageUploadButtonStyle>
      </ImageUploader>
      <BackgroundListStyle>
        {BackgroundTypeList.map((background) => (
          <BackgroundImageStyle
            src={background.thumbnail}
            onClick={() => {
              updateModel(setProfileModel, 'backgroundImage', background);
              setCurrentImage(background.publicUrl);
            }}
          />
        ))}
      </BackgroundListStyle>
      <WhiteButton
        type="SAVE"
        onClick={handleClickSave}
        disabled={isFormValid.result}
        style={{ width: '100%', marginTop: '40px' }}
      />
      <Confirm
        title={t('backgroundDelete')}
        closeText={t('cancellation')}
        visible={showConfirm}
        setVisible={setShowConfirm}
        onConfirm={handleClickConfirm}
      />
    </BackgroundContainerStyle>
  );
});

const BackgroundContainerStyle = styled.div`
  position: relative;
`;

const DeleteButtonStyle = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  text-align: center;
  width: 40px;
  height: 40px;
  right: -2px;
  z-index: 1;
  cursor: pointer;
`;

const DeleteImageStyle = styled.img`
  width: 100%;
  height: 100%;
`;

const ImageUploadButtonStyle = styled.div<{ backgroundImage?: string }>`
  width: 100%;
  height: 250px;
  border: 1px solid #eeeeee;
  border-radius: 20px;
  background: #fbfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: 50% 50%;
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    height: 180px;
  }
`;

const MobileAreaContainerStyle = styled.div`
  width: 35%;
  height: 98%;
  border: 3px dotted #7e7e7e;
  border-radius: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
  @media ${(props) => props.theme.media.mobile} {
    width: 35%;
    height: 98%;
  }
`;

const MobileAreaStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 50px;
  background: #9e9e9e;
  @media ${(props) => props.theme.media.mobile} {
    width: 45px;
    height: 45px;
  }
`;

const MobileAreaImageStyle = styled.img`
  width: 50px;
  height: 50px;
  @media ${(props) => props.theme.media.mobile} {
    width: 30px;
    height: 30px;
  }
`;

const BackgroundListStyle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0 0 0;
  > img {
    margin: 10px 0px 10px 0px;
    border-radius: 20px;
    width: 150px;
    height: 150px;
  }
  @media ${(props) => props.theme.media.mobile} {
    > img {
      width: 105px;
      height: 105px;
      margin: 5px 5px 5px 5px;
    }
  }
`;

const BackgroundImageStyle = styled.img.attrs({
  width: '120px',
  height: '160px',
  alt: '',
})`
  cursor: pointer;
`;

export default BackgroundImageComponent;
