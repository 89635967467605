import React from 'react';
import { CommonBetweenRowStyle, CommonButtonStyle } from 'resources/styles/common/styles';
import { BottomSheet } from 'react-spring-bottom-sheet';
import styled from 'styled-components';
import {
  /** sharing count */
  FacebookShareCount,
  HatenaShareCount,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  VKShareCount,

  /** buttons */
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,

  /** icons */
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from 'react-share';

type Props = {
  visible: boolean;
  username: string | undefined;
  onClose: () => void;
};

const WebShareBottomSheet = ({ visible, onClose, username }: Props) => {
  const url =
    typeof username !== 'undefined' ? `https://ohmoss.com/${username}` : 'https://ohmoss.com';

  return (
    <BottomSheet open={visible} onDismiss={onClose}>
      <ContainerStyle>
        <CommonBetweenRowStyle marginBottom={20}>
          <TitleStyle>프로필 공유</TitleStyle>
        </CommonBetweenRowStyle>
        <FacebookShareButton url={url} style={{ marginRight: 5 }}>
          <FacebookIcon size={45} round />
        </FacebookShareButton>
        <TwitterShareButton url={url} style={{ marginRight: 5 }}>
          <TwitterIcon size={45} round />
        </TwitterShareButton>
        <LineShareButton url={url} style={{ marginRight: 5 }}>
          <LineIcon size={45} round />
        </LineShareButton>
        <TelegramShareButton url={url} style={{ marginRight: 5 }}>
          <TelegramIcon size={45} round />
        </TelegramShareButton>
        <EmailShareButton url={url} style={{ marginRight: 5 }}>
          <EmailIcon size={45} round />
        </EmailShareButton>
        <LinkedinShareButton url={url} style={{ marginRight: 5 }}>
          <LinkedinIcon size={45} round />
        </LinkedinShareButton>
        <WorkplaceShareButton url={url} style={{ marginRight: 5 }}>
          <WorkplaceIcon size={45} round />
        </WorkplaceShareButton>
        <PocketShareButton url={url} style={{ marginRight: 5 }}>
          <PocketIcon size={45} round />
        </PocketShareButton>
        <RedditShareButton url={url} style={{ marginRight: 5 }}>
          <RedditIcon size={45} round />
        </RedditShareButton>
      </ContainerStyle>
    </BottomSheet>
  );
};

const ContainerStyle = styled.div`
  padding: 15px 15px 15px 15px;
`;

const TitleStyle = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export default WebShareBottomSheet;
