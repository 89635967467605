import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import { useTranslation } from 'react-i18next';
import _, { debounce } from 'lodash';
import Select from 'components/report/Select';
import { ImageUploader, Confirm } from 'components/commons';
import ClearIcon from '@mui/icons-material/Clear';

const ReportPage = observer(() => {
  const { authStore, meStore } = useStore();
  const { t } = useTranslation();

  const [isValidForm, setIsValidForm] = useState<boolean>(true);
  const [showDeleteCardConfirm, setShowDeleteCardConfirm] = useState<boolean>(false);
  const [model, setModel] = useState<any>();

  const reportUser = localStorage.getItem('reportUserName');

  const selectList = [
    { label: '유해사이트(유해컨텐츠 포함)', value: 'harmful' },
    { label: '기타', value: 'etc' },
  ];

  const setReportInfo = (key: string, value: any) => {
    setModel((prev: any) => ({ ...prev, [key]: value }));
  };

  const handleClickViewImage = () => {
    //
  };

  const handleClickDeleteIamge = () => {
    //
  };

  const handleClickConfirmReport = () => {
    setShowDeleteCardConfirm(true);
    localStorage.removeItem('reportUserName');
  };

  const handleClickReport = () => {
    //
  };

  const images = [
    { id: 1, imgUrl: '/image/dummy1.jpg' },
    { id: 2, imgUrl: '/image/dummy2.jpg' },
    { id: 3, imgUrl: '/image/dummy3.jpg' },
    { id: 4, imgUrl: '/image/dummy4.jpg' },
    { id: 5, imgUrl: '/image/dummy5.jpg' },
  ];

  useEffect(() => {
    if (!_.isUndefined(reportUser) && !_.isNull(reportUser)) {
      setReportInfo('userName', reportUser);
    }
  }, [reportUser]);

  return (
    <MobileSizeWrapper>
      <Confirm
        title="신고하기"
        subtitle="신고하시겠습니까?"
        onConfirm={handleClickReport}
        visible={showDeleteCardConfirm}
        setVisible={setShowDeleteCardConfirm}
        closeText="취소"
      />
      <Title>신고하기</Title>
      <Paper>
        <Select
          placeholder="신고유형을 선택해주세요."
          selectstyle={{ height: '60px', width: '100%' }}
          selectList={selectList}
          onClick={(item: string) => {
            setReportInfo('type', item);
          }}
        />
        <TextArea
          placeholder="내용을 입력해주세요."
          onChange={debounce((e: any) => {
            setReportInfo('content', e.target.value);

            // handleChangeReportContent(e.target.value);
          }, 300)}
        />
        <ImageUploader
          attachType="media"
          cropSize={{ width: 1600, height: 1600 }}
          onSelected={(thumbnail: any) => {
            // updateModel('footerImage', thumbnail[0]);
          }}
        >
          <ImageUploaderWrapper>
            <ImageUploaderIcon
              src="/image/adminProfile/image-icon-white.png"
              title="신고 사진 업로드"
            />
          </ImageUploaderWrapper>
        </ImageUploader>
        <ImageWrapper>
          {images.map((item: any, i: number) => (
            <div key={i} style={{ display: 'flex' }}>
              <Image src={item.imgUrl} alt="" onClick={handleClickViewImage} />
              <ImageDelete onClick={handleClickDeleteIamge}>
                <ClearIcon fontSize="small" sx={{ color: 'white' }} />
              </ImageDelete>
            </div>
          ))}
        </ImageWrapper>
      </Paper>
      <Footer isValidForm={isValidForm} onClick={handleClickConfirmReport}>
        신고하기
      </Footer>
    </MobileSizeWrapper>
  );
});

const MobileSizeWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    padding-left: 0.25em;
    padding-right: 0.25em;
    background-color: white;
    height: 8em;
    width: calc(100% - 0.5em);
  }
  @media ${(props) => props.theme.media.desk} {
    margin-left: calc(50% - 300px);
    width: 600px;
  }
`;

const Title = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
  @media ${(props) => props.theme.media.desk} {
    margin-top: 120px;
    text-align: center;
    font-size: 36px;
  }
`;

const Paper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 60px;
    padding: 16px;
  }
  @media ${(props) => props.theme.media.desk} {
    background-color: white;
    border-radius: 24px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.05);
    padding: 30px;
    margin-top: 40px;
  }
`;

const TextArea = styled.textarea`
  border-radius: 8px;
  border: 1px solid #dddddd;
  margin-top: 16px;
  padding: 16px;
  resize: none;
  @media ${(props) => props.theme.media.mobile} {
    width: calc(100% - 2.5em);
    height: 200px;
  }
  @media ${(props) => props.theme.media.desk} {
    width: 506px;
    height: 240px;
  }
  ::placeholder {
    font-size: 16px;
    color: #333333;
  }
`;

const ImageUploaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  background: #aeb3b7;
  border-radius: 8px;
  margin-top: 8px;
`;

const ImageUploaderIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  @media ${(props) => props.theme.media.mobile} {
    width: calc(100% - 0em);
    overflow-x: scroll;
  }
  @media ${(props) => props.theme.media.desk} {
    width: 600px;
  }
`;

const Image = styled.img`
  object-fit: cover;
  object-position: center;
  width: 68px;
  height: 68px;
  border-radius: 60px;
  padding: 4px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const ImageDelete = styled.div`
  width: 20px;
  height: 20px;
  margin-left: -21px;
  background: #3e64ea;
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;
  &:hover {
    opacity: 0.7;
  }
`;

const Footer = styled.div<{ isValidForm?: boolean }>`
  justify-content: center;
  text-align: center;
  height: 60px;
  line-height: 60px;
  margin-top: 80px;
  color: white;
  cursor: ${({ isValidForm }) => (isValidForm ? 'pointer' : 'none')};
  pointer-events: ${({ isValidForm }) => (isValidForm ? 'auto' : 'none')};
  background-color: ${({ isValidForm }) => (isValidForm ? '#3e64ea' : '#999999')};

  @media ${(props) => props.theme.media.desk} {
    width: 120px;
    border-radius: 32px;
    margin-left: 236px;
  }

  @media ${(props) => props.theme.media.mobile} {
    position: fixed;
    bottom: 0;
    left: -1em;
    width: calc(100% + 1em);
  }
`;

export default ReportPage;
