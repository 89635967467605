import React from 'react';
import { CustomUserProfile } from 'types/CommonTypes';
import { observer } from 'mobx-react';
import styled from 'styled-components';

interface Props {
  userInfo?: CustomUserProfile;
}

const Fnb = observer(({ userInfo }: Props) => {
  return (
    <ContainerStyle>
      {typeof userInfo?.footerImage !== 'undefined' && (
        <div>
          <LogoImageStyle noTitle={false} src={userInfo?.footerImage?.publicUrl} alt="" />
        </div>
      )}
      <UserInfoStyle>{userInfo?.footerTitle || process.env.REACT_APP_SERVEICE_NAME}</UserInfoStyle>
      <LineStyle />
    </ContainerStyle>
  );
});

const ContainerStyle = styled.div`
  top: 100vh;
  position: sticky;
  margin-top: 30px;
  text-align: center;
  bottom: 20px;
  padding: 0 20px 0 20px;
`;

const LogoImageStyle = styled.img<{ noTitle: boolean }>`
  width: ${(props) => (props.noTitle ? 100 : 70)}px;
  text-align: center;
  border-radius: 30px;
`;

const UserInfoStyle = styled.div`
  margin: 10px 0 0 0;
`;

const LineStyle = styled.div`
  height: 30px;
`;

export default Fnb;
