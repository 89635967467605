import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import BasicButton from 'components/commons/BasicButton';
import { observer } from 'mobx-react';
import { useStore } from 'stores/StoreHelper';
import MobileHeader from 'components/commons/MobileHeader';
// import Input from 'containers/account/Input';
import { Input } from 'components/commons';
import { useTranslation } from 'react-i18next';
import _, { debounce } from 'lodash';
import Form from 'controls/Form';

interface userInfoDto {
  userName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const AccountInfoPage = observer(() => {
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [dupUserNameState, setDupUserNameState] = useState<boolean>(true);
  const [passwordCheckState, setPasswordCheckState] = useState<boolean>(false);
  const [userNameErrorMessage, setUserNameErrorMessage] = useState<string>('');
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  const [passwordCheckErrorMessage, setPasswordCheckErrorMessage] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordCheck, setPasswordCheck] = useState<string>('');

  const { authStore, meStore } = useStore();
  const { t } = useTranslation();

  const i18nextLng = localStorage.getItem('i18nextLng');

  const init = () => {
    meStore.getMe();
  };

  const handleClickUserInfo = () => {
    //
  };

  const handleChangeUserInfo = (key: string, inputValue: string) => {
    //
  };

  const checkValidationUsername = (inputValue: string) => {
    const userNameExp = /^[A-Za-z0-9+]{4,15}$/;
    const validationCheck = userNameExp.test(inputValue);
    if (inputValue.length < 4) {
      setUserNameErrorMessage(t('leastCharacterLongId'));
    } else if (validationCheck === true) {
      setUserNameErrorMessage('');
    } else {
      setUserNameErrorMessage(t('onlyEnNumAllow'));
    }
  };
  const checkDupUserName = debounce(async (inputValue: string) => {
    const result: any = await authStore.duplicateUserName(inputValue);
    if (inputValue.length > 3) {
      if (result === true) {
        setUserNameErrorMessage(t('idAvailable'));
        setDupUserNameState(true);
      } else if (meStore.me?.userName === inputValue) {
        setUserNameErrorMessage('');
        setDupUserNameState(true);
      } else {
        setUserNameErrorMessage(t('idInUse'));
        setDupUserNameState(false);
      }
    }
  }, 300);
  const checkValidationEmail = (inputValue: string) => {
    const userNameExp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    const validationCheck = userNameExp.test(inputValue);
    if (inputValue.length < 4) {
      setEmailErrorMessage(t('notFormatEmail'));
    } else if (validationCheck === true) {
      setEmailErrorMessage('');
    } else {
      setEmailErrorMessage(t('notFormatEmail'));
    }
  };
  const checkValidationPassword = (inputValue: string) => {
    const passwordExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    const validationCheck = passwordExp.test(inputValue);
    if (inputValue.length < 8) {
      setPasswordErrorMessage(t('mustLeastCharacterEnNumSpecial'));
    } else if (validationCheck === true) {
      setPasswordErrorMessage('');
    } else if (passwordCheck !== inputValue) {
      setIsValidForm(false);
    } else {
      setPasswordErrorMessage(t('mustLeastCharacterEnNumSpecial'));
    }
  };
  const checkValidationPasswordCheck = (inputValue: string) => {
    const passwordExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    const validationCheck = passwordExp.test(inputValue);
    if (inputValue.length < 8) {
      setPasswordCheckErrorMessage(t('mustLeastCharacterEnNumSpecial'));
    } else if (validationCheck === true) {
      setPasswordCheckErrorMessage('');
    } else {
      setPasswordCheckErrorMessage(t('mustLeastCharacterEnNumSpecial'));
    }
  };
  const comparePassword = (inputValue: string) => {
    if (passwordCheck === inputValue) {
      setPasswordCheckState(true);
      setPasswordCheckErrorMessage('');
    } else if (passwordCheck === '') {
      setPasswordCheckErrorMessage('');
    } else {
      setPasswordCheckState(false);
      setPasswordCheckErrorMessage(t('passwordNotMatch'));
    }
  };
  const comparePasswordCheck = (inputValue: string) => {
    if (password === inputValue) {
      setPasswordCheckState(true);
      setPasswordCheckErrorMessage('');
    } else if (password === '') {
      setPasswordCheckErrorMessage('');
    } else {
      setPasswordCheckState(false);
      setPasswordCheckErrorMessage(t('passwordNotMatch'));
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <MobileSizeWrapper>
      {/* <div>{t('hello')}</div> */}
      {/* <MobileHeader title="프로필 변경" /> */}
      <Form
        isValidForm={(result: boolean) => {
          setIsValidForm(result);
        }}
      >
        {/* <IdSubLabel i18nextLng={i18nextLng}>{t('englishNumberAvailableNoMore')}</IdSubLabel> */}
        <LabelWrapper>
          <div style={{ paddingRight: '16px' }}>{t('userName')}</div>
          <div style={{ fontWeight: '200' }}>{t('englishNumberAvailableNoMore')}</div>
        </LabelWrapper>
        <Input
          required
          // label={t('userName')}
          id="userNameInput"
          maxLength={15}
          defaultValue={!_.isUndefined(meStore.me) ? meStore.me?.userName : ''}
          placeholder={t('userName')}
          customValidation={{
            expression: /^[A-Za-z0-9+]{4,15}$/,
            message: userNameErrorMessage,
          }}
          containerStyle={containerStyle}
          labelStyle={marginBottom}
          onChangeValue={(value: string) => {
            checkValidationUsername(value);
            checkDupUserName(value);
          }}
        />
        <LabelWrapper>
          <div style={{ paddingRight: '16px' }}>{t('logInId')}</div>
          <div style={{ fontWeight: '200' }}>{t('emailAddress')}</div>
        </LabelWrapper>
        <Input
          required
          // label={t('logInId')}
          id="emailInput"
          format="email"
          customValidation={{
            expression: /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            message: emailErrorMessage,
          }}
          defaultValue={!_.isUndefined(meStore.me) ? meStore.me?.email : ''}
          placeholder="예) abc@naver.com"
          containerStyle={containerStyle}
          labelStyle={marginBottom}
          onChangeValue={(value: string) => {
            checkValidationEmail(value);
          }}
        />
        <Input
          required
          label={t('password')}
          id="passwordInput"
          placeholder={t('password')}
          customValidation={{
            expression: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
            message: passwordErrorMessage,
          }}
          type="password"
          maxLength={20}
          containerStyle={containerStyle}
          labelStyle={marginTopBottom}
          onChangeValue={(value: string) => {
            checkValidationPassword(value);
            setPassword(value);
            comparePassword(value);
          }}
        />
        <Input
          required
          label={t('passwordCheck')}
          placeholder={t('passwordCheck')}
          id="passwordCheckInput"
          customValidation={{
            expression: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
            message: passwordCheckErrorMessage,
          }}
          type="password"
          maxLength={20}
          containerStyle={containerStyle}
          labelStyle={marginTopBottom}
          onChangeValue={(value: string) => {
            checkValidationPasswordCheck(value);
            setPasswordCheck(value);
            comparePasswordCheck(value);
          }}
        />
      </Form>
      <Footer isValidForm={isValidForm && passwordCheckState && dupUserNameState}>
        {t('registering')}
      </Footer>
      {/* <MainTitle>프로필 변경</MainTitle> */}
    </MobileSizeWrapper>
  );
});

const MobileSizeWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    padding-left: 1em;
    padding-right: 1em;
    background-color: white;
    padding-top: 8em;
    height: calc(100% - 8em);
    /* width: 100vw; */
  }
  @media ${(props) => props.theme.media.desk} {
    padding-top: 46px;
    margin-left: calc(50% - 300px);
    width: 600px;
  }
`;
const ButtonGroup = styled.div`
  margin-bottom: 32px;
`;

const MainTitle = styled.div`
  text-align: center;
  font-size: 50px;
  padding-bottom: 16px;
  @media ${(props) => props.theme.media.mobile} {
    display: none;
  }
`;

const Title = styled.div`
  color: #666666;
  padding-bottom: 16px;
`;

const Footer = styled.div<{ isValidForm?: boolean }>`
  position: absolute;
  text-align: center;
  height: 60px;
  line-height: 60px;
  margin-top: 80px;
  color: white;
  /* background-color: ${(props) => props.theme.colors.pointColor}; */
  cursor: ${({ isValidForm }) => (isValidForm ? 'pointer' : 'none')};
  pointer-events: ${({ isValidForm }) => (isValidForm ? 'auto' : 'none')};
  background-color: ${({ isValidForm }) => (isValidForm ? '#3e64ea' : '#999999')};
  @media ${(props) => props.theme.media.desk} {
    width: 600px;
    border-radius: 8px;
  }
  @media ${(props) => props.theme.media.mobile} {
    /* bottom: 0;
    left: 0; */
    left: -1em;
    width: calc(100% + 1em);
  }
`;

const marginBottom: React.CSSProperties = {
  marginBottom: '1em',
};

const marginTopBottom: React.CSSProperties = {
  marginTop: '1em',
  marginBottom: '1em',
};

const containerStyle: React.CSSProperties = {
  height: '48px',
  border: '1px solid #dddddd',
  borderRadius: '8px',
  // width: '100%',
  paddingLeft: '16px',
  fontSize: '16px',
};

const idLeftPosition = (i18nextLng: string) => {
  switch (i18nextLng) {
    case 'ko':
      return '56px';
    case 'en':
      return '95px';
    case 'jp':
      return '10px';
    case 'cn':
      return '10px';
    case 'sp':
      return '10px';
    default:
      return '95px';
  }
};
const emailLeftPosition = (i18nextLng: string) => {
  switch (i18nextLng) {
    case 'ko':
      return '103px';
    case 'en':
      return '77px';
    case 'jp':
      return '10px';
    case 'cn':
      return '10px';
    case 'sp':
      return '10px';
    default:
      return '77px';
  }
};
const subLabel = css`
  position: relative;
  top: 23px;
  font-weight: 300;
`;
const IdSubLabel = styled.div<{ i18nextLng: any }>`
  ${subLabel}/* left: ${({ i18nextLng }) => idLeftPosition(i18nextLng)}; */
`;
const EmailSubLabel = styled.div<{ i18nextLng: any }>`
  ${subLabel}/* left: ${({ i18nextLng }) => emailLeftPosition(i18nextLng)}; */
`;
const LabelWrapper = styled.div`
  display: flex;
  padding-top: 16px;
`;
export default AccountInfoPage;
