/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { FileDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class File<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 프로필 이미지 업로드
   *
   * @tags File
   * @name UploadProfileImage
   * @summary 프로필 이미지 업로드
   * @request POST:/v1/images/profile
   * @secure
   */
  uploadProfileImage = (data: { file?: File }, params: RequestParams = {}) =>
    this.request<FileDto, any>({
      path: `/v1/images/profile`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 이미지 업로드
   *
   * @tags File
   * @name UploadFile
   * @summary 링크 이미지 업로드
   * @request POST:/v1/images/link
   * @secure
   */
  uploadFile = (data: { file?: File }, params: RequestParams = {}) =>
    this.request<FileDto, any>({
      path: `/v1/images/link`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * @description Layout 이미지 업로드
   *
   * @tags File
   * @name UploadLayoutImage
   * @summary Layout 이미지 업로드
   * @request POST:/v1/images/layout
   * @secure
   */
  uploadLayoutImage = (data: { file?: File }, params: RequestParams = {}) =>
    this.request<FileDto, any>({
      path: `/v1/images/layout`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * @description 프로필 배경이미지 업로드
   *
   * @tags File
   * @name UploadBackgroundImage
   * @summary 프로필 배경이미지 업로드
   * @request POST:/v1/images/background
   * @secure
   */
  uploadBackgroundImage = (data: { file?: File }, params: RequestParams = {}) =>
    this.request<FileDto, any>({
      path: `/v1/images/background`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * @description 링크 이미지 삭제
   *
   * @tags File
   * @name DeleteFile
   * @summary 링크 이미지 삭제
   * @request DELETE:/v1/images/link/{fileId}
   * @secure
   */
  deleteFile = (fileId: string, query: { fileId: number }, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/images/link/${fileId}`,
      method: 'DELETE',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Layout 이미지 삭제
   *
   * @tags File
   * @name DeleteLayoutImage
   * @summary Layout 이미지 삭제
   * @request DELETE:/v1/images/layout/{fileId}
   * @secure
   */
  deleteLayoutImage = (fileId: string, query: { fileId: number }, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/v1/images/layout/${fileId}`,
      method: 'DELETE',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
