import React, { useRef, memo } from 'react';
import { TemplateWrapper } from 'features/adminFeatures/template/components';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import useScreenType from 'libs/hooks/useScreenType';
import styled from 'styled-components';

type Props = {
  isShowSimulator?: boolean;
};

const Simulator = observer(({ isShowSimulator }: Props) => {
  const { userStore, templateStore } = useStore();
  const simulatorRef = useRef<HTMLDivElement>(null);
  const screenType = useScreenType();

  return (
    <SimulatorContainer
      ref={simulatorRef}
      isMobile={screenType === 'mobile' ? true : false}
      isShowSimulator={isShowSimulator}
    >
      <TemplateWrapper
        isSimulator={isShowSimulator}
        // size={{
        //   width:
        //     typeof simulatorRef.current?.clientWidth !== 'undefined'
        //       ? simulatorRef.current?.clientWidth
        //       : 0,
        //   height:
        //     typeof simulatorRef.current?.clientHeight !== 'undefined'
        //       ? simulatorRef.current?.clientHeight
        //       : 0,
        // }}
        // profile={templateStore.model}
        // links={userStore.links}
        // socials={userStore.socialLinks}
      />
    </SimulatorContainer>
  );
});

const SimulatorContainer = styled.div<{
  isShowSimulator?: boolean;
  isMobile: boolean;
}>`
  /* width: ${(props) => (props.isShowSimulator ? 'calc(100% - 20px)' : '360px')}; */
  width: 400px;
  height: 720px;
  overflow: hidden;
  scroll-behavior: smooth;
  border-radius: 40px;
  border: 10px solid #111;
  box-shadow: 0px 0px 37px -16px #111;
  /* left: ${(props) => (props.isShowSimulator ? 'calc(-50% + 0px)' : '50%')}; */
  left: 50%;
  top: 50%;
  transform: ${(props) => (props.isMobile ? 'translate(-50%, -50%)' : 'translate(50%, -50%)')};
  position: fixed;
  /* @media ${(props) => props.theme.media.mobile} {
    width: 90%;
    height: calc(100vh - 150px);
    z-index: 1;
    position: absolute;
    transition-duration: 0.35s;
    transition-timing-function: ease;
    ${(props) => !props?.isShowSimulator && 'left: 150%'};
  } */
`;

export default memo(Simulator);
