import React, { CSSProperties, useEffect, useState } from 'react';
import { CardCommonProps, CustomUserProfile } from 'types/CommonTypes';
import { Fnb } from 'features/commonFeatures/navigationBar/containers';
import { TextToolBox } from 'components/commons';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import WhiteButton from 'components/commons/WhiteButton';
import Input from 'components/commons/Input';
import styled from 'styled-components';

const ProfileFooterComponent = observer(({ commonProps }: { commonProps: CardCommonProps }) => {
  const [profileModel, setProfileModel] = useState<CustomUserProfile>();
  const { simulatorStore } = useStore();
  const { profile } = simulatorStore;
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof profile !== 'undefined') {
      setProfileModel(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (typeof profile?.footerProps === 'undefined') {
      commonProps.updateModel('footerProps', {
        style: { ...commonProps.profile?.footerProps?.style, textAlign: 'center' },
      });
    }
  }, []);

  return (
    <ProfileFooterContainerStyle>
      <FooterBoxStyle>
        <FooterFnbStyle style={{ ...profileModel?.footerProps?.style }}>
          <Fnb userInfo={profile} />
        </FooterFnbStyle>
        <FooterTextToolBoxStyle>
          <TextToolBox
            show
            showImageIcon
            style={commonProps.profile?.footerProps?.style}
            containerStyle={toolBoxStyle}
            imageUrl={commonProps.profile?.footerImage?.publicUrl}
            onChangeImage={(action: 'add' | 'delete', thumbnail?: any) => {
              if (action === 'delete') {
                commonProps.updateModel('footerImage', undefined);
              } else if (action === 'add') {
                commonProps.updateModel('footerImage', thumbnail);
              }
            }}
            onChangeStyle={(style: CSSProperties) => {
              commonProps.updateModel('footerProps', {
                ...commonProps.profile?.footerProps,
                style: { ...commonProps.profile?.footerProps?.style, ...style },
              });
            }}
          />
          <Input
            placeholder={t('content')}
            type="text"
            containerStyle={{ border: 0, marginBottom: '10px' }}
            style={{
              color: '#111',
              fontSize: 16,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              padding: '0 24px 0 24px',
              margin: '0 5px 0 5px',
            }}
            defaultValue={commonProps.profile?.footerTitle}
            onChangeValue={(value: string) => {
              commonProps.updateModel('footerTitle', value);
            }}
          />
        </FooterTextToolBoxStyle>
      </FooterBoxStyle>
      <WhiteButton
        type="SAVE"
        onClick={commonProps.handleClickSaveProfile}
        style={{ marginTop: 45 }}
      />
    </ProfileFooterContainerStyle>
  );
});

const toolBoxStyle = {
  border: 'none',
  borderRadius: 0,
};

const ProfileFooterContainerStyle = styled.div`
  margin: 50px 0 50px 0;
`;

const FooterBoxStyle = styled.button`
  border-radius: 5px;
  background-color: #fdfdfd;
  width: 100%;
  padding: 0 0 0 0;
  border: 1px solid #eee;
  word-break: break-all;
`;

const FooterFnbStyle = styled.div`
  > div {
    position: relative;
    top: 0;
    text-align: inherit;
    > div:first-child {
      display: flex;
      justify-content: center;
      margin: 0 0 15px 0;
    }
  }
`;

const FooterTextToolBoxStyle = styled.div`
  border-top: 1px solid #eeeeee;
`;

export default ProfileFooterComponent;
