import React, { useEffect, useState, useRef } from 'react';
import { FileDto } from 'services/data-contracts';
import { CropType } from 'types/CommonTypes';
import ImageSelectorBottomSheet from './ImageSelectorBottomSheet';
import ImageSelectorModal from './ImageSelectorModal';
import useScreenType from 'libs/hooks/useScreenType';
import { multiply } from 'lodash';
/**
 * @description 이미지 선택부터 크롭까지 제공한다.
 * @returns
 */
const ImageUploader = ({
  children,
  style,
  useCrop = true,
  useColor = false,
  currentImage,
  attachType,
  currentColor,
  cropType,
  origin,
  cropSize,
  show,
  mode = 'direct',
  multipleUpload,
  onColorChanged,
  onSelected,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  useCrop?: boolean;
  mode?: 'direct' | 'modal';
  origin?: 'card' | 'video' | undefined;
  attachType: 'video' | 'image' | 'media';
  currentImage?: FileDto;
  currentColor?: string;
  show?: boolean;
  cropType?: CropType;
  /**
   * @deprecated
   */
  useColor?: boolean;
  cropSize?: { width: number; height: number };
  multipleUpload?: boolean;
  onColorChanged?: (color: string) => void;
  onSelected: (image: any) => void;
}) => {
  const screenType = useScreenType();
  const onChangeFile = useRef<any>(null);

  /** @description 이미지 선택 모달 표시여부 */
  const [showImageSelector, setShowImageSelector] = useState<boolean>(false);
  const files = useRef<any>(null);
  const [uploaderMode, setUploaderMode] = useState<'direct' | 'modal'>();
  const [accept, setAccept] = useState<string>('');

  useEffect(() => {
    switch (attachType) {
      case 'image':
        setAccept('.jpg, .jpeg, .png, .gif');
        break;
      case 'video':
        setAccept('.mp4');
        break;
      case 'media':
        setAccept('.jpg, .jpeg, .png, .gif, .mp4');
        break;

      default:
        setAccept('');
        break;
    }
  }, [attachType]);

  useEffect(() => {
    if (typeof mode !== 'undefined') {
      setUploaderMode(mode);
    }
  }, [mode]);

  /** @description 크롭된 이미지 */
  const _style: React.CSSProperties = { ...style, cursor: 'pointer' };
  return (
    <>
      <div
        style={_style}
        onClick={(e: any) => {
          if (uploaderMode === 'direct') {
            onChangeFile.current.click(e);
          } else {
            setShowImageSelector(true);
          }
        }}
      >
        {children}
      </div>
      <input
        type="file"
        multiple={multipleUpload}
        accept={accept}
        id="file"
        ref={(ref) => (onChangeFile.current = ref)}
        onChange={(e) => {
          files.current = onChangeFile.current.files;
          setShowImageSelector(true);
          //   handleChangeImage(e);
        }}
        onInput={(event) => {
          files.current = onChangeFile.current.files;
          setShowImageSelector(true);
          //   handleChangeImage(e);
        }}
        style={{ display: 'none' }}
      />
      {screenType === 'window' ? (
        <ImageSelectorModal
          origin={origin}
          files={files.current}
          multipleUpload={multipleUpload}
          currentImage={currentImage}
          isShow={showImageSelector}
          useCrop={useCrop}
          attachType={attachType}
          cropType={cropType}
          useColor={useColor}
          cropSize={cropSize}
          onColorChanged={onColorChanged}
          onChangeThumbnails={(thumbnail: any) => {
            onSelected(thumbnail);
          }}
          onClose={() => {
            files.current = null;
            onChangeFile.current.value = '';
            setShowImageSelector(false);
          }}
        />
      ) : (
        <ImageSelectorBottomSheet
          origin={origin}
          files={files.current}
          multipleUpload={multipleUpload}
          currentImage={currentImage}
          attachType={attachType}
          isShow={showImageSelector}
          currentColor={currentColor}
          useCrop={useCrop}
          cropType={cropType}
          cropSize={cropSize}
          onColorChanged={onColorChanged}
          onChangeThumbnails={(thumbnail: any) => {
            onSelected(thumbnail);
          }}
          onClose={() => {
            files.current = undefined;
            setShowImageSelector(false);
          }}
        />
      )}
    </>
  );
};

export default ImageUploader;
