/* eslint-disable no-param-reassign */
import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { CardLinkRenderType, CardLinkModel, InternalLinkCard } from 'types/CommonTypes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStore } from 'stores/StoreHelper';
import { Pagination } from 'swiper';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import AddLinkIcon from '@mui/icons-material/AddLink';
import useScreenType from 'libs/hooks/useScreenType';
import styled, { css } from 'styled-components';
import 'swiper/css/pagination';
import 'swiper/css';
import VideoEditor from 'features/adminFeatures/commons/components/VideoEditor';
// import { LinkDto } from 'services/data-contracts';

type defaultProps = {
  item: CardLinkModel;
};

const CardLinkComponent = ({ item }: defaultProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { templateStore } = useStore();
  const [cards, setCards] = useState<Array<InternalLinkCard>>();
  const [renderType, setRenderType] = useState<CardLinkRenderType>();
  const [swiper, setSwiper] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);
  const [offsetWidth, setOffsetWidth] = useState<number | undefined>(0);

  const screenType = useScreenType();
  const cardItem = (card: InternalLinkCard) => {
    const getStyle = (card: InternalLinkCard, style?: CSSProperties) => {
      //배경이 없는경우, 기본 검정으로 배경을 지정한다.
      if (typeof style === 'undefined') {
        style = { background: '#000' };
      }
      return {
        minWidth: screenType !== 'mobile' ? '100%' : '100%',
        aspectRatio: '1 / 1',
        width: '100%',
        cursor: typeof card.linkUrl !== 'undefined' ? 'pointer' : '',
        ...style,
      };
    };

    return (
      <div
        style={getStyle(card, card.customObject?.backgroundStyle)}
        onClick={() => {
          if (typeof card.linkUrl !== 'undefined') {
            window.open(card.linkUrl, '_blank');
          }
        }}
      >
        {typeof card.thumbnail !== 'undefined' && (
          <div style={{ overflow: 'hidden', height: '100%' }}>
            {card.thumbnail?.contentType?.indexOf('image/') === 0 ? (
              <div
                style={{
                  width: '100%',
                  aspectRatio: '1',
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  backgroundSize: 'cover',
                  backgroundImage: `url(${card.thumbnail.publicUrl})`,
                  backgroundPosition: 'center center',
                }}
              />
            ) : (
              <VideoComponent
                isCover={card.thumbnail?.sizeInfo?.isCover}
                video={card.thumbnail}
                // containerSize={
                //   templateStore.isSimulator ? 400 : screenType === 'mobile' ? 350 : 550
                // }
              />
            )}
          </div>
        )}
        <div className="card-canvas-text-container">
          <>
            <div
              style={{
                ...card.customObject?.descriptionContents?.style,
                textAlign: 'center',
                fontSize: 24,
                fontWeight: 600,
                textShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
              }}
            >
              {card.customObject?.descriptionContents?.text}
            </div>
          </>
        </div>
        <div className="card-canvas-title-container">
          {typeof card?.customObject?.titleContents?.text !== 'undefined' && (
            <div className="card-canvas-title">{card?.customObject.titleContents.text}</div>
          )}
          {/* {typeof card.linkUrl !== 'undefined' && (
            <div className="card-canvas-title-link-container">
              <div className="card-canvas-title-link">
                <a href={card.linkUrl}>
                  <AddLinkIcon style={{ position: 'relative', top: 7 }} />
                </a>
              </div>
            </div>
          )} */}
        </div>
      </div>
    );
  };

  /** 왼쪽 화살표 클릭 이벤트 */
  const handleClickArrow = (isForward: boolean) => {
    if (isForward) {
      setIndex((prev) => {
        return item.cards?.length! - 1 === prev ? prev : prev + 1;
      });
    } else {
      setIndex((prev) => {
        return prev === 0 ? prev : prev - 1;
      });
    }
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    item?.cards?.map((card: InternalLinkCard) => {
      if (typeof card?.content !== 'undefined') {
        // card.contentObject = JSON.parse(card?.content);
        const middleObject = JSON.parse(card.content);
        card.customObject = middleObject.customContent;
        if (typeof card.thumbnail !== 'undefined') {
          card.thumbnail.sizeInfo = middleObject.sizeInfo;
        }
      }
    });
    if (typeof item?.cards !== 'undefined') {
      setCards(item?.cards);
    }
  }, [item?.cards]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(index);
    }
  }, [index]);

  useEffect(() => {
    if (typeof item.custom !== 'undefined') {
      const _cardLink: CardLinkModel = { customObject: JSON.parse(item.custom) };
      setRenderType(_cardLink.customObject?.renderType);
      if (_cardLink.customObject?.renderType === 'grid') {
        // setOffsetWidth(containerRef.current?.offsetWidth! / 3);
        setOffsetWidth(33.3);
      }
    }
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        overflowY: 'hidden',
        marginBottom: 15,
        borderRadius: 15,
        height: 'auto',
        background: 'transparent',
      }}
    >
      {renderType === 'grid' ? (
        <div
          className="row"
          style={{ justifyContent: 'space-evenly', display: 'flex', flexWrap: 'wrap' }}
        >
          {cards?.map((card: InternalLinkCard) => (
            <GridItemStyle
              minWidth={`${offsetWidth}%`}
              isSimulatore={templateStore.isSimulator}
              isMobile={screenType === 'mobile'}
            >
              {cardItem(card)}
            </GridItemStyle>
          ))}
        </div>
      ) : (
        <div style={{ position: 'relative' }}>
          {cards?.length! > 1 && (
            <ArrowStyleLeft
              isMobile={screenType === 'mobile'}
              style={{ opacity: index === 0 ? 0.2 : 0.7 }}
              onClick={() => {
                handleClickArrow(false);
              }}
            >
              <img style={{ width: screenType === 'mobile' ? 6 : 10 }} src="/image/leftArrow.png" />
            </ArrowStyleLeft>
          )}
          <Swiper
            pagination={true}
            modules={[Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => setSwiper(swiper)}
          >
            {cards?.map((card: InternalLinkCard) => (
              <SwiperSlide className="swiper" key={card.id}>
                <>{cardItem(card)}</>
              </SwiperSlide>
            ))}
          </Swiper>
          {cards?.length! > 1 && (
            <ArrowStyleRight
              isMobile={screenType === 'mobile'}
              style={{ opacity: index === cards?.length! - 1 ? 0.2 : 0.7 }}
              onClick={() => {
                handleClickArrow(true);
              }}
            >
              <img
                style={{ width: screenType === 'mobile' ? 6 : 10 }}
                src="/image/rightArrow.png"
              />
            </ArrowStyleRight>
          )}
        </div>
      )}
    </div>
  );
};
// min-width: ${(props) => (props.isSimulatore ? '100px' : props.isMobile ? '110px' : '150px')};
const GridItemStyle = styled.div<{
  isSimulatore: boolean;
  isMobile: boolean;
  minWidth: string;
}>`
  flex: 1;
  min-width: ${(props) => props.minWidth};
  height: 100%;

  position: relative;
`;

const ArrowStyle = css<{ isMobile: boolean }>`
  top: 50%;
  width: ${(props) => (props.isMobile ? '20px' : '30px')};
  height: ${(props) => (props.isMobile ? '20px' : '30px')};
  padding: 5px 5px 5px 5px;
  border-radius: ${(props) => (props.isMobile ? '20px' : '30px')};
  background: rgba(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
  position: absolute;
`;

const ArrowStyleLeft = styled.div`
  ${ArrowStyle}
  left: 5px;
`;

const ArrowStyleRight = styled.div`
  ${ArrowStyle}
  right:5px;
`;
export default CardLinkComponent;
//https://css-tricks.com/practical-css-scroll-snapping/
