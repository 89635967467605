/* eslint-disable no-param-reassign */
import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { CardLinkModel, InternalLinkCard, LinkItemModel, ItemLinkModel } from 'types/CommonTypes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStore } from 'stores/StoreHelper';
import { Pagination } from 'swiper';
import { useParams, useNavigate } from 'react-router-dom';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import CurrencyFormat from 'react-currency-format';
import useScreenType from 'libs/hooks/useScreenType';
import styled, { css } from 'styled-components';
import Modal from 'components/commons/UniversalModal/Modal';

import TossPayment from 'containers/adminContainers/payments/TossPayment';

import 'swiper/css/pagination';
import 'swiper/css';
import ItemLinkDetailComponent from './ItemLinkDetailComponent';

type defaultProps = {
  item: ItemLinkModel;
};

const ItemLinkComponent = ({ item }: defaultProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { uiStore, userStore } = useStore();
  const [items, setItems] = useState<Array<LinkItemModel>>();
  const [isPaymentMode, setIsPaymentMode] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<LinkItemModel>();
  const [selectedCount, setSelectedCount] = useState<number>(1);
  const [showItemDetail, setShowItemDetail] = useState<boolean>(false);
  const [swiper, setSwiper] = useState<any>(null);
  const [index, setIndex] = useState<number>(0);
  const { userName } = useParams();
  const screenType = useScreenType();
  const getItem = (item: LinkItemModel) => {
    const getStyle = (item: LinkItemModel, style?: CSSProperties) => {
      //배경이 없는경우, 기본 검정으로 배경을 지정한다.
      if (typeof style === 'undefined') {
        style = { background: 'white' };
      }
      return {
        minWidth: screenType !== 'mobile' ? '100%' : '100%',
        aspectRatio: '1 / 1.3',
        width: '100%',
        overflow: 'hidden',
        ...style,
      };
    };

    return (
      <div style={getStyle(item, item.customObject?.backgroundStyle)}>
        {typeof item.thumbnail !== 'undefined' && (
          <div
            style={{
              overflow: 'hidden',
              height: '100%',
              padding: 15,
            }}
          >
            {item.thumbnail?.contentType?.indexOf('image/') === 0 ? (
              <div
                style={{
                  width: '100%',
                  height: '82%',
                  borderRadius: 10,
                  backgroundSize: 'cover',
                  backgroundImage: `url(${item.thumbnail.publicUrl})`,
                  backgroundPosition: 'center center',
                }}
              />
            ) : (
              <VideoComponent isCover={item.thumbnail?.sizeInfo?.isCover} video={item.thumbnail} />
            )}
          </div>
        )}
        <div className="card-canvas-text-container">
          <>
            <div
              style={{
                ...item.customObject?.descriptionContents?.style,
                textAlign: 'center',
                fontSize: 24,
                fontWeight: 600,
                textShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
              }}
            >
              {item.customObject?.descriptionContents?.text}
            </div>
          </>
        </div>
        <div className="card-canvas-title-container">
          <MenuTitleStyle
            isMobile={screenType === 'mobile'}
            onClick={() => {
              handleClickItem(item);
            }}
          >
            <div className="row" style={{ justifyContent: 'space-between' }}>
              <div style={{ fontSize: 20, fontWeight: 600 }}>{item?.title}</div>
              <div style={{ fontSize: 20, fontWeight: 500 }}>
                <CurrencyFormat
                  value={item?.price}
                  displayType={'text'}
                  thousandSeparator
                  suffix={'원'}
                />
              </div>
            </div>
          </MenuTitleStyle>
        </div>
      </div>
    );
  };

  const handleClickItem = async (linkItem: LinkItemModel) => {
    if (typeof userName !== 'undefined') {
      const menuItems = (await userStore.getLink(userName, item.id!)) as ItemLinkModel;
      const selectedItem = menuItems?.items?.filter((item) => item.id === linkItem.id);
      if (typeof selectedItem !== 'undefined') {
        setSelectedItem(selectedItem[0]!);
        setShowItemDetail(true);
      }
    }
  };

  /** 왼쪽 화살표 클릭 이벤트 */
  const handleClickArrow = (isForward: boolean) => {
    if (isForward) {
      setIndex((prev) => {
        return item.items?.length! - 1 === prev ? prev : prev + 1;
      });
    } else {
      setIndex((prev) => {
        return prev === 0 ? prev : prev - 1;
      });
    }
  };

  const handleOrderNow = () => {
    setIsPaymentMode(true);
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    item?.items?.map((item: InternalLinkCard) => {
      if (typeof item?.content !== 'undefined') {
        // card.contentObject = JSON.parse(card?.content);
        const middleObject = JSON.parse(item.content);
        item.customObject = middleObject.customContent;
        if (typeof item.thumbnail !== 'undefined') {
          item.thumbnail.sizeInfo = middleObject.sizeInfo;
        }
      }
    });
    if (typeof item?.items !== 'undefined') {
      setItems(item?.items);
    }
  }, [item?.items]);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(index);
    }
  }, [index]);

  useEffect(() => {
    if (typeof item.custom !== 'undefined') {
      const _cardLink: CardLinkModel = { customObject: JSON.parse(item.custom) };
    }
  }, []);

  return (
    <>
      <div
        ref={containerRef}
        style={{
          overflowY: 'hidden',
          marginBottom: 15,
          borderRadius: 15,
          height: 'auto',
          background: 'transparent',
        }}
      >
        <div style={{ position: 'relative', height: '100%' }}>
          {items?.length! > 1 && (
            <ArrowStyleLeft
              isMobile={screenType === 'mobile'}
              style={{ opacity: index === 0 ? 0.2 : 0.7 }}
              onClick={() => {
                handleClickArrow(false);
              }}
            >
              <img style={{ width: screenType === 'mobile' ? 6 : 10 }} src="/image/leftArrow.png" />
            </ArrowStyleLeft>
          )}
          <Swiper
            pagination
            modules={[Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => setSwiper(swiper)}
          >
            {items?.map((item: InternalLinkCard) => (
              <SwiperSlide className="swiper" key={item.id}>
                <>{getItem(item)}</>
              </SwiperSlide>
            ))}
          </Swiper>
          {items?.length! > 1 && (
            <ArrowStyleRight
              isMobile={screenType === 'mobile'}
              style={{ opacity: index === items?.length! - 1 ? 0.2 : 0.7 }}
              onClick={() => {
                handleClickArrow(true);
              }}
            >
              <img
                style={{ width: screenType === 'mobile' ? 6 : 10 }}
                src="/image/rightArrow.png"
                alt=""
              />
            </ArrowStyleRight>
          )}
        </div>
      </div>
      {isPaymentMode && typeof selectedItem?.price !== 'undefined' && (
        <TossPayment title={selectedItem?.title} qty={selectedCount} amount={selectedItem?.price} />
      )}
      <Modal show={showItemDetail}>
        {typeof selectedItem !== 'undefined' && (
          <ItemLinkDetailComponent
            item={selectedItem}
            onClose={() => {
              setShowItemDetail(false);
            }}
            onOrderClick={handleOrderNow}
            onChangeQty={(qty: number) => {
              setSelectedCount(qty);
            }}
          />
        )}
      </Modal>
    </>
  );
};

const MenuTitleStyle = styled.div<{ isMobile: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  padding: 0 25px;
  cursor: pointer;
  line-height: ${(props) => (props.isMobile ? '100px' : '80px')};
  background: transparent;
`;

const ArrowStyle = css<{ isMobile: boolean }>`
  top: 50%;
  width: ${(props) => (props.isMobile ? '20px' : '30px')};
  height: ${(props) => (props.isMobile ? '20px' : '30px')};
  padding: 5px 5px 5px 5px;
  border-radius: ${(props) => (props.isMobile ? '20px' : '30px')};
  background: rgba(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 20;
  position: absolute;
`;

const ArrowStyleLeft = styled.div`
  ${ArrowStyle}
  left: 5px;
`;

const ArrowStyleRight = styled.div`
  ${ArrowStyle}
  right:5px;
`;
export default ItemLinkComponent;
//https://css-tricks.com/practical-css-scroll-snapping/
