import React, { CSSProperties } from 'react';
import { LinkDto } from 'services/data-contracts';
import styled from 'styled-components';

const SimplePreview = ({ model, type }: { model?: LinkDto; type: 'inine' | 'multiline' }) => {
  return (
    <PreviewContainerStyle>
      <PreviewStyle>
        <LinkContainerTextStyle>
          {typeof model?.thumbnail?.publicUrl !== 'undefined' && (
            <div style={imgStyle}>
              <img
                src={model?.thumbnail?.publicUrl}
                width={40}
                height={40}
                alt=""
                style={{ borderRadius: '50%' }}
              />
            </div>
          )}
          <LinckContainerTitleStyle>
            <LinkTitleStyle
              style={{
                display: 'table-cell',
                whiteSpace: type === 'multiline' ? 'pre-line' : 'normal',
              }}
            >
              {model?.content || model?.title || 'Preview'}
            </LinkTitleStyle>
          </LinckContainerTitleStyle>
        </LinkContainerTextStyle>
      </PreviewStyle>
    </PreviewContainerStyle>
  );
};

const imgStyle: CSSProperties = {
  height: 50,
  width: 50,
};

const PreviewContainerStyle = styled.div`
  margin: 30px 0 0 0;
  > div > div {
    width: 100%;
    /* width: calc(${window.innerWidth}px - 50px); */
    min-height: 40px;
    border-radius: 40px;
    border: 1px solid #dadada;
    box-shadow: none;
  }
  > div > div > div > img {
    margin: 0 0 0 10px;
  }
`;

const PreviewStyle = styled.div`
  width: 100%;
  display: table;
  height: 60px;
  overflow: auto;
  text-overflow: clip;
  word-break: break-all;
`;

const LinkContainerTextStyle = styled.div`
  border-radius: 10px;
  text-align: center;
  word-wrap: break-word;
  min-height: 50px;
  padding: 5px 5px 5px 5px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative;
  box-shadow: 0px 0px 7px -1px #c8c8c8;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const LinckContainerTitleStyle = styled.div`
  flex: 1;
  display: table;
`;

const LinkTitleStyle = styled.div`
  font-size: 18px;
`;
export default SimplePreview;
