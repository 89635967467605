import { setTokens } from 'libs/helper/localStorageHelper';
import { makeAutoObservable } from 'mobx';
import { Auth as AuthService } from 'services/Auth';
import {
  JoinDto,
  TokenDto,
  UserDto,
  ForgotPasswordDto,
  ChangePasswordDto,
} from 'services/data-contracts';
// import _ from "lodash";
import { REST_API_URL } from 'stores/AppStore';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  error?: any;
  statusText?: string;
}

export interface isValid {
  isValid: boolean;
}

class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: AuthService<unknown> = new AuthService({
    baseURL: REST_API_URL,
  });

  users: UserDto;

  status: number;

  userNameIsValid: isValid;

  emailIsValid: isValid;

  authError: any;

  clearStorage() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('me');
    localStorage.removeItem('refreshToken');
  }
  /** ============================================================================================== */

  *login(loginInfo: JoinDto) {
    const { data, error, status } = yield this.api.login(loginInfo);
    if (data !== null) {
      setTokens(data);
      return { result: true };
    } else {
      this.authError = error;
      return { result: false, error };
    }
  }

  logout = () => {
    /** 에디터에 intro 효과를 본 경험이 있는지 여부 */
    const editorIntro = localStorage.getItem('editorIntro');

    if (editorIntro !== null) {
      this.clearStorage();
      localStorage.setItem('editorIntro', 'true');
    } else {
      this.clearStorage();
    }
    window.location.href = '/login';
  };

  *refreshToken(token: TokenDto) {
    const { data } = yield this.api.refreshToken(token);
    return data;
  }

  *join(joinInfo: JoinDto) {
    const { data, status } = yield this.api.join(joinInfo);
    if (data !== null) {
      // setTokens(data);
      return { result: true };
    } else {
      return { result: false };
    }
  }

  *resign() {
    const { status } = yield this.api.resign();
    if (status === 200) {
      /** 에디터에 intro 효과를 본 경험이 있는지 여부 */
      const editorIntro = localStorage.getItem('editorIntro');

      if (editorIntro !== null) {
        this.clearStorage();
        localStorage.setItem('editorIntro', 'on');
      } else {
        this.clearStorage();
      }
      window.location.href = '/';
    }
  }
  /** ============================================================================================== */

  *confirmJoin(token: string) {
    const { data, status, error } = yield this.api.confirmJoin({ token }, { noToast: true });
    if (status === 200) {
      setTokens(data);
      window.location.href = '/admin';
      return data as TokenDto;
    } else {
      return error;
    }
  }

  /**
   * @description 가입인증 링크 메일 요청
   * @param joinInfo userName, password
   */
  *requestJoinToken(joinInfo: JoinDto) {
    const { data, status } = yield this.api.requestJoinToken(joinInfo);
    if (status === 200) {
      window.location.href = '/email-auth';
    } else {
      this.authError = data;
    }
  }
  /** ============================================================================================== */

  *duplicateUserName(dupUserName: any) {
    const { data } = yield this.api.duplicateUserName({
      userName: dupUserName,
    });
    this.userNameIsValid = data;
    if (data.isValid === true) {
      return true;
    } else {
      return false;
    }
  }
  /** ============================================================================================== */

  *forgotPassword(info: ForgotPasswordDto) {
    const { data, status, error } = yield this.api.forgotPassword(info);
    if (status === 200) {
      window.location.href = '/email-auth';
    } else {
      this.authError = data;
      return error;
    }
  }

  *changePassword(passworInfo: ChangePasswordDto) {
    const { data, status } = yield this.api.changePassword(passworInfo);
    if (status === 200) {
      window.location.href = '/admin';
    } else {
      this.authError = data;
    }
  }

  *resetPassword(token: string, passworInfo: ChangePasswordDto) {
    const { data, status } = yield this.api.resetPassword({ token: token }, passworInfo);
    if (status === 200) {
      window.location.href = '/admin/';
    } else {
      this.authError = data;
    }
  }

  // *duplicatePhoneNumber(dupEmail: any) {
  //   const { data } = yield this.api.duplicatePhoneNumber({ email: dupEmail });
  //   this.emailIsValid = data;
  //   if (data.isValid === true) {
  //     return { newEmail: true };
  //   } else {
  //     return { newEmail: false };
  //   }
  // }
}

export default AuthStore;
