import { makeAutoObservable } from 'mobx';
import { Me as MeService } from 'services/Me';
import { UsersParamInterface } from 'types/UserTpes';
import { LinkDto, SocialLinkDto, LinkPageDto } from 'services/data-contracts';
import { REST_API_URL } from 'stores/AppStore';
import _ from 'lodash';

class LinkStore {
  constructor() {
    makeAutoObservable(this);
  }

  api: MeService<unknown> = new MeService({
    baseURL: REST_API_URL,
  });

  links: Array<LinkDto>;
  link: LinkDto;

  /** ========================================================================================= */

  *getLinks() {
    const { data } = yield this.api.getLinks({ page: 0, size: 1000 });
    this.links = data.slice();
    return data;
  }

  /**
   * @deprecated
   * @param linkId
   */
  *getLink(linkId: number) {
    const { data } = yield this.api.getLink1(linkId);
    this.link = data;
  }

  *getLinkById(linkId: number) {
    const { data } = yield this.api.getLink1(linkId);
    this.link = data;
    return data;
  }

  *createLink(linkData: LinkDto) {
    const { data } = yield this.api.createLink(linkData);
  }

  *updateLink(LinkData: LinkDto) {
    const { data } = yield this.api.updateLink(LinkData.id as number, LinkData);
  }

  *deleteLink(linkId: number) {
    const { status, data, error } = yield this.api.deleteLink(linkId);
    if (status === 200) {
      return data;
    } else {
      return error;
    }
  }

  *createSocialLink(snsData: SocialLinkDto) {
    const { status, data, error } = yield this.api.createSocialLink(snsData);
    if (status === 200) {
      return data;
    } else {
      return error;
    }
  }

  *deleteSocialLink(linkId: number) {
    const { status, data, error } = yield this.api.deleteSocialLink(linkId);
    if (status === 200) {
      return data;
    } else {
      return error;
    }
  }

  *updateSocialLink(linkId: number, link: SocialLinkDto) {
    const { status, data, error } = yield this.api.updateSocialLink(linkId, link);
    if (status === 200) {
      return data;
    } else {
      return error;
    }
  }

  *reOrderLinkSeq(linkId: number, link: LinkDto) {
    const { data } = yield this.api.updateLinkSeq(linkId, link);
  }

  *getUsers(params?: UsersParamInterface) {
    // if (typeof params === "undefined") {
    //   params = { size: 1000 };
    // }
    // const { data } = yield this.api.getUsers(params);
  }
}

export default LinkStore;
