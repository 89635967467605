import styled from 'styled-components';

export const ItemContainerStyle = styled.div`
  overflow: hidden;
  border-radius: 15px;
  min-height: 60px;
  background: #fff;

  width: 100%;
  margin-bottom: 15px;
  text-align: center;
  height: 100%;
  border: 1px solid #fff;
  box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.15);

  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.15);
    /* color: #fff; */
    /* background: #efefef; */
  }
`;

// 아이템 스타일 새로운 디자인으로 변경 (우선 simpleLink와 TelLink에만 적용)
export const NewItemContainerStyle = styled.div`
  overflow: hidden;
  background: #fff;
  /* height: 50px; */
  margin: 0 auto 15px auto;
  text-align: center;
  border: 1px solid #eeeeee;
  border-radius: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out;

  &:hover {
    /* border: 1px solid #eeeeee; */
    box-shadow: 0px 0px 15px -4px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-out;
  }
  a > div > div > div {
    display: flex;
    align-items: center;
    margin-left: 2px;
  }
`;

export const SimpleLinkStyle = styled(NewItemContainerStyle)`
  a > div > div > div > img {
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

export const TelLinkStyle = styled(NewItemContainerStyle)`
  > div > div {
    display: flex;
    align-items: center;
  }
  > div > div > img {
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
  }
`;
