import React, { useEffect } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import SocialLinksContainer from 'features/adminFeatures/link/containers/SocialLinksContainer';
import Simulator from 'features/adminFeatures/simulator/containers/Simulator';
import useScreenType from 'libs/hooks/useScreenType';
import styled from 'styled-components';

const SocialLinksPage = observer(() => {
  const screenType = useScreenType();
  const { meStore, userStore, templateStore } = useStore();

  useEffect(() => {
    const init = async () => {
      // profile 취득
      const profile: any = await meStore.getMyProfile();
      const data: any = await userStore.getUserInfo(profile.userName);
      // template Store에 기본 모델을 바인딩하여 변경을 바로 반영할수 있도록 한다.
      templateStore.setModel(data?.profile);
      // 사용자 링크취득
      userStore.getUserInfo(data?.profile?.userName);
    };
    init();
  }, [meStore, userStore, templateStore]);
  return (
    <>
      <div className="row">
        <div style={{ flex: 1 }}>
          <AdminContainerOuter>
            <div style={{ marginRight: 20, marginLeft: 20 }}>
              <SocialLinksContainer />
            </div>
          </AdminContainerOuter>
        </div>
        {screenType === 'window' && (
          <div style={{ display: 'table' }}>
            <div
              style={{
                display: 'table-cell',
                verticalAlign: 'middle',
                width: 600,
                height: 'calc(100vh - 110px)',
                overflow: 'scroll',
              }}
            >
              <Simulator />
            </div>
          </div>
        )}
      </div>
    </>
  );
});

const AdminContainerOuter = styled.div`
  max-width: 600px;
  margin: auto;
  flex: 1;
`;

export default SocialLinksPage;
