import React, { useState, useEffect, useRef } from 'react';
import { SizeInfo, VideoLinkModel } from 'types/CommonTypes';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import useScreenType from 'libs/hooks/useScreenType';
import VideoEditor from 'features/adminFeatures/commons/components/VideoEditor';

const YouTubeLink = ({ item }: { item: VideoLinkModel }) => {
  const youtubePrefix = 'https://www.youtube.com/embed/';
  const containerRef = useRef<HTMLDivElement>(null);
  const CINEMA = 1.777; //16:9

  const screenType = useScreenType();
  const [videoType, setVideoType] = useState<'upload' | 'external'>();
  const [sizeInfo, setSizeInfo] = useState<SizeInfo>();
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    if (typeof item.custom !== 'undefined') {
      item.customObject = JSON.parse(item.custom);
      setVideoType(item.customObject?.videoType);
      setSizeInfo(item.customObject?.videoSize);

      setHeight(containerRef.current?.offsetWidth! / CINEMA);
    }
  }, [item]);

  return (
    <>
      <div className="card-container" style={{ borderRadius: '20px' }} ref={containerRef}>
        <div
          style={{
            minHeight: videoType === 'external' ? height : 'auto',
          }}
          className="div-round"
        >
          {videoType === 'upload' ? (
            <VideoEditor
              video={item?.thumbnail}
              isCover={sizeInfo?.isCover}
              mode="view"
              maxHeight="500px"
            />
          ) : (
            // <VideoComponent video={item?.thumbnail} sizeInfo={sizeInfo} />
            <iframe
              className="iframe-round"
              height={screenType === 'window' ? 500 : 300}
              src={youtubePrefix + item.linkUrl}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen; picture-in-picture"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default YouTubeLink;
