import React from 'react';
import { CommonBetweenRowStyle } from 'resources/styles/common/styles';
import { SocialLinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import socialServiceList from 'types/socialServiceList';
import Theme from 'resources/styles/common/theme';
import styled from 'styled-components';
import _ from 'lodash';

type SocialServiceListProps = {
  registeredSocials: SocialLinkDto[];
  onPressService: (service: {
    key: string;
    value: string;
    type: string;
    translation: string;
  }) => void;
  onRequestClose: () => void;
};

const SocialServiceList = ({
  registeredSocials,
  onPressService,
  onRequestClose,
}: SocialServiceListProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      {socialServiceList.map((social) => {
        const isRegistered =
          typeof _.find(registeredSocials, { socialType: social.type }) !== 'undefined';
        return (
          <ServiceRow
            isRegistered={isRegistered}
            key={`list_${social.key}`}
            onClick={() => {
              if (isRegistered) return;
              onPressService(social);
            }}
          >
            <span>
              {t(_.find(socialServiceList, { key: social.key })?.translation || 'youtube')}
              <span style={{ fontSize: 12, color: '#666' }}>{isRegistered && t('registered')}</span>
            </span>
            <ServiceIconLarger src={`/image/sns/${social.value}`} alt="" />
          </ServiceRow>
        );
      })}
      <ButtonArea>
        <Button
          width={'100%'}
          backgroundColor={Theme.colors.borderColor}
          onClick={() => onRequestClose()}
        >
          {t('close')}
        </Button>
      </ButtonArea>
    </Container>
  );
};

const Container = styled.div``;

const ServiceRow = styled(CommonBetweenRowStyle)<{ isRegistered?: boolean }>`
  cursor: ${(props) => (props.isRegistered ? 'auto' : 'pointer')};
  padding: 16px 16px 16px 16px;
  border: 1px solid #eee;
  border-radius: 8px;
  margin: 16px 0 16px 0;
  background-color: ${(props) => (props.isRegistered ? '#eee' : 'white')};
  opacity: ${(props) => (props.isRegistered ? 0.5 : 1)};
`;

const ServiceIcon = styled.img`
  height: 20px;
`;
const ServiceIconLarger = styled.img`
  height: 30px;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0 0 0;
`;

const Button = styled.div<{ backgroundColor?: string; fontColor?: string; width: string }>`
  display: flex;
  cursor: pointer;
  width: ${(props) => props.width};
  padding: 10px 15px 10px 15px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor || 'white'};
  color: ${(props) => props.fontColor || 'black'};
`;

export default SocialServiceList;
