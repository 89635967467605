import React, { useEffect, useState } from 'react';
import { LinkDto } from 'services/data-contracts';
import { observer } from 'mobx-react';
import {
  SimpleLink,
  CardLinkComponent,
  MapLinkComponent,
  YouTubeLink,
  TelLink,
  PageLink,
  TextLink,
  ItemLinkComponent,
} from 'components/items';
import { ItemLinkModel } from 'types/CommonTypes';
import { useStore } from 'stores/StoreHelper';

/**
 * @description 실제 링크를 표시하는 컴포넌트
 * @description 시뮬레이터와 사용자링크 페이지에서 함께 사용하므로
 * @description 사이드이펙트에 주의할것.
 */
const ItemsComponent = observer(({ links }: { links?: Array<LinkDto> }) => {
  const { simulatorStore, templateStore } = useStore();
  const [isSimulatorMode, setIsSimulatorMode] = useState<boolean>(false);
  const [items, setItems] = useState<Array<ItemLinkModel>>();

  const getLinkItem = (link: any) => {
    switch (link.linkType) {
      case 'SimpleLink':
        return <SimpleLink item={link} />;
      case 'TextLink':
        return <TextLink item={link} />;
      case 'CardLink':
        return <CardLinkComponent item={link} />;
      case 'MapLink':
        return <MapLinkComponent item={link} height={isSimulatorMode ? 200 : 350} />;
      case 'Youtube':
        return <YouTubeLink item={link} />;
      case 'TelLink':
        return <TelLink item={link} />;
      case 'Page':
        return <PageLink item={link} />;
      case 'ItemLink':
        return <ItemLinkComponent item={link} />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (typeof links !== 'undefined') {
      const lawItems = links.filter((item) => item.linkType === 'ItemLink');
      setItems(lawItems);
    }
    if (typeof simulatorStore !== 'undefined') {
      setIsSimulatorMode(simulatorStore.simulatorMode);
    }
  }, [simulatorStore]);
  return (
    <>
      <div className="global-width" style={{ position: 'relative', zIndex: 1, height: '100%' }}>
        {items?.map((link: ItemLinkModel) => (
          <div key={link?.id}>{getLinkItem(link)}</div>
        ))}
      </div>
    </>
  );
});

export default ItemsComponent;
