import React, { useState, InputHTMLAttributes, CSSProperties, useEffect } from 'react';
import { ImageUploader } from 'components/commons';
import { ColorPicker } from 'components/items';
import FormatItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
import TextSizeSelect from 'components/commons/TextSizeSelect';
import useScreenType from 'libs/hooks/useScreenType';
import styled from 'styled-components';
import _ from 'lodash';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  show: boolean;
  style?: CSSProperties;
  imageUrl?: string | undefined;
  showImageIcon?: boolean;
  containerStyle?: CSSProperties;
  onChangeStyle: (style: CSSProperties) => void;
  onChangeImage?: (action: 'add' | 'delete', thumbnail?: any) => void;
}

/**
 * @description 이미지 선택부터 크롭까지 제공한다.
 * @returns
 */
const TextToolBox = ({
  show,
  style,
  imageUrl,
  showImageIcon,
  containerStyle,
  onChangeStyle,
  onChangeImage,
}: InputProps) => {
  const screenType = useScreenType();
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [textColor, setTextColor] = useState<string>();
  const handleChangeStyle = (style: CSSProperties) => {
    onChangeStyle(style);
  };
  //TODO: 언어에 따라 기본 폰트 패밀리 변경
  const fontFamilyList = [
    { value: 'Noto Sans KR' },
    { value: 'Nanum Gothic' },
    { value: 'Nanum Myeongjo' },
  ];

  const textSizeList = [
    { label: 14, value: '14pt' },
    { label: 16, value: '16pt' },
    { label: 18, value: '18pt' },
    { label: 20, value: '20pt' },
    { label: 24, value: '24pt' },
    { label: 30, value: '30pt' },
    { label: 36, value: '36pt' },
    { label: 48, value: '48pt' },
  ];

  useEffect(() => {
    setTextColor(style?.color);
  }, [style?.color]);
  /** bold, italic, color, line-through 처리 fragment */
  const fontFormat = () => {
    return (
      <>
        <FormatButtonStyle
          onClick={() => {
            handleChangeStyle({
              fontWeight:
                typeof style?.fontWeight === 'undefined' || Number(style?.fontWeight) === 400
                  ? 600
                  : 400,
            });
          }}
        >
          <BoldStyle active={style?.fontWeight === 600}>B</BoldStyle>
        </FormatButtonStyle>
        <FormatButtonStyle
          onClick={() => {
            handleChangeStyle({
              fontStyle:
                typeof style?.fontStyle === 'undefined' || style?.fontStyle === 'normal'
                  ? 'italic'
                  : 'normal',
            });
          }}
        >
          <ItalicStyle active={style?.fontStyle === 'italic'} />
        </FormatButtonStyle>
        <ColorPickerButtonStyle
          style={style}
          onClick={() => {
            setShowColorPicker(true);
          }}
        >
          <ColorPickerButtonIconStyle
            alt=""
            src={
              typeof style?.color !== 'undefined'
                ? '/image/palate_active.png'
                : '/image/palate_inactive.png'
            }
          />
        </ColorPickerButtonStyle>
        {showColorPicker === true && (
          <div style={{ position: 'relative' }}>
            <ColorContainerStyle>
              <ColorPicker
                show
                defaultValue={textColor || '#000000'}
                onClose={() => {
                  setShowColorPicker(false);
                }}
                containerStyle={{}}
                onColorChanged={(color: string) => {
                  handleChangeStyle({ color });
                }}
              >
                <></>
              </ColorPicker>
            </ColorContainerStyle>
          </div>
        )}
        {/* <FormatButtonStyle
          onClick={() => {
            handleChangeStyle({
              textDecoration: style?.textDecoration !== 'line-through' ? 'line-through' : 'none',
            });
          }}
        >
          <FormatIconStyle
            src={
              style?.textDecoration !== 'line-through'
                ? '/image/strikethrough_inactive.png'
                : '/image/strikethrough_active.png'
            }
          />
        </FormatButtonStyle> */}
      </>
    );
  };

  return (
    <>
      {show && (
        <ContainerStyle
          className={`${show ? 'on' : ''}`}
          type={!_.isUndefined(screenType) ? screenType : ''}
          style={{ ...containerStyle }}
        >
          <OptionContainerStyle style={{ zIndex: 1000 }}>
            <TextSizeSelect
              style={{ minWidth: 185, fontSize: 15, color: '#111' }}
              containerStyle={{ marginRight: 10, marginLeft: 14 }}
              textSizeList={fontFamilyList}
              key={typeof style?.fontFamily !== 'undefined' ? String(style?.fontFamily) : 'Font'}
              defaultValue={
                typeof style?.fontFamily !== 'undefined' ? String(style?.fontFamily) : 'Font'
              }
              onClick={(font: string) => {
                handleChangeStyle({ fontFamily: font });
              }}
            />
            <TextSizeSelect
              style={{ width: 100, fontSize: 15, color: '#111' }}
              textSizeList={textSizeList}
              key={typeof style?.fontSize !== 'undefined' ? String(style?.fontSize) : '16pt'}
              defaultValue={
                typeof style?.fontSize !== 'undefined'
                  ? String(style?.fontSize).replace('px', 'pt')
                  : '16pt'
              }
              onClick={(size: number) => {
                handleChangeStyle({ fontSize: String(size).replace('pt', 'px') });
              }}
            />
            {screenType === 'window' && fontFormat()}
          </OptionContainerStyle>
          {screenType === 'mobile' && <OptionContainerStyle>{fontFormat()}</OptionContainerStyle>}
          <OptionContainerStyle>
            <FormatButtonStyle onClick={() => handleChangeStyle({ textAlign: 'left' })}>
              <FormatIconStyle
                src={
                  style?.textAlign === 'left'
                    ? '/image/format_align_left_active.png'
                    : '/image/format_align_left.png'
                }
              />
            </FormatButtonStyle>
            <FormatButtonStyle onClick={() => handleChangeStyle({ textAlign: 'center' })}>
              <FormatIconStyle
                src={
                  style?.textAlign === 'center' || typeof style?.textAlign === 'undefined'
                    ? '/image/format_align_center_active.png'
                    : '/image/format_align_center.png'
                }
              />
            </FormatButtonStyle>
            <FormatButtonStyle onClick={() => handleChangeStyle({ textAlign: 'right' })}>
              <FormatIconStyle
                src={
                  style?.textAlign === 'right'
                    ? '/image/format_align_right_active.png'
                    : '/image/format_align_right.png'
                }
              />
            </FormatButtonStyle>
            {showImageIcon && (
              <ImageContainerStyle>
                {typeof imageUrl !== 'undefined' && (
                  <DeleteButtonStyle
                    onClick={() => {
                      if (typeof onChangeImage !== 'undefined') {
                        onChangeImage('delete');
                      }
                    }}
                  >
                    <DeleteIconStyle src="/image/delete_box.png" />
                  </DeleteButtonStyle>
                )}
                <ImageUploader
                  attachType="image"
                  cropSize={{ width: 1600, height: 1600 }}
                  onSelected={(thumbnails: any) => {
                    if (typeof onChangeImage !== 'undefined') {
                      onChangeImage('add', thumbnails[0]);
                    }
                  }}
                >
                  <FormatButtonStyle>
                    <FormatIconStyle
                      src={
                        typeof imageUrl !== 'undefined'
                          ? '/image/image_active.png'
                          : '/image/image_inactive.png'
                      }
                    />
                  </FormatButtonStyle>
                </ImageUploader>
              </ImageContainerStyle>
            )}
          </OptionContainerStyle>
        </ContainerStyle>
      )}
    </>
  );
};

const ContainerStyle = styled.div<{ type: string }>`
  border: ${(props) => (props.type === 'mobile' ? '1px solid #bbb' : '')};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid #bbb;
  border-left: 1px solid #bbb;
  border-right: 1px solid #bbb;
  padding: 5px 0 15px 0;
`;

const OptionContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 100%; 부모가 flex-wrap:wrap을 선언할 경우 100%이면 적용되지 않아 주석처리 함. */
  padding: 10px 0 0 0;
`;

const FormatButtonStyle = styled.div<{ marginRight?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 20px;
  margin: 0 ${(props) => (props?.marginRight ? props?.marginRight : 0)}px 0 14px;
  cursor: pointer;
  &:hover {
    background-color: #efefef;
    border-radius: 8px;
  }
`;

const FormatIconStyle = styled.img`
  width: 24px;
  height: 24px;
`;

const BoldStyle = styled.div<{ active: boolean }>`
  color: ${(props) => (props.active ? '#333' : '#ccc')};
  font-size: 19px;
  font-weight: 900;
`;

const ItalicStyle = styled(FormatItalicOutlinedIcon)<{ active: boolean }>`
  color: ${(props) => (props.active ? '#333' : '#ccc')};
`;

const ColorContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: -220px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  @media ${(props) => props.theme.media.mobile} {
    left: -120px;
    top: 40px;
  }
`;

const ColorPickerButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: 0 0 0 14px;
  cursor: pointer;
  &:hover {
    background-color: #efefef;
    border-radius: 8px;
  }
`;

const ColorPickerButtonIconStyle = styled.img`
  width: 24px;
  height: 24px;
`;

const ImageContainerStyle = styled.div`
  position: relative;
`;

const DeleteButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 100px;
  position: absolute;
  top: -1px;
  right: 0;
  z-index: 2;
  cursor: pointer;
`;

const DeleteIconStyle = styled.img`
  width: 13px;
  height: 13px;
`;

export default TextToolBox;
