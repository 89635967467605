import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import AuthInput from 'features/mainFeatures/auth/components/AuthInput';
import BlueBtn from 'features/mainFeatures/auth/components/BlueBtn';
import { toast } from 'react-toastify';

const FindIdPage = () => {
  const { t } = useTranslation();
  const { authStore } = useStore();
  const [isActiveButton, setIsActiveButton] = useState<boolean>(false);
  const idRef = useRef<any>(null);

  const handleChangeUserInfo = (info: string) => {
    idRef.current = info;
    idRef.current.length > 4 ? setIsActiveButton(true) : setIsActiveButton(false);
  };

  const handleClickFindPassword = async () => {
    const result: any = await authStore.forgotPassword({ account: idRef.current });

    if (typeof result?.data?.errorCode !== 'undefined') {
      if (result?.status === 404) {
        toast(t('noUserFound'), { type: 'error', position: 'top-center' });
      }
    }
  };

  return (
    <FindIdContainer>
      <LoginTitle>{process.env.REACT_APP_SERVEICE_NAME}</LoginTitle>
      <div style={{ width: '300px' }}>
        <AuthInput
          // defaultValue={!_.isUndefined(meStore.me) ? meStore.me?.userName : ''}
          label={t('idAndUserName')}
          // subLabel="(영문, 숫자만 가능. 15자 이내)"
          placeholder={t('userNameAndUserName')}
          maxLength={20}
          onChange={handleChangeUserInfo}
        />
      </div>
      <FindPasswordButtonStyled onClick={handleClickFindPassword} isActiveButton={isActiveButton}>
        {t('passwordFind')}
      </FindPasswordButtonStyled>
    </FindIdContainer>
  );
};

const flexCenter = css`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
`;

const FindIdContainer = styled.div`
  margin: auto;
  height: 100vh;
  ${flexCenter};
  flex-direction: column;
`;

const LoginTitle = styled.div`
  text-align: center;
  font-family: 'Barlow';
  font-size: 100px;
  color: #3e64ea;
  font-weight: 500;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 4em;
  }
`;

const FindPasswordButtonStyled = styled(BlueBtn)<{ isActiveButton?: boolean }>`
  margin-top: 56px;
  width: 160px;
  cursor: ${({ isActiveButton }) => (isActiveButton ? 'pointer' : 'none')};
  pointer-events: ${({ isActiveButton }) => (isActiveButton ? 'auto' : 'none')};
  background-color: ${({ isActiveButton }) => (isActiveButton ? '#3e64ea' : '#999999')};
`;

export default FindIdPage;
