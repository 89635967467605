/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApprovalTosspayRequestDto, OrderDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Payment<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 결제요청
   *
   * @tags payment
   * @name Pay
   * @summary 결제요청
   * @request POST:/v1/api/payments/toss/pay
   * @secure
   */
  pay = (data: OrderDto, params: RequestParams = {}) =>
    this.request<OrderDto, any>({
      path: `/v1/api/payments/toss/pay`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 결제승인
   *
   * @tags payment
   * @name ApproveTosspay
   * @summary 결제승인
   * @request POST:/v1/api/payments/toss/approve
   * @secure
   */
  approveTosspay = (
    query: { orderId: number },
    data: ApprovalTosspayRequestDto,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/v1/api/payments/toss/approve`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 결제취소
   *
   * @tags payment
   * @name CancelPayment
   * @summary 결제취소
   * @request DELETE:/v1/api/payments/{orderId}/cancel
   * @secure
   */
  cancelPayment = (orderId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/v1/api/payments/${orderId}/cancel`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
}
