import React from 'react';
import { CustomUserProfile } from 'types/CommonTypes';
import { observer } from 'mobx-react';

type DefaultProps = {
  profile?: CustomUserProfile;
};

const SelfIntroduction = observer(({ profile }: DefaultProps) => {
  return (
    <>
      {typeof profile?.selfIntroduction !== 'undefined' && (
        <div
          style={{ ...profile.selfIntroductionProps?.style, marginTop: 20, whiteSpace: 'pre-line' }}
        >
          {profile?.selfIntroduction}
        </div>
      )}
    </>
  );
});

export default SelfIntroduction;
