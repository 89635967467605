import React, { useEffect, useState } from 'react';
import { LinkDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import LinkContainerLayout from 'features/adminFeatures/link/components/LinkContainerLayout';
import IconButton from 'resources/controls/IconButton';
import styled from 'styled-components';
import { Row } from 'components/commons/layouts';
import { Checkbox } from '@mui/material';

type defaultProps = {
  item: LinkDto;
  on: boolean;
  /** @description  view일때는 클릭/삭제만 가능 */
  mode?: 'create' | 'edit' | 'view';
  onSelect?: (item: LinkDto) => void;
  onEdit?: (item: LinkDto) => void;
  onDelete?: (item: LinkDto) => void;
  onShowChange?: (isShow: boolean) => void;
};

const LinkComponent = ({
  item,
  mode,
  on,
  onSelect,
  onDelete,
  onEdit,
  onShowChange,
}: defaultProps) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>();

  const getLinkType = () => {
    switch (item?.linkType) {
      case 'SimpleLink':
        return 'link.png';
      case 'TextLink':
        return 'text.png';
      case 'CardLink':
        return 'card.png';
      case 'MapLink':
        return 'map.png';
      case 'Youtube':
        return 'youtube.png';
      case 'TelLink':
        return 'phone.png';
      case 'Page':
        return 'page.png';
      default:
        break;
    }
  };

  return (
    <LinkContainerLayout on={on}>
      <Checkbox
        defaultChecked={item.isActive}
        onClick={(e: any) => {
          // setIsActive((prev: boolean) => true);
          if (typeof onShowChange === 'function') {
            onShowChange(e.target.checked);
          }
        }}
      />
      <LinkInfoContainerStyle
        onClick={() => {
          if (typeof onSelect === 'function') {
            onSelect(item);
          }
        }}
      >
        {/* <LinkIconStyle src={`/image/linkIcons/${getLinkType()}`} alt="" /> */}

        <TitleStyle>{item?.title}</TitleStyle>
      </LinkInfoContainerStyle>
      <ButtonContainerStyle>
        {/* <IconButton
          onClick={() => {
            if (typeof onEdit !== 'undefined') {
              onEdit(item);
            }
          }}
          image="/image/edit.png"
          // hoverColor={'#f5f5f5'}
          width={50}
        />
        <LineOfButtonStyle /> */}

        <IconButton
          onClick={() => {
            if (typeof onDelete !== 'undefined') {
              onDelete(item);
              // toast('링크가 삭제되었습니다.');
              // toast.update('toastId', {
              //   render: '링크가 삭제되었습니다.',
              //   type: toast.TYPE.INFO,
              //   autoClose: 5000,
              //   toastId: myNewToastId,
              // });
            }
          }}
          image="/image/delete.png"
          // hoverColor={'#f5f5f5'}
          width={50}
        />
      </ButtonContainerStyle>
    </LinkContainerLayout>
  );
};

const LinkInfoContainerStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  margin: 0 0 0 5px;
  border-radius: 50px;
  cursor: pointer;
`;

const LinkIconStyle = styled.img`
  width: 40px;
  height: 40px;
`;

const TitleStyle = styled.div`
  flex: 1;
  width: 300px;
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 0 0 20px;
`;

const ButtonContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LineOfButtonStyle = styled.div`
  width: 1px;
  height: 25px;
  border-left: 1px solid #eee;
`;

export default LinkComponent;
