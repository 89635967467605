import React from 'react';
import { LinkDto } from 'services/data-contracts';
import { SimpleLinkStyle } from 'features/adminFeatures/template/components/ItemContainerStyle';
import _ from 'lodash';

type defaultProps = {
  item: LinkDto;
  mode?: 'create' | 'edit';
  onDelete?: (item: LinkDto) => void;
};

const SimpleLink = ({ item, mode, onDelete }: defaultProps) => {
  const IMAGE_WIDTH = 50;
  let urlRef = '';

  if (!_.isEmpty(item) && !_.isUndefined(item.linkUrl)) {
    urlRef =
      item?.linkUrl.startsWith('http://') || item?.linkUrl.startsWith('https://')
        ? item?.linkUrl
        : `//${item?.linkUrl}`;
  }

  return (
    <SimpleLinkStyle>
      <a
        rel="noreferrer"
        href={String(urlRef)}
        target="_blank"
        style={{ textDecoration: 'none', color: 'black', width: '100%' }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <div className="link-container-text">
            {typeof item.thumbnail !== 'undefined' && (
              <div
                style={{
                  height: IMAGE_WIDTH,
                  width: IMAGE_WIDTH,
                  float: 'left',
                  position: 'relative',
                }}
              >
                <img
                  alt=""
                  src={item.thumbnail?.publicUrl}
                  width={IMAGE_WIDTH}
                  height={IMAGE_WIDTH}
                  style={{ borderRadius: 8 }}
                />
              </div>
            )}
            <div
              style={{
                // position: 'relative',
                left: typeof item.thumbnail !== 'undefined' ? IMAGE_WIDTH + 20 : 10,
                right: typeof item.thumbnail !== 'undefined' ? IMAGE_WIDTH + 20 : 10,
                width: typeof item.thumbnail !== 'undefined' ? 'calc(100% - 100px)' : '100%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  verticalAlign: 'middle',
                  textAlign: 'center',
                  fontSize: 16,
                  letterSpacing: 2,
                }}
              >
                {item?.title}
              </div>
            </div>
          </div>
        </div>
      </a>
      {mode === 'edit' && (
        <div className="row">
          <div>E</div>
          <div
            style={{
              cursor: 'pointer',
              width: 20,
            }}
            onClick={() => {
              if (typeof onDelete !== 'undefined') {
                onDelete(item);
              }
            }}
          >
            <img src="/image/trash.png" alt="" />
          </div>
        </div>
      )}
    </SimpleLinkStyle>
  );
};

export default SimpleLink;
