import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface BasicProps {
  isShow: boolean;
  title: string;
  content?: string | JSX.Element | undefined;
  cancelTitle?: string;
  approvalTitle?: string;
  cancel: (itme?: any | undefined) => void | undefined;
  approval: (itme?: any | undefined) => void | undefined;
}

const SecessionConfirmModal = ({
  isShow,
  title,
  content,
  cancelTitle,
  cancel,
  approvalTitle,
  approval,
}: BasicProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isShow}
      style={{
        overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
        content: {
          maxWidth: 320,
          height: 212,
          margin: 'auto',
        },
      }}
    >
      <ConfirmWrapper>
        <ConfirmTitle>{title}</ConfirmTitle>
        <ConfirmContent>{content}</ConfirmContent>
        <ButtonGroup>
          <CancelButton
            onClick={(item?: any | undefined) => {
              cancel(item);
            }}
          >
            {!_.isUndefined(cancelTitle) ? cancelTitle : t('cancellation')}
          </CancelButton>
          <ApprovalButton
            onClick={(item?: any | undefined) => {
              approval(item);
            }}
          >
            {!_.isUndefined(approvalTitle) ? approvalTitle : t('confirm')}
          </ApprovalButton>
        </ButtonGroup>
      </ConfirmWrapper>
    </Modal>
  );
};

const ConfirmWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    background-color: white;
  }
`;

const ConfirmTitle = styled.div`
  text-align: center;
  font-size: 18px;
  color: #4ac3de;
`;
const ConfirmContent = styled.div`
  /* line-height: 140px; */
  font-size: 14px;
  /* text-align: center; */
  /* white-space: pre-wrap; */
  color: #00000084;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 12px;
    height: 120px;
  }
  @media ${(props) => props.theme.media.desk} {
    height: 140px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 16px; */
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 24px;
  }
`;
const CancelButton = styled.div`
  background-color: ${(props) => props.theme.colors.pointColor};
  color: white;
  height: 40px;
  line-height: 40px;
  width: 120px;
  border-radius: 8px;
  text-align: center;
  &:hover {
    cursor: pointer;
    opacity: 0.4;
  }
`;
const ApprovalButton = styled.div`
  background-color: #dddddd;
  color: white;
  height: 40px;
  line-height: 40px;
  width: 120px;
  border-radius: 8px;
  text-align: center;
  &:hover {
    cursor: pointer;
    opacity: 0.4;
  }
`;

export default SecessionConfirmModal;
