import FileStore from 'stores/FileStore';
import MeStore from 'stores/MeStore';
import { CustomUserProfile } from 'types/CommonTypes';

const saveProfile = async (
  profileModel: CustomUserProfile,
  meStore: MeStore,
  fileStore: FileStore,
) => {
  /** 저장된 number포맷 or bullet포맷 적용 */
  const newProfileModel = { ...profileModel };

  if (newProfileModel?.profileImage?.file?.constructor.name === 'Blob') {
    const image: any = await fileStore.uploadProfileImage(profileModel?.profileImage?.file);
    // newProfileModel.profileImage.id = image.id;
    newProfileModel.profileImage = { id: image.id };
  }
  if (newProfileModel?.backgroundImage?.file?.constructor.name === 'Blob') {
    const image: any = await fileStore.uploadProfileImage(profileModel?.backgroundImage?.file);
    // newProfileModel.backgroundImage.id = image.id;
    newProfileModel.backgroundImage = { id: image.id };
  }
  if (newProfileModel?.footerImage?.file?.constructor.name === 'Blob') {
    const image: any = await fileStore.uploadProfileImage(profileModel?.footerImage?.file);
    newProfileModel.footerImage.id = image.id;
  }

  newProfileModel.custom = JSON.stringify({
    titleProps: newProfileModel.titleProps,
    selfIntroductionProps: newProfileModel.selfIntroductionProps,
    template: newProfileModel.template,
    footerProps: newProfileModel.footerProps,
    titleYPosition: newProfileModel.titleYPosition,
    addInImage:
      newProfileModel.backgroundImage?.fileType === 'Background'
        ? newProfileModel.backgroundImage
        : undefined,
  });

  /** filetype이 백그라운드인경우, 미리 지정된 이미지이므로 삭제한다(안그러면 서버에러남) */
  if (newProfileModel.backgroundImage?.fileType === 'Background') {
    newProfileModel.backgroundImage = undefined;
  }
  /** 저장후, 변경된 userInfo의 정보를 가져온다 */
  meStore.saveMyProfile(newProfileModel);
  //   toast(t('yourProfileSave'), { position: 'top-center' });
  await meStore.getMyProfile();
  return true;
};

export { saveProfile };
