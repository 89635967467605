import React, { CSSProperties } from 'react';
import { Input, TextArea, TextToolBox, ImageUploader } from 'components/commons';
import { CustomUserProfile, Template } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';

const ProfileTitleModal = ({
  value,
  style,
  onValueChange,
  onStyleChange,
}: {
  value?: string;
  style?: CSSProperties;
  onValueChange: (value: string) => void;
  onStyleChange: (style: CSSProperties) => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ marginTop: 20 }}>{t('profileTitle')}</div>
      <TextToolBox
        style={style}
        show={true}
        onChangeStyle={(style: CSSProperties) => {
          onStyleChange(style);
        }}
      />
      <Input
        required
        id="profileTitle"
        containerStyle={{ ...containerStyle }}
        style={{ ...style, textAlign: 'center' }}
        defaultValue={value}
        onChangeValue={(value: string) => {
          onValueChange(value);
        }}
      />
    </>
  );
};

const containerStyle = {
  background: 'rgba(0, 0, 0, 0.05)',
  border: '1px dashed rgba(0, 0, 0, 0.2)',
  backdropFilter: 'blur(4px)',
};

export default ProfileTitleModal;
