import React, { useRef, useState, useEffect, CSSProperties } from 'react';
import {
  InternalLinkCard,
  CustomContent,
  defaultLinkEditorProps,
  CustomFile,
  CardLinkModel,
} from 'types/CommonTypes';
import { ToggleListButton, LinkItemType } from 'components/commons/ToogleListButton';
import { Layouts, Buttons, TextToolBox, ImageUploader, Input } from 'components/commons';
import { updateLink, updateModel } from 'components/items/commonLogic';
import { isValid, cloneDeep } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from 'components/items';
import { toast } from 'react-toastify';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import InputComponent from 'components/items/InputComponent';
import UrlComponent from 'components/items/UrlComponent';
import WhiteButton from 'components/commons/WhiteButton';
import IconButton from 'resources/controls/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useForm from 'libs/hooks/useForm';
import styled from 'styled-components';
import ToggleButton from 'controls/toggleButton/ToggleButton';
import VideoEditor from 'features/adminFeatures/commons/components/VideoEditor';

const disPlayTypeList: Array<LinkItemType> = [
  {
    id: 'slide',
    title: 'Slide',
    image: '/image/slider-horizontal.png',
  },
  {
    id: 'grid',
    title: 'Grid',
    image: '/image/grid.png',
  },
];

const CardLinkEditor = ({ model, stores, onRegistComplete }: defaultLinkEditorProps<any>) => {
  const { t } = useTranslation();
  const textContents = useRef<any>();
  const isFormValid = useForm(stores.formValidationStore);
  const [cards, setCards] = useState<Array<InternalLinkCard>>([]);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedCard, setSelectedCard] = useState<InternalLinkCard>();
  const [linkModel, setLinkModel] = useState<CardLinkModel>();
  const [renderType, setRenderType] = useState<'slide' | 'grid' | undefined>();
  // const inputRef = useRef<HTMLInputElement>(null);
  // const [clickToggle, setClickToggle] = useState<boolean>(false);

  const handleSaveLink = async () => {
    stores.uiStore.progress.show({});
    await preProcess(linkModel).then((response: any) => {});
    const result = await updateLink(linkModel!, isValid(model), { ...stores });
    stores.uiStore.progress.close();
    onRegistComplete(result!);
    if (result!) {
      toast(t('linkRegistration'));
    }
  };

  const preProcess = async (model: any) => {
    return Promise.all(
      await model?.cards?.map(async (card: InternalLinkCard) => {
        if (typeof card?.customObject !== 'undefined') {
          const newType = {
            customContent: card?.customObject,
            sizeInfo: card?.thumbnail?.sizeInfo,
          };
          card.content = JSON.stringify(newType);
        }
        if (card?.thumbnail?.file?.constructor.name === 'Blob') {
          const data: any = await stores.fileStore.uploadImage(card?.thumbnail.file);
          card.thumbnail = { id: data.id };
        }
        return card;
      }),
    );
  };

  const handleClickAddCard = () => {
    if (cards.length > 8) {
      // TODO: translate
      alert(t('createNineCard'));
      return;
    }
    setCards((prevState) => [
      ...prevState,
      { seq: cards[cards.length - 1].seq! + 1, customObject: cloneDeep(defalutCardCustomProps) },
    ]);
  };

  const handleClickChangeCard = (index: number) => {
    setSelectedId(index);
    setSelectedCard(cards[index]);
    appendValues(cards[index]);
  };

  const appendValues = (card: InternalLinkCard) => {
    if (typeof card?.customObject?.descriptionContents !== 'undefined') {
      textContents.current.textContent = card?.customObject?.descriptionContents?.text;
    } else {
      textContents.current.textContent = '';
    }
  };

  const handleDeleteCard = () => {
    stores.uiStore.confirm.show({
      message: 'Are you sure you want to delete this item?',
      onConfirmed: () => {
        const newCards = cards.filter((card, index) => {
          return index !== selectedId;
        });
        setCards(newCards);
        handleClickChangeCard(0);
      },
    });
  };

  // 멀티플 업로드 대응
  const handleBindMultipleImage = (images: Array<any>) => {
    let newCards = [...cards];
    if (cards.length === 1) {
      if (newCards[0].customObject?.isModified === false) {
        newCards = [];
      }
    }
    let _seq =
      cards.length === 0
        ? 0
        : typeof cards[cards.length - 1] === 'undefined'
        ? 0
        : cards[cards.length - 1].seq!;
    images.map((image: any, index: number) => {
      const _file: any = image.file;
      _file.publicUrl = image.publicUrl;
      newCards.push({
        seq: ++_seq,
        thumbnail: image as CustomFile,
        customObject: cloneDeep(defalutCardCustomProps),
      });
      setCards([...newCards]);
    });
  };

  // 부모로부터 전달받은 모델 할당
  useEffect(() => {
    const newModel: CardLinkModel = { ...(model as CardLinkModel) };

    if (Object.keys(newModel).length > 0) {
      if (typeof newModel.custom !== 'undefined') {
        newModel.customObject = JSON.parse(newModel.custom);
        setRenderType(newModel.customObject?.renderType);
      }

      setLinkModel(newModel);
      if (typeof newModel?.cards !== 'undefined') {
        const _cards = cloneDeep(newModel?.cards);
        _cards.map((card: InternalLinkCard) => {
          if (typeof card.content !== 'undefined') {
            card.customObject = cloneDeep(defalutCardCustomProps);
            const middleObject = JSON.parse(card.content);

            // 과거 데이터때문에 우선 처리
            if (typeof middleObject.customContent === 'undefined') {
              Object.assign(card.customObject!, middleObject);
            } else {
              Object.assign(card.customObject!, middleObject.customContent);
            }
            if (typeof card.thumbnail !== 'undefined') {
              card.thumbnail.sizeInfo = middleObject.sizeInfo;
            }
          }
          return card;
        });
        setCards(_cards);
        appendValues(_cards[0]);
      }
    } else {
      setCards([{ seq: 0, customObject: cloneDeep(defalutCardCustomProps) }]);
      setRenderType('slide');
      setLinkModel({
        linkType: 'CardLink',
        customObject: { renderType: 'slide', cardLinkType: 'cardItem' },
      });
    }
    setSelectedId(0);
  }, [model]);

  useEffect(() => {
    updateModel(setLinkModel, 'cards', cards);
  }, [cards]);

  return (
    <>
      {typeof cards !== 'undefined' && (
        <>
          <LinkTitleContainerStyle>
            <Input
              required
              placeholder="Link title"
              id="title"
              defaultValue={linkModel?.title}
              onChangeValue={(value: string) => {
                updateModel(setLinkModel, 'title', value);
              }}
            />
          </LinkTitleContainerStyle>

          <ToggleListButtonContainerStyle>
            <ToggleListTitle>{t('displayForm')}</ToggleListTitle>
            <ToggleListButton
              defaultChecked={renderType}
              toggleItems={disPlayTypeList}
              onClick={(item: LinkItemType) => {
                updateModel(setLinkModel, 'customObject', {
                  renderType: item.id,
                });
              }}
            />
          </ToggleListButtonContainerStyle>

          {/* 카드관련 버튼들 */}
          {/* <SectionBoxStyle> */}
          <CardContainerStyle>
            <Layouts.Row style={{ height: 70, borderBottom: '1px solid #eee' }}>
              <Buttons.IconButton
                containerStyle={{ background: '#636363' }}
                image="/images/gallery-add.svg"
                onClick={handleClickAddCard}
              >
                {t('cardAdd')}
              </Buttons.IconButton>
              <Buttons.IconButton
                containerStyle={{ background: '#636363' }}
                image="/images/gallery-remove.svg"
                onClick={handleDeleteCard}
                disabled={cards.length === 1}
              >
                {t('cardDelete')}
              </Buttons.IconButton>
              <ImageUploader
                cropSize={{ width: 1200, height: 1200 }}
                attachType="media"
                cropType={'square'}
                multipleUpload={true}
                onSelected={(thumbnails: any) => {
                  handleBindMultipleImage(thumbnails);
                }}
              >
                <Buttons.IconButton
                  containerStyle={{ background: '#636363' }}
                  image="/images/3square.svg"
                >
                  {t('multiUpload')}
                </Buttons.IconButton>
              </ImageUploader>
            </Layouts.Row>

            {/* 카드번호 */}
            <CardNumberContainerStyle>
              <CardNumberContainerButtonStyle>
                {cards?.map((card: InternalLinkCard, index: number) => (
                  <CardNumberButtonStyle
                    selected={card.seq === cards[selectedId]?.seq}
                    className="small-button"
                    onClick={() => {
                      handleClickChangeCard(index);
                    }}
                  >
                    {index + 1}
                  </CardNumberButtonStyle>
                ))}
              </CardNumberContainerButtonStyle>
            </CardNumberContainerStyle>

            {typeof cards !== 'undefined' && (
              <>
                <CardCanvasContainerStyle style={cards[selectedId]?.customObject?.backgroundStyle}>
                  {typeof cards[selectedId]?.thumbnail !== 'undefined' && (
                    <>
                      {cards[selectedId]?.thumbnail?.contentType?.indexOf('image/') === 0 ? (
                        <>
                          {typeof cards[selectedId]?.thumbnail?.publicUrl !== 'undefined' && (
                            <BackgroundImageStyle
                              style={{
                                backgroundImage: `url(${cards[selectedId]?.thumbnail?.publicUrl})`,
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <VideoEditor
                            maxHeight="100%"
                            mode="view"
                            video={cards[selectedId]?.thumbnail}
                            isCover={cards[selectedId].thumbnail?.sizeInfo?.isCover}
                          ></VideoEditor>
                          {/* <VideoComponent
                            containerSize={{ width: '100%', height: '100%' }}
                            // origin="card"
                            video={cards[selectedId]?.thumbnail}
                            // sizeInfo={cards[selectedId]?.thumbnail?.sizeInfo}
                            isCover={cards[selectedId].thumbnail?.sizeInfo?.isCover}
                          /> */}
                        </>
                      )}
                    </>
                  )}
                  <CardCanvasTextContainerStyle>
                    <CardCanvasTextContentStyle
                      style={{
                        ...cards[selectedId]?.customObject?.descriptionContents?.style,
                        whiteSpace: 'pre-line',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: cards[selectedId]?.customObject?.descriptionContents
                          ?.text as string,
                      }}
                    />
                  </CardCanvasTextContainerStyle>
                </CardCanvasContainerStyle>

                {/* 이미지 버튼 */}
                <ImageColorBackgroundStyle className="row">
                  <ImageUploader
                    cropSize={{ width: 1200, height: 1200 }}
                    attachType="media"
                    cropType={'square'}
                    multipleUpload={false}
                    onSelected={(thumbnails: any) => {
                      if (typeof thumbnails === 'undefined') {
                        return;
                      }
                      const newArr = [...cards];
                      newArr[selectedId].thumbnail = thumbnails[0];
                      cards[selectedId].customObject!.isModified = true;
                      setCards(newArr);
                    }}
                  >
                    <IconButton image="/image/background-icon.png" width={35} />
                  </ImageUploader>
                  <ColorPicker
                    className="absolute-vertical-center"
                    pickerStyle={{ width: 'auto', marginBottom: 65 }}
                    defaultValue={selectedCard?.customObject?.backgroundStyle?.backgroundColor}
                    onColorChanged={(color: string) => {
                      cards[selectedId].customObject!.backgroundStyle = {
                        ...cards[selectedId].customObject!.backgroundStyle,
                        backgroundColor: color,
                      };
                      cards[selectedId].customObject!.isModified = true;
                      setCards([...cards]);
                    }}
                  >
                    <IconButton image="/image/color-icon.png" width={35} />
                  </ColorPicker>
                  <IconButton
                    onClick={() => {
                      cards[selectedId].customObject!.backgroundStyle = {
                        ...cards[selectedId].customObject!.backgroundStyle,
                        backgroundColor: 'transparent',
                      };
                      cards[selectedId].thumbnail = undefined;
                      cards[selectedId].customObject!.isModified = true;
                      setCards([...cards]);
                    }}
                    width={35}
                  >
                    <CloseIcon />
                  </IconButton>
                  {cards[selectedId]?.thumbnail?.contentType?.indexOf('video/') === 0 && (
                    <div style={{ marginLeft: 10 }}>
                      <ToggleButton
                        label="Fit to screen"
                        checked={cards[selectedId].thumbnail?.sizeInfo?.isCover}
                        onToggleClick={(isChecked: boolean) => {
                          cards[selectedId].thumbnail!.sizeInfo = {
                            ...cards[selectedId].thumbnail?.sizeInfo,
                            isCover: isChecked,
                          };

                          setCards([...cards]);
                        }}
                      />
                    </div>
                  )}
                </ImageColorBackgroundStyle>

                {/* 카드 에디터 */}
                <CardEditorContainerStyle>
                  <TextToolBox
                    show
                    containerStyle={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      border: 'none',
                      borderTop: '1px solid #eee',
                      padding: 0,
                      margin: 0,
                    }}
                    style={selectedCard?.customObject?.descriptionContents?.style}
                    onChangeStyle={(style: CSSProperties) => {
                      cards[selectedId].customObject!.descriptionContents!.style = {
                        ...cards[selectedId].customObject!.descriptionContents!.style,
                        ...style,
                      };
                      cards[selectedId].customObject!.isModified = true;
                      setCards([...cards]);
                    }}
                  />
                  <ContentTextStyle
                    ref={textContents}
                    className="input"
                    contentEditable="true"
                    placeholder={t('content')}
                    onInput={(e) => {
                      cards[selectedId].customObject!.descriptionContents!.text =
                        e.currentTarget.innerText;
                      cards[selectedId].customObject!.isModified = true;
                      setCards([...cards]);
                    }}
                  />
                  <UrlComponent
                    required={false}
                    linkModel={selectedCard}
                    onChangeValue={(value: string) => {
                      cards[selectedId].linkUrl = value;
                      cards[selectedId].customObject!.isModified = true;
                      setCards([...cards]);
                    }}
                  />
                </CardEditorContainerStyle>
                <WhiteButton
                  type="SAVE"
                  disabled={!isFormValid.result}
                  onClick={handleSaveLink}
                  style={{
                    marginTop: 20,
                    width: '96%',
                    margin: '20px auto',
                  }}
                />
                {/* <PrimaryButtonStyle
                  disabled={!isFormValid.result}
                  style={{ marginTop: 20 }}
                  onClick={handleSaveLink}
                >
                  {t('pleaseSave')}
                </PrimaryButtonStyle> */}
              </>
            )}
          </CardContainerStyle>
          {/* </SectionBoxStyle> */}

          {/* <ImageSelectorModal
            isShow={showImageSelector}
            attachType="media"
            cropSize={{ width: 1200, height: 1200 }}
            onChangeThumbnails={(thumbnails: any) => {
              if (typeof thumbnails === 'undefined') {
                return;
              }
              const newArr = [...cards];
              newArr[selectedId].thumbnail = thumbnails[0];
              cards[selectedId].customObject!.isModified = true;
              setCards(newArr);
            }}
            onClose={() => {
              setShowImageSelector(false);
            }}
          /> */}
        </>
      )}
      {/* <PrimaryButtonStyle disabled={!isFormValid.result} onClick={handleSaveLink}>
        {t('pleaseSave')}
      </PrimaryButtonStyle> */}
    </>
  );
};

const LinkTitleContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

// const TitleShowStyle = styled.div`
//   width: 120px;
//   font-size: 12px;
//   margin: 24px 0 0 0;
//   color: #666666;
//   @media ${(props) => props.theme.media.mobile} {
//     margin: 24px 0 0 -30px;
//   }
// `;

// const ToggleInputStyle = styled.input`
//   &:checked + label {
//     background: #80e530;
//     > span {
//       transition: all 0.3s ease-in-out;
//       left: calc(100% - 1.8rem);
//       background: #fff;
//     }
//   }
// `;

// const ToggleLabelStyle = styled.label`
//   margin: 15px 20px 20px -20px;
//   width: 100px;
//   height: 32px;
//   display: block;
//   position: relative;
//   border-radius: 30px;
//   background-color: #dfdfdf;
//   @media ${(props) => props.theme.media.mobile} {
//     width: 100px;
//     margin: 15px 0 0 0;
//   }
//   cursor: pointer;
//   > span {
//     transition: all 0.3s ease-in-out;
//     width: 24px;
//     height: 24px;
//     position: absolute;
//     top: 50%;
//     left: 6px;
//     transform: translateY(-50%);
//     border-radius: 50%;
//     background: #fff;
//   }
// `;

// const ToggleTextStyle = styled.div<{ clickToggle?: boolean }>`
//   color: white;
//   position: relative;
//   top: 8px;
//   font-size: 14px;
//   left: ${({ clickToggle }) => (clickToggle ? '6px' : '33px')};
// `;

const defalutCardCustomProps: CustomContent = {
  titleContents: {
    text: undefined,
    style: {},
    use: false,
  },
  descriptionContents: {
    text: undefined,
    use: false,
    style: { fontSize: 16, color: '#000000' },
  },
  isModified: false,
};

const ToggleListButtonContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin: 10px 0 6px 0;
`;

const ToggleListTitle = styled.div`
  width: 100%;
  font-family: 'Raleway';
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

// const SectionBoxStyle = styled.button`
//   width: 100%;
//   border-radius: 5px;
//   background-color: #fdfdfd;
//   border: none;
// `;

const CardContainerStyle = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dddddd;
`;

const CardNumberContainerStyle = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0 0 0;
`;

const CardNumberContainerButtonStyle = styled.div`
  align-items: center;
  align-content: center;
  display: flex;
  margin: 0 0 10px 0;
`;

const CardNumberButtonStyle = styled.div<{ selected: boolean }>`
  display: inline-flex;
  justify-content: center;
  background: ${(props) => (props.selected ? '#3E64EA' : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  border: 1px solid #efefef;
  margin: 0 0 10px 0;
`;

const CardCanvasContainerStyle = styled.div`
  max-width: 350px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin: 20px auto 10px auto;
  border: 6px solid #c2c2c2;
  aspect-ratio: 1 / 1;
`;

const CardCanvasTextContainerStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

const CardCanvasTextContentStyle = styled.div`
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
`;

const BackgroundImageStyle = styled.img`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  // background-image: ${(props) => props.src};
`;

const ImageColorBackgroundStyle = styled.div`
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: auto;
  border-radius: 30px;
  padding: 0 10px 0 10px;
  margin-top: 10px;
`;

const CardEditorContainerStyle = styled.div`
  padding: 10px 10px 10px 10px;
`;

const ContentTextStyle = styled.div`
  min-height: 100px;
  border-radius: 10px;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 10px);
  outline: none;
`;

export default CardLinkEditor;
