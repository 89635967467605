import React, { useRef, useState, useEffect, CSSProperties } from 'react';
import {
  InternalLinkCard,
  CustomContent,
  defaultLinkEditorProps,
  CustomFile,
  CardLinkModel,
  ItemLinkModel,
  LinkItemModel,
} from 'types/CommonTypes';
import { ToggleListButton, LinkItemType } from 'components/commons/ToogleListButton';
import { Layouts, Buttons, TextToolBox, ImageUploader, Input } from 'components/commons';
import { updateLink, updateModel } from 'components/items/commonLogic';
import { isValid, cloneDeep } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from 'components/items';
import { toast } from 'react-toastify';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import InputComponent from 'components/items/InputComponent';
import UrlComponent from 'components/items/UrlComponent';
import WhiteButton from 'components/commons/WhiteButton';
import IconButton from 'resources/controls/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useForm from 'libs/hooks/useForm';
import styled from 'styled-components';
import ToggleButton from 'controls/toggleButton/ToggleButton';
import VideoEditor from 'features/adminFeatures/commons/components/VideoEditor';
import Editor from 'components/commons/webEditor/Editor';

const disPlayTypeList: Array<LinkItemType> = [
  {
    id: 'slide',
    title: 'Slide',
    image: '/image/slider-horizontal.png',
  },
  {
    id: 'grid',
    title: 'Grid',
    image: '/image/grid.png',
  },
];

const ItemLinkEditor = ({
  model,
  stores,
  onRegistComplete,
}: defaultLinkEditorProps<ItemLinkModel>) => {
  const { t } = useTranslation();
  const textContents = useRef<any>();
  const isFormValid = useForm(stores.formValidationStore);
  const [items, setItems] = useState<Array<LinkItemModel>>([]);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedItem, setSelectedItem] = useState<LinkItemModel>();
  const [linkModel, setLinkModel] = useState<ItemLinkModel>();
  const [renderType, setRenderType] = useState<'slide' | 'grid' | undefined>();
  // const inputRef = useRef<HTMLInputElement>(null);
  // const [clickToggle, setClickToggle] = useState<boolean>(false);

  const handleSaveLink = async () => {
    stores.uiStore.progress.show({});
    await preProcess(linkModel).then((response: any) => {});
    const result = await updateLink(linkModel!, isValid(model), { ...stores });
    stores.uiStore.progress.close();
    onRegistComplete(result!);
    if (result!) {
      toast(t('linkRegistration'));
    }
  };

  const preProcess = async (model: any) => {
    return Promise.all(
      await model?.items?.map(async (item: LinkItemModel) => {
        if (typeof item?.customObject !== 'undefined') {
          const newType = {
            customContent: item?.customObject,
            sizeInfo: item?.thumbnail?.sizeInfo,
          };
        }
        if (item?.thumbnail?.file?.constructor.name === 'Blob') {
          const data: any = await stores.fileStore.uploadImage(item?.thumbnail.file);
          item.thumbnail = { id: data.id };
        }
        return item;
      }),
    );
  };

  const handleClickAddCard = () => {
    setItems((prevState) => [
      ...prevState,
      {
        seq: items[items.length - 1].seq! + 1,
        customObject: cloneDeep(defalutItemCustomProps),
        usePayment: true,
        description: '',
      },
    ]);
  };

  const handleClickChangeCard = (index: number) => {
    setSelectedId(index);
    setSelectedItem({ ...items[index] });
    //appendValues(items[index]);
  };

  // const appendValues = (card: InternalLinkCard) => {
  //   if (typeof card?.customObject?.descriptionContents !== 'undefined') {
  //     textContents.current.textContent = card?.customObject?.descriptionContents?.text;
  //   } else {
  //     textContents.current.textContent = '';
  //   }
  // };

  const handleDeleteCard = () => {
    stores.uiStore.confirm.show({
      message: 'Are you sure you want to delete this item?',
      onConfirmed: () => {
        const newItems = items.filter((item, index) => {
          return index !== selectedId;
        });
        setItems(newItems);
        handleClickChangeCard(0);
      },
    });
  };

  // 멀티플 업로드 대응
  const handleBindMultipleImage = (images: Array<any>) => {
    let newItems = [...items];
    if (items.length === 1) {
      if (newItems[0].customObject?.isModified === false) {
        newItems = [];
      }
    }
    let _seq =
      items.length === 0
        ? 0
        : typeof items[items.length - 1] === 'undefined'
        ? 0
        : items[items.length - 1].seq!;
    images.map((image: any, index: number) => {
      const _file: any = image.file;
      _file.publicUrl = image.publicUrl;
      newItems.push({
        seq: ++_seq,
        thumbnail: image as CustomFile,
        customObject: cloneDeep(defalutItemCustomProps),
      });
      setItems([...newItems]);
    });
  };

  const init = async () => {
    /** description은 긴 내용으로 인해 기본 링크에는 포함되지 않으므로
     * 새롭게 아이템만 취득하여야 한다.
     */
    if (typeof stores.meStore?.me?.userName !== 'undefined') {
      const menuItem = (await stores.userStore.getLink(
        stores.meStore?.me?.userName,
        model.id!,
      )) as ItemLinkModel;
      if (typeof menuItem.items !== 'undefined') {
        menuItem.items.map((item: LinkItemModel) => {
          item.customObject = cloneDeep(defalutItemCustomProps);
          return item;
        });
        setItems(menuItem.items);
        setSelectedItem(menuItem.items[0]);
      }
    } else {
      alert('상점명이 바르지 않습니다.');
    }
  };

  // 부모로부터 전달받은 모델 할당
  useEffect(() => {
    init();
    const newModel: ItemLinkModel = { ...(model as ItemLinkModel) };
    if (Object.keys(newModel).length > 0) {
      if (typeof newModel.custom !== 'undefined') {
      }

      setLinkModel(newModel);
    } else {
      setItems([
        {
          seq: 0,
          customObject: cloneDeep(defalutItemCustomProps),
          usePayment: true,
          description: '',
        },
      ]);
      setRenderType('slide');
      setLinkModel({
        linkType: 'ItemLink',
        customObject: { renderType: 'slide', cardLinkType: 'cardItem' },
      });
    }
    setSelectedId(0);
  }, [model]);

  useEffect(() => {
    updateModel(setLinkModel, 'items', items);
  }, [items]);

  return (
    <>
      {typeof items !== 'undefined' && (
        <>
          <LinkTitleContainerStyle>
            <Input
              required
              placeholder="Item title"
              id="title"
              defaultValue={linkModel?.title}
              onChangeValue={(value: string) => {
                updateModel(setLinkModel, 'title', value);
              }}
            />
          </LinkTitleContainerStyle>
          {/* 
          <ToggleListButtonContainerStyle>
            <ToggleListTitle>{t('displayForm')}</ToggleListTitle>
            <ToggleListButton
              defaultChecked={renderType}
              toggleItems={disPlayTypeList}
              onClick={(item: LinkItemType) => {
                updateModel(setLinkModel, 'customObject', {
                  renderType: item.id,
                });
              }}
            />
          </ToggleListButtonContainerStyle> */}

          {/* 카드관련 버튼들 */}
          {/* <SectionBoxStyle> */}
          <CardContainerStyle>
            <Layouts.Row style={{ height: 70, borderBottom: '1px solid #eee' }}>
              <Buttons.IconButton
                containerStyle={{ background: '#636363' }}
                image="/images/gallery-add.svg"
                onClick={handleClickAddCard}
              >
                {t('addItem')}
              </Buttons.IconButton>
              <Buttons.IconButton
                containerStyle={{ background: '#636363' }}
                image="/images/gallery-remove.svg"
                onClick={handleDeleteCard}
                disabled={items.length === 1}
              >
                {t('deleteItem')}
              </Buttons.IconButton>
              <ImageUploader
                cropSize={{ width: 1000, height: 1000 }}
                cropType={0.8}
                attachType="media"
                multipleUpload
                onSelected={(thumbnails: any) => {
                  handleBindMultipleImage(thumbnails);
                }}
              >
                <Buttons.IconButton
                  containerStyle={{ background: '#636363' }}
                  image="/images/3square.svg"
                >
                  {t('multiUpload')}
                </Buttons.IconButton>
              </ImageUploader>
            </Layouts.Row>

            {/* 카드번호 */}
            <CardNumberContainerStyle>
              <CardNumberContainerButtonStyle>
                {items?.map((card: InternalLinkCard, index: number) => (
                  <CardNumberButtonStyle
                    selected={card.seq === items[selectedId]?.seq}
                    className="small-button"
                    onClick={() => {
                      handleClickChangeCard(index);
                    }}
                  >
                    {index + 1}
                  </CardNumberButtonStyle>
                ))}
              </CardNumberContainerButtonStyle>
            </CardNumberContainerStyle>

            {typeof items !== 'undefined' && (
              <>
                <CardCanvasContainerStyle style={items[selectedId]?.customObject?.backgroundStyle}>
                  {typeof items[selectedId]?.thumbnail !== 'undefined' && (
                    <>
                      {items[selectedId]?.thumbnail?.contentType?.indexOf('image/') === 0 ? (
                        <>
                          {typeof items[selectedId]?.thumbnail?.publicUrl !== 'undefined' && (
                            <BackgroundImageStyle
                              style={{
                                backgroundImage: `url(${items[selectedId]?.thumbnail?.publicUrl})`,
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <VideoEditor
                            maxHeight="100%"
                            mode="view"
                            video={items[selectedId]?.thumbnail}
                            isCover={items[selectedId].thumbnail?.sizeInfo?.isCover}
                          ></VideoEditor>
                          {/* <VideoComponent
                            containerSize={{ width: '100%', height: '100%' }}
                            // origin="card"
                            video={cards[selectedId]?.thumbnail}
                            // sizeInfo={cards[selectedId]?.thumbnail?.sizeInfo}
                            isCover={cards[selectedId].thumbnail?.sizeInfo?.isCover}
                          /> */}
                        </>
                      )}
                    </>
                  )}
                  <CardCanvasTextContainerStyle>
                    <CardCanvasTextContentStyle
                      style={{
                        ...items[selectedId]?.customObject?.descriptionContents?.style,
                        whiteSpace: 'pre-line',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: items[selectedId]?.customObject?.descriptionContents
                          ?.text as string,
                      }}
                    />
                  </CardCanvasTextContainerStyle>
                </CardCanvasContainerStyle>

                {/* 이미지 버튼 */}
                <ImageColorBackgroundStyle className="row">
                  <ImageUploader
                    cropSize={{ width: 1000, height: 1000 }}
                    attachType="media"
                    cropType={0.8}
                    multipleUpload={false}
                    onSelected={(thumbnails: any) => {
                      if (typeof thumbnails === 'undefined') {
                        return;
                      }
                      const newArr = [...items];
                      newArr[selectedId].thumbnail = thumbnails[0];
                      items[selectedId].customObject!.isModified = true;
                      setItems(newArr);
                    }}
                  >
                    <IconButton image="/image/background-icon.png" width={35} />
                  </ImageUploader>
                  <ColorPicker
                    className="absolute-vertical-center"
                    pickerStyle={{ width: 'auto', marginBottom: 65 }}
                    defaultValue={selectedItem?.customObject?.backgroundStyle?.backgroundColor}
                    onColorChanged={(color: string) => {
                      items[selectedId].customObject!.backgroundStyle = {
                        ...items[selectedId].customObject!.backgroundStyle,
                        backgroundColor: color,
                      };
                      items[selectedId].customObject!.isModified = true;
                      setItems([...items]);
                    }}
                  >
                    <IconButton image="/image/color-icon.png" width={35} />
                  </ColorPicker>
                  <IconButton
                    onClick={() => {
                      items[selectedId].customObject!.backgroundStyle = {
                        ...items[selectedId].customObject!.backgroundStyle,
                        backgroundColor: 'transparent',
                      };
                      items[selectedId].thumbnail = undefined;
                      items[selectedId].customObject!.isModified = true;
                      setItems([...items]);
                    }}
                    width={35}
                  >
                    <CloseIcon />
                  </IconButton>
                  {items[selectedId]?.thumbnail?.contentType?.indexOf('video/') === 0 && (
                    <div style={{ marginLeft: 10 }}>
                      <ToggleButton
                        label="Fit to screen"
                        checked={items[selectedId].thumbnail?.sizeInfo?.isCover}
                        onToggleClick={(isChecked: boolean) => {
                          items[selectedId].thumbnail!.sizeInfo = {
                            ...items[selectedId].thumbnail?.sizeInfo,
                            isCover: isChecked,
                          };

                          setItems([...items]);
                        }}
                      />
                    </div>
                  )}
                </ImageColorBackgroundStyle>

                {/* 카드 에디터 */}
                <CardEditorContainerStyle>
                  <Editor
                    defaultValue={selectedItem?.description}
                    onEditorChange={(contents: string) => {
                      items[selectedId].description = contents;
                      items[selectedId].customObject!.isModified = true;
                      setItems([...items]);
                    }}
                  />
                  <LinkTitleContainerStyle>
                    <Input
                      required
                      placeholder="Item name"
                      id="itemName"
                      defaultValue={items[selectedId]?.title}
                      onChangeValue={(value: string) => {
                        items[selectedId].title = value;
                        items[selectedId].customObject!.isModified = true;
                        setItems([...items]);
                      }}
                    />
                  </LinkTitleContainerStyle>
                  <LinkTitleContainerStyle>
                    <Input
                      required
                      pattern="\d*"
                      type="Number"
                      placeholder="Item price"
                      id="price"
                      // defaultValue={items[selectedId]?.price?.toString()}
                      defaultValue={items[selectedId]?.price}
                      onChangeValue={(value: string) => {
                        items[selectedId].price = Number(value);
                        items[selectedId].customObject!.isModified = true;
                        setItems([...items]);
                      }}
                    />
                  </LinkTitleContainerStyle>
                </CardEditorContainerStyle>
                <div style={{ marginTop: 20 }}>
                  <ToggleButton
                    checked={items[selectedId]?.usePayment}
                    label="Use payment"
                    onToggleClick={(isChecked: boolean) => {
                      items[selectedId].usePayment = isChecked;
                      setItems([...items]);
                    }}
                  />
                </div>
                <WhiteButton
                  type="SAVE"
                  disabled={!isFormValid.result}
                  onClick={handleSaveLink}
                  style={{
                    marginTop: 20,
                    width: '96%',
                    margin: '20px auto',
                  }}
                />
              </>
            )}
          </CardContainerStyle>
        </>
      )}
    </>
  );
};

const LinkTitleContainerStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const defalutItemCustomProps: CustomContent = {
  // titleContents: {
  //   text: undefined,
  //   style: {},
  //   use: false,
  // },
  // descriptionContents: {
  //   text: undefined,
  //   use: false,
  //   style: { fontSize: 16, color: '#000000' },
  // },

  isModified: false,
};

const ToggleListButtonContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin: 10px 0 6px 0;
`;

const ToggleListTitle = styled.div`
  width: 100%;
  font-family: 'Raleway';
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 14px;
  }
`;

// const SectionBoxStyle = styled.button`
//   width: 100%;
//   border-radius: 5px;
//   background-color: #fdfdfd;
//   border: none;
// `;

const CardContainerStyle = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #dddddd;
`;

const CardNumberContainerStyle = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  margin: 10px 0 0 0;
`;

const CardNumberContainerButtonStyle = styled.div`
  align-items: center;
  align-content: center;
  display: flex;
  margin: 0 0 10px 0;
`;

const CardNumberButtonStyle = styled.div<{ selected: boolean }>`
  display: inline-flex;
  justify-content: center;
  background: ${(props) => (props.selected ? '#3E64EA' : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : '#000')};
  border: 1px solid #efefef;
  margin: 0 0 10px 0;
`;

const CardCanvasContainerStyle = styled.div`
  max-width: 350px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin: 20px auto 10px auto;
  border: 6px solid #c2c2c2;
  aspect-ratio: 0.8 / 1;
`;

const CardCanvasTextContainerStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

const CardCanvasTextContentStyle = styled.div`
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
`;

const BackgroundImageStyle = styled.img`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  // background-image: ${(props) => props.src};
`;

const ImageColorBackgroundStyle = styled.div`
  justify-content: center;
  align-items: center;
  width: 100px;
  margin: auto;
  border-radius: 30px;
  padding: 0 10px 0 10px;
  margin-top: 10px;
`;

const CardEditorContainerStyle = styled.div`
  padding: 10px 10px 10px 10px;
`;

const ContentTextStyle = styled.div`
  min-height: 100px;
  border-radius: 10px;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 10px);
  outline: none;
`;

export default ItemLinkEditor;
