import React, { useEffect, useRef } from 'react';
import { useStore } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { GnbAdmin } from 'features/commonFeatures/navigationBar/containers';
import { useNavigate } from 'react-router-dom';

import Editor from 'features/adminFeatures/editor/containers/Editor';
import styled from 'styled-components';
import useScreenType from 'libs/hooks/useScreenType';
const AdminMainPage = observer(() => {
  const { uiStore } = useStore();
  const screenType = useScreenType();
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  /** drawer 출현시에 view를 drawer width 만큼 밀어내는 이벤트 효과 */
  useEffect(() => {
    const me = localStorage.getItem('me');
    if (me === null) {
      navigate('/login');
    }
    if (containerRef.current === null) return;

    if (uiStore.drawer.drawerShow) {
      switch (screenType) {
        case 'window':
          containerRef.current.style.width = 'calc(100% - 500px)';
          containerRef.current.style.marginRight = '500px';
          break;
        case 'mobile':
          containerRef.current.style.opacity = '0';
          break;
        default:
          break;
      }
    } else {
      switch (screenType) {
        case 'window':
          containerRef.current.style.width = '';
          containerRef.current.style.marginRight = '';
          break;
        case 'mobile':
          containerRef.current.style.opacity = '';
          break;
        default:
          break;
      }
    }
  }, [uiStore.drawer.drawerShow]);

  return (
    <>
      <div id="gnb_container">
        <GnbAdmin />
      </div>
      <ContainerStyle ref={containerRef}>
        <Editor />
      </ContainerStyle>
    </>
  );
});

const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 750px;
  background-image: url('/images/editorBackground/editor_background1.jpg');
  background-size: cover;
  filter: grayscale(20%);
  overflow: scroll;
  position: absolute;
  transition: all 0.5s ease-in-out;

  @media ${(props) => props.theme.media.mobile} {
    overflow: hidden;
  }
`;

export default AdminMainPage;
