import { CustomFile, LinkModel } from 'types/CommonTypes';

/**
 * @description 카드를 생성하거나 업데이트 한다
 * @param model model that will updated
 * @param isCreate true: create, false : update
 * @returns
 */
export const updateLink = async (model: LinkModel, isModify: boolean, stores: any) => {
  let returnMessage = '';
  try {
    if (model?.thumbnail?.file?.constructor.name === 'Blob') {
      const data: CustomFile = await stores.fileStore.uploadImage(model?.thumbnail?.file);
      model.thumbnail = { id: data.id };
    }

    if (typeof model.customObject !== 'undefined') {
      model.custom = JSON.stringify(model.customObject);
    }
    if (isModify) {
      // TODO: 예외처리 필요
      stores.linkStore.updateLink(model);
      // TODO: translate
      returnMessage = 'update completed';
    } else {
      stores.linkStore.createLink(model);
      returnMessage = 'create completed';
    }
    return returnMessage;
  } catch (e) {
    console.log(e);
  }
};

export const updateModel = (model: any, key: string, value: any) => {
  model((prevState: any) => {
    return {
      ...prevState,
      [key]: value,
    };
  });
};

/**
 * @description Input의 입력값이 정상적인 경우, http를 붙여서 반환한다.
 * @param value
 * @returns
 */
export const replaceStringToUrl = (value: string) => {
  const v = value.trim().indexOf('http');
  if (v === 0) {
    return value;
  }
  if (v === -1) {
    // 대부분의 웹사이트는 http로 접근하면 자동으로 https로 리다이렉트 한다.
    // https로 선언하지 않는 이유는 아직까지 https를 기본으로 하는 사이트가 존재하기 때문에 오류가 발생한다.
    return `http://${value.trim()}`;
  } else {
    return value;
  }
};
