/* eslint-disable no-param-reassign */
import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { CardLinkModel, InternalLinkCard, LinkItemModel, ItemLinkModel } from 'types/CommonTypes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStore } from 'stores/StoreHelper';
import { Pagination } from 'swiper';
import { useParams, useNavigate } from 'react-router-dom';
import VideoComponent from 'features/adminFeatures/commons/components/VideoComponent';
import CurrencyFormat from 'react-currency-format';
import useScreenType from 'libs/hooks/useScreenType';
import styled, { css } from 'styled-components';
import Modal from 'components/commons/UniversalModal/Modal';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import VideoEditor from 'features/adminFeatures/commons/components/VideoEditor';
import WhiteButton from 'components/commons/WhiteButton';
import CloseIcon from '@mui/icons-material/Close';
import { SingleButtonStyle } from 'resources/styles/common/button';
import QtyController from 'components/commons/QtyController/QtyController';
import TossPayment from 'containers/adminContainers/payments/TossPayment';

import 'swiper/css/pagination';
import 'swiper/css';

type defaultProps = {
  item: LinkItemModel;
  onClose: () => void;
  onOrderClick: () => void;
  onChangeQty: (qty: number) => void;
};

const ItemLinkDetailComponent = ({ item, onClose, onOrderClick, onChangeQty }: defaultProps) => {
  const [selectedCount, setSelectedCount] = useState<number>(1);

  useEffect(() => {
    onChangeQty(selectedCount);
  }, [selectedCount]);
  return (
    <>
      <ModalContainerStyle>
        <div
          style={{
            width: '100%',
            height: 30,
            position: 'relative',
            marginBottom: 20,
          }}
        >
          <SingleButtonStyle
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </SingleButtonStyle>
        </div>
        <div style={{ borderRadius: 5, margin: '0 15px', overflow: 'hidden' }}>
          <ImageStyle src={item?.thumbnail?.publicUrl} />
        </div>
        <div className="row" style={{ justifyContent: 'space-between' }}>
          <ModalMenuTitleStyle>{item?.title}</ModalMenuTitleStyle>
        </div>

        {typeof item?.description !== 'undefined' && (
          <ItemDescriptionStyle
            style={{ paddingBottom: 20, padding: '0 20px' }}
            dangerouslySetInnerHTML={{
              __html: `${item?.description}`,
            }}
          />
        )}
        {/* <div
          style={{
            fontSize: 20,
            fontWeight: 600,
            marginLeft: 20,
            marginTop: 10,
            justifyContent: 'space-between',
          }}
          className="row"
        >
          <CurrencyFormat
            value={item?.price}
            displayType={'text'}
            thousandSeparator
            suffix={'원'}
          />
        </div> */}
        <div
          className="row"
          style={{
            justifyContent: 'space-between',
            margin: 20,
            padding: 15,
            borderRadius: 5,
            alignItems: 'center',
            background: '#F3F3F3',
          }}
        >
          <div style={{ color: '#999', letterSpacing: -0.32 }}>수량</div>
          <div style={{ marginRight: 20 }}>
            <QtyController
              maxCount={5}
              onChangeValue={(value: number) => {
                setSelectedCount(value);
              }}
            />
          </div>
          <div>
            <CurrencyFormat
              style={{ fontSize: 30, fontWeight: 500 }}
              value={item.price! * selectedCount}
              displayType={'text'}
              thousandSeparator
            />
            <span> 원</span>
          </div>
        </div>
        <div style={{ marginLeft: 20, marginRight: 20 }} className="row">
          <WhiteButton
            style={{
              marginRight: 5,
              background: '#fff',
              border: '2px solid ##185DB6 !important',
            }}
            type="SAVE"
          >
            <span style={{ color: '#111' }}> 장바구니</span>
          </WhiteButton>
          <WhiteButton
            onClick={onOrderClick}
            style={{ background: '#2D2D2D', fontWeight: 500 }}
            type="SAVE"
          >
            <div>바로주문</div>
          </WhiteButton>
        </div>
      </ModalContainerStyle>
    </>
  );
};

const MenuTitleStyle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  padding: 0 25px;
  cursor: pointer;
  line-height: 90px;
`;

const ModalMenuTitleStyle = styled.div`
  font-size: 24px;
  font-weight: 500;
  padding: 0 20px;
  padding-top: 20px;
`;

const ModalContainerStyle = styled.div`
  padding: 20px 0;
`;

const ItemDescriptionStyle = styled.div`
  color: #666666;
  letter-spacing: -0.32px;
  > p > img {
    width: 100%;
  }
`;

const ImageStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export default ItemLinkDetailComponent;
