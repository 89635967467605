import React, { useState, useCallback, useRef, useEffect } from 'react';

import { ColorPicker } from 'components/items';
import { ModalHeader } from 'components/commons';
import { ImageSelectorCommonProps, CustomFile, SizeInfo, CropType } from 'types/CommonTypes';
import { useTranslation } from 'react-i18next';
import { cropImage, convertBase64Files } from './commonLogin';
import ImageEditor from 'features/adminFeatures/commons/components/ImageEditor';
import VideoEditor from 'features/adminFeatures/commons/components/VideoEditor';
import IconButton from 'resources/controls/IconButton';
import styled from 'styled-components';
import Modal from 'react-modal';
// import { useStore } from 'stores/StoreHelper';
/**
 * @description 특정 이미지를 크롭후 리사이즈 한다.
 * @param param0
 * @returns
 */
const ImageSelectorModal = ({
  isShow,
  cropSize,
  useCrop,
  useColor = false,
  multipleUpload,
  currentImage,
  cropType,
  attachType = 'image',
  origin,
  files,
  onColorChanged,
  onChangeThumbnails,
  onClose,
}: ImageSelectorCommonProps) => {
  const { t } = useTranslation();
  // const { uiStore } = useStore();
  /** @description 파일업로드용 ref */
  const onChangeFile = useRef<any>(null);

  const [modalShow, setModalShow] = useState<boolean>(false);

  /** @description 크롭윈도우 표시여부 */
  const [cropShow, setCropShow] = useState<boolean>(false);

  /** @description 크롭할 대상 사진(base64) */
  const [targetImage, setTargetImage] = useState<CustomFile>();

  /** @description 크롭관련 프로퍼티들 */
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [rotation, setRotation] = useState(0);
  const [multiple, setMultiple] = useState({});
  const [imageCropType, setCropType] = useState<CropType>();
  const videoSize = useRef<SizeInfo>();

  /**
   * @description 이미지 크롭핑 후 리사이즈(기본 200 * 200)
   */
  const handleClickApplyCrop = useCallback(async () => {
    if (typeof cropSize === 'undefined') return;
    try {
      // Blob file
      const croppedImage = (await cropImage(
        cropSize,
        targetImage,
        croppedAreaPixels,
        rotation,
      )) as CustomFile;

      onChangeThumbnails([croppedImage]);
      setModalShow(false);
      onClose();
      setCropShow(false);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const handleClickApplyVideo = () => {
    if (typeof targetImage !== 'undefined') {
      const newImage = { ...targetImage };
      newImage.sizeInfo = videoSize.current;
      onChangeThumbnails([newImage]);
    }
    setModalShow(false);
    onClose();
  };
  /**
   * @description 이미지 첨부버튼 클릭
   * @param e
   */
  const handleChangeImage = async (e: any) => {
    const images = (await convertBase64Files(e)) as Array<CustomFile>;
    if (multipleUpload === true) {
      onChangeThumbnails(images);
      setModalShow(false);
      onClose();

      return;
    }
    if (images.length === 1) {
      if (images[0].file?.type.indexOf('image/') === 0) {
        setTargetImage(images[0]);
        setCropShow(true);
      } else {
        onChangeThumbnails(images);
        setModalShow(false);
        onClose();
      }
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleOnsettingVideo = (setting: SizeInfo) => {
    videoSize.current = setting;
  };

  useEffect(() => {
    setModalShow(isShow);
  }, [isShow]);

  useEffect(() => {
    if (onChangeFile.current !== null) {
      onChangeFile.current.click();
    }
  }, [onChangeFile]);

  useEffect(() => {
    if (typeof cropType !== 'undefined') {
      setCropType(cropType);
    }
  }, [cropType]);

  useEffect(() => {
    typeof files !== 'undefined' && handleChangeImage(files);
  }, [files]);

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalShow}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          border: 'none',
          zIndex: 3010000,
        },
        content: {
          height: 500,
          padding: 0,
          background: '#fff',
          top: '50%',
          left: '50%',
          width: '80%',
          maxWidth: 600,
          border: 'none',
          transform: 'translate(-50%, -50%',
        },
      }}
    >
      {cropShow ? (
        <>
          <ModalHeader
            onClose={() => {
              setModalShow(false);
              onClose();
              setCropShow(false);
            }}
          >
            <div>{t('imageInsideRectangleDisplay')}</div>
          </ModalHeader>
          <div className="crop-container">
            {targetImage?.file?.type.indexOf('image/') === 0 ? (
              <ImageEditor
                image={targetImage}
                cropType={imageCropType}
                onCropComplete={(croppedArea: any, croppedAreaPixel: any) => {
                  onCropComplete(croppedArea, croppedAreaPixel);
                }}
              />
            ) : (
              <VideoEditor
                maxHeight="100%"
                video={targetImage}
                onSettingComplete={(setting: SizeInfo) => {
                  handleOnsettingVideo(setting);
                }}
              />
            )}
          </div>

          <div
            style={{
              position: 'absolute',
              bottom: 12,
              right: 0,
              left: 0,
              textAlign: 'center',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <div
              className="button default"
              style={{
                width: 180,
                marginRight: 10,
                backgroundColor: '#111',
              }}
              onClick={() => {
                // uiStore.progress.show();
                if (targetImage?.file?.type.indexOf('image/') === 0) {
                  handleClickApplyCrop();
                } else {
                  handleClickApplyVideo();
                }
              }}
            >
              {t('apply')}
            </div>
            <div
              className="button default"
              style={{
                width: 100,
                backgroundColor: '#111',
              }}
              onClick={() => {
                setModalShow(false);
                onClose();
                setCropShow(false);
              }}
            >
              {t('cancellation')}
            </div>
          </div>
        </>
      ) : (
        <>
          <ModalHeader
            onClose={() => {
              setModalShow(false);
              onClose();
            }}
          >
            <div>{t('pleaseSelectDesireImageFormat')}</div>
          </ModalHeader>
          <ContentsStyle>
            <IconButton
              onClick={(e: any) => {
                onChangeFile.current.click(e);
              }}
              containerStyle={containerStyle}
              image="/image/adminProfile/user-circle-add-icon.png"
            >
              <div> {t('imageUpload')}</div>
            </IconButton>
            {useColor === true && (
              <ColorPicker
                pickerStyle={{ top: 0, left: 0 }}
                onColorChanged={(color: string) => {
                  if (typeof onColorChanged !== 'undefined') {
                    onColorChanged(color);
                  }
                  // updateModel('backgroundColor', color);
                }}
              >
                <IconButton
                  // onClick={() => {
                  //   onChangeThumbnails([]);
                  //   setModalShow(false);
                  //   onClose();
                  // }}
                  containerStyle={containerStyle}
                  image="/image/adminProfile/brush-icon.png"
                >
                  <div>{t('colorSolid')}</div>
                </IconButton>
              </ColorPicker>
            )}
            {typeof currentImage !== 'undefined' && currentImage !== null && (
              <IconButton
                onClick={() => {
                  onChangeThumbnails([]);
                  setModalShow(false);
                  onClose();
                }}
                containerStyle={containerStyle}
                width={35}
                image="/image/close.png"
              >
                <div>{t('defaultImage')}</div>
              </IconButton>
            )}
            <input
              type="file"
              {...multiple}
              accept={attachType === 'image' ? '.jpg, .jpeg, .png, .gif' : '.mp4'}
              id="file"
              ref={(ref) => (onChangeFile.current = ref)}
              onChange={(e) => {
                handleChangeImage(e);
              }}
              style={{ display: 'none' }}
            />
            {/* </div> */}
          </ContentsStyle>
        </>
      )}
    </Modal>
  );
};

const containerStyle = {
  width: 160,
  height: 120,
  border: '1px solid #efefef',
  borderRadius: 10,
  marginBottom: 10,
};
const ContentsStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
`;
export default ImageSelectorModal;
