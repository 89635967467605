import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

const EmailPage = () => {
  const navigate = useNavigate();
  const handleClickBtn = () => {
    //회원가입한 사용자 페이지
    navigate('/');
  };

  return (
    <EmailContainer>
      <EmailBox>
        <img src="image/livelink.png" alt="" width={150} />
        <Text fontSize={'20px'} fontWeight={500} marginTop={'2em'}>
          안녕하세요
          <br />
          {process.env.REACT_APP_SERVEICE_NAME}입니다.
        </Text>
        <Text>
          하기 ‘인증’버튼 눌러 인증을 완료하시고
          <br />
          {process.env.REACT_APP_SERVEICE_NAME}에서 서비스를 만끽하세요
        </Text>
        <Btn onClick={handleClickBtn}>인증</Btn>
      </EmailBox>
    </EmailContainer>
  );
};

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmailContainer = styled.div`
  height: 100vh;
  ${flexCenter}
`;

const EmailBox = styled.div`
  padding: 3em 0;
  background: #f8f8f8;
  ${flexCenter}
  flex-direction: column;
  text-align: center;
  width: 90%;
  border-radius: 30px;
`;

const Text = styled.p<{ fontSize?: string; fontWeight?: number; marginTop?: string }>`
  font-size: ${(props) => props.fontSize || '16px'};
  font-weight: ${(props) => props.fontWeight || 400};
  margin-top: ${(props) => props.marginTop || '0'};
`;

const Btn = styled.button.attrs((props) => ({
  type: 'submit',
}))`
  margin-top: 1em;
  background: #3e64ea;
  border: none;
  border-radius: 50%;
  width: 5em;
  height: 5em;
  color: white;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
`;

export default EmailPage;
