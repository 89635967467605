import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'stores/StoreHelper';
import { useParams, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import BackgroundImageComponent from 'features/adminFeatures/template/components/BackgroundImageComponent';
import { validAll } from 'libs/helper/utils';
import styled from 'styled-components';
import WhiteButton from 'components/commons/WhiteButton';

const PaymentSuccessPage = observer(() => {
  const params = useParams();
  const { uiStore, paymentStore, userStore } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const init = async () => {
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get('orderId');
    const paymentKey = params.get('paymentKey');
    const amount = params.get('amount');
    const username = params.get('username');
    // karbot1660137055435
    const isValidAll = validAll([orderId, paymentKey, amount]);

    // const cancel = paymentStore.cancelOrder('karbot1660137055435');
    if (isValidAll) {
      const result = paymentStore.confirmOrder(
        paymentKey!,
        Number(orderId!),
        orderId!,
        Number(amount),
      );
      if (username !== null) {
        userStore.getUserInfo(username);
      }
      setIsSuccess(true);
      setTimeout(() => {
        uiStore.progress.close();
      }, 1000);
    }
  };

  useEffect(() => {
    // uiStore.progress.show();
    init();
  }, [params]);

  return (
    <>
      <>
        <BackgroundImageComponent profile={userStore?.userInfo?.profile} useEmpty={false} />
        {isSuccess && (
          <ContainerStyle className="global-width absolute-center">
            {typeof userStore.userInfo?.profile?.profileImage !== 'undefined' && (
              <>
                <ProfileImageStyle
                  src={userStore.userInfo?.profile?.profileImage.publicUrl}
                  width="200"
                  alt=""
                />
                <div style={{ fontSize: 24, fontWeight: 500, margin: '20px 0' }}>
                  {userStore.userInfo?.profile?.userName}
                </div>
                <div style={{ fontSize: 24, fontWeight: 300, margin: '20px 0', marginBottom: 30 }}>
                  주문 해주셔서 감사합니다.
                </div>

                <WhiteButton
                  onClick={() => {
                    navigate(`/${userStore.userInfo?.profile?.userName}`);
                    // navigate('/my/orderinfo');
                  }}
                  style={{ width: 340, marginTop: 100, margin: 'auto' }}
                >
                  {userStore.userInfo?.profile?.userName}홈으로
                </WhiteButton>
              </>
            )}
          </ContainerStyle>
        )}
      </>
    </>
  );
});

const ProfileImageStyle = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 80px;
`;

const ContainerStyle = styled.div`
  background: white;
  opacity: 0.9;
  padding: 40px;
  border-radius: 10px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
`;
export default PaymentSuccessPage;
