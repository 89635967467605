import React, { useEffect, useState } from 'react';
import { CustomType, CustomUserProfile } from 'types/CommonTypes';
import { isValid } from 'libs/helper/utils';
import { observer } from 'mobx-react';
import styled from 'styled-components';

type AvatarSizeProps = 'default' | 'small' | 'medium' | 'large';

type AvatarProps = {
  size?: AvatarSizeProps;
  profile?: CustomUserProfile;
  customStyle?: CustomType;
  containerHover?: boolean;
};

const Avatar = observer(
  ({ size = 'default', profile, customStyle, containerHover }: AvatarProps) => {
    const [currentSize, setCurrentSize] = useState<number>(100);

    useEffect(() => {
      switch (size) {
        case 'default':
          return setCurrentSize(100);
        case 'small':
          return setCurrentSize(80);
        case 'medium':
          return setCurrentSize(120);
        case 'large':
          return setCurrentSize(140);
        default:
          return setCurrentSize(100);
      }
    }, []);

    return (
      <>
        <AvatarContainerStyle customStyle={customStyle} containerHover={containerHover}>
          <AvatarLayoutStyle
            size={currentSize}
            useBorder={profile?.profileImage?.borderInfo?.useBorder}
            borderColor={profile?.profileImage?.borderInfo?.borderColor}
          >
            {isValid(profile?.profileImage?.publicUrl) && (
              <AvatarImageStyle src={`${profile?.profileImage?.publicUrl}`} alt="" />
            )}
          </AvatarLayoutStyle>
        </AvatarContainerStyle>
      </>
    );
  },
);

const AvatarContainerStyle = styled.div<{
  customStyle?: CustomType;
  containerHover?: boolean;
  isUserPage?: boolean;
  didScroll?: boolean;
}>`
  margin: 30px 0 0 0;
  ${(props) => props.customStyle?.avatarWrapper}
  > div {
    ${(props) => props.customStyle?.avatarContainer}
    ${(props) => (props.containerHover ? props.customStyle?.avatarContainerHover : '')}
    ${(props) => (props.isUserPage ? props.customStyle?.userAvatarContainer : '')}
    ${(props) => (props.didScroll ? props.customStyle?.avatarContainerHover : '')}
    > img {
      ${(props) => props.customStyle?.avatar}
      ${(props) => (props.containerHover ? props.customStyle?.avatarHover : '')}
      ${(props) => (props.isUserPage ? props.customStyle?.userAvatar : '')}
      ${(props) => (props.didScroll ? props.customStyle?.avatarHover : '')}
    }
  }
`;

const AvatarLayoutStyle = styled.div<{
  size: number;
  useBorder?: boolean;
  borderColor?: string;
}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  position: relative;
  left: 0;
  right: 0;
  border: ${(props) =>
    props.useBorder
      ? (props) => `2px solid ${props.borderColor || 'black'}`
      : '2px solid transparent'};
  border-radius: 50%;
  margin: 70px auto 0 auto;
  text-align: center;
  overflow: hidden;
  z-index: 10;
  @media screen and (max-width: 800px) {
    width: ${(props) => props.size - 20}px;
    height: ${(props) => props.size - 20}px;
  }
  @media screen and (max-width: 650px) {
    width: ${(props) => props.size - 40}px;
    height: ${(props) => props.size - 40}px;
  }
  @media screen and (max-width: 375px) {
    width: ${(props) => props.size - 60}px;
    height: ${(props) => props.size - 60}px;
  }
`;

const AvatarImageStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default Avatar;
